import {
	Box,
	Button,
	Grid,
	InputLabel,
	Select,
	styled,
	TextField,
	ToggleButtonGroup,
	ToggleButton,
	Paper,
} from '@mui/material'
import autoMergeLevel1 from 'redux-persist/es/stateReconciler/autoMergeLevel1'

export const StyledTabs = styled(ToggleButtonGroup)(({ theme }) => ({
	'&.Mui-selected': {
		background: theme.colors.gradient,
	},
	'& .MuiTabs-indicatorSpan': {
		background: theme.colors.gradient,
	},
	height: '15px',
	marginTop: '3px',
	marginBottom: '10px',
}))

export const StyledTab = styled(ToggleButton)(({ theme }) => ({
	'&.Mui-selected': {
		background: theme.colors.gradient,
		backgroundPosition: '20%',
		color: 'white',
	},
	'& .MuiTabs-indicatorSpan': {
		background: theme.colors.gradient,
	},
	border: theme.colors.gradient,
	borderRadius: '10px',
	color: 'black',
	aspectRatio: '4/1',
	fontWeight: 'bolder',
}))

export const UniqueCards = styled(Box)(({ theme }) => ({
	height: 'auto',
	minHeight: '40vh',
	// maxHeight: '70vh',
	width: '95%',
	padding: '20px',
	paddingBottom: '60px',
	display: 'flex',
	borderRadius: '15px',
	justifyContent: 'center',
	marginTop: '20px',
	boxShadow: theme.shadows[8],
}))

export const RulesCards = styled(Box)(({ theme }) => ({
	height: 'fit-content',
	minHeight: '40vh',
	// maxHeight: '70vh',
	width: '90%',
	padding: '20px',
	paddingBottom: '60px',
	display: 'flex',
	borderRadius: '15px',
	justifyContent: 'center',
	marginTop: '20px',
	boxShadow: theme.shadows[8],
}))

export const PlusTab = styled(Button)(({ theme }) => ({
	'& .MuiTabs-indicatorSpan': {
		background: theme.colors.green,
	},
	'& .MuiTabs-hover': {
		background: theme.colors.gradient,
	},
	'&.Mui-selected': {
		background: theme.colors.green,
		backgroundPosition: '20%',
		color: 'white',
	},
	height: '24px',
	marginLeft: '10px',
	background: theme.colors.green,
	color: 'white',
	borderRadius: '30px',
	fontWeight: 'bold',
	fontSize: '12px',
}))

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
	color: '#808080',
	top: '-5%',
	fontWeight: 'bolder',
	left: '2px',
	fontSize: '12px',
	width: 'auto',
	minHeight: '18px',
}))
export const StyledFormInputLabel = styled(InputLabel)(({ theme }) => ({
	color: '#808080',
	top: '0%',
	fontWeight: 'bolder',
}))
export const ButtonBar = styled(Box)(({ theme }) => ({
	position: 'relative',
	display: 'flex',
	width: '97%',
	gap: '60px',
	top: '40px',
	justifyContent: 'center',
	alignItems: 'center',
	paddingBottom: '60px',
	marginLeft: '-20px',
}))
export const StyledSelect = styled(Select)(({ theme }) => ({
	backgroundColor: 'rgba(238, 238, 238, 0.6)',
	borderRadius: theme.dimentions.smallerRadius,
	height: '40px',
	padding: '0px 0px 0px 8px',
	fontWeight: '500',
	margin: '0px',
	width: '100%',
	fontSize: '14px',
}))
export const StyledInputEvent = styled(TextField)(({ theme }) => ({
	borderRadius: theme.dimentions.smallerRadius,
	height: '90%',
	width: '80%',
	fontWeight: 'bold',
	marginTop: '2px',
	fontSize: '12px',
	background: theme.colors.base.white,
	color: theme.colors.text.titles,

	'& .MuiOutlinedInput-input': {
		height: '35px',
		paddingRight: 0,
		fontWeight: 'bold',
		fontSize: '12px',
		marginLeft: 24,
		borderRadius: theme.dimentions.smallerRadius,
		background: theme.colors.base.white,
		color: theme.colors.text.titles,
	},
	'&.MuiOutlinedInput-root': {
		borderRadius: theme.dimentions.smallerRadius,
		background: theme.colors.base.white,
		color: theme.colors.text.titles,
	},
}))
export const StyledSelectEvent = styled(Select)(({ theme }) => ({
	backgroundColor: 'rgba(238, 238, 238, 0.6)',
	borderRadius: theme.dimentions.smallerRadius,
	height: '30px',
	width: '100%',
	maxWidth: '95%',
	margin: '4px',
	fontSize: '12px',
	fontWeight: 'bold',
}))
export const StyledItem = styled(Paper)(({ theme }) => ({
	background: theme.colors.base.white,
	borderRadius: theme.dimentions.smallerRadius,
	height: '120px',
	width: '100px',
	margin: '4px',
	color: theme.colors.text.titles,
	'& .MuiAutocomplete-input': {
		color: theme.colors.text.titles,
	},
	'& .MuiInputBase-root .MuiOutlinedInput-root': {
		color: theme.colors.text.titles + ' !important',
	},
	'& .MuiOutlinedInput-input.Mui-disabled': {
		opacity: 1, // Ensure the opacity doesn't dim the color
		WebkitTextFillColor: theme.colors.base.grey600, // Override the webkit fill color
	},
}))
export const PlusButton = styled(Button)(({ theme }) => ({
	background: theme.colors.base.green200,
	borderRadius: theme.dimentions.biggerRadius,
	position: 'relative',
	height: '25px',
	width: '25px',
	minWidth: '10px',
	marginTop: '60px',
	marginLeft: '30px',
}))

export const EventWrapper = (props: {
	children: any
	eventCount?: number
	parentWidth?: number
	container: boolean
}) => {
	const Component = styled(Grid)(({ theme }) => ({
		// position: 'absolute',
		// maxWidth: '70%',
		// minWidth: '10px',
		// left: left,
		// borderRadius: theme.dimentions.biggerRadius,
		maxHeight: '100%',
		width: '100%',
		// marginTop: '19px',
		display: 'flex',
		justifyContent: 'flex-start',
		// marginLeft: '26px',
		// paddingBottom: '12px',
		// paddingTop: '30px',
	}))
	return <Component container={props.container}>{props.children}</Component>
}

export const StyledItemLabels = styled(Paper)(({ theme }) => ({
	backgroundColor: 'transparent',
	boxShadow: 'none',
	height: '120px',
	width: '140px',
	margin: '4px',
	zIndex: '-5',
	padding: '0px 4px',
}))

export const StyledInputLabelEvent = styled(InputLabel)(({ theme }) => ({
	color: theme.colors.base.grey600,
	top: '-15px',
	overflow: 'visible',
	fontWeight: theme.font.weight.normal,
	fontSize: theme.font.size.body,
	marginTop: '22px',
	backgroundColor: 'none',
}))
