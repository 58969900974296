import React, { useState } from 'react'
import { SalesPlanApp } from '../../models/model.interface'
import { TableWrapper } from '../components/reusableComponents'
import { GridColDef } from '@mui/x-data-grid'
import { Typography, useTheme } from '@mui/material'
import { TableComponent } from '../components/TableComponents'

interface Props {
	apps: SalesPlanApp[]
	setLoading: any
}

export const AppTable: React.FC<Props> = (props) => {
	const theme = useTheme()
	const [pageSize, setPageSize] = useState(15)
	const [rowCount, setRowCount] = useState(3)
	const [rowWidth, setRowWidth] = useState(450)

	const generateRowsWithIds = (data: SalesPlanApp[]) => {
		try {
			const rowsWithIds = data.map((item, index) => {
				return {
					id: index,
					...item,
				}
			})
			return rowsWithIds
		} catch (err) {
			return []
		}
	}
	const [tableRows, setTableRows] = useState(generateRowsWithIds(props.apps))

	const linkToAppHandler = (id: string) => {
		let url = ``
		if (id.startsWith('id')) {
			url = `https://apps.apple.com/us/app/${id}`
		} else {
			url = `https://play.google.com/store/apps/details?id=${id}`
		}
		window.open(url, '_blank')
	}

	const columns: GridColDef[] = [
		{
			field: 'app_name',
			headerName: 'App Name',
			width: rowWidth * 1.2,
			renderHeader: (row) => (
				<Typography
					sx={{ fontWeight: 600, fontSize: 16, paddingInlineStart: '90px' }}
				>
					App Name
				</Typography>
			),
			renderCell: ({ value }) => (
				<Typography
					sx={{ fontWeight: 500, fontSize: 14, paddingInlineStart: '90px' }}
				>
					{value}
				</Typography>
			),
		},
		{
			field: 'vertical',
			headerName: 'Vertical',
			width: rowWidth * 1.2,
			renderHeader: (row) => (
				<Typography
					sx={{ fontWeight: 600, fontSize: 16, paddingInlineStart: '120px' }}
				>
					Vertical
				</Typography>
			),
			renderCell: ({ value }) => (
				<Typography
					sx={{ fontWeight: 500, fontSize: 14, paddingInlineStart: '120px' }}
				>
					{value}
				</Typography>
			),
		},
		{
			field: 'app_id',
			headerName: 'App ID',
			width: rowWidth * 1.2,
			renderHeader: (row) => (
				<Typography sx={{ fontWeight: 600, fontSize: 16 }}>App ID</Typography>
			),
			renderCell: ({ value }) => (
				<Typography
					onClick={() => linkToAppHandler(value)}
					sx={{ fontWeight: 500, fontSize: 14, cursor: 'pointer' }}
				>
					{value}
				</Typography>
			),
		},
	]

	return (
		<div
			style={{
				marginBlockStart: '40px',
				width: '1200px',
				position: 'relative',
				height: '80vh',
				marginInlineStart: '-200px',
			}}
		>
			<TableWrapper
				theme={theme}
				rowCount={rowCount}
				setRowWidth={setRowWidth}
				// height='auto'
				style={{
					paddingBottom: '60px',
					paddingInlineStart: '56px',
					height: '100%',
					overflow: 'auto',
					position: 'relative',
					width: '100%',
				}}
			>
				<TableComponent
					setLoading={props.setLoading}
					rows={tableRows}
					setPageSize={setPageSize}
					pageSize={pageSize}
					rowsPerPageOptions={[15, 25, 50]}
					columns={columns}
					rowHeight={80}
					style={{
						width: '100%',
						height: '100%',
						position: 'relative',
					}}
					customSx={{
						'& .MuiDataGrid-columnHeaderTitle': {
							lineHeight: '18px',
							whiteSpace: 'pre-line',
						},
					}}
				/>
			</TableWrapper>
		</div>
	)
}
