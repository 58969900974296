import { NewsInterface } from '../../models/model.interface'
import { ActionType } from '../action-types/index'
import { Action } from '../actions'

export interface GetLogsInterface {
	logs: NewsInterface | any
}

const initialState: GetLogsInterface = {
	logs: [],
}

const reducer = (
	state: GetLogsInterface = initialState,
	action: Action,
): GetLogsInterface => {
	switch (action.type) {
		case ActionType.GET_LOGS: {
			return {
				...state,
				logs: action.payload,
			}
		}
		case ActionType.LOGOUT:
			return {
				...state,
				logs: [],
			}
		default: {
			return state
		}
	}
}

export default reducer
