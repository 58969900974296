import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { PlusButton } from '../campaigns/CampaignStyled'
import EmailElement from './EmailElement'
import { useParams } from 'react-router-dom'

const P360Component = (props: {
	emailGroup: any
	setEmailGroup: any
	app: any
	params: any
}) => {
	const { publisher, advertiser } = useTypedSelector((state) => state)
	const [allPublishersNames, setAllPublishersNames] = useState([])
	const [filteredAdvertiser, setFilteredAdvertiser] = useState([])
	const [reportSettings, setReportSettings] = useState<any>()
	const [newRule, setNewRule] = useState({
		id: uuidv4(),
		publisher: [],
		PID: [],
		email: [],
		report: '',
		timeframe: '',
		repeat: '',
		events: [],
	})

	useEffect(() => {
		if (publisher.publisher) {
			const arr = publisher.publisher.map((el: any) => el.publisher_name).sort()
			setAllPublishersNames(arr)
			// console.log(props.app, 'this is props.app')
			if (advertiser.advertiser && props.app) {
				const arr = advertiser.advertiser.filter(
					(el: any) => el.advertiser_name === props.app.advertiser_name,
				)
				setFilteredAdvertiser(arr[0])
			}
		}
		if (props.emailGroup && props.emailGroup.length > 1) {
			if (props.params.uuid) {
				const report = props.emailGroup.find(
					(rule: any) => rule.id === props.params.uuid,
				)
				setReportSettings(report)
			}
		} else if (props.emailGroup && props.emailGroup.length === 1) {
			setReportSettings(props.emailGroup[0])
		} else {
			setReportSettings(newRule)
			props.setEmailGroup([newRule])
		}
	}, [])
	return (
		<>
			{props.app && props.app.app_id && props.emailGroup && reportSettings && (
				<EmailElement
					allPublishers={publisher.publisher}
					allPublishersNames={allPublishersNames}
					selectedAdvertiser={filteredAdvertiser}
					reportSettings={props.params.uuid ? reportSettings : newRule}
					key={newRule.id}
					emailGroup={props.emailGroup}
					setEmailGroup={props.setEmailGroup}
					index={0}
					app_id={props.app.app_id}
				/>
			)}
		</>
	)
}

export default P360Component
