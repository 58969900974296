import React from 'react'
import StatusPagesCommonLayout from './StatusPagesCommonLayout'
import { useTheme, styled, keyframes } from '@mui/material'

const dotAnimation = keyframes`
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px);
  }
`

const DotsContainer = styled('div')(({ theme }) => ({
	display: 'inline-flex',
	alignItems: 'center',
	marginLeft: theme.spacing(1),
}))

const Dot = styled('div')(({ theme }) => ({
	width: 10,
	height: 10,
	borderRadius: '50%',
	backgroundColor: theme.colors.base.grey900,
	marginLeft: theme.spacing(0.5),
	animation: `${dotAnimation} 1.5s infinite ease-in-out`,
	'&:nth-of-type(1)': {
		animationDelay: '0s',
	},
	'&:nth-of-type(2)': {
		animationDelay: '0.3s',
	},
	'&:nth-of-type(3)': {
		animationDelay: '0.6s',
	},
}))

const Authorizing: React.FC = () => {
	const theme = useTheme()
	const isDarkMode = theme.palette.mode === 'dark'

	return (
		<StatusPagesCommonLayout>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<h1
					style={{
						color: isDarkMode ? theme.colors.white : theme.colors.black,
						fontSize: '46px',
						fontWeight: 600,
						fontFamily: 'Lato',
						margin: 0,
					}}
				>
					Authorizing
				</h1>
				<DotsContainer>
					<Dot />
					<Dot />
					<Dot />
				</DotsContainer>
			</div>
		</StatusPagesCommonLayout>
	)
}

export default Authorizing
