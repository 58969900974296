import {
	Tab,
	Tabs,
	ToggleButton,
	ToggleButtonGroup,
	styled,
	useTheme,
} from '@mui/material'
import React, { CSSProperties } from 'react'

export const TabContainer = ({
	tab,
	handleChangeTab,
	tabProps,
	options,
	tabStyle,
	mainStyle,
}: {
	tab: number
	handleChangeTab: any
	tabProps: any
	options: any
	tabStyle?: CSSProperties
	mainStyle?: CSSProperties
}) => {
	const theme = useTheme()
	return (
		<div style={{ width: 'auto', display: 'flex', ...mainStyle }}>
			<StyledTabs value={tab} aria-label='basic tabs example'>
				{options.map((el: any) => {
					return (
						<SingleTab
							tabProps={tabProps}
							tabPropsValue={el.tabIndex}
							label={el.label}
							onClick={handleChangeTab}
							tab={tab}
							style={{ ...tabStyle }}
							key={el.tabIndex}
						/>
					)
				})}
			</StyledTabs>
		</div>
	)
}

export const SingleTab = ({
	tab,
	tabProps,
	label,
	style,
	tabPropsValue,
	onClick,
}: {
	tab: number
	tabProps: any
	label: string
	style?: any
	tabPropsValue: number
	onClick: any
}) => {
	const theme = useTheme()
	return (
		<StyledTab
			style={{
				...style,
				borderLeft:
					tabPropsValue !== 0
						? '1px solid ' + theme.colors.chip.border
						: 'none',
			}}
			label={label}
			{...tabProps} // Spread the tabProps object here directly
			onClick={(e) => onClick(e, tabPropsValue)}
			selected={tab === tabPropsValue}
		/>
	)
}

export const StyledTabs = styled(Tabs)(() => {
	const theme = useTheme()
	return {
		border: '1px solid ' + theme.colors.chip.border,
		width: 'auto',
		borderRadius: '56px',
		height: '28px',
		color: theme.colors.text.general,
		display: 'flex',
		minHeight: '0px',
		backgroundColor: theme.colors.base.grey50,
		'& .MuiTabs-indicator': {
			backgroundColor: theme.colors.base.green200,
			transition: 'none',
		},
	}
})
export const StyledTab = styled(Tab)(() => {
	const theme = useTheme()
	return {
		height: '28px',
		minHeight: '0px',
		// borderLeft: '1px solid rgba(0,0,0,0.2)',
		textTransform: 'none',
		minWidth: '10vw',
		display: 'flex',
		'&.Mui-selected': {
			color: 'rgba(36, 45, 50, 1)',
			backgroundColor: theme.colors.base.green200,
		},
	}
})
