import {
	DialogActions,
	DialogContent,
	DialogContentText,
	Grid,
} from '@mui/material'
import { isValid } from 'date-fns'
import { StyledDialog, StyledNameField } from '../components/reusableComponents'
import { InputFieldText } from '../components/Inputs'
import { DropList } from '../components/SelectableInputs'
import { SubmitButton } from '../components/Buttons'

const GraphEditPopUp = (props: {
	popUpVisible: any
	setPopUpVisible: any
	object: any
	setTitle: any
	setMetric: any
	setValue: any
	setDepth: any
	setType: any
	optionsBreakdowns: any
	optionsStatistics: any
}) => {
	return (
		<StyledDialog open={props.popUpVisible}>
			<DialogContent>
				<DialogContentText>Make your own shit here!</DialogContentText>
			</DialogContent>
			<DialogActions>
				<InputFieldText
					// disabled={
					// 	// !(
					// 	// 	(props.duplicating || props.isAdding || !row) &&
					// 	// 	role === 'M'
					// 	// )
					// }
					label='App Name'
					type='text'
					value={props.object.title}
					onChange={props.setTitle}
					// visible={role === 'M'}
				/>
				<Grid container spacing={4} xs={12}>
					<Grid item xs={12}>
						<DropList
							onChange={props.setMetric}
							options={props.optionsBreakdowns}
							label={'Metric'}
							value={props.object.metric}
						/>
					</Grid>
					<Grid item xs={12}>
						<DropList
							onChange={props.setValue}
							options={props.optionsStatistics}
							label={'Value'}
							value={props.object.value}
						/>
					</Grid>
					<Grid item xs>
						<DropList
							onChange={props.setDepth}
							options={['1', '2', '3', '4', '5', '6']}
							label={'Amount'}
							value={props.object.depth}
						/>
					</Grid>
					<Grid item xs>
						<DropList
							onChange={props.setType}
							options={['pie', 'line', 'bar']}
							label={'Graph type'}
							value={props.object.type}
						/>
					</Grid>
				</Grid>

				<SubmitButton
					disabled={!isValid}
					style={{ margin: 'auto' }}
					onClick={() => {
						props.setPopUpVisible(false)
					}}
				>
					Done
				</SubmitButton>
			</DialogActions>
		</StyledDialog>
	)
}
export default GraphEditPopUp
