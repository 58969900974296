import { Container, Typography, useTheme } from '@mui/material'
import { el } from 'date-fns/locale'
import React, { useEffect, useState } from 'react'
import { PlusButton } from '../campaigns/CampaignStyled'
import TextPiece from './TextPiece'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

const NewsletterElementOnlyText = (props: {
	titles?: string[]
	setTitles?: any
	value: string
	id: string
	index: number
	mainObj: any
	editMode: boolean
	handleDeleteElement: any
	orderEditor: any
	length: number
}) => {
	const theme = useTheme()
	const handleAddTextElement = () => {
		const obj = {
			value: '',
			boldValue: '',
			parentId: props.id,
			id: Date.now().toString(),
		}
		props.setTitles((prev: any) => (prev.length > 0 ? [...prev, obj] : [obj]))
		props.mainObj.titles.push(obj)
	}
	const handleDeleteTextElement = (id: any) => {
		const arr = props.titles!.filter((el: any) => el.id !== id)
		props.mainObj.titles = props.mainObj.titles.filter(
			(el: any) => el.id !== id,
		)
		props.setTitles(arr)
	}

	return (
		<Container
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'flex-start',
				marginLeft: '46px',
				height: '181px',
				// width: props.editMode ? '200px' : '400px',
				width: '250px',
				gap: '2px',
				paddingLeft: '16px',
				alignSelf: 'flex-start',
				border: props.editMode ? '1px dashed rgba(234,29,67,0.4)' : 'none',
			}}
		>
			{props.editMode && (
				<>
					<PlusButton
						style={{
							background: theme.colors.base.red300,
							margin: '0px',
							left: '99%',
							top: '8px',
							marginTop: '-6px',
							width: '20px',
							height: '20px',
							paddingTop: '4px',
							marginBottom: '-16px',
						}}
						onClick={(e: any) => props.handleDeleteElement(props.id)}
					>
						x
					</PlusButton>
				</>
			)}
			{props.titles &&
				props.titles.map((el: any) => {
					if (props.id === el.parentId) {
						return (
							<React.Fragment key={el.id + 'fragment'}>
								<TextPiece
									value={el.value}
									editMode={props.editMode}
									boldValue={el.boldValue}
									key={el.id}
									id={el.id}
									titles={props.titles}
									handleDeleteTextElement={handleDeleteTextElement}
								/>
							</React.Fragment>
						)
					}
				})}

			{props.editMode && (
				<button
					style={{ opacity: '0.8', color: theme.colors.text.titles }}
					onClick={handleAddTextElement}
				>
					Add text
				</button>
			)}
			{props.editMode && (
				<div
					style={{
						display: 'flex',
						width: 'inherit',
						justifyContent: 'center',
						marginLeft: '-16px',
					}}
				>
					{props.index !== 0 ? (
						<button
							style={{ display: 'flex', alignItems: 'center' }}
							onClick={() => props.orderEditor(props.id, props.index - 1)}
						>
							<ArrowBackIcon></ArrowBackIcon>
						</button>
					) : (
						<div></div>
					)}
					{props.length !== props.index + 1 ? (
						<button
							style={{ display: 'flex', alignItems: 'center' }}
							onClick={() => props.orderEditor(props.id, props.index + 1)}
						>
							<ArrowForwardIcon></ArrowForwardIcon>
						</button>
					) : (
						<div></div>
					)}
				</div>
			)}
		</Container>
	)
}

export default NewsletterElementOnlyText
