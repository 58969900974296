import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { useState } from 'react'
import { TreeCheckboxContainer } from '../components/TreeCheckBox'
import { useTheme } from '@mui/material'

const FilterComponent = (props: {
	nodes: any
	label: string
	selectedElements: any
	setSelectedElements: any
	notExpandable?: boolean
	disabledBasedOnLength?: number
	elementsWidth?: string
	isCountry?: boolean
	countryData?: any
	setCountryData?: any
	setChangeToFilterDone?: any
	customInputLabel?: string
}) => {
	const theme = useTheme()
	const [expand, setExpand] = useState(false)
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'flex-start',
				border: '1px solid rgba(0,0,0,0.1)',
				paddingRight: '40px',
				cursor: !expand ? 'pointer' : 'default',
				padding: '10px 40px 10px 0px',
			}}
			onClick={() => (expand === false ? setExpand(true) : null)}
		>
			<div style={{ width: '80%' }}>
				<TreeCheckboxContainer
					nodes={props.nodes}
					label={props.label}
					selectedElements={props.selectedElements}
					setSelectedElements={props.setSelectedElements}
					notExpandable={props.notExpandable}
					disabledBasedOnLength={props.disabledBasedOnLength}
					elementsWidth={props.elementsWidth}
					isCountry={props.isCountry}
					countryData={props.countryData}
					setCountryData={props.setCountryData}
					setChangeToFilterDone={props.setChangeToFilterDone}
					expandFilter={expand}
					setExpandFilter={setExpand}
					customInputLabel={props.customInputLabel}
				/>
			</div>
			<span style={{ paddingTop: '10px' }}>
				{props.selectedElements.length > 0 ? (
					<CheckCircleOutlineIcon
						style={{ color: theme.colors.base.green300 }}
					/>
				) : (
					<WarningAmberIcon style={{ color: theme.colors.base.red300 }} />
				)}
			</span>
		</div>
	)
}
export const FilterComponentMinimalistic = (props: {
	nodes: any
	label: string
	selectedElements: any
	setSelectedElements: any
	notExpandable?: boolean
	disabledBasedOnLength?: number
	elementsWidth?: string
	isCountry?: boolean
	countryData?: any
	setCountryData?: any
	setChangeToFilterDone?: any
	customInputLabel?: string
}) => {
	const [expand, setExpand] = useState(false)
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'flex-start',
				border: '1px solid rgba(0,0,0,0.1)',
				borderRadius: '15px',
				paddingRight: '40px',
				marginInline: '10px',
				marginBottom: '10px',
				marginTop: '10px',
				cursor: !expand ? 'pointer' : 'default',
				padding: '10px 40px 10px 0px',
			}}
			onClick={() => (expand === false ? setExpand(true) : null)}
		>
			<div style={{ width: '80%' }}>
				<TreeCheckboxContainer
					nodes={props.nodes}
					label={props.label}
					selectedElements={props.selectedElements ?? []}
					setSelectedElements={props.setSelectedElements}
					notExpandable={props.notExpandable}
					disabledBasedOnLength={props.disabledBasedOnLength}
					elementsWidth={props.elementsWidth}
					isCountry={props.isCountry}
					countryData={props.countryData}
					setCountryData={props.setCountryData}
					setChangeToFilterDone={props.setChangeToFilterDone}
					expandFilter={expand}
					setExpandFilter={setExpand}
					customInputLabel={props.customInputLabel}
					dontGrayOut
				/>
			</div>
		</div>
	)
}
export default FilterComponent
