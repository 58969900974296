import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import SignUpDetailsAdvertiser from './SignUpDetailsAdvertiser'
import SignUpDetailsPublisher from './SignUpDetailsPublisher'
import { SubmitButton } from '../components/Buttons'
import { useTheme, Box } from '@mui/material'
import logoWhite from '../../assets/varys-logo-white.png'
import { useUserActions } from '../../hooks/useActions'
import SignUpFinished from './SignUpFinished'

const SignUpDetailsPage: React.FC = () => {
	const location = useLocation()
	const theme = useTheme()
	const navigate = useNavigate()
	const [errorMessage, setErrorMessage] = useState('')
	const [formData, setFormData] = useState<any>({})
	const [isFinished, setIsFinished] = useState(false)
	const [settingsData, setSettingsData] = useState({})

	const { signUpAction, getSignUpData } = useUserActions()

	// Extracting the query params from the URL
	const searchParams = new URLSearchParams(location.search)
	const token = searchParams.get('token')

	const data = atob(token!)
	const values = JSON.parse(data)
	const { clientType } = values

	const isValid = () => {
		if (clientType.toLowerCase() === 'advertiser') {
			return (
				!!formData.legalEntity &&
				!!formData.companyAddress &&
				!!formData.mmp &&
				!!formData.appWebCampaign
			)
		}
		if (clientType.toLowerCase() === 'publisher') {
			return (
				!!formData.legalEntity &&
				!!formData.companyAddress &&
				!!formData.appWebCampaign &&
				!!formData.selfServiceAccount &&
				!!formData.cityTargetedCampaigns
			)
		}
		return false
	}

	useEffect(() => {
		const getSettings = async () => {
			const settings = await getSignUpData()
			console.log(settings)
			setSettingsData(settings)
		}
		getSettings()
		// get settings data to display as options
	}, [])

	const updateFormData = (data: any) => {
		setFormData(data)
	}

	const handleSubmit = async () => {
		const dataToSave = Object.assign(values, formData)
		const res = await signUpAction(dataToSave, setIsFinished)
	}

	// Determine which component to render based on company type
	const renderSignUpComponent = () => {
		switch (clientType.toLowerCase()) {
			case 'advertiser':
				return (
					<SignUpDetailsAdvertiser
						values={values}
						updateFormData={updateFormData}
						settings={settingsData}
					/>
				)
			case 'publisher':
				return (
					<SignUpDetailsPublisher
						values={values}
						updateFormData={updateFormData}
						settings={settingsData}
					/>
				)
			default:
				return <div>Error processing your request, please try again...</div>
		}
	}

	return (
		<>
			<button
				style={{
					display: 'flex',
					position: 'fixed',
					top: '5.5%',
					left: '2%',
					border: 'none',
					backgroundColor: 'transparent',
					cursor: 'pointer',
					zIndex: 25,
				}}
				onClick={() => navigate('/')}
			>
				Go Back
			</button>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					width: '100vw',
					background: theme.colors.gradientMenu,
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'flex-end',
						height: '13vh',
						width: '100%',
						padding: '0 2rem',
					}}
				>
					<img src={logoWhite} alt='logo' style={{ height: '80%' }} />
				</div>
			</Box>
			<div style={{ margin: 'auto', padding: '20px' }}>
				{/* Conditionally render SignUpFinished or the SignUp details component */}
				{isFinished ? (
					<SignUpFinished
						setCurrentView={() => {
							navigate('/')
							return null
						}}
					/>
				) : (
					<>
						{renderSignUpComponent()}
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								paddingTop: '20px',
							}}
						>
							<SubmitButton
								disabled={!isValid()}
								onClick={handleSubmit}
								style={{
									width: '20%',
									borderRadius: '4px',
									margin: 0,
									textTransform: 'none',
									fontSize: theme.font.size.body,
									color: theme.colors.text.titles,
								}}
								sx={{
									'&.Mui-disabled': {
										background: theme.colors.base.grey100,
									},
									'&:hover': {
										background: theme.colors.base.green20,
										border: '2px solid ' + theme.colors.base.green200,
										color: theme.colors.base.grey900,
									},
									'&.Mui-focused': {
										background: theme.colors.base.green100,
									},
								}}
							>
								Get Started
							</SubmitButton>
						</div>
					</>
				)}
			</div>
		</>
	)
}

export default SignUpDetailsPage
