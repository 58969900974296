import { MenuItem, Paper, useTheme } from '@mui/material'
import { useRef, useState } from 'react'
import { StyledAutocomplete, StyledTextInputField } from './Inputs'
import { UnborderedButton } from './Buttons'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

export const StaticChipInputList = (props: {
	onChange?: any
	label?: string
	options?: any[]
	hint?: string
	hintmargin?: string
	disabled?: boolean
	value?: string[]
	float?: 'left' | 'right'
	fullWidth?: boolean
	multiple?: boolean
	singular?: boolean
	style?: React.CSSProperties
	onBlur?: any
	seeAll?: boolean
	loginRole?: string
	openWithClick?: boolean
	setMainHeightChange?: any
	onlyOneOptionAllowed?: boolean
	ShowSelected?: boolean
	onlyOptionsAllowed?: boolean
	placeholder?: string
}) => {
	const theme = useTheme()
	const ref = useRef()
	const [open, setOpen] = useState(false)

	let gotCurrentHeight = false
	const getCurrentHeight = () => {
		const { current }: any = ref
		if (current?.clientHeight) {
			if (!gotCurrentHeight) {
				requestAnimationFrame(getCurrentHeight)
			}
			gotCurrentHeight = true
			//2.0 is hardcoded. It's the number of max px/ this element px that looks optimal to messure if the main buttons need to move.
			if (2.0 > window.innerHeight / current.clientHeight) {
				props.setMainHeightChange !== undefined &&
					props.setMainHeightChange(true)
			}
		}
	}
	getCurrentHeight()
	const StyleForSelectedOption = (value: string, list: string[]) => {
		if (list.indexOf(value) !== -1) {
			return {
				fontWeight: '900',
				backgroundColor: 'rgba(238,238,238,0.7)',
				width: '100%',
			}
		}
	}
	const selectedOptions: string[] = []
	const notSelectedOptions: string[] = []
	props.options!.forEach((el) => {
		if (props.value?.includes(el)) {
			selectedOptions.push(el)
		} else {
			notSelectedOptions.push(el)
		}
	})
	const orderedOptions = [...selectedOptions, ...notSelectedOptions]
	props.seeAll && orderedOptions.push('All')

	const renderOptionsWhenOpening = (propsd: any, option: any) => {
		// if (props.seeAll && option === 'All') {
		// 	return null
		// }
		return (
			<div {...propsd} key={propsd.id}>
				<MenuItem
					key={option}
					value={option}
					style={StyleForSelectedOption(option, props.value ? props.value : [])}
				>
					{option}
				</MenuItem>
			</div>
		)
	}
	const renderInputField = (params: any) => {
		return (
			<>
				<StyledTextInputField
					{...params}
					focused
					label={props.label}
					margin='dense'
					fullWidth
					key={params + 'key-input'}
					variant='outlined'
					style={{
						border: 0,
						clip: 'rect(0 0 0 0)',
						height: '1px',
						margin: '-1px',
						overflow: 'hidden',
						padding: 0,
						position: 'absolute',
						width: '130px',
						whiteSpace: 'nowrap',
						float: props.float,
					}}
					InputProps={{
						...params.InputProps,
						style: {
							paddingLeft: '10px',
						},
					}}
					InputLabelProps={{
						style: { top: '-10px' },
					}}
					onClick={() => {
						if (props.openWithClick && !props.disabled) {
							setOpen(!open)
						}
					}}
					placeholder={props.placeholder}
				/>
				<UnborderedButton
					style={{
						minWidth: '100px',
						color: theme.colors.text.titles,
						fontSize: theme.font.size.caption,
					}}
					onClick={() => {
						if (props.openWithClick && !props.disabled) {
							setOpen(!open)
						}
					}}
					icon={
						open ? (
							<KeyboardArrowUpIcon style={{ scale: '1', color: 'inherit' }} />
						) : (
							<KeyboardArrowDownIcon style={{ scale: '1', color: 'inherit' }} />
						)
					}
				>
					<span>{props.placeholder}</span>{' '}
				</UnborderedButton>
				<button
					style={{
						border: 'none',
						borderRadius: '60px',
						height: '24px',
						width: '24px',
						fontSize: '12px',
						display: 'flex',
						alignItems: 'center',
						marginLeft: -8,
						background: theme.colors.base.grey250,
						cursor: props.value?.length === 0 ? 'not-allowed' : 'pointer',
					}}
					onClick={() => {
						props.onChange([])
					}}
					disabled={props.value && props.value.length === 0}
				>
					<FilterAltOffIcon
						fontSize={'inherit'}
						style={{
							color:
								props.value?.length === 0
									? theme.colors.base.grey300
									: theme.colors.base.green200,
						}}
					/>
				</button>
			</>
		)
	}
	function compareArrays(array1: string[], array2: string[]) {
		for (let i = 0; i < array1.length; i++) {
			if (!array2.includes(array1[i])) {
				return false
			}
		}
		return true
	}

	return (
		<StyledAutocomplete
			ref={props.setMainHeightChange !== undefined ? ref : undefined}
			multiple={props.multiple ? false : true}
			freeSolo={!props.singular}
			disabled={props.disabled}
			open={
				props.seeAll && props.value!.length === props.options!.length
					? false
					: open
			}
			options={orderedOptions}
			style={props.style}
			id='tags-filled'
			value={
				props.seeAll
					? props.value &&
					  props.value.length > 0 &&
					  props.value!.length === props.options!.length
						? ['All']
						: props.value || []
					: props.value && props.value.length === 1 && props.value[0] === ''
					? []
					: props.value
			}
			PaperComponent={({ children, ...paperProps }) => (
				<Paper
					{...paperProps}
					sx={{
						border: `1px solid ${theme.colors.base.grey300}`,
						padding: 1,
						background: theme.colors.base.white,
						color: theme.colors.text.titles,
					}}
				>
					{children}
				</Paper>
			)}
			onChange={(a: any, n: any) => {
				if (props.onlyOneOptionAllowed) {
					n.reverse()
					n[0] ? props.onChange([n[0]]) : props.onChange([])
				}
				if (props.onlyOptionsAllowed) {
					if (compareArrays(n, props.options as string[])) {
						props.onChange(n)
					} else return null
				} else props.onChange(n)
			}}
			isOptionEqualToValue={(option: any, value: any) => {
				return option === value ? true : false
			}}
			onBlur={() => {
				setOpen(false)
				props.setMainHeightChange !== undefined &&
					props.setMainHeightChange(false)
				return props.onBlur
			}}
			renderOption={renderOptionsWhenOpening}
			renderTags={() => <></>}
			renderInput={renderInputField}
		/>
	)
}
