// Container.tsx
import { styled } from '@mui/material/styles'
import { Box, Grid, GridProps } from '@mui/material'

type CustomGridProps = GridProps & {
	customStyle?: React.CSSProperties
}

export const GridContainer = styled(Grid, {
	shouldForwardProp: (prop) => prop !== 'customStyle',
})<CustomGridProps>(({ customStyle }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-start',
	padding: '5px',
	width: '100%',
	...customStyle,
}))

export const UniqueCards = styled(Box)(({ style }) => ({
	height: 'auto',
	minHeight: '40vh',
	display: 'flex',
	justifyContent: 'center',
	...style,
}))
