import {
	DialogActions,
	DialogContent,
	DialogContentText,
	useTheme,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useActions, useLogActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import {
	GetPasswordsInterface,
	UserInterface,
} from '../../models/model.interface'
import {
	checkInputStartsWithHttp,
	shallowEqual,
} from '../../utils/helpers/tableHelper'
import { StyledDialog } from '../components/reusableComponents'
import _ from 'lodash'
import { InputFieldText } from '../components/Inputs'
import { DropList, OwnerSelect } from '../components/SelectableInputs'
import { ChipInputList } from '../components/ChipInput'
import { SubmitButton, UnborderedButton } from '../components/Buttons'

const PasswordsEdit = (props: {
	PopUpVisible: boolean
	setPopUpVisible: any
	row?: GetPasswordsInterface
	setLoading: any
	errorMessage: null | string
	setErrorMessage: any
	isErrorPromptOpened: boolean
	setIsErrorPromptOpened: any
	advertisers: any
	setIsSuccessPromptOpened: any
	isSuccessPromptOpened: any
	blockPasswords: any
	isAdding: any
	setIsAdding: any
	setRow: any
	ruleSet: any
}): JSX.Element => {
	const theme = useTheme()
	const { updatePasswordsAction } = useActions()
	const { insertLog } = useLogActions()
	const { login, users } = useTypedSelector((state) => state)
	const [prevState, setPrevState] = useState<any>(null)
	const [username, setUsername] = useState(
		!!props.row ? props.row.user_name || '' : '',
	)
	const id = !!props.row ? props.row.id || '' : ''
	const [notes, setNotes] = useState(!!props.row ? props.row.notes || '' : '')
	const [type, setType] = useState(!!props.row ? props.row.type || '' : '')
	const [internal, setInternal] = useState(
		!!props.row ? props.row.internal_poc || '' : '',
	)
	const [url, setUrl] = useState(!!props.row ? props.row.url || '' : '')
	const [name, setName] = useState(!!props.row ? props.row.name || '' : '')
	const [password, setPassword] = useState(
		!!props.row ? props.row.password || '' : '',
	)
	const [access, setAccess] = useState(
		!!props.row ? props.row.access || '' : '',
	)
	const [contact, setContact] = useState(
		!!props.row ? props.row.contact_person || '' : '',
	)
	const [email, setEmail] = useState(
		!!props.row ? props.row.contact_email || '' : '',
	)
	const [skype, setSkype] = useState(
		!!props.row ? props.row.contact_skype || '' : '',
	)
	const [whitelist, setWhitelist] = useState(
		!!props.row ? props.row.whitelist || [] : [login.user.name],
	)
	const [createdBy, setCreatedBy] = useState(
		!!props.row ? props.row.created_by || '' : '',
	)
	// const usersList = users.users.map((el: any) => el.email)
	const [isValid, setIsValid] = useState(false)
	const createPayload = () => {
		return {
			id: id,
			name: name,
			internal_poc: internal,
			url: url,
			user_name: username,
			password: password,
			access: access,
			type: type,
			contact_person: contact,
			contact_email: email,
			contact_skype: skype,
			notes: notes,
			user_login: login.user.email,
			whitelist: whitelist,
			created_by: createdBy === '' ? login.user.email : createdBy,
		}
	}
	const preparePrevState = (prevState: any) => {
		const { ...result } = prevState
		return result
	}
	const prepareNewState = (newState: any) => {
		const { ...result } = newState
		return result
	}
	const handleLog = async (payload: any) => {
		// console.log('prev', prevState, payload)
		const shallow = shallowEqual(prevState, prepareNewState(payload))
		if (shallow.length > 0) {
			const log = {
				email: login.user.email,
				object: name! + ' in Passwords',
				change: JSON.stringify(shallow),
			}
			await insertLog(log)
		}
	}

	const nullifyFields = () => {
		setUsername('')
		setNotes('')
		setType('')
		setInternal('')
		setUrl('')
		setName('')
		setPassword('')
		setAccess('')
		setContact('')
		setEmail('')
		setSkype('')
		setWhitelist([])
		setCreatedBy('')
		props.setRow(undefined)
		setPrevState(null)
	}

	useEffect(() => {
		if (props.row) {
			const obj = _.cloneDeep(createPayload())
			const prev = preparePrevState(obj)
			if (!prevState) {
				setPrevState({ ...prev })
			}
		}
		if (whitelist && whitelist.length > 0) {
			// Filter out blocked users
			const filteredWhitelist = whitelist.filter((username) => {
				const user = users.users.find((u: any) => u.name === username)
				// Keep the user if they exist and are not blocked
				return user && !user.blocked
			})
			setWhitelist(filteredWhitelist)
		}
	}, [props.row])

	const trySubmit = async () => {
		// const headers = {
		// 	Authorization: `Token ${login.user.token}`,
		// }
		//TODO: deal with ruleset
		const payload = { dto: createPayload(), rule_set: {} }
		// const payload = { dto: createPayload(), rule_set: props.ruleSet }
		props.setLoading(true)
		const failed = await updatePasswordsAction(payload, props.setErrorMessage)
		props.setIsAdding(false)
		props.setLoading(false)
		if (!failed) {
			props.setPopUpVisible(false)
			if (props.row) {
				props.setIsSuccessPromptOpened(true)
			}
		}
		handleLog(payload.dto)
	}
	return (
		<StyledDialog
			open={props.PopUpVisible}
			style={{ zIndex: '20' }}
			sx={{ width: 900, maxWidth: 900, mx: 'auto' }}
		>
			<DialogContent
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '12px',
					padding: '40px 60px 60px 60px',
					backgroundColor: theme.colors.base.white,
				}}
			>
				<InputFieldText
					label='Name'
					type='text'
					value={name}
					onChange={setName}
				/>
				{createdBy && login.user.role.toLowerCase() === 'super' && (
					<InputFieldText
						label='Created by'
						type='text'
						value={createdBy}
						disabled={true}
					/>
				)}
				<OwnerSelect
					label='Owner'
					value={internal}
					float='left'
					// options={usersList}
					onChange={(e: any) => setInternal(e)}
				/>
				<InputFieldText
					label='Url'
					type='text'
					value={url}
					onChange={setUrl}
					color={
						url && !checkInputStartsWithHttp(url) && isValid === false
							? 'error'
							: 'primary'
					}
				/>
				<InputFieldText
					label='Username'
					type='text'
					value={username}
					onChange={setUsername}
				/>
				<InputFieldText
					label='Password'
					type='text'
					value={
						(!props.isAdding &&
							!props.blockPasswords &&
							props.row?.whitelist.includes(login.user.name)) ||
						props.isAdding ||
						(!props.blockPasswords && props.row?.access === 'Open')
							? password
							: '******'
					}
					onChange={setPassword}
				/>
				<DropList
					label='Access'
					value={access}
					float='left'
					options={['Open', 'Limited']}
					onChange={(e: any) => setAccess(e)}
				/>
				<DropList
					label='Type'
					value={type}
					float='right'
					options={['MMP', 'Advertiser System', 'Publisher System', 'Other']}
					onChange={(e: any) => setType(e)}
				/>
				<ChipInputList
					disabled={access !== 'Limited'}
					label='Users'
					value={whitelist}
					options={users.users
						.filter((u: UserInterface) => {
							return !u.blocked
						})
						.map((u: UserInterface) => u.name)
						.sort()}
					onChange={setWhitelist}
					openWithClick={true}
					onlyOptionsAllowed={true}
					// noMarginTop
				/>

				<InputFieldText
					label='Contact'
					type='text'
					value={contact}
					onChange={setContact}
				/>
				<InputFieldText
					label='Email'
					type='text'
					value={email}
					onChange={setEmail}
				/>
				<InputFieldText
					label='Skype'
					type='text'
					value={skype}
					onChange={setSkype}
				/>
				<InputFieldText
					label='Notes'
					type='text'
					value={notes}
					onChange={setNotes}
				/>
			</DialogContent>
			<DialogActions style={{ backgroundColor: theme.colors.base.white }}>
				<UnborderedButton
					style={{ margin: 'auto', height: 120 }}
					onClick={() => {
						props.setPopUpVisible(false)
						nullifyFields()
					}}
					label={'Cancel'}
				></UnborderedButton>
				{(!props.row ||
					props.row?.created_by === login.user.email ||
					login.user.role.toLowerCase() === 'super') && (
					<SubmitButton
						//disabled={!isValid}
						style={{ margin: 'auto' }}
						onClick={() => {
							trySubmit()
							props.setPopUpVisible(false)
							nullifyFields()
						}}
					>
						{props.row ? 'Update' : 'Save'}
					</SubmitButton>
				)}
			</DialogActions>
		</StyledDialog>
	)
}
export default PasswordsEdit
