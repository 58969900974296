import { Box, Grid, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ThemeProvider } from '@emotion/react'
import { ChipInputList, PageWrapper } from '../components/reusableComponents'
import FiltersContainer from './FiltersContainer'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { useNavigate } from 'react-router'
import ContentContainer from './ContentContainer'
import { TitleComponent } from '../components/Informative'

function MediaPlanPage() {
	const theme = useTheme()
	const { settings, publisher } = useTypedSelector((state) => state)
	const navigate = useNavigate()

	const countryData = [
		{ value: 'global', payout: '', volume: '' },
		{ value: 'Antartica', payout: '', volume: '' },
		{ value: 'Africa', payout: '', volume: '' },
		{ value: 'Asia', payout: '', volume: '' },
		{ value: 'Europe', payout: '', volume: '' },
		{ value: 'North America', payout: '', volume: '' },
		{ value: 'Oceania', payout: '', volume: '' },
		{ value: 'South America', payout: '', volume: '' },
	]
	const allPublishersNamesAndIds = publisher.publisher
		.filter((pub: any) => pub.status === true)
		.map((pub: any) => {
			return { id: pub.id, name: pub.publisher_name, score: 100 }
		})

	const [publisherList, setPublisherList] = useState(
		publisher.publisher.filter((pub: any) => pub.status == true),
	)
	const [filteredPublisherList, setFilteredPublisherList] = useState(
		allPublishersNamesAndIds,
	)
	const [totalFiltersSelected, SetTotalFiltersSelected] = useState(0)
	const [changeToFilterDone, setChangeToFilterDone] = useState(false)
	const [selectedDirect, setSelectedDirect] = useState<any>([])
	const [selectedGeneralCountryData, setSelectedGeneralCountryData] =
		useState(countryData)
	const [selectedCountryElements, setSelectedCountryElements] = useState([])
	const [selectedDevices, setSelectedDevices] = useState([])
	const [selectedTypeOfPublisher, setSelectedTypeOfPublisher] = useState([])
	const [selectedAdType, setSelectedAdType] = useState([])
	const [selectedFraudTools, setSelectedFraudTools] = useState([])
	const [selectedPaymentModel, setSelectedPaymentModel] = useState([])
	const [selectedTrafficIndicators, setSelectedTrafficIndicators] = useState([])
	const [selectedDailyCap, setSelectedDailyCap] = useState([])
	const [selectedAbilities, setSelectedAbitilies] = useState([])
	const [selectedAvailablePlatforms, setSelectedAvailablePlatforms] = useState(
		[],
	)
	const [selectedRelevantCategories, setSelectedRelevantCategories] = useState(
		[],
	)
	const publisherCapabilitiesMap = {
		selectedDirect,
		setSelectedDirect,
		selectedCountryElements,
		setSelectedCountryElements,
		selectedGeneralCountryData,
		setSelectedGeneralCountryData,
		selectedDevices,
		setSelectedDevices,
		selectedRelevantCategories,
		setSelectedRelevantCategories,
		selectedTypeOfPublisher,
		setSelectedTypeOfPublisher,
		selectedAdType,
		setSelectedAdType,
		selectedAvailablePlatforms,
		setSelectedAvailablePlatforms,
		selectedFraudTools,
		setSelectedFraudTools,
		selectedPaymentModel,
		setSelectedPaymentModel,
		selectedAbilities,
		setSelectedAbitilies,
		selectedTrafficIndicators,
		setSelectedTrafficIndicators,
		selectedDailyCap,
		setSelectedDailyCap,
	}
	useEffect(() => {
		if (changeToFilterDone) {
			setChangeToFilterDone(false)
		}
		filterPublishers()
		// console.log(selectedCountryElements, 'country')
		// console.log(selectedDevices, 'selectedDevices')
		// console.log(selectedRelevantCategories, 'selectedRelevantCategories')
		// console.log(selectedDirect, 'selectedDirect')
		// console.log(selectedFraudTools, 'selectedFraudTools')
		// console.log(selectedTypeOfPublisher, 'selectedTypeOfPublisher')
		// console.log(selectedAdType, 'SelectedAdType')
		// console.log(selectedAvailablePlatforms, 'selectedAvailablePlatforms')
		// console.log(selectedPaymentModel, 'selectedPaymentModel')
		// console.log(selectedAbilities, 'selectedAbilities')
		// console.log(selectedDailyCap, 'selectedDailyCap')
		// console.log(selectedTrafficIndicators, 'selectedTrafficIndicators')
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		selectedDirect,
		selectedCountryElements,
		selectedDevices,
		selectedRelevantCategories,
		selectedTypeOfPublisher,
		selectedAdType,
		selectedAvailablePlatforms,
		selectedFraudTools,
		selectedPaymentModel,
		selectedAbilities,
		selectedDailyCap,
		changeToFilterDone,
		selectedTrafficIndicators,
	])
	const filterPublishers = () => {
		const filteredArr: any = []
		if (
			arraysAllEmpty([
				selectedDirect,
				selectedCountryElements,
				selectedDevices,
				selectedRelevantCategories,
				selectedTypeOfPublisher,
				selectedAdType,
				selectedAvailablePlatforms,
				selectedFraudTools,
				selectedPaymentModel,
				selectedAbilities,
				selectedDailyCap,
				selectedTrafficIndicators,
			])
		) {
			setFilteredPublisherList(allPublishersNamesAndIds)
		} else {
			for (const publisher of publisherList) {
				publisher.media_buying_score = {}
				if (publisher.media_buying_capabilities) {
					let media_buying_capabilities: any
					if (typeof publisher.media_buying_capabilities === 'string') {
						media_buying_capabilities = JSON.parse(
							publisher.media_buying_capabilities,
						)
					} else {
						media_buying_capabilities = publisher.media_buying_capabilities
					}
					if (selectedDirect.length > 0) {
						if (
							checkCapability('Non Direct', selectedDirect) &&
							!nonDirectChecker(
								selectedDirect[0],
								media_buying_capabilities.available_platforms,
							)
						) {
							continue
						}
						const totalSelected = selectedDirect.length
						const matched = 1
						publisher.media_buying_score.available_platforms =
							matched / totalSelected
					}
					if (selectedDevices.length > 0) {
						const totalSelected = selectedDevices.length
						const matched = countArrayMatches(
							selectedDevices,
							media_buying_capabilities.devices,
						)
						if (matched === 0) {
							continue
						}
						publisher.media_buying_score.devices = matched / totalSelected
					}
					if (selectedCountryElements.length > 0) {
						const totalSelected = selectedCountryElements.length
						const matched = countArrayMatches(
							selectedCountryElements,
							media_buying_capabilities.country,
						)
						publisher.media_buying_score.country = matched / totalSelected
						if (
							!arraysOverlap(
								selectedCountryElements,
								media_buying_capabilities.country,
							)
						) {
							let total = selectedCountryElements.length
							let matched = 0
							for (const country of selectedCountryElements) {
								if ((country as any).payout) {
									total++
									if (
										payoutChecker(country, media_buying_capabilities.country)
									) {
										matched++
									}
								}
								if ((country as any).volume) {
									total++
									if (
										volumeChecker(country, media_buying_capabilities.country)
									) {
										matched++
									}
								}
							}
							publisher.media_buying_score.country_abilities = matched / total
						}
					}
					if (selectedRelevantCategories.length > 0) {
						const totalSelected = selectedRelevantCategories.length
						const matched = countArrayMatches(
							selectedRelevantCategories,
							media_buying_capabilities.relevant_categories,
						)
						publisher.media_buying_score.relevant_categories =
							matched / totalSelected
					}
					if (selectedTypeOfPublisher.length > 0) {
						const totalSelected = selectedTypeOfPublisher.length
						const matched = countArrayMatches(
							selectedTypeOfPublisher,
							media_buying_capabilities.type_publisher,
						)
						publisher.media_buying_score.type_publisher =
							matched / totalSelected
					}
					if (selectedAdType.length > 0) {
						const totalSelected = selectedAdType.length
						const matched = countArrayMatches(
							selectedAdType,
							media_buying_capabilities.ad_type,
						)
						publisher.media_buying_score.ad_type = matched / totalSelected
					}
					if (selectedFraudTools.length > 0) {
						const totalSelected = selectedFraudTools.length
						const matched = countArrayMatches(
							selectedFraudTools,
							media_buying_capabilities.fraud_tools,
						)
						publisher.media_buying_score.fraud_tools = matched / totalSelected
					}
					if (selectedPaymentModel.length > 0) {
						const totalSelected = selectedPaymentModel.length
						const matched = countArrayMatches(
							selectedPaymentModel,
							media_buying_capabilities.payment_model,
						)
						publisher.media_buying_score.payment_model = matched / totalSelected
					}
					if (selectedAbilities.length > 0) {
						if (
							checkCapability('Incent', selectedAbilities) &&
							!checkCapability('Incent', media_buying_capabilities.abilities)
						) {
							continue
						}
						const totalSelected = selectedAbilities.length
						const matched = countArrayMatches(
							selectedAbilities,
							media_buying_capabilities.abilities,
						)
						publisher.media_buying_score.abilities = matched / totalSelected
					}
					if (
						selectedDailyCap.length > 0 &&
						Array.isArray(media_buying_capabilities.daily_cap)
					) {
						let totalSelected = selectedDailyCap.length
						let matched = countArrayMatches(
							selectedDailyCap,
							media_buying_capabilities.daily_cap,
						)
						if (
							(selectedDailyCap[0] as any).inputValue <=
							media_buying_capabilities.daily_cap.inputValue
						) {
							matched++
						}
						publisher.media_buying_score.daily_cap = matched / totalSelected
					}
					if (selectedTrafficIndicators.length > 0) {
						const totalSelected = selectedTrafficIndicators.length
						const matched = countArrayMatches(
							selectedTrafficIndicators,
							media_buying_capabilities.traffic_indicators,
						)
						publisher.media_buying_score.traffic_indicators =
							matched / totalSelected
					}
					filteredArr.push(publisher)
				}
			}
			const minimumAcceptableScore = 15
			const result = sortAndFilterByScore(filteredArr, minimumAcceptableScore)
			setFilteredPublisherList(result)
		}
	}
	const arraysOverlap = (arr1: any[], arr2: any[]): boolean => {
		return !arr1.every((element1) =>
			arr2.some((element2) => element1.value === element2.value),
		)
	}
	const countArrayMatches = (arr1: any[], arr2: any[]): number => {
		let matchCount = 0

		arr1.forEach((element1) => {
			arr2.forEach((element2) => {
				if (element1.value === element2.value) {
					matchCount++
				}
			})
		})

		return matchCount
	}
	const nonDirectChecker = (element: any, arr: any) => {
		if (element.value === 'Non Direct') {
			for (const saved of arr) {
				if (saved.value === 'Affise') {
					return true
				}
			}
		} else {
			for (const saved of arr) {
				if (saved.value === 'Affise') {
					return false
				}
			}
			return true
		}
		return false
	}
	const payoutChecker = (element: any, arr: any[]) => {
		for (const saved of arr) {
			if (element.value === saved.value) {
				if (
					element.payout &&
					parseInt(element.payout) >= parseInt(saved.payout)
				) {
					return true
				}
			}
		}
		return false
	}
	const volumeChecker = (element: any, arr: any[]) => {
		for (const saved of arr) {
			if (element.value === saved.value) {
				if (element.volume === 'N/A') return true
				if (element.volume && element.volume === saved.volume) {
					return true
				}
			}
		}
		return false
	}
	const arraysAllEmpty = (arrOfArrs: any[]): boolean => {
		for (const possibleEmpty of arrOfArrs) {
			if (possibleEmpty.length > 0) {
				return false
			}
		}
		return true
	}

	const navigateToOptionPage = (option: any, ev: React.MouseEvent) => {
		// Find the publisher by name
		const publisher = allPublishersNamesAndIds.find(
			(pub: any) => pub.name === option,
		)

		// If the publisher doesn't exist, exit
		if (!publisher) return

		// Construct the URL with the publisher's ID
		const futureRoute = `/publishers/${publisher.id}/preview`

		// Open in a new tab if the user holds CTRL or Command key
		if (ev.ctrlKey || ev.metaKey) {
			window.open(futureRoute, '_blank')
			return
		}

		// Navigate to the constructed URL
		navigate(futureRoute)
	}

	return (
		<ThemeProvider theme={theme}>
			<PageWrapper>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flexDirection: 'column',
					}}
				>
					<TitleComponent>Media Plan</TitleComponent>

					<p style={{ color: theme.colors.text.titles, opacity: '0.4' }}>
						Choose the filters you need and find the ideal publishers for your
						campaign
					</p>
				</div>
				<hr
					style={{
						width: '60%',
						color: theme.colors.text.titles,
						opacity: '0.4',
					}}
				></hr>
				<Grid
					container
					columnSpacing={2}
					direction='row'
					style={{ marginTop: '40px' }}
				>
					<Grid item xs={8}>
						<Box
							style={{
								color: 'black',
								height: '70vh',
								overflowY: 'scroll',
								marginBottom: '5vh',
							}}
							id={'mediaPlanFiltersContainer'}
						>
							<FiltersContainer
								publisherCapabilitiesMap={publisherCapabilitiesMap}
								setChangeToFilterDone={setChangeToFilterDone}
								settings={settings}
							></FiltersContainer>
						</Box>
					</Grid>
					<Grid item xs={3.5}>
						<Box
							style={{
								color: 'black',
								height: '70vh',
								display: 'flex',
								justifyContent: 'space-evenly',
								flexDirection: 'column',
								alignItems: 'center',
							}}
						>
							<div
								style={{
									display: 'inherit',
									alignItems: 'inherit',
									flexDirection: 'inherit',
								}}
							>
								<span
									style={{
										color: theme.colors.text.titles,
										opacity: '0.4',
										height: '30px',
									}}
								>
									The following {filteredPublisherList.length} Publishers are
									relevant for you:
								</span>
								<div
									style={{
										overflowY: 'scroll',
										height: '50vh',
									}}
								>
									{/* <ChipInputList
										onChange={(e: any) => setFilteredPublisherList(e)}
										options={filteredPublisherList}
										value={filteredPublisherList}
										style={{ marginTop: '-20px' }}
										clickableFunction={navigateToOptionPage}
									/> */}
									<ContentContainer
										onChange={(e: any) => setFilteredPublisherList(e)}
										options={filteredPublisherList}
										value={filteredPublisherList}
										clickableFunction={navigateToOptionPage}
									></ContentContainer>
								</div>
							</div>
							<ul
								style={{
									color: theme.colors.text.titles,
									opacity: '0.4',
									fontSize: '15px',
								}}
							>
								Please make sure to check the publisher needs including:
								<li>Ad Formats and Sizes</li>
								<li>Special requests</li>
								<li>How long it takes them to go live</li>
							</ul>
						</Box>
					</Grid>
				</Grid>
			</PageWrapper>
		</ThemeProvider>
	)
}

export default MediaPlanPage

const checkCapability = (capability: string, selectedArr: any) => {
	for (const element of selectedArr) {
		if (element.value === capability) {
			return true
		}
	}
	return false
}

const sortAndFilterByScore = (publishersArr: any, minimumScore: number) => {
	let arr = []
	let totalEvaluating = Object.keys(publishersArr[0].media_buying_score).length

	for (const pub of publishersArr) {
		let totalMatching = 0
		for (const key of Object.keys(pub.media_buying_score)) {
			const score = pub.media_buying_score[key]
			totalMatching += score
		}
		const finalScore = Math.round((totalMatching / totalEvaluating) * 100)
		if (finalScore > minimumScore) {
			const obj = { publisher_name: pub.publisher_name, score: finalScore }
			arr.push(obj)
		}
	}
	const sorted = arr
		.sort((a, b) => b.score - a.score)
		.map((pub: any) => {
			return { name: pub.publisher_name, score: pub.score }
		})
	return sorted
}
