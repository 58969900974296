import React from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import { useTheme } from '@mui/material'

function MediaPlanChip(props: {
	label: string
	score: number
	buttonClick: any
	clickableFunction: any
}) {
	const theme = useTheme()
	const scoreColor = calculateColorBasedOnScore(props.score, theme)
	return (
		<span
			style={{
				display: 'flex',
				border: '1px solid ' + scoreColor,
				padding: '8px 10px 8px 4px',
				borderRadius: '10px',
				whiteSpace: 'nowrap',
				textAlign: 'center',
				fontWeight: '500',
				fontSize: '12px',
				textOverflow: 'ellipsis',
				gridColumnStart: 'span 1',
				justifyContent: 'space-evenly',
				gap: '4px',
				color: theme.colors.text.titles,
			}}
			key={props.label + props.score}
		>
			<button
				style={{
					width: '75%',
					whiteSpace: 'break-spaces',
					textAlign: 'center',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					border: 'none',
					cursor: 'pointer',
					fontSize: '12px',
					color: scoreColor,
				}}
				onClick={(e) => {
					props.clickableFunction(props.label, e)
				}}
			>
				{props.label}
			</button>
			<span
				style={{
					width: '25%',
					color: scoreColor,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				{props.score}%
			</span>
			<button
				style={{
					display: 'flex',
					cursor: 'pointer',
					fontSize: '12px',
					border: 'none',
					height: '100%',
					alignItems: 'center',
				}}
				onClick={() => props.buttonClick(props.label)}
			>
				<ClearIcon style={{ scale: '0.8', color: 'rgba(0,0,0,0.4)' }} />
			</button>
		</span>
	)
}

function ContentContainer(props: {
	onChange: any
	options: any
	value: any
	clickableFunction: any
}) {
	const theme = useTheme()
	const removeElement = (selected: string) => {
		const newArr = props.value.filter((el: any) => el.name !== selected)
		props.onChange(newArr)
	}

	return (
		<div
			style={{
				display: 'grid',
				height: '44vh',
				width: '22vw',
				padding: '22px 22px 22px 12px',
				border: '1px solid rgb(228,229,229)',
				borderRadius: '14px',
				gap: '12px',
				gridTemplateColumns: 'repeat(auto-fit, minmax(160px, 1fr))',
				gridAutoFlow: 'dense',
				overflowY: 'auto',
				overflowX: 'hidden',
				color: theme.colors.text.titles,
			}}
		>
			{props.value.map((el: any) => {
				return (
					<MediaPlanChip
						label={el.name}
						score={el.score}
						buttonClick={removeElement}
						clickableFunction={props.clickableFunction}
						key={el.name + el.score + 'main'}
					></MediaPlanChip>
				)
			})}
		</div>
	)
}

export default ContentContainer

function calculateColorBasedOnScore(score: number, theme: any): string {
	if (score >= 80) {
		return theme.colors.base.green300
	}
	if (score >= 50) {
		return theme.colors.base.red300
	}
	if (score <= 20) {
		return theme.colors.base.red300
	}

	return theme.colors.base.red300
}
