import React, { useState, useEffect } from 'react'
import RegistrationCard from '../components/RegistrationCard'

interface RegistrationProps {
	values: any
	updateFormData: any
    settings: any
}

const RegistrationDetailsAdvertiser = (props: RegistrationProps) => {
	const { values, updateFormData, settings } = props

	const [legalEntity, setLegalEntity] = useState('')
	const [companyAddress, setCompanyAddress] = useState('')
	const [preferredChannel, setPreferredChannel] = useState([])
	const [mmp, setMMP] = useState([])
	const [appWebCampaign, setAppWebCampaign] = useState('')
	const [os, setOs] = useState('')
	const [serviceInterest, setServiceInterest] = useState([])
	const [vertical, setVertical] = useState([])
	const [geo, setGeo] = useState([])
	const [text, setText] = useState('')

	useEffect(() => {
		// update the data in the signUpPage Component
		updateFormData({
			legalEntity,
			companyAddress,
			preferredChannel,
			mmp,
			appWebCampaign,
			os,
			serviceInterest,
			vertical,
			geo,
			text,
		})
	}, [
		legalEntity,
		companyAddress,
		preferredChannel,
		mmp,
		appWebCampaign,
		os,
		serviceInterest,
		vertical,
		geo,
		text,
	])

	const fields = [
		{
			type: 'text',
			label: 'Legal Entity',
			value: legalEntity,
			onChange: setLegalEntity,
            mandatory: true
		},
		{
			type: 'text',
			label: 'Company Address',
			value: companyAddress,
			onChange: setCompanyAddress,
            mandatory: true
		},
		{
			type: 'chiplist',
			label: 'Preferred communication channel ',
			value: preferredChannel,
			onChange: setPreferredChannel,
			options: settings.communicationChannel, 
		},
		{
			type: 'chiplist',
			label: 'Platforms',
			value: mmp,
			onChange: setMMP,
			options: settings.platforms, 
            mandatory: true
		},
		{
			type: 'droplist',
			label: 'Type of Campaign',
			value: appWebCampaign,
			onChange: setAppWebCampaign,
			options: [
				{ value: 'app', label: 'App' },
				{ value: 'web', label: 'Web' },
				{ value: 'both', label: 'Both' },
			],
			single: true,
            mandatory: true
		},
		{
			type: 'droplist',
			label: 'OS',
			value: os,
			onChange: setOs,
			options: [
				{ value: 'ios', label: 'iOS' },
				{ value: 'android', label: 'Android' },
				{ value: 'both', label: 'Both' },
			],
		},
		{
			type: 'chiplist',
			label: 'Type of service of interest',
			value: serviceInterest,
			onChange: setServiceInterest,
			options: [
				'Progerammatic Media Buying',
				'UGC & Ads Production',
				'Influencer Marketing',
				'Performence Marketing',
			],
		},
		{
			type: 'chiplist',
			label: 'Vertical',
			value: vertical,
			onChange: setVertical,
			options: settings.vertical, 
		},
		{
			type: 'country',
			label: 'Target Country',
			value: geo,
			onChange: setGeo,
		},
		{
			type: 'textarea',
			label: 'Special Requests',
			value: text,
			onChange: setText,
			placeholder: 'Please provide any special requests or preferences',
		},
	]

	return (
		<RegistrationCard
			title='Registration Details - Advertiser'
			fields={fields}
		/>
	)
}

export default RegistrationDetailsAdvertiser
