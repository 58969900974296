import React, { CSSProperties, useEffect, useState } from 'react'
import { StyledBadge } from './reusableComponents'
import FilterListIcon from '@mui/icons-material/FilterList'
import { useTheme } from '@mui/material'

export const HeaderFilterComponent = ({
	label,
	width,
	openFilter,
	setOpenFilter,
	field,
	customFilter,
	currentFilters,
	setCurrentFilters,
	setFilterLabel,
	optionsFilters,
	style,
}: {
	label: string
	width: number
	openFilter: boolean
	setOpenFilter: any
	field: string
	customFilter: any
	currentFilters: any
	setCurrentFilters: any
	setFilterLabel: any
	optionsFilters: any
	style?: CSSProperties
}) => {
	const theme = useTheme()
	const [mouseIn, setMouseIn] = useState(false)
	const handleClick = (event?: any) => {
		if (event) {
			event.stopPropagation()
		}
		setOpenFilter(!openFilter)
		setCurrentFilters(field)
		setFilterLabel(label)
	}
	useEffect(() => {
		handleClick()
		setOpenFilter(false)
	}, [])

	return (
		<>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'flex-start',
					width: width,
					gap: '12px',
					marginLeft: '-8px',
					lineHeight: '18px',
					...style,
				}}
				onMouseEnter={() => setMouseIn(true)}
				onMouseLeave={() => setMouseIn(false)}
			>
				<div
					style={{
						width: '20px',
						display: 'flex',
						justifyContent: 'flex-start',
						marginRight: '12px',
						color: theme.colors.text.titles,
					}}
				>
					<button
						style={{
							border: '0px',
							color: 'inherit',
							display: 'flex',
							justifyContent: 'center',
							cursor: 'pointer',
						}}
						onClick={(e) => handleClick(e)}
					>
						{customFilter[field] && customFilter[field].length > 0 ? (
							<StyledBadge variant={'dot'}>
								<FilterListIcon />
							</StyledBadge>
						) : (
							<FilterListIcon />
						)}
					</button>
				</div>
				<div
					style={{
						// width: '80%',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
					}}
				>
					<span
						style={{
							fontWeight: '500',
							whiteSpace: 'pre-line',
						}}
					>
						{label}
					</span>
				</div>
			</div>
		</>
	)
}
