import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
} from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { StyledTextInputField } from '../Inputs'
import { formatDate } from '../../../utils/helpers/tableHelper'
import { SubmitButton } from '../Buttons'
import { DateComponent } from '../../reports/aggregated/ReportsStyled'
import AdapterDateFns from '@date-io/date-fns'

export const DatePickerComponent = (props: {
	setDatePicked: any
	setDatePickerOpened: any
	datePicked: any
	datePickerOpened: any
	trySubmit: any
}) => {
	return (
		<Dialog open={props.datePickerOpened}>
			<DialogContent
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<DialogContentText>
					{'Choose the date to save the campaign from'}
				</DialogContentText>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<DateComponent
						onChange={(e: any) => props.setDatePicked(formatDate(e))}
						value={props.datePicked}
						inputFormat='dd/MM/yyyy'
						OpenPickerButtonProps={{
							style: { paddingRight: '20px', paddingLeft: '0px' },
						}}
						renderInput={(params: any) => (
							<StyledTextInputField
								{...params}
								focused
								fullWidth
								InputLabelProps={{
									style: { top: '-20%' },
								}}
							/>
						)}
					></DateComponent>
				</LocalizationProvider>
			</DialogContent>
			<DialogActions>
				<SubmitButton
					onClick={() => {
						props.setDatePicked(null)
						props.setDatePickerOpened(false)
					}}
				>
					Close
				</SubmitButton>
				<SubmitButton
					onClick={() => {
						props.trySubmit()
					}}
				>
					Update
				</SubmitButton>
			</DialogActions>
		</Dialog>
	)
}
