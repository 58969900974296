import { Public } from '@mui/icons-material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import EditIcon from '@mui/icons-material/Edit'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import LanguageIcon from '@mui/icons-material/Language'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import { Grid, useTheme } from '@mui/material'
import { Property } from 'csstype'
import React, { useEffect, useState } from 'react'
import ReactCountryFlag from 'react-country-flag'
import { useNavigate, useParams } from 'react-router'
import driveIcon from '../../assets/svg/driveIcon.svg'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import {
	canAccessResource,
	getApplicableRules,
} from '../../utils/helpers/helperFuncs'
import { PageWrapper } from '../components/reusableComponents'
import LinkIcon from '@mui/icons-material/Link'
import { PasswordField } from '../components/Informative'
import { SubmitButton, UnborderedButton } from '../components/Buttons'
import {
	ContactField,
	ExpandableInfoField,
	StatusTitleTag,
	TitleComponent,
	TitleTag,
} from '../components/Informative'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import CompanyInformationHeader from '../components/CompanyInformationHeader'
import defaultIcon from '../../assets/default-icon.webp'
import SocialLinks from '../components/SocialLinksHeader'
import { useIsAuthorized } from '../../hooks/useIsAuthorized'
import { permissionNames } from '../../utils/helpers/permissionsHelper'

//this file is rendering the preview page for advertiser. We have different components here.

//this component is used to generate the "boxes" of capabilities.
const CapabilityContainer = (props: { value: any; label: string }) => {
	const theme = useTheme()
	const calculateAmountOfRows = (values: any[]) => {
		let result = 0
		if (!values || values.length === 0) {
			return result
		}
		for (const element of values) {
			if (element.value) {
				//since inside the container, if a value is bigger than 12 length it's going to use 2 grid columns we use this to know the final amount of rows.
				if (element.value.length > 12) {
					result += 2
				} else {
					result++
				}
			}
		}
		return Math.ceil(result / 3)
	}
	//we assume that in 1 row-grid we can have at max something between 3 and 4 rows of values:
	const gridMaxAmountOfRows = 4
	const gridRowHeightFrames = Math.ceil(
		calculateAmountOfRows(props.value) / gridMaxAmountOfRows,
	)
	return (
		props.value && (
			<div
				style={{
					gridRowStart: `span ${gridRowHeightFrames}`,
					border: '1px solid ' + theme.colors.base.grey200,
					borderRadius: '20px',
					padding: '20px 20px 30px 20px',
					display: 'flex',
					flexDirection: 'column',
					gap: '12px',
					height: 'calc(100% - 50px)',
				}}
			>
				<span style={{ fontSize: theme.font.size.body, fontWeight: '600' }}>
					{props.label}
				</span>
				<div
					style={{
						display: 'grid',
						gap: '12px',
						gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
						gridAutoFlow: 'dense',
					}}
				>
					{props.value.map((element: any) => {
						return (
							<span
								style={{
									// color: theme.colors.base.white,
									border: '2px solid ' + theme.colors.base.green200,
									padding: '6px',
									borderRadius: '10px',
									whiteSpace: 'nowrap',
									textAlign: 'center',
									fontWeight: theme.font.weight.skinny,
									fontSize: theme.font.size.body,
									gridColumnStart:
										element.value.length < 12 ? 'span 1' : 'span 2',
								}}
								key={element.value}
							>
								{element.value}
							</span>
						)
					})}
				</div>
			</div>
		)
	)
}
//this one is used for the box of budget.
const BudgetContainer = (props: { value: string; label: string }) => {
	return (
		<>
			<span
				style={{
					color: 'rgba(159, 159, 159, 1)',
					background: 'rgba(236, 238, 238, 1)',
					padding: '6px 0px 6px 20px',
					borderRadius: '10px',
					whiteSpace: 'nowrap',
					textAlign: 'center',
					fontWeight: '500',
					fontSize: '12px',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					gap: '20px',
					width: '220px',
				}}
			>
				{props.label}:
				<div
					style={{
						display: 'flex',
						width: '80px',
						justifyContent: 'flex-end',
						color: 'black',
						marginRight: '20px',
					}}
				>
					<span
						style={{
							color: 'black',
						}}
					>
						{props.value}
					</span>
				</div>
			</span>
		</>
	)
}
//this is the component of the small box that renders the info. The "tags" inside the containers.
//based on if it's a country or not it will be rendered differently.
export const InfoField = (props: {
	label: string
	value: string | string[]
	isArray?: boolean
	isCountry?: boolean
	whiteSpace?: Property.WhiteSpace
	isLink?: boolean
	link?: string
}) => {
	const theme = useTheme()
	const goToLink = () => {
		window.open(props.link, '_blank')
	}
	return (
		<div
			style={{
				display: 'flex',
				gap: '6px',
				fontWeight: theme.font.weight.normal,
				// whiteSpace: props.whiteSpace ? props.whiteSpace : 'nowrap',
				// alignItems: props.isCountry ? 'center' : 'flex-start',
				alignItems: 'center',
				// flexWrap: 'wrap',
			}}
			key={props.label + '_key'}
		>
			<span
				style={{
					fontSize: theme.font.size.body,
					color: theme.colors.base.grey600,
					fontWeight: theme.font.weight.normal,
				}}
			>
				{props.label ? props.label + ':' : ''}
			</span>
			{props.isCountry ? (
				Array.isArray(props.value) ? (
					props.value.map((country: string) => {
						return (
							<React.Fragment key={country + 'key' + props.label}>
								<ReactCountryFlag
									style={{
										height: '9px',
										borderRadius: '100px',
										border: `1px solid ${theme.colors.black}`,
									}}
									key={'flags'}
									className='roundedFlag'
									countryCode={country === 'UK' ? 'GB' : country}
									svg
								/>
								<span
									style={{
										fontWeight: theme.font.weight.normal,
										overflowWrap: 'anywhere',
									}}
								>
									{country}
								</span>
							</React.Fragment>
						)
					})
				) : (
					<>
						<ReactCountryFlag
							style={{
								height: '9px',
								borderRadius: '100px',
								border: `1px solid ${theme.colors.black}`,
							}}
							key={'flags'}
							className='roundedFlag'
							countryCode={props.value === 'UK' ? 'GB' : props.value}
							svg
						/>
						<span
							style={{
								fontWeight: theme.font.weight.normal,
								overflowWrap: 'anywhere',
							}}
						>
							{props.value}
						</span>
					</>
				)
			) : props.isArray ? (
				props.value.length > 0 && (
					<div
						style={{
							width: '200%',
							display: 'grid',
							gap: '4px',
							gridTemplateColumns:
								props.value.length > 4
									? 'repeat(auto-fit, minmax(120px, 1fr)'
									: 'repeat(4, 1fr)',
						}}
					>
						{(props.value as string[]).map((element: string) => {
							return (
								<span
									key={element + 'key' + props.label}
									style={{
										padding: '6px',
										// border: `1px solid ${theme.colors.lightGray}`,
										// border: '1px solid ' + 'rgb(175,24,98)',
										background: theme.colors.base.green200,
										borderRadius: '10px',
										fontSize: theme.font.size.body,
										gridColumnStart: element.length < 16 ? 'span 1' : 'span 2',
										whiteSpace: 'nowrap',
										textAlign: 'center',
										fontWeight: theme.font.weight.normal,
									}}
								>
									{element}
								</span>
							)
						})}
					</div>
				)
			) : props.isLink ? (
				props.link ? (
					<span
						onClick={goToLink}
						style={{ cursor: 'pointer', marginBlock: '-3px' }}
					>
						<LinkIcon />
					</span>
				) : (
					<span style={{ marginBlock: '-3px' }}>
						<LinkIcon />
					</span>
				)
			) : (
				<span
					style={{
						fontWeight: theme.font.weight.normal,
						overflowWrap: 'anywhere',
						fontSize: theme.font.size.body,
						color: theme.colors.text.general,
					}}
				>
					{props.value}
				</span>
			)}
		</div>
	)
}

interface Password {
	password: string
	username: string
	url: string
	whiteList: string[]
}
const InformationContainer = (props: {
	advertiser: any
	closeDetailedView: any
	openEdit: any
	PopUpVisible: any
	setPopUpVisible: any
	setSuccessMessage: any
	setIsSuccessPromptOpened: any
}) => {
	const theme = useTheme()
	const { publisher, app, users, login, settings } = useTypedSelector(
		(state) => state,
	)
	const editOwnAdvertiser =
		useIsAuthorized(permissionNames.EDIT_ADVERTISER_OWNER_ONLY) &&
		props.advertiser.email === login.user.email
	const permissions = {
		edit:
			useIsAuthorized(permissionNames.ADD_EDIT_ADVERTISER) || editOwnAdvertiser,
		viewAdvertiserContacts:
			useIsAuthorized(permissionNames.VIEW_ADVERTISER_CONTACTS) ||
			editOwnAdvertiser,
		preview: useIsAuthorized(permissionNames.PREVIEW_ADVERTISERS),
		viewPasswords: useIsAuthorized(permissionNames.VIEW_ADVERTISER_PASSWORDS),
	}
	const handleClickedLink = (link: string) => {
		if (!link.startsWith('http')) {
			return window.open('http://' + link)
		}
		return window.open(link)
	}

	const [usedPublishers, setUsedPublishers] = useState<string[]>(
		props.advertiser?.used_publisher || [],
	)
	const [passwords, setPasswords] = useState<Password[]>(
		props.advertiser.passwords || [],
	)
	const [notUsedPublishers, setNotUsedPublishers] = useState<string[]>([])
	const appIdList = app.app
		.filter(
			(element: any) =>
				element.advertiser_name === props.advertiser?.advertiser_name,
		)
		.map((element: any) => element.app_id)
		.join(' - ')
	const pastOwner = props.advertiser?.past_owner
		? JSON.parse(props.advertiser.past_owner)
				.map((element: any) => element.owner)
				.join(', ')
		: []
	const publisherList = publisher.publisher
		.filter((el: any) => el.status === true)
		.map((el: any) => el.publisher_name)
		.sort((a: any, b: any) => a.localeCompare(b))

	let trafficRestrictions = []
	let existingChannels = []
	try {
		trafficRestrictions =
			props.advertiser?.traffic_restrictions?.length > 0
				? JSON.parse(props.advertiser.traffic_restrictions)
				: []
		existingChannels =
			props.advertiser?.existing_channels?.length > 0
				? JSON.parse(props.advertiser.existing_channels)
				: []
	} catch (error) {
		console.error(error)
	}
	const canView =
		canAccessResource(
			login.user,
			props.advertiser.email,
			users,
			props.advertiser.sales_rep,
		) || permissions.viewPasswords

	//if we found used publishers saved, then we are going to create a list of unused to show.
	useEffect(() => {
		if (usedPublishers) {
			const arr = publisherList.filter(
				(pub: any) => !usedPublishers.includes(pub),
			)
			setNotUsedPublishers(arr)
		}
	}, [usedPublishers])
	//we want to filter the contacts between finance and non finance.
	const financialContacts: any = []
	const mainContacts: any = []
	props.advertiser?.contacts &&
		props.advertiser?.contacts.forEach((contact: any) => {
			if (contact.type === 'finance') {
				financialContacts.push(contact)
			} else {
				mainContacts.push(contact)
			}
		})
	const isBudgetLimit =
		props.advertiser.total_budget_limit > 0 ||
		props.advertiser.monthly_budget_limit > 0 ||
		props.advertiser.daily_budget_limit > 0

	const updatePasswords = (oldPassword: string, newPassword: string) => {
		const updatedPasswords = passwords.map((pwd) =>
			pwd.password === oldPassword ? { ...pwd, password: newPassword } : pwd,
		)
		setPasswords(updatedPasswords)
	}

	const accessiblePasswords = passwords.filter(
		(passwordItem: Password) =>
			canView || passwordItem.whiteList?.includes(login.user.name),
	)
	if (!permissions.edit && !permissions.preview) {
		return <></>
	}
	return (
		<>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					width: '100%',
				}}
			>
				<div style={{ display: 'flex', width: '90%' }}>
					<UnborderedButton
						style={{
							width: '80px',
							display: 'flex',
							justifyContent: 'flex-start',
							color: theme.colors.text.titles,
						}}
						onClick={props.closeDetailedView}
						icon={
							<ArrowBackIosIcon
								style={{ height: '16px', marginRight: '0px', marginLeft: -10 }}
							/>
						}
					>
						Back
					</UnborderedButton>
					{permissions.edit && (
						<UnborderedButton
							style={{
								display: 'flex',
								marginLeft: 'auto',
								color: theme.colors.text.titles,
							}}
							onClick={props.openEdit}
							icon={<EditIcon />}
						>
							Edit
						</UnborderedButton>
					)}
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						width: '95%',
						padding: '1rem 1rem',
					}}
				>
					<div
						style={{
							display: 'flex',
							marginLeft: '-1.3rem',
						}}
					>
						<CompanyInformationHeader
							name={props.advertiser.advertiser_name}
							iconUrl={props.advertiser.advertiser_icon || defaultIcon}
						/>
						<StatusTitleTag status={props.advertiser.status} />
					</div>
					<SocialLinks
						links={{
							website: props.advertiser.website,
							linkedin: props.advertiser.linkedin,
							io: props.advertiser.link_to_io,
							pub: props.advertiser.link_to_pub,
						}}
					/>
				</div>
				<Grid
					item
					xs={12}
					container
					direction='row'
					columnSpacing={2}
					rowSpacing={2}
					columns={12}
					style={{
						fontSize: theme.font.size.body,
						marginBottom: '30px',
					}}
				>
					{props.advertiser.status === false && (
						<>
							<Grid item xs={3}>
								<InfoField
									label={'Pause Reason'}
									value={props.advertiser.pause_reason}
								/>
							</Grid>
							<Grid item xs={9}>
								<InfoField
									label={'Pause Explanation'}
									value={props.advertiser.pause_explanation}
								/>
							</Grid>
						</>
					)}
					<Grid item xs={3}>
						<InfoField
							label={'Legal Name'}
							value={props.advertiser.legal_name}
							whiteSpace={
								props.advertiser.legal_name &&
								props.advertiser.legal_name.length > 20
									? 'normal'
									: 'nowrap'
							}
						/>
					</Grid>
					<Grid item xs={3}>
						<InfoField
							label={'Head Office Geo'}
							value={props.advertiser.geo}
							isCountry
						/>
					</Grid>
					<Grid item xs={3}>
						<InfoField label={'Branch'} value={props.advertiser.branch} />
					</Grid>
					<Grid item xs={3}>
						<InfoField label={'Owner'} value={props.advertiser.email} />
					</Grid>
					<Grid item xs={3}>
						<InfoField
							label={'Address'}
							value={props.advertiser.address}
							whiteSpace={'normal'}
						/>
					</Grid>
					<Grid item xs={3}>
						<InfoField label={'Team'} value={props.advertiser.team} />
					</Grid>
					<Grid item xs={3}>
						<InfoField
							label={'Payment Terms'}
							value={props.advertiser.payment_terms}
						/>
					</Grid>
					<Grid item xs={3}>
						<InfoField
							label={
								props.advertiser?.platform?.length > 1
									? 'Tracking Systems'
									: 'Tracking System'
							}
							value={props.advertiser?.platform?.join(' | ')}
						/>
					</Grid>
					<Grid item xs={3}>
						<InfoField
							label={'Communication Channel'}
							value={props.advertiser?.communication_channel?.join(', ')}
						/>
					</Grid>
					<Grid item xs={3}>
						<InfoField
							label={'Past Owner'}
							value={pastOwner}
							whiteSpace={'normal'}
						/>
					</Grid>
					<Grid item xs={3}>
						<InfoField
							label={'Fraud Tool'}
							value={props.advertiser?.fraud_tool?.join(', ')}
						/>
					</Grid>
					<Grid item xs={3}>
						<InfoField label={'Vertical'} value={props.advertiser.vertical} />
					</Grid>
					<Grid item xs={3}>
						<InfoField
							label={'Sales Representative'}
							value={props.advertiser.sales_rep}
						/>
					</Grid>
					<Grid item xs={3}>
						<InfoField
							label={'Type'}
							value={props.advertiser.advertiser_type}
						/>
					</Grid>
					<Grid item xs={6}>
						<InfoField
							label={"Apps ID's"}
							value={appIdList || []}
							whiteSpace={'normal'}
						/>
					</Grid>
					{/* if at least one budget limit is greater than 0 it will show */}
					{/* {isBudgetLimit && (
							<div style={{ marginTop: '48px' }}>
								<TitleComponent
									style={{
										fontSize: '12px',
										// color: 'rgba(128,128,128, 0.8)',
										color: 'rgba(0, 0, 0, 1)',
										fontWeight: '600',
										marginLeft: '0px',
										marginBottom: '8px',
									}}
								>
									Budget:
								</TitleComponent>
								<div
									style={{
										border: '1px solid' + theme.colors.lightGray,
										padding: '4px 14px 4px 14px',
										fontSize: '14px',
										borderRadius: '8px',
										width: '88%',
										lineHeight: '1.5',
										whiteSpace: 'pre-wrap',
										gap: '12px',
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									{props.advertiser.daily_budget_limit > 0 && (
										<BudgetContainer
											value={
												props.advertiser.daily_budget_limit.toString() + ' $'
											}
											label={'Daily Budget'}
										/>
									)}
									{props.advertiser.monthly_budget_limit > 0 && (
										<BudgetContainer
											value={
												props.advertiser.monthly_budget_limit.toString() + ' $'
											}
											label={'Monthly Budget'}
										/>
									)}
									{props.advertiser.total_budget_limit > 0 && (
										<BudgetContainer
											value={
												props.advertiser.total_budget_limit.toString() + ' $'
											}
											label={'Total Period Budget'}
										/>
									)}
								</div>
							</div>
						)} */}

					<Grid item xs={12}>
						<TitleComponent
							style={{
								fontSize: theme.font.size.body,
								color: theme.colors.base.grey500,
								fontWeight: theme.font.weight.normal,
							}}
						>
							Brief:
						</TitleComponent>
						<div
							style={{
								// padding: '4px 14px 4px 14px',
								fontSize: theme.font.size.body,
								borderRadius: '8px',
								width: '88%',
								minHeight: '8vh',
								lineHeight: '1.4',
								whiteSpace: 'pre-wrap',
							}}
						>
							<p>
								{props.advertiser.brief
									? props.advertiser.brief
									: 'No brief at the moment'}
							</p>
						</div>
					</Grid>
					<Grid item xs={6}>
						<TitleComponent
							style={{
								fontSize: theme.font.size.body,
								color: theme.colors.base.grey500,
								fontWeight: theme.font.weight.normal,
							}}
						>
							Special Request:
						</TitleComponent>
						<div
							style={{
								// padding: '4px 14px 4px 14px',
								fontSize: theme.font.size.body,
								borderRadius: '8px',
								width: '88%',
								lineHeight: '1.5',
								whiteSpace: 'pre-wrap',
							}}
						>
							<p>
								{props.advertiser.special_request
									? props.advertiser.special_request
									: 'No Special Request at the moment'}
							</p>
						</div>
					</Grid>
					<Grid item xs={6}>
						<TitleComponent
							style={{
								fontSize: theme.font.size.body,
								color: theme.colors.base.grey500,
								fontWeight: theme.font.weight.normal,
							}}
						>
							Parameters Template:
						</TitleComponent>
						<div
							style={{
								// padding: '4px 14px 4px 14px',
								fontSize: theme.font.size.body,
								borderRadius: '8px',
								width: '88%',
								lineHeight: '1.5',
								whiteSpace: 'pre-wrap',
							}}
						>
							<p>
								{props.advertiser.parameters_template
									? props.advertiser.parameters_template
									: 'No Parameters Template at the moment'}
							</p>
						</div>
					</Grid>
					{props.advertiser.status === false && (
						<Grid item xs={12}>
							<TitleComponent
								style={{
									fontSize: theme.font.size.body,
									color: theme.colors.base.grey500,
									fontWeight: theme.font.weight.normal,
								}}
							>
								Pause Reason:
							</TitleComponent>
							<div
								style={{
									padding: '4px 14px 4px 14px',
									fontSize: theme.font.size.body,
									fontWeight: theme.font.weight.normal,
									borderRadius: '8px',
									width: '88%',
									lineHeight: '1.5',
									whiteSpace: 'pre-wrap',
									display: 'flex',
									flexDirection: 'column',
									gap: '22px',
								}}
							>
								<InfoField
									label={'Reason'}
									value={props.advertiser.pause_reason}
								/>
								<InfoField
									label={'Explanation'}
									value={props.advertiser.pause_explanation}
								/>
							</div>
						</Grid>
					)}
					{(canView || permissions.viewAdvertiserContacts) &&
						mainContacts.length > 0 && (
							<Grid item xs={11}>
								<TitleComponent
									style={{
										fontSize: theme.font.size.body,
										alignSelf: 'flex-start',
									}}
								>
									Contacts
								</TitleComponent>
								<div
									style={{
										display: 'flex',
										gap: '12px',
										flexDirection: 'column',
										padding: '12px 0px 12px 0px',
										width: '100%',
									}}
								>
									{mainContacts.map((contact: any) => {
										return (
											<ContactField
												contact={contact}
												key={contact.name}
											></ContactField>
										)
									})}
								</div>
							</Grid>
						)}
					{(canView || permissions.viewAdvertiserContacts) &&
						financialContacts.length > 0 && (
							<Grid item xs={11}>
								<TitleComponent
									style={{
										fontSize: theme.font.size.body,
										alignSelf: 'flex-start',
									}}
								>
									Finance
								</TitleComponent>
								<div
									style={{
										display: 'flex',
										gap: '12px',
										flexDirection: 'column',
										padding: '12px 0px 12px 0px',
										width: '100%',
									}}
								>
									{financialContacts.map((contact: any) => {
										return (
											<ContactField
												contact={contact}
												key={contact.name}
											></ContactField>
										)
									})}
								</div>
							</Grid>
						)}
					{props.advertiser.used_publisher && (
						<ExpandableInfoField
							label={'Publishers never used'}
							value={notUsedPublishers}
						/>
					)}

					{/* <Grid
					container
					direction='row'
					columnSpacing={6}
					rowSpacing={4}
					columns={12}
					style={{
						fontSize: theme.font.size.body,
						marginBottom: '30px',
						width: '95%',
					}}
				> */}
					<Grid item xs={11 / 2}>
						<CapabilityContainer
							value={trafficRestrictions}
							label={'Traffic Restrictions'}
						/>
					</Grid>
					<Grid item xs={11 / 2}>
						<CapabilityContainer
							value={existingChannels}
							label={'Existing Channels'}
						/>
					</Grid>
					{accessiblePasswords.length > 0 && (
						<Grid item xs={11}>
							<TitleComponent
								style={{
									fontSize: theme.font.size.body,
									alignSelf: 'flex-start',
								}}
							>
								Passwords
							</TitleComponent>
							<div
								style={{
									display: 'flex',
									gap: '12px',
									flexDirection: 'column',
									padding: '12px 0px 12px 0px',
									width: '100%',
								}}
							>
								{accessiblePasswords.map((passwordItem: Password) => (
									<PasswordField
										popUpVisible={props.PopUpVisible}
										setPopUpVisible={props.setPopUpVisible}
										password={{
											password: passwordItem.password,
											username: passwordItem.username,
											url: passwordItem.url,
											whiteList: passwordItem.whiteList
										}}
										updatePasswords={updatePasswords}
										ownerEmail={props.advertiser.email}
										setSuccessMessage={props.setSuccessMessage}
										setIsSuccessPromptOpened={props.setIsSuccessPromptOpened}
										canEdit={canView}
									/>
								))}
							</div>
						</Grid>
					)}
				</Grid>
			</div>
		</>
	)
}

//main preview page. This just renders the informationContainer.
const AdvertiserPreviewPage = (props: {
	setLoading: any
	errorMessage: any
	setErrorMessage: any
	isErrorPromptOpened: any
	setIsErrorPromptOpened: any
	isSuccessPromptOpened: any
	setIsSuccessPromptOpened: any
	viewRecord: any
	setViewRecord: any
	PopUpVisible: any
	setPopUpVisible: any
	isCompanyPersonalView?: boolean
	companyData?: any
	setSuccessMessage?: any
}) => {
	const { advertiser, users, login, settings } = useTypedSelector(
		(state) => state,
	)
	const theme = useTheme()
	const navigate = useNavigate()
	const params = useParams()
	let row: any
	if (props.isCompanyPersonalView) {
		row = props.companyData
	} else {
		row =
			advertiser.advertiser.find((e: any) => e.id === Number(params.id)) || []
	}
	const closeDetailedView = () => {
		if (props.isCompanyPersonalView) {
			navigate('/companySettings')
		} else {
			navigate('/advertisers')
		}
		props.setPopUpVisible(false)
	}
	const openEdit = () => {
		navigate('/advertisers/' + row.id)
	}

	return (
		<PageWrapper
			style={{
				color: theme.colors.text.titles,
				display: 'flex',
				// marginTop: '8vh',
				flexDirection: 'column',
				gap: '30px',
				paddingBottom: '30px',
			}}
		>
			<InformationContainer
				advertiser={row}
				closeDetailedView={closeDetailedView}
				openEdit={openEdit}
				PopUpVisible={props.PopUpVisible}
				setPopUpVisible={props.setPopUpVisible}
				setSuccessMessage={props.setSuccessMessage}
				setIsSuccessPromptOpened={props.setIsSuccessPromptOpened}
			/>
		</PageWrapper>
	)
}

export default AdvertiserPreviewPage
