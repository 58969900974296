import {
	Box,
	LinearProgress,
	Paper,
	ThemeProvider,
	useTheme,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { Logo } from '../../pages/components/reusableComponents'
import logo from '../varys-logo-colored.png'
import './loading.css'
export const Loading = (props: { loading: boolean; report?: boolean }) => {
	if (!props.loading) {
		return <></>
	}
	return (
		<Paper
			style={{
				position: 'absolute',
				top: '0%',
				right: '0%',
				width: 'calc(100vw)',
				height: props.report ? '200vh' : '100vh',
				backdropFilter: 'blur(6px)',
				zIndex: '99',
				backgroundColor: 'transparent',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<div
				className='lds-ring'
				style={{ position: 'absolute', marginTop: '-5%' }}
			>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</Paper>
	)
}

export const FirstLoading = (props: { progress: number; style?: any }) => {
	const theme = useTheme()
	// useEffect(() => {
	//  console.log(props.progress)
	// }, [props.progress])

	return (
		<>
			<ThemeProvider theme={theme}>
				<Paper
					style={{
						position: 'fixed',
						top: '0%',
						width: '100%',
						height: '100%',
						zIndex: '12',
						backgroundColor: theme.colors.base.white,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						left: '0%',
						flexDirection: 'column',
						overflow: 'hidden',
						...props.style,
					}}
				>
					<Paper
						style={{
							position: 'absolute',
							top: '0%',
							width: '100%',
							height: '100%',
							zIndex: '12',
							backgroundColor: 'transparent',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							left: '0%',
							flexDirection: 'column',
							overflow: 'hidden',
						}}
					></Paper>
					<div
						style={{
							display: 'flex',
							position: 'relative',
							// transform: 'scale(0.8)'
							marginTop: -160,
						}}
					>
						<img height={300} src={logo} alt='Varys' />
					</div>
					<Box
						sx={{
							position: 'relative',
							width: '30%',
							height: '60px',
							marginTop: '40px',
						}}
					>
						<LinearProgress
							style={{
								zIndex: '13',
								position: 'relative',
								top: -2,
								height: 9,
								borderRadius: '10px',
								backgroundColor: theme.colors.base.white,
							}}
							variant='determinate'
							value={Math.min(props.progress, 100)} // Ensure it doesn't exceed 100%
							color='primary'
							classes={{}}
						/>
						<Box
							height={'60px'}
							sx={{
								position: 'relative',
								width: '101%',
								height: '11px',
								// marginTop: '100px',
								marginLeft: '-0.5%',
								marginTop: -1.5,
								borderRadius: '10px',
								background: `linear-gradient(90deg, ${theme.colors.base.green200} 0%, ${theme.colors.base.green400} 100%)`,
							}}
						></Box>
					</Box>
					{
						<span style={{ color: theme.colors.text.titles, zIndex: '13' }}>
							Daily download might take longer
						</span>
					}
				</Paper>
			</ThemeProvider>
		</>
	)
}

export const SmallLoading: React.FC<{ style?: React.CSSProperties }> = ({
	style = {},
}) => {
	return (
		<Paper
			elevation={0}
			style={{
				position: 'absolute',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
				zIndex: '11',
				backgroundColor: 'transparent',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				...style,
			}}
		>
			<div
				className='lds-ring-small'
				style={{ position: 'absolute', marginTop: '-5%' }}
			>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</Paper>
	)
}

export const CustomLoader: React.FC<{ isLoading: boolean }> = ({
	isLoading,
}) => {
	if (!isLoading) return null

	return (
		<Paper
			elevation={0}
			style={{
				position: 'fixed',
				top: '50%',
				left: '50%',
				zIndex: 1000,
				transform: 'translate(-50%, -50%)',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				borderRadius: '50%',
				padding: '1rem',
				backgroundColor: 'transparent',
			}}
		>
			<div className='lds-ring-small'>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</Paper>
	)
}

export const LoadingOverlay = (props: { loading: boolean }) => {
	const [showLoader, setShowLoader] = useState(props.loading)

	useEffect(() => {
		let timer: NodeJS.Timeout
		if (props.loading) {
			setShowLoader(true)
			timer = setTimeout(() => {
				setShowLoader(false)
			}, 1500)
		} else {
			setShowLoader(false)
		}
		return () => clearTimeout(timer)
	}, [props.loading])

	if (!showLoader) {
		return null
	}

	return (
		<div
			style={{
				position: 'fixed',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
				zIndex: 1200,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				width: '100%',
				height: '100%',
			}}
		>
			<div className='lds-ring'>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
	)
}
