import { Grid } from '@mui/material'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { checkInputStartsWithHttp } from '../../utils/helpers/tableHelper'
import { countryList } from '../components/countryList'
import { OwnerSelect, StyledNameField } from '../components/reusableComponents'
import { stringIsValidName } from '../../utils/helpers/helperFuncs'
import moment from 'moment'
import { InputFieldText, RadioChipInput } from '../components/Inputs'
import { DropList } from '../components/SelectableInputs'

//this component will render and allow to edit / save a new publisher. It's the main file for all the inputs.
export const FinanceInfo = (props: {
	paymentTerm: string
	setPaymentTerm: any
	branch: string
	setBranch: Dispatch<SetStateAction<string>>
	vat: string
	setVat: any
	financeId: string
	setFinanceId: any
	financeIdInUse: any
	settings: any
	loginRole: any
	mandatoryFields: any
}) => {
	return (
		<Grid
			container
			columnSpacing={2}
			direction='row'
			style={{
				display: 'flex',
				gap: '4rem',
				marginLeft: '6px',
				marginTop: '2.5rem',
				marginBottom: 300,
			}}
		>
			<Grid item xs={2}>
				<InputFieldText
					label='Finance ID'
					type='number'
					value={props.financeId}
					onChange={props.setFinanceId}
					errormessage={
						props.financeIdInUse ? 'This id is already in use' : undefined
					}

					// style={{boxShadow: !props.externalId ? 'red 0px 0px 7px': 'none',}}
				/>
			</Grid>
			<Grid item xs={2}>
				<DropList
					label={'Internal Legal Entity'}
					options={
						props.settings.settings.branches
							? props.settings.settings.branches.sort()
							: []
					}
					value={props.branch}
					onChange={(e: string) => props.setBranch(e)}
					disabled={props.loginRole === 'view'}
				/>
			</Grid>
			<Grid item xs={2.8}>
				<RadioChipInput
					label='Payment Term'
					options={
						props.settings.settings.paymentTerms
							? ['Prepayment', ...props.settings.settings.paymentTerms].sort()
							: []
					}
					value={props.paymentTerm}
					onChange={(e: string) => props.setPaymentTerm(e)}
					disabled={props.loginRole === 'view'}
				/>
			</Grid>

			<Grid item xs={2.8}>
				<RadioChipInput
					label={'VAT (%)'}
					options={
						props.settings.settings.vatValues
							? props.settings.settings.vatValues.sort()
							: []
					}
					value={props.vat}
					onChange={(e: string) => props.setVat(e)}
					disabled={props.loginRole === 'view'}
				/>
			</Grid>
		</Grid>
	)
}
