import React, { useEffect, useState } from 'react'
import { InputFieldText } from '../components/Inputs'
import { SubmitButton } from '../components/Buttons'
import success from '../../assets/success-icon.svg'
import { setISODay } from 'date-fns'
import { useTheme } from '@mui/material'

const ResetPassword = (props: {
	setCurrentView: any
	onResetPassword: any
	errorMsg?: string
	isSubmit?: boolean
}) => {
	const theme = useTheme()
	const [isSubmit, setIsSubmit] = useState(false)
	const [email, setEmail] = useState('')
	const [errorMessage, setErrorMessage] = useState('')
	const handleReset = async () => {
		setErrorMessage('')
		await props.onResetPassword(email)
	}

	useEffect(() => {
		if (props.errorMsg) {
			setErrorMessage(props.errorMsg)
		} else {
			setErrorMessage('')
		}
	}, [props.errorMsg])

	useEffect(() => {
		props.isSubmit ? setIsSubmit(props.isSubmit) : setIsSubmit(false)
	}, [props.isSubmit])

	const handleGoBack = () => {
		props.setCurrentView('login')
	}

	const handleSetEmail = (e: string) => {
		setErrorMessage('')
		setEmail(e)
	}
	return (
		<>
			{!isSubmit && (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						gap: '1.25rem',
					}}
				>
					<span
						style={{
							fontSize: '2.5rem',
							fontWeight: 'normal',
							color: theme.colors.base.grey900,
						}}
					>
						Reset Password
					</span>
					<span
						style={{ textAlign: 'center', color: theme.colors.base.grey600 }}
					>
						Enter your email address and we'll send you an email with
						instructions to reset your password
					</span>
					<InputFieldText
						errormessage={errorMessage ? errorMessage : undefined}
						label='Email'
						value={email}
						type={'text'}
						onChange={(e: string) => handleSetEmail(e)}
					/>
					<SubmitButton
						onClick={handleReset}
						disabled={!email}
						style={{
							width: '11.75rem',
							borderRadius: '0.25rem',
							marginTop: '1.25rem',
							background: theme.colors.base.green200,
							border: '2px solid ' + theme.colors.base.green200,
							textTransform: 'none',
							fontSize: theme.font.size.body,
							color: theme.colors.text.titles,
							'&.Mui-disabled': {
								background: theme.colors.base.grey100,
							},
							'&:hover': {
								background: theme.colors.base.green20,
								border: '2px solid ' + theme.colors.base.green200,
								color: theme.colors.base.grey900,
							},
							'&.Mui-focused': {
								background: theme.colors.base.green100,
							},
						}}
					>
						Reset
					</SubmitButton>
				</div>
			)}
			{isSubmit && (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						gap: '1.25rem',
					}}
				>
					<img
						style={{
							textAlign: 'center',
							width: '5.375rem',
							color: theme.colors.base.green200,
						}}
						src={success}
						alt='success'
					/>
					<span
						style={{
							textAlign: 'center',
							color: theme.colors.base.green200,
							fontSize: '4rem',
							fontWeight: theme.font.weight.bold,
						}}
					>
						Success!
					</span>
					<span
						style={{ color: theme.colors.base.grey900, textAlign: 'center' }}
					>
						An email has been sent to your email. Please check for an email from
						the company and follow the instructions to reset your password
					</span>
					<SubmitButton
						onClick={handleGoBack}
						style={{
							width: '11.75rem',
							borderRadius: '4px',
							marginTop: '1.25rem',
							background: theme.colors.base.green200,
							border: '2px solid ' + theme.colors.base.green200,
							textTransform: 'none',
							fontSize: theme.font.size.body,
							color: theme.colors.text.titles,
							'&.Mui-disabled': {
								background: theme.colors.base.grey100,
							},
							'&:hover': {
								background: theme.colors.base.green20,
								border: '2px solid ' + theme.colors.base.green200,
								color: theme.colors.base.grey900,
							},
							'&.Mui-focused': {
								background: theme.colors.base.green100,
							},
						}}
					>
						Back to home
					</SubmitButton>
				</div>
			)}
		</>
	)
}

export default ResetPassword
