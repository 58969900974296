import React, { useState, useEffect } from 'react'
import RegistrationCard from '../components/RegistrationCard'

interface RegistrationProps {
	values: any
	updateFormData: any
    settings: any
}

const RegistrationDetailsPublishers = (props: RegistrationProps) => {
	const { values, updateFormData, settings } = props

	const [legalEntity, setLegalEntity] = useState('')
	const [companyAddress, setCompanyAddress] = useState('')
	const [preferredChannel, setPreferredChannel] = useState([])
	const [platformCapabilities, setPlatformCapabilities] = useState([])
	const [appWebCampaign, setAppWebCampaign] = useState('')
	const [os, setOs] = useState('')
	const [fraudTools, setFraudTools] = useState([])
	const [vertical, setVertical] = useState([])
	const [geo, setGeo] = useState([])
	const [selfServiceAccount, setSelfServiceAccount] = useState('')
	const [cityTargetedCampaigns, setCityTargetedCampaigns] = useState('')
	const [rebrokingCampaigns, setRebrokingCampaigns] = useState('')
	const [text, setText] = useState('')

	useEffect(() => {
		updateFormData({
			legalEntity,
			companyAddress,
			preferredChannel,
			platformCapabilities,
			appWebCampaign,
			os,
			fraudTools,
			vertical,
			geo,
			selfServiceAccount,
			cityTargetedCampaigns,
			rebrokingCampaigns,
			text,
		})
	}, [
		legalEntity,
		companyAddress,
		preferredChannel,
		platformCapabilities,
		appWebCampaign,
		os,
		fraudTools,
		vertical,
		geo,
		selfServiceAccount,
		cityTargetedCampaigns,
		rebrokingCampaigns,
		text,
	])

	const fields = [
		{
			type: 'text',
			label: 'Legal Entity',
			value: legalEntity,
			onChange: setLegalEntity,
            mandatory: true
		},
		{
			type: 'text',
			label: 'Company Address',
			value: companyAddress,
			onChange: setCompanyAddress,
            mandatory: true
		},
		{
			type: 'chiplist',
			label: 'Preferred communication channel',
			value: preferredChannel,
			onChange: setPreferredChannel,
			options: settings.communicationChannel, // replace with Varys settings data
		},
		{
			type: 'chiplist',
			label: 'Platforms',
			value: platformCapabilities,
			onChange: setPlatformCapabilities,
			options: settings.platforms, // replace with Varys settings data
		},
		{
			type: 'droplist',
			label: 'Type of Campaign',
			value: appWebCampaign,
			onChange: setAppWebCampaign,
			options: [
				{ value: 'app', label: 'App' },
				{ value: 'web', label: 'Web' },
				{ value: 'both', label: 'Both' },
			],
            mandatory: true
		},
		{
			type: 'droplist',
			label: 'OS',
			value: os,
			onChange: setOs,
			options: [
				{ value: 'ios', label: 'iOS' },
				{ value: 'android', label: 'Android' },
				{ value: 'both', label: 'Both' },
			],
			single: true,
		},
		{
			type: 'chiplist',
			label: 'Fraud Tools',
			value: fraudTools,
			onChange: setFraudTools,
			options: settings.fraudTools, // replace with Varys settings data
		},
		{
			type: 'chiplist',
			label: 'Vertical',
			value: vertical,
			onChange: setVertical,
			options: settings.vertical, // replace with Varys settings data
		},
		{
			type: 'country',
			label: 'Market Of Interest',
			value: geo,
			onChange: setGeo,
            mandatory: true,
		},
		{
			type: 'droplist',
			label: 'Self-Service Account',
			value: selfServiceAccount,
			onChange: setSelfServiceAccount,
			options: [
				{ value: 'yes', label: 'Yes' },
				{ value: 'no', label: 'No' },
			],
			single: true,
            mandatory: true,
		},
		{
			type: 'droplist',
			label: 'City Targeted Campaigns',
			value: cityTargetedCampaigns,
			onChange: setCityTargetedCampaigns,
			options: [
				{ value: 'yes', label: 'Yes' },
				{ value: 'no', label: 'No' },
			],
			single: true,
            mandatory: true,
		},
		{
			type: 'droplist',
			label: 'Rebroking Campaigns',
			value: rebrokingCampaigns,
			onChange: setRebrokingCampaigns,
			options: [
				{ value: 'yes', label: 'Yes' },
				{ value: 'no', label: 'No' },
			],
			single: true,
		},
		{
			type: 'textarea',
			label: 'Special Requests',
			value: text,
			onChange: setText,
			placeholder: 'Please provide any special requests or preferences',
		},
	]

	return (
		<RegistrationCard
			title='Registration Details - Publisher'
			fields={fields}
		/>
	)
}

export default RegistrationDetailsPublishers
