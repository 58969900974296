import { Dispatch } from 'redux'
import { ActionType } from '../action-types'
import { Action } from '../actions/index'
import { v4 as uuidv4 } from 'uuid'
import { ALERT_STATUS_SUCCESS, Alert } from '../../models/alert.interface'
import { ReportsByUser } from '../reducers/reportReducer'
import { AlertStatus } from '../../models/alert.interface'

export const addAlert = (
	message: string,
	isError: boolean = false,
	link?: string,
	reportUuid?: string | undefined,
	id?: string,
	status?: AlertStatus,
): Action => {
	return {
		type: ActionType.ADD_ALERT,
		payload: {
			id: id || uuidv4(),
			message,
			isError,
			seen: false,
			link,
			extra_data: reportUuid,
			status: status || ALERT_STATUS_SUCCESS,
		},
	}
}

export const removeAlert = (id: string): Action => {
	return {
		type: ActionType.REMOVE_ALERT,
		payload: id,
	}
}

export const markAlertsAsSeen = (alerts: Alert[]): Action => {
	return {
		type: ActionType.MARK_ALERTS_AS_SEEN,
		payload: alerts.map((alert) => ({ ...alert, seen: true })),
	}
}
export const openAlert = (): Action => {
	return {
		type: ActionType.OPEN,
		payload: [],
	}
}
export const closeAlert = (): Action => {
	return {
		type: ActionType.CLOSE,
		payload: [],
	}
}

export const setTabIndex = (
	userEmail: string,
	reportType: keyof ReportsByUser,
	tabIndex: number,
) => {
	return (dispatch: Dispatch<Action>) => {
		dispatch({
			type: ActionType.SET_TAB_INDEX,
			payload: {
				userEmail,
				reportType,
				tabIndex,
			},
		})
	}
}

export const updateAlert = (
	id: string,
	message?: string,
	isError?: boolean,
	status?: AlertStatus,
	link?: string,
): Action => {
	return {
		type: ActionType.UPDATE_ALERT,
		payload: {
			id,
			message,
			isError,
			status,
			link,
		},
	}
}

export const minimizeAlert = (id: string): Action => {
	return {
		type: ActionType.MINIMIZE_ALERT,
		payload: id,
	}
}

export const restoreAllAlerts = (): Action => ({
	type: ActionType.RESTORE_ALL_ALERTS,
})
