import React, { useEffect, useState } from 'react'
import { StyledTab, StyledTabs } from '../advertisers/AdvertiserStyled'
import { PageWrapper } from '../components/reusableComponents'
import { TabPanel } from '../advertisers/AdvertiserPopUp'
import { SalesPlan } from './SalesPlan'
import { AdEditor } from './AdEditor'
import { useTheme } from '@mui/material'

interface Props {
	setErrorMessage?: any
	setUserMessage?: any
	setIsUserMessagePromptOpened?: any
	setLoading: any
}

export const AdsPlacementPage: React.FC<Props> = (props) => {
	const [tab, setTab] = useState(1)
	const theme = useTheme()

	useEffect(() => {
		props.setLoading(false)

		return () => {}
	}, [])

	const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
		setTab(newValue)
	}
	function a11yProps(index: number) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		}
	}
	return (
		<PageWrapper
			style={{
				marginTop: '20px',
				display: 'flex',
				alignItems: 'center',
				flexDirection: 'column',
				height: '90vh',
			}}
		>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					marginTop: '2rem',
				}}
			>
				<StyledTabs
					value={tab}
					onChange={handleChangeTab}
					aria-label='basic tabs example'
				>
					<StyledTab
						style={{
							paddingLeft: '20px',
							paddingRight: '20px',
							width: '250px',
							color:
								tab === 1 ? theme.colors.text.grey : theme.colors.base.white,
							textTransform: 'none',
						}}
						label='Apps'
						{...a11yProps(0)}
					/>
					<StyledTab
						style={{
							paddingLeft: '20px',
							paddingRight: '20px',
							width: '250px',
							color:
								tab === 0 ? theme.colors.text.grey : theme.colors.base.white,
							textTransform: 'none',
						}}
						label='Ads Placement'
						{...a11yProps(1)}
					/>
				</StyledTabs>
			</div>
			<TabPanel value={tab} index={0}>
				<div style={{ width: '100%' }}>
					<SalesPlan
						setErrorMessage={props.setErrorMessage}
						setLoading={props.setLoading}
					/>
				</div>
			</TabPanel>
			<TabPanel value={tab} index={1}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<AdEditor
						setUserMessage={props.setUserMessage}
						setErrorMessage={props.setErrorMessage}
						setIsUserMessagePromptOpened={props.setIsUserMessagePromptOpened}
						setLoading={props.setLoading}
					/>
				</div>
			</TabPanel>
		</PageWrapper>
	)
}
