import { AppInterface } from '../../models/model.interface'
import { ActionType } from '../action-types/index'
import { Action } from '../actions'

export interface AppReducerInterface {
	app: AppInterface[] | any
	appIcons: any
}

const initialState = {
	app: [],
	appIcons: [],
}

const reducer = (
	state: AppReducerInterface = initialState,
	action: Action | any,
): AppReducerInterface => {
	switch (action.type) {
		case ActionType.GET_APP:
			return {
				...state,
				app: action.payload,
			}
		case ActionType.GET_ICONS:
			return {
				...state,
				appIcons: action.payload,
			}
		case ActionType.UPDATE_APP_P360:
			for (const app of state.app) {
				if (app.emailGroup) {
					const newEmailGroup = JSON.parse(app.emailGroup)
					for (const group of newEmailGroup) {
						if (group.publisher.includes(action.payload.app.publisher)) {
							group.email.push(action.payload.app.email)
						}
					}
					app.emailGroup = JSON.stringify(newEmailGroup)
				}
			}
			return {
				...state,
			}
		case ActionType.REMOVE_APP_P360:
			for (const app of state.app) {
				if (app.emailGroup) {
					const newEmailGroup = JSON.parse(app.emailGroup)
					for (const group of newEmailGroup) {
						if (group.publisher.includes(action.payload.app.publisher)) {
							group.email = group.email.filter(
								(email: string) => email !== action.payload.app.email,
							)
						}
					}
					app.emailGroup = JSON.stringify(newEmailGroup)
				}
			}
			return {
				...state,
			}
		case ActionType.INSERT_P360_RULE:
			for (const app of state.app) {
				if (
					app.app_id === action.payload.app_id &&
					app.advertiser_name === action.payload.advertiser_name
				) {
					if (app.emailGroup && typeof app.emailGroup !== 'object') {
						app.emailGroup = JSON.parse(app.emailGroup)
					}
					if (app.emailGroup) {
						app.emailGroup.push(JSON.parse(action.payload.emailGroup))
					} else {
						app.emailGroup = JSON.parse(action.payload.emailGroup)
					}
					app.emailGroup = JSON.stringify(app.emailGroup)
				}
			}
			return {
				...state,
			}
		case ActionType.REMOVE_P360_RULE:
			for (const app of state.app) {
				if (
					app.app_id === action.payload.app_id &&
					app.advertiser_name === action.payload.advertiser_name
				) {
					if (app.emailGroup && typeof app.emailGroup !== 'object') {
						app.emailGroup = JSON.parse(app.emailGroup)
					}
					if (app.emailGroup) {
						app.emailGroup.filter(
							(el: any) => el.id !== action.payload.emailGroup[0].id,
						)
						app.emailGroup = JSON.stringify(app.emailGroup)
					} else {
						app.emailGroup = JSON.stringify([])
					}
				}
			}
			return {
				...state,
			}
		case ActionType.UPDATE_APP:
			const index = state.app.findIndex(
				(v: any) => v.app_id === action.payload.app_id,
			)
			state.app[index] = action.payload
			return {
				...state,
			}
		case ActionType.LOGOUT:
			return {
				...state,
				app: [],
			}
		default:
			return state
	}
}

export default reducer
