import { Dispatch } from 'redux'
import { ActionType } from '../action-types'
import { Action } from '../actions/index'
import AxiosCustom from '../../utils/Axios'
import { permissionNames } from '../../utils/helpers/permissionsHelper'
import { endpoints } from '../../config'

// export const getCompanySettings = () => {
// 	return async (dispatch: Dispatch<Action>) => {
// 		try {
// 			const { data } = await AxiosCustom.get(endpoints.COMPANY_SETTINGS, {
// 				headers: {
// 					'x-permission-name': permissionNames.VIEW_SETTINGS, // add company settings permissions
// 				},
// 			})
// 			dispatch({
// 				type: ActionType.GET_COMPANY_SETTINGS,
// 				payload: data.payload[0],
// 			})
// 		} catch (err) {}
// 	}
// }

// export const updateCompanySettings = (settings: any) => {
// 	return async (dispatch: Dispatch<Action>) => {
// 		try {
// 			const { data } = await AxiosCustom.post(
// 				endpoints.COMPANY_SETTINGS,
// 				settings,
// 				{
// 					headers: {
// 						'x-permission-name': permissionNames.ADD_EDIT_SETTINGS, // add company settings permissions
// 					},
// 				},
// 			)
// 			// console.log('updated settings for company settings: ', data)
// 			dispatch({
// 				type: ActionType.UPDATE_COMPANY_SETTINGS,
// 				payload: data.payload,
// 			})
// 		} catch (err) {}
// 	}
// }

export const getSettingsAction = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.SETTINGS, {
				headers: {
					'x-permission-name': permissionNames.VIEW_SETTINGS,
				},
			})
			const settings = {
				allowedPlatforms: data.payload[0].allowedPlatforms
					? data.payload[0].allowedPlatforms
					: [],
				vertical: data.payload[0].vertical ? data.payload[0].vertical : [],
				creatorVertical: data.payload[0].creatorVertical
					? data.payload[0].creatorVertical
					: [],
				capabilities: data.payload[0].capabilities
					? data.payload[0].capabilities
					: [],
				salesRepresentative: data.payload[0].salesRepresentative
					? data.payload[0].salesRepresentative
					: [],
				teams: data.payload[0].teams ? data.payload[0].teams : [],
				fraudTools: data.payload[0].fraudTools
					? data.payload[0].fraudTools
					: [],
				platforms: data.payload[0].platforms ? data.payload[0].platforms : [],
				contactRole: data.payload[0].contactRole
					? data.payload[0].contactRole
					: [],
				banners: data.payload[0].banners ? data.payload[0].banners : [],
				videos: data.payload[0].videos ? data.payload[0].videos : [],
				paymentTerms: data.payload[0].paymentTerms
					? data.payload[0].paymentTerms
					: [],
				pauseReason: data.payload[0].pauseReason
					? data.payload[0].pauseReason
					: [],
				trafficRestrictions: data.payload[0].trafficRestrictions
					? data.payload[0].trafficRestrictions
					: [],
				existingChannels: data.payload[0].existingChannels
					? data.payload[0].existingChannels
					: [],
				communicationChannel: data.payload[0].communicationChannel
					? data.payload[0].communicationChannel
					: [],
				targetAudience: data.payload[0].targetAudience
					? data.payload[0].targetAudience
					: [],
				targetAudienceOthers: data.payload[0].targetAudienceOthers
					? data.payload[0].targetAudienceOthers
					: [],
				campaignGoals: data.payload[0].campaignGoals
					? data.payload[0].campaignGoals
					: [],
				currency: data.payload[0].currency ? data.payload[0].currency : [],
				paymentMethod: data.payload[0].paymentMethod
					? data.payload[0].paymentMethod
					: [],
				branches: data.payload[0].branches ? data.payload[0].branches : [],
				vatValues: data.payload[0].vatValues ? data.payload[0].vatValues : [],
				// permissionGroups: data.payload[0].permissionGroups
				// 	? data.payload[0].permissionGroups
				// 	: [],
				teamLeaders: data.payload[0].teamLeaders
					? data.payload[0].teamLeaders
					: [],
				userPositions: data.payload[0].userPositions
					? data.payload[0].userPositions
					: [],
				// userTypes: data.payload[0].userTypes ? data.payload[0].userTypes : [],
			}
			dispatch({
				type: ActionType.GET_SETTINGS,
				payload: settings,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
export const updateSettings = (
	// headers: any,
	obj: any,
	setErrorMessage: any,
	setSucessMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const settings = {
				settings: obj,
			}
			const { data } = await AxiosCustom.post(endpoints.SETTINGS, settings, {
				headers: {
					'x-permission-name': permissionNames.ADD_EDIT_SETTINGS,
				},
			})
			if (!data.successful) {
				setErrorMessage(data)
				return
			}
			setSucessMessage(true)
			dispatch({
				type: ActionType.UPDATE_SETTINGS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
