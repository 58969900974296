import React from 'react'
import StatusPagesCommonLayout from './StatusPagesCommonLayout'
import { LoginButton } from '../login/loginPageComponents'
import { useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const UnderMaintenance = () => {
	const navigate = useNavigate()
	const theme = useTheme()
	return (
		<StatusPagesCommonLayout
			title="We'll be back soon!"
			description='The page is currently under maintenance.'
			maintenance
		>
			<LoginButton
				variant='contained'
				size='large'
				onClick={() => navigate('/')}
				style={{
					width: '436px',
					height: '44px',
					font: 'Lato',
					backgroundColor: theme.customComponents.button.green,
					color: '#242D32',
					borderRadius: '6px',
				}}
			>
				Back to home
			</LoginButton>
		</StatusPagesCommonLayout>
	)
}

export default UnderMaintenance
