import '@inovua/reactdatagrid-community/index.css'
import ReportIcon from '@mui/icons-material/Report'
import { Chip, Tooltip, Typography, useTheme } from '@mui/material'
import {
	DataGridPro,
	GridColDef,
	GridToolbarContainer,
	GridToolbarFilterButton,
} from '@mui/x-data-grid-pro'
import React, { useEffect, useState } from 'react'
import ReactCountryFlag from 'react-country-flag'
import {
	capitalizeFirstLetter,
	filterForDates,
	generateRowsWithIds,
	itemExists,
	searchFor,
	summariseDictionaryField,
} from '../../utils/helpers/tableHelper'
import {
	// TableSearchComponent,
	TableWrapper,
} from '../components/reusableComponents'
import { TableSearchComponent } from '../components/Search'
import { GraphWrapper } from '../p360dashboard/GraphCreator'

export interface summedRowInterface {
	[key: string]: Object | number | null | string
}

const DashboardTable = (props: {
	report: any
	reportType: string
	setLoading: any
	rowCount: number
	dates: any
	invalidReport: any
	setReport: any
	spreadByDate: any
	setSpreadByDate: any
	filters: any[]
	dataObj: any
	datesArray: any
	rules: any
	graphs: any
	breakdownFields: any
	statisticsFields: any
}) => {
	let allDatesArray: any[] = []
	const theme = useTheme()
	const dateee = new Date().setMonth(new Date().getMonth() - 1)
	for (var i = 0; i !== 31; i++) {
		const newDate: Date = new Date(dateee)
		newDate.setDate(newDate.getDate() + i)
		allDatesArray.push(newDate.toISOString().substring(0, 10))
	}

	const nameField =
		props.reportType === 'daily'
			? 'date'
			: props.reportType === 'advertiser'
			? 'advertiser_name'
			: props.reportType === 'publisher'
			? 'publisher_name'
			: props.reportType === 'country'
			? 'country'
			: props.reportType === 'campaign'
			? 'campaign_name'
			: props.reportType === 'accountManager'
			? 'advertiser_owner'
			: props.reportType === 'publisherManager'
			? 'publisher_owner'
			: ''

	const [pinnedRow, setPinnedRow] = useState({})
	const [summRow, setSummRow] = useState(undefined)
	const [dictionary, setDictionary] = useState<any>({ empty: [], filled: [] })
	const [invalidDictionary, setInvalidDictionary] = useState<any>({})
	const [useFound, setUseFound] = useState(false)
	const [rowWidth, setRowWidth] = useState(
		Math.floor(
			(window.innerWidth - theme.dimentions.drawerWidth) / props.rowCount,
		),
	)

	let tableType = 0
	const tableTypeOne = ['daily', 'advertiser', 'publisher', 'country']
	const tableTypeTwo = ['campaign']
	const tableTypeThree = ['accountManager', 'publisherManager', 'salesManager']

	const [found, setFound] = useState<any[]>(props.report)
	const [filterStatus, setFilterStatus] = useState(0)
	const [filteredForDates, setFilteredForDates] = useState<any[]>([])
	const [filterButtonEl, setFilterButtonEl] =
		React.useState<HTMLButtonElement | null>(null)
	const [search, setSearch] = useState('')

	var diff = Math.abs(props.dates.start.getTime() - props.dates.end.getTime())
	var diffDays = Math.ceil(diff / (1000 * 3600 * 24))

	if (tableTypeOne.includes(props.reportType)) {
		tableType = 1
	}
	if (tableTypeTwo.includes(props.reportType)) {
		tableType = 2
	}
	if (tableTypeThree.includes(props.reportType)) {
		tableType = 3
	}

	let clicksSum = 0
	let costSum = 0
	let impressionsSum = 0
	let installsSum = 0
	let profitSum = 0
	let revenueSum = 0
	let crSum = 0
	let invClicksSum = 0
	let invImpressionsSum = 0
	let invInstallsSum = 0
	let invCRSum = 0
	let level2EventSum = 0
	let level3EventSum = 0
	let level4EventSum = 0
	let level5EventSum = 0

	const summedRow: summedRowInterface = {
		clicks: clicksSum,
		cost: costSum,
		date: null,
		id: null,
		cr: crSum,
		impressions: impressionsSum,
		installs: installsSum,
		invalidClicks: invClicksSum,
		invalidImpressions: invImpressionsSum,
		invalidInstalls: invInstallsSum,
		invalidCr: invCRSum,
		profit: profitSum,
		publisher_name: null,
		advertiser_name: null,
		campaign_name: null,
		country: null,
		revenue: revenueSum,
		level_2_event: level2EventSum,
		level_3_event: level3EventSum,
		level_4_event: level4EventSum,
		level_5_event: level5EventSum,
	}

	useEffect(() => {
		setRowWidth(
			Math.floor(
				!props.spreadByDate
					? (window.innerWidth - theme.dimentions.drawerWidth) / props.rowCount
					: (window.innerWidth - theme.dimentions.drawerWidth * 3.5) /
							props.rowCount,
			),
		)
	}, [props.rowCount, props.spreadByDate])

	const columns: GridColDef[] = props.spreadByDate
		? [
				{
					field: 'date',
					headerName: capitalizeFirstLetter('date'),
					width: rowWidth,
					align: 'left',
					headerAlign: 'left',
					type: 'string',
					valueFormatter: ({ value }: { value: any }) => value,
				},
				{
					field: 'impressions',
					headerName: capitalizeFirstLetter('impressions'),
					width: rowWidth,
					align: 'left',
					headerAlign: 'left',
					type: 'number',
					valueFormatter: ({ value }: { value: any }) =>
						new Intl.NumberFormat().format(value),
				},

				{
					field: 'clicks',
					headerName: capitalizeFirstLetter('clicks'),
					width: rowWidth,
					align: 'left',
					headerAlign: 'left',
					type: 'number',
					valueFormatter: ({ value }: { value: any }) =>
						new Intl.NumberFormat().format(value),
				},
				{
					field: 'installs',
					headerName: capitalizeFirstLetter('installs'),
					width: rowWidth,
					align: 'left',
					headerAlign: 'left',
					type: 'number',
					valueFormatter: ({ value }: { value: any }) =>
						new Intl.NumberFormat().format(value),
				},
				{
					field: 'cr',
					headerName: 'CR',
					width: rowWidth,
					renderCell: ({ value }: any) =>
						value === undefined ? (
							<>{<ReportIcon style={{ fill: 'red' }} />}</>
						) : (
							Number(value * 100).toLocaleString() + ' %'
						),
				},
				{
					field: 'revenue',
					headerName: capitalizeFirstLetter('revenue'),
					width: rowWidth,
					type: 'number',
					align: 'left',
					headerAlign: 'left',
					valueFormatter: ({ value }: { value: any }) =>
						new Intl.NumberFormat('en-US', {
							style: 'currency',
							currency: 'USD',
						}).format(value),
					renderCell: ({ value }: any) =>
						value === undefined ? (
							<>{<ReportIcon style={{ fill: 'red' }} />}</>
						) : (
							Number(value).toLocaleString() + ' $'
						),
				},
				{
					field: 'cost',
					headerName: capitalizeFirstLetter('cost'),
					width: rowWidth,
					type: 'number',
					align: 'left',
					headerAlign: 'left',
					valueFormatter: ({ value }: { value: any }) =>
						new Intl.NumberFormat('en-US', {
							style: 'currency',
							currency: 'USD',
						}).format(value),
					renderCell: ({ value }: any) =>
						value === undefined ? (
							<>{<ReportIcon style={{ fill: 'red' }} />}</>
						) : (
							Number(value).toLocaleString() + ' $'
						),
				},
				{
					field: 'profit',
					headerName: capitalizeFirstLetter('profit'),
					width: rowWidth,
					type: 'number',
					align: 'left',
					headerAlign: 'left',
					valueFormatter: ({ value }: { value: any }) =>
						new Intl.NumberFormat('en-US', {
							style: 'currency',
							currency: 'USD',
						}).format(value),
					renderCell: ({ value }: any) =>
						value === undefined ? (
							<>{<ReportIcon style={{ fill: 'red' }} />}</>
						) : (
							Number(value).toLocaleString() + ' $'
						),
				},
		  ]
		: [
				{
					field: 'impressions',
					headerName: capitalizeFirstLetter('impressions'),
					width: rowWidth,
					align: 'left',
					headerAlign: 'left',
					type: 'number',
					valueFormatter: ({ value }: { value: any }) =>
						new Intl.NumberFormat().format(value),
				},

				{
					field: 'clicks',
					headerName: capitalizeFirstLetter('clicks'),
					width: rowWidth,
					align: 'left',
					headerAlign: 'left',
					type: 'number',
					valueFormatter: ({ value }: { value: any }) =>
						new Intl.NumberFormat().format(value),
				},
				{
					field: 'installs',
					headerName: capitalizeFirstLetter('installs'),
					width: rowWidth,
					align: 'left',
					headerAlign: 'left',
					type: 'number',
					valueFormatter: ({ value }: { value: any }) =>
						new Intl.NumberFormat().format(value),
				},
				{
					field: 'cr',
					headerName: 'CR',
					width: rowWidth,
					renderCell: ({ value }: any) =>
						value === undefined ? (
							<>{<ReportIcon style={{ fill: 'red' }} />}</>
						) : (
							Number(value * 100).toLocaleString() + ' %'
						),
				},
				{
					field: 'revenue',
					headerName: capitalizeFirstLetter('revenue'),
					width: rowWidth,
					type: 'number',
					align: 'left',
					headerAlign: 'left',
					valueFormatter: ({ value }: { value: any }) =>
						new Intl.NumberFormat('en-US', {
							style: 'currency',
							currency: 'USD',
						}).format(value),
					renderCell: ({ value }: any) =>
						value === undefined ? (
							<>{<ReportIcon style={{ fill: 'red' }} />}</>
						) : (
							Number(value).toLocaleString() + ' $'
						),
				},
				{
					field: 'cost',
					headerName: capitalizeFirstLetter('cost'),
					width: rowWidth,
					type: 'number',
					align: 'left',
					headerAlign: 'left',
					valueFormatter: ({ value }: { value: any }) =>
						new Intl.NumberFormat('en-US', {
							style: 'currency',
							currency: 'USD',
						}).format(value),
					renderCell: ({ value }: any) =>
						value === undefined ? (
							<>{<ReportIcon style={{ fill: 'red' }} />}</>
						) : (
							Number(value).toLocaleString() + ' $'
						),
				},
				{
					field: 'profit',
					headerName: capitalizeFirstLetter('profit'),
					width: rowWidth,
					type: 'number',
					align: 'left',
					headerAlign: 'left',
					valueFormatter: ({ value }: { value: any }) =>
						new Intl.NumberFormat('en-US', {
							style: 'currency',
							currency: 'USD',
						}).format(value),
					renderCell: ({ value }: any) =>
						value === undefined ? (
							<>{<ReportIcon style={{ fill: 'red' }} />}</>
						) : (
							Number(value).toLocaleString() + ' $'
						),
				},
		  ]
	let customField: GridColDef
	let groupModel = [
		{
			groupId: 'Data Points',
			children: !props.spreadByDate
				? nameField != 'date'
					? [{ field: nameField }, { field: 'date' }]
					: [{ field: nameField }]
				: [{ field: nameField }],
		},
		{
			groupId: 'Statistics',
			children: [{ field: 'revenue' }, { field: 'cost' }, { field: 'profit' }],
		},
	]

	useEffect(() => {
		//SWITCHES ARRAYS DEPENDING ON FILTERING AND SPREADING TODO:
		if (props.dates && props.report) {
			setFound(filteredForDates)
		}
		if (search.length > 0 && props.dates && props.report) {
			const wht = searchFor(search, filteredForDates, columns)
			setFound(wht)
		}
		if (props.dataObj.filters && props.dates && props.report) {
			const arr = []
			let filtered = false
			for (const key in props.dataObj.filters) {
				if (props.dataObj.filters[key].length > 0) {
					filtered = true
					for (let filterEntry of props.dataObj.filters[key]) {
						const wht = searchFor(filterEntry, filteredForDates, columns)

						arr.push(...wht)
					}
				}
			}
			if (filtered) {
				const uniqueArray = [
					...new Set(arr.map((obj) => JSON.stringify(obj))),
				].map((str) => JSON.parse(str))
				setFound(uniqueArray)
			}
		}
		// if (props.filters.length > 0 && props.dates && props.report) {
		//  if (props.spreadByDate != true) {
		//      const wht = searchFor(props.filters, filteredForDates)
		//      setFound(wht)
		//  } else {
		//      setFound(searchFor(props.filters, filteredForDates))
		//  }
		// }
	}, [
		search,
		filteredForDates,
		props.filters,
		props.spreadByDate,

		props.dataObj,
	])

	const calculateCR = (reportArray: any, sum: boolean) => {
		if (!sum) {
			for (let row of reportArray) {
				const invalidCR = row['invalidInstalls'] / row['invalidClicks']
				const cr = row['installs'] / row['clicks']

				row['cr'] =
					cr.toString() != 'NaN' ? parseFloat(cr.toString()) : parseFloat('0')
				row['invalidCr'] =
					invalidCR.toString() != 'NaN'
						? parseFloat(invalidCR.toString())
						: parseFloat('0')
			}
		} else {
			const invalidCR =
				reportArray['invalidInstalls'] / reportArray['invalidClicks']
			const cr = reportArray['installs'] / reportArray['clicks']

			reportArray['cr'] =
				cr.toString() != 'NaN' ? parseFloat(cr.toString()) : parseFloat('0')
			reportArray['invalidCr'] =
				invalidCR.toString() != 'NaN'
					? parseFloat(invalidCR.toString())
					: parseFloat('0')
		}
	}

	const combineDictionaries = (addTo: any, addFrom: any) => {
		let finalObject: any = Object.assign(
			{},
			...Object.keys(addTo.empty).map((x: any) => ({
				[x]: Object.assign(
					{},
					...allDatesArray.map((d: any) => ({
						[d]: [],
					})),
				),
			})),
		)
		let emptyObject: any = Object.assign(
			{},
			...Object.keys(addTo.empty).map((x: any) => ({
				[x]: Object.assign(
					{},
					...allDatesArray.map((d: any) => ({
						[d]: [],
					})),
				),
			})),
		)

		if (addTo) {
			for (let date of allDatesArray) {
				for (let name of Object.keys(addTo.filled)) {
					finalObject[name][date] = Object.assign(
						{},
						{
							valid: addTo.filled[name][date],
							invalid: {
								invalidClicks:
									addFrom.filled[name] &&
									addFrom.filled[name][date] &&
									addFrom.filled[name][date][0]
										? addFrom.filled[name][date][0]['clicks']
										: 0,
								invalidInstalls:
									addFrom.filled[name] &&
									addFrom.filled[name][date] &&
									addFrom.filled[name][date][0]
										? addFrom.filled[name][date][0]['installs']
										: 0,
								invalidImpressions:
									addFrom.filled[name] &&
									addFrom.filled[name][date] &&
									addFrom.filled[name][date][0]
										? addFrom.filled[name][date][0]['impressions']
										: 0,
							},
						},
					)
				}
			}
		}

		return { filled: finalObject, empty: emptyObject }
	}

	useEffect(() => {
		switch (nameField) {
			case 'advertiser_name':
			case 'publisher_name':
			case 'country':
			case 'date':
				const r_dict = makeDictionaries(props.report)
				const i_dict = makeDictionaries(props.invalidReport)
				const combined = combineDictionaries(r_dict, i_dict)
				setDictionary(combined)
				break
			case 'campaign_name':
			case 'advertiser_owner':
			case 'publisher_owner':
				const f_dict = makeDictionaries(props.report)
				const comb = combineDictionaries(f_dict, f_dict)
				setDictionary(comb)

				break
			default:
				break
		}
	}, [props.report])

	useEffect(() => {
		// FILTERS DICTIONARY AND CONVERTS IT TO ARRAY FOR TABLES

		const result = filterForDates(
			nameField,
			props.spreadByDate,
			dictionary,
			props.datesArray,
		)
		calculateCR(result, false)
		setFilteredForDates(result)
	}, [
		dictionary,
		props.dates.start,
		props.dates.end,
		props.spreadByDate,
		props.datesArray,
	])

	const makeDictionaries = (report: any) => {
		//MAKES DICTIONARIES TO PROPOGATE THROUGH STATE NAME - DATE - DICTIONARY

		const report_dictionary = Object.assign(
			{},
			...report.map((x: any) => ({
				[x[nameField]]: Object.assign(
					{},
					...allDatesArray.map((d: any) => ({
						[d]: [],
					})),
				),
			})),
		)
		const empty_dictionary = Object.assign(
			{},
			...Object.keys(report_dictionary).map((x: any) => ({
				[x]: Object.assign(
					{},
					...allDatesArray.map((d: any) => ({
						[d]: [],
					})),
				),
			})),
		)
		for (var i = 0; i < report.length; i++) {
			if (report[i][nameField]) {
				if (!itemExists(Object.values(report_dictionary), report[i])) {
					for (const [key, value] of Object.entries(report_dictionary)) {
						if (
							key === report[i][nameField] &&
							allDatesArray.includes(report[i]['date'])
						) {
							report_dictionary[report[i][nameField]][report[i]['date']].push(
								report[i],
							)
						}
					}
				}
			}
		}
		return { empty: empty_dictionary, filled: report_dictionary }
	}

	useEffect(() => {
		if (found !== props.report || found) {
			setUseFound(true)
		}
	}, [found])

	switch (tableType) {
		case 1:
			if (props.reportType === 'country') {
				customField = {
					field: props.reportType,
					headerName: capitalizeFirstLetter(props.reportType),
					width: rowWidth,
					renderCell: ({ value }) => (
						<Chip
							icon={
								<ReactCountryFlag
									style={{
										width: '1em',
										height: '1em',
										borderRadius: '30px',
									}}
									countryCode={value === 'uk' ? 'GB' : value}
									svg
								/>
							}
							style={{ borderWidth: '0px' }}
							variant='outlined'
							label={String(value) ? value.toUpperCase() : ''}
						/>
					),
				}
			} else {
				customField = {
					field:
						props.reportType === 'daily'
							? 'date'
							: props.reportType === 'advertiser' || 'publisher' || 'campaign'
							? props.reportType + '_name'
							: props.reportType,
					headerName: capitalizeFirstLetter(props.reportType),
					width: rowWidth,
					align: 'left',
					headerAlign: 'left',
					renderCell: ({ value }) => {
						return (
							<Tooltip
								title={value != undefined && value[0] ? value[0] : 'NO VALUE'}
							>
								<Typography fontSize={12}>
									{props.reportType === 'daily'
										? value
										: value != undefined && value[0]
										? capitalizeFirstLetter(value[0])
										: 'NO VALUE'}
								</Typography>
							</Tooltip>
						)
					},
				}
			}
			columns.unshift(customField)

			const invalidDataColumns: GridColDef[] = [
				{
					field: 'invalidImpressions',
					headerName: capitalizeFirstLetter('invalid impressions'),
					width: rowWidth,
					align: 'left',
					headerAlign: 'left',
					type: 'number',
					valueFormatter: ({ value }: { value: any }) =>
						new Intl.NumberFormat().format(value),
				},
				{
					field: 'invalidClicks',
					headerName: capitalizeFirstLetter('invalid clicks'),
					width: rowWidth,
					align: 'left',
					headerAlign: 'left',
					type: 'number',
					valueFormatter: ({ value }: { value: any }) =>
						new Intl.NumberFormat().format(value),
				},
				{
					field: 'invalidInstalls',
					headerName: capitalizeFirstLetter('invalid installs'),
					width: rowWidth,
					align: 'left',
					headerAlign: 'left',
					type: 'number',
					valueFormatter: ({ value }: { value: any }) =>
						new Intl.NumberFormat().format(value),
				},
				{
					field: 'invalidCr',
					headerName: 'invalid CR',
					width: rowWidth,
					renderCell: ({ value }: any) =>
						value === undefined ? (
							<>{<ReportIcon style={{ fill: 'red' }} />}</>
						) : (
							Number(value * 100).toLocaleString() + ' %'
						),
				},
			]
			!props.spreadByDate
				? columns.splice(5, 0, ...invalidDataColumns)
				: columns.splice(6, 0, ...invalidDataColumns)

			groupModel.unshift(
				...[
					{
						groupId: 'Approved',
						children: [
							{ field: 'impressions' },
							{ field: 'clicks' },
							{ field: 'installs' },
							{ field: 'cr' },
						],
					},
					{
						groupId: 'Invalid',
						children: [
							{ field: 'invalidImpressions' },
							{ field: 'invalidClicks' },
							{ field: 'invalidInstalls' },
							{ field: 'invalidCr' },
						],
					},
				],
			)

			break
		case 2:
			customField = {
				field: props.reportType + '_name',
				headerName: capitalizeFirstLetter(props.reportType),
				width: rowWidth,
				align: 'left',
				headerAlign: 'left',
				renderCell: ({ value }: any) => {
					return (
						<Tooltip title={value}>
							<Typography fontSize={12}>{value?.toString()}</Typography>
						</Tooltip>
					)
				},
			}
			columns.unshift(customField)
			const eventDataColumns: GridColDef[] = [
				{
					field: 'level_2_event',
					headerName: capitalizeFirstLetter('level 2 Event'),
					width: rowWidth,
					align: 'left',
					headerAlign: 'left',
					type: 'number',
					valueFormatter: ({ value }: { value: any }) =>
						new Intl.NumberFormat().format(value),
				},
				{
					field: 'level_3_event',
					headerName: capitalizeFirstLetter('level 3 Event'),
					width: rowWidth,
					align: 'left',
					headerAlign: 'left',
					type: 'number',
					valueFormatter: ({ value }: { value: any }) =>
						new Intl.NumberFormat().format(value),
				},
				{
					field: 'level_4_event',
					headerName: capitalizeFirstLetter('level 4 Event'),
					width: rowWidth,
					align: 'left',
					headerAlign: 'left',
					type: 'number',
					valueFormatter: ({ value }: { value: any }) =>
						new Intl.NumberFormat().format(value),
				},
				{
					field: 'level_5_event',
					headerName: capitalizeFirstLetter('level 5 Event'),
					width: rowWidth,
					align: 'left',
					headerAlign: 'left',
					type: 'number',
					valueFormatter: ({ value }: { value: any }) =>
						new Intl.NumberFormat().format(value),
				},
			]
			columns.splice(5, 0, ...eventDataColumns)

			groupModel.unshift(
				...[
					{
						groupId: 'Data',
						children: [
							{ field: 'impressions' },
							{ field: 'clicks' },
							{ field: 'installs' },
							{ field: 'cr' },
						],
					},
					{
						groupId: 'Events',
						children: [
							{ field: 'level_2_event' },
							{ field: 'level_3_event' },
							{ field: 'level_4_event' },
							{ field: 'level_5_event' },
						],
					},
				],
			)

			break
		case 3:
			customField = {
				field:
					props.reportType === 'accountManager'
						? 'advertiser_owner'
						: 'publisher_owner',
				headerName:
					capitalizeFirstLetter(props.reportType).slice(
						0,
						props.reportType.indexOf('Manager'),
					) + ' Manager',
				width: rowWidth,
				align: 'left',
				headerAlign: 'left',
				renderCell: ({ value }: any) => {
					return (
						<Tooltip title={value && value[0] ? value[0] : 'NO VALUE'}>
							<Typography fontSize={12}>
								{capitalizeFirstLetter(
									value && value[0] ? value[0] : 'NO VALUE',
								)}
							</Typography>
						</Tooltip>
					)
				},
			}
			columns.unshift(customField)
			if (props.reportType !== 'salesManager') {
				const teamField: GridColDef = {
					field: 'team',
					headerName: capitalizeFirstLetter('team'),
					width: rowWidth,
					align: 'left',
					headerAlign: 'left',
					renderCell: ({ value }: any) => {
						return (
							<Tooltip title={value}>
								<Typography fontSize={12}>
									{value && capitalizeFirstLetter(value)}
								</Typography>
							</Tooltip>
						)
					},
				}
				columns.splice(1, 0, teamField)
			}
			groupModel.unshift(
				...[
					{
						groupId: 'Credentials',
						children: [{ field: props.reportType }, { field: 'team' }],
					},
					{
						groupId: 'Data',
						children: [
							{ field: 'impressions' },
							{ field: 'clicks' },
							{ field: 'installs' },
							{ field: 'cr' },
						],
					},
				],
			)

			break
		default:
			break
	}
	const filtersSelected: any = {
		os: undefined,
		orderBy: undefined,
		accountManager: props.dataObj.filters.advertiser_manager,
		publisherManager: props.dataObj.filters.publisher_manager,
		appsflyerAccount: undefined,
	}

	let graphsComponents: any[] = props.graphs.map(
		(paramObj: any, index: number) => (
			<GraphWrapper
				data={props.report}
				filters={{ filters: filtersSelected }}
				users={[undefined]}
				paramObjectReference={props.graphs}
				setRefresh={() => undefined}
				index={index}
				optionsBreakdowns={props.breakdownFields}
				optionsStatistics={props.statisticsFields}
			></GraphWrapper>
		),
	)

	useEffect(() => {
		if (found && found.length > 0) {
			const sumRow = summariseDictionaryField(found, true, nameField, true)
			calculateCR(sumRow, true)
			const pinnedSumRow = {
				bottom: generateRowsWithIds([sumRow]),
			}

			setPinnedRow(pinnedSumRow)
		}
	}, [found])
	// console.log(props.report)
	return (
		<>
			{/*
			{graphsComponents} */}
			<TableWrapper
				style={{
					marginInline: '-2vw',
					marginTop: '120px',
					width: '99vw',
					paddingBottom: '60px',
				}}
				theme={theme}
				rowCount={props.rowCount}
				setRowWidth={setRowWidth}
			>
				{props.report && (
					<DataGridPro
						style={{
							fontSize: '0.75rem',
						}}
						columns={columns}
						rowHeight={23}
						rows={
							props.rules['Permissionto see aggregated + network report']
								? generateRowsWithIds(useFound ? found : props.report)
								: []
						}
						pageSizeOptions={[15, 25, 50]}
						showColumnVerticalBorder={true}
						columnGroupingModel={groupModel}
						pinnedRows={pinnedRow}
						slots={{
							toolbar: CustomDashboardToolbar,
						}}
						slotProps={{
							panel: {
								anchorEl: filterButtonEl,
							},
							toolbar: {
								setFilterButtonEl,
								filterStatus: filterStatus,
								setFilterStatus: setFilterStatus,
								setSearch: setSearch,
								search: search,
							},
						}}
					></DataGridPro>
				)}
			</TableWrapper>
		</>
	)
}

export const dashboardToolbar = () => {
	return <GridToolbarContainer></GridToolbarContainer>
}

const CustomDashboardToolbar: React.FunctionComponent<{
	setFilterButtonEl: React.Dispatch<
		React.SetStateAction<HTMLButtonElement | null>
	>
	filterStatus?: any
	setFilterStatus?: any
	setSearch: any
	search: any
}> = ({
	setFilterButtonEl,
	filterStatus,
	setFilterStatus,
	setSearch,
	search,
}) => {
	const theme = useTheme()
	const [filterTitleColor, setFilterTitleColor] = useState(
		theme.colors.text.titles,
	)
	const activeFilterHandler = () => {
		const number = filterStatus + 1
		if (number === 3) setFilterStatus(0)
		else setFilterStatus(number)
	}
	const handleFilterColor = (isHovered: boolean) => {
		if (isHovered) {
			setFilterTitleColor(theme.colors.base.white)
		} else {
			setFilterTitleColor(theme.colors.text.titles)
		}
	}
	return (
		<GridToolbarContainer
			style={{ gap: '12px', display: 'flex', justifyContent: 'flex-start' }}
		>
			<GridToolbarFilterButton
				ref={undefined}
				style={{ color: filterTitleColor }}
				// nonce={undefined}
				onMouseEnter={() => handleFilterColor(true)}
				onMouseLeave={() => handleFilterColor(false)}
			/>

			{search !== undefined && (
				<TableSearchComponent
					style={{
						width: '18vw',
						boxShadow: 'none',
						border: '1px solid rgba(0,0,0,0.12)',
						borderRadius: '3px',
						marginTop: '9px',
					}}
					setSearch={setSearch}
					search={search}
				/>
			)}
		</GridToolbarContainer>
	)
}

export default DashboardTable
