import { Box, Checkbox, FormGroup, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import {
	AdvertiserInterface,
	AppInterface,
	CampaignInterface,
	PublisherInterface,
	UserInterface,
} from '../../models/model.interface'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { countryList } from '../components/countryList'
import { AddButton } from '../components/reusableComponents'
import { ReportFieldsInterface } from './FiltersPage'
import { StyledCheckboxFormControl } from '../reports/aggregated/FiltersStyled'
import { ChipInputList } from '../components/ChipInput'
import { CountryListSelector } from '../components/SelectableInputs'

export const RawFiltersComponentList = (props: {
	setAppIds: any
	setAdvertisers: any
	setUser: any
	setPid: any
	setPublishers: any
	setCampaignNames: any
	setCampaignIds: any
	setCountries: any
	dataObj: ReportFieldsInterface
	setDataObj: any
}) => {
	const [appIdsChecked, setAppIdsChecked] = useState<boolean>(false)
	const [advertisersChecked, setAdvertisersChecked] = useState<boolean>(false)
	const [userChecked, setUserChecked] = useState<boolean>(false)
	const [pidChecked, setPidChecked] = useState<boolean>(false)
	const [publishersChecked, setPublishersChecked] = useState<boolean>(false)
	const [campaignNamesChecked, setCampaignNamesChecked] =
		useState<boolean>(false)
	const [campaignIdsChecked, setCampaignIdsChecked] = useState<boolean>(false)
	const [countriesChecked, setCountriesChecked] = useState<boolean>(false)
	const [addFilterIsOpen, setAddFilterIsOpen] = useState<boolean>(false)
	const [newFilters, setNewFilters] = useState<any[]>([])

	const { publisher, users, campaign, app, advertiser } = useTypedSelector(
		(state) => state,
	)
	const appIdList = app.app.map((e: AppInterface) => e.app_id)
	const advertiserList = advertiser.advertiser.map(
		(e: AdvertiserInterface) => e.advertiser_name,
	)
	const userList = users.users
		.filter((u: UserInterface) => {
			return !u.blocked
		})
		.map((e: UserInterface) => e.email)

	const pidListRaw: string[] = publisher.publisher
		.map((e: PublisherInterface) => e.media_source_pid)
		.flat()
		.filter((e: string) => e !== 'All' && e !== 'all')
	let pidList: string[] = []
	pidListRaw.forEach((pid) => {
		if (!pidList.includes(pid)) {
			pidList.push(pid)
		}
	})
	const publisherList = publisher.publisher.map(
		(e: PublisherInterface) => e.publisher_name,
	)
	const campaignNameList = campaign.campaign.map(
		(e: CampaignInterface) => e.campaign_name,
	)

	const campaignIdList = campaign.campaign.map((e: CampaignInterface) => e.id)
	// const ref = useRef(null)
	// function useOutsideAlerter(ref: any) {
	// 	useEffect(() => {
	// 		function handleClickOutside(event: any) {
	// 			if (ref.current && !ref.current.contains(event.target)) {
	// 				if (!event.target.className.includes('addFilterButton')) {
	// 					setAddFilterIsOpen(false)
	// 				}
	// 			}
	// 		}
	// 		document.addEventListener('mousedown', handleClickOutside)
	// 		return () => {
	// 			document.removeEventListener('mousedown', handleClickOutside)
	// 		}
	// 	}, [ref])
	// }
	// useOutsideAlerter(ref)
	// const checkIfFilterAlreadyAdded = (label: string) => {
	// 	for (const filter of newFilters) {
	// 		if (label === filter.id) {
	// 			return true
	// 		}
	// 	}
	// 	return false
	// }
	// const handleNewFilter = (label: string) => {
	// 	const appIdFilter = {
	// 		onChange: setAppIdsChecked,
	// 		checked: true,
	// 		label: 'App Id',
	// 		id: 'app_id',
	// 		arrayOnChange: props.setAppIds,
	// 		array: appIdList,
	// 		disabled: props.dataObj.filters.app_id === undefined,
	// 		value: props.dataObj.filters.app_id,
	// 	}
	// 	const pidFilter = {
	// 		onChange: setAppIdsChecked,
	// 		checked: true,
	// 		label: 'PID',
	// 		id: 'media_source_pid',
	// 		arrayOnChange: props.setPid,
	// 		array: pidList,
	// 		disabled: props.dataObj.filters.media_source_pid === undefined,
	// 		value: props.dataObj.filters.media_source_pid,
	// 	}
	// 	const campaignNameFilter = {
	// 		onChange: setCampaignNamesChecked,
	// 		checked: true,
	// 		label: 'Campaign Name',
	// 		id: 'campaign_name',
	// 		arrayOnChange: props.setCampaignNames,
	// 		array: campaignNameList,
	// 		disabled: campaignIdsChecked,
	// 		value: props.dataObj.filters.campaign_name,
	// 	}
	// 	const campaignIdFilter = {
	// 		onChange: setCampaignIdsChecked,
	// 		checked: true,
	// 		label: 'Campaign Id',
	// 		id: 'campaign_id',
	// 		arrayOnChange: props.setCampaignIds,
	// 		array: campaignIdList,
	// 		disabled: campaignNamesChecked,
	// 		value: props.dataObj.filters.campaign_id,
	// 	}
	// 	const countryFilter = {
	// 		onChange: setCountriesChecked,
	// 		checked: true,
	// 		label: 'Country',
	// 		id: 'country',
	// 		arrayOnChange: props.setCountries,
	// 		array: countryList,
	// 		disabled: props.dataObj.filters.country === undefined,
	// 		value: props.dataObj.filters.country,
	// 	}
	// 	const possibleFilters = [
	// 		appIdFilter,
	// 		pidFilter,
	// 		campaignNameFilter,
	// 		campaignIdFilter,
	// 		countryFilter,
	// 	]

	// 	for (const filter of possibleFilters) {
	// 		if (label === filter.id) {
	// 			setNewFilters((prev: any) =>
	// 				prev.length > 0 ? [...prev, filter] : [filter],
	// 			)
	// 			setAddFilterIsOpen(false)
	// 			break
	// 		}
	// 	}
	// }
	// const removeFilter = (label: string) => {
	// 	const arr = newFilters.filter((el) => el.id !== label)
	// 	setNewFilters(arr)
	// 	if(label === 'campaign_id'){
	// 		setCampaignIdsChecked(false)
	// 	} else if(label === 'campaign_name'){
	// 		setCampaignNamesChecked(false)
	// 	}
	// }

	return (
		<div
			style={{
				width: 'inherit',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			{/* <span
				style={{
					height: '50px',
					display: 'inherit',
					justifyContent: 'flex-end',
				}}
			>
				{newFilters.length < 4 && (
					<AddButton
						style={{ width: '100px', fontSize: '12px', alignSelf: 'flex-end' }}
						onClick={() => setAddFilterIsOpen(!addFilterIsOpen)}
						className={'addFilterButton'}
					>
						Add filter
					</AddButton>
				)}
			</span> */}
			<FormGroup
				key={'form-group-2'}
				style={{
					width: '260px',
					marginTop: '0px',
					display: 'flex',
					gap: '18px',
				}}
			>
				<CheckedFilter
					{...props}
					onChange={setAdvertisersChecked}
					checked={true}
					label={'Advertiser'}
					id={'advertiser_name'}
					arrayOnChange={props.setAdvertisers}
					array={advertiserList}
					disabled={props.dataObj.filters.advertiser_name === undefined}
					value={props.dataObj.filters.advertiser_name}
				/>
				<CheckedFilter
					{...props}
					onChange={setPublishersChecked}
					checked={true}
					label={'Publisher'}
					id={'publisher'}
					arrayOnChange={props.setPublishers}
					array={publisherList}
					disabled={props.dataObj.filters.media_source_pid === undefined}
					value={props.dataObj.filters.publisher}
				/>
				<CheckedFilter
					{...props}
					onChange={setUserChecked}
					checked={true}
					label={'User'}
					id={'email'}
					arrayOnChange={props.setUser}
					array={userList}
					disabled={props.dataObj.filters.email === undefined}
					value={props.dataObj.filters.email}
				/>
				<CheckedFilter
					{...props}
					onChange={setAppIdsChecked}
					checked={true}
					label={'App Id'}
					id={'app_id'}
					arrayOnChange={props.setAppIds}
					array={appIdList}
					disabled={props.dataObj.filters.app_id === undefined}
					value={props.dataObj.filters.app_id}
				/>
				<CheckedFilter
					{...props}
					onChange={setAppIdsChecked}
					checked={true}
					label={'PID'}
					id={'media_source_pid'}
					arrayOnChange={props.setPid}
					array={pidList}
					disabled={props.dataObj.filters.media_source_pid === undefined}
					value={props.dataObj.filters.media_source_pid}
				/>
				<CheckedFilter
					{...props}
					onChange={setCampaignNamesChecked}
					checked={true}
					label={'Campaign Name'}
					id={'campaign_name'}
					arrayOnChange={props.setCampaignNames}
					array={campaignNameList}
					disabled={campaignIdsChecked}
					value={props.dataObj.filters.campaign_name}
				/>
				<CheckedFilter
					{...props}
					onChange={setCountriesChecked}
					checked={true}
					label={'Country'}
					id={'country'}
					arrayOnChange={props.setCountries}
					array={countryList}
					disabled={props.dataObj.filters.country === undefined}
					value={props.dataObj.filters.country}
				/>
				{/* {newFilters.map((el) => {
					return (
						<CheckedFilter
						{...props}
							key={el.id}
							onChange={el.onChange}
							checked={el.checked}
							label={el.label}
							id={el.id}
							arrayOnChange={el.arrayOnChange}
							array={el.array}
							disabled={el.disabled}
							value={el.value}
							remove={removeFilter}
						/>
					)
				})} */}
			</FormGroup>
			{/* {addFilterIsOpen && (
				<Box
					style={{
						width: '200px',
						height: `${
							newFilters.length > 0 ? 340 / newFilters.length : 340
						}px`,
						background: 'white',
						display: 'flex',
						position: 'absolute',
						top: '5%',
						right: '25%',
						borderRadius: '8px',
						flexDirection: 'column',
						justifyContent: 'space-evenly',
						alignItems: 'center',
						boxShadow: '0 14px 15px rgb(0 0 0 / 10%)'
					}}
					ref={ref}
				>
					{!checkIfFilterAlreadyAdded('app_id') && (
						<AddButton
							style={{ width: '130px', height: '26px', fontSize: '12px' }}
							onClick={() => handleNewFilter('app_id')}
						>
							App ID
						</AddButton>
					)}
					{!checkIfFilterAlreadyAdded('media_source_pid') && (
						<AddButton
							style={{ width: '130px', height: '26px', fontSize: '12px' }}
							onClick={() => handleNewFilter('media_source_pid')}
						>
							PID
						</AddButton>
					)}
					{!checkIfFilterAlreadyAdded('campaign_name') &&
						!campaignIdsChecked && (
							<AddButton
								style={{ width: '130px', height: '26px', fontSize: '12px' }}
								onClick={() => {
									handleNewFilter('campaign_name')
									setCampaignNamesChecked(true)
								}}
							>
								Campaign Name
							</AddButton>
						)}
					{!checkIfFilterAlreadyAdded('campaign_id') &&
						!campaignNamesChecked && (
							<AddButton
								style={{ width: '130px', height: '26px', fontSize: '12px' }}
								onClick={() => {
									handleNewFilter('campaign_id')
									setCampaignIdsChecked(true)
								}}
							>
								Campaign ID
							</AddButton>
						)}
					{!checkIfFilterAlreadyAdded('country') && (
						<AddButton
							style={{ width: '130px', height: '26px', fontSize: '12px' }}
							onClick={() => handleNewFilter('country')}
						>
							Country
						</AddButton>
					)}
				</Box>
			)} */}
		</div>
	)
}

const CheckedFilter = (props: {
	id: any
	onChange: any
	checked: boolean
	label: string
	arrayOnChange: any
	array: any[]
	value?: any[]
	disabled?: boolean
	setDataObj?: any
	dataObj?: any
	// remove?: any
}) => {
	const [extraFilter, setExtraFilter] = useState(false)
	useEffect(() => {
		const possibleFilters = [
			'app_id',
			'media_source_pid',
			'campaign_name',
			'campaign_id',
			'country',
		]
		if (possibleFilters.includes(props.id)) {
			setExtraFilter(true)
		}
	}, [])

	// const handleChange = () => {
	// 	props.onChange(!props.checked)
	// 	props.setDataObj({
	// 		from_date: props.dataObj.from_date,
	// 		to_date: props.dataObj.to_date,
	// 		breakdowns: props.dataObj.breakdowns,
	// 		statistics: props.dataObj.statistics,
	// 		filters: getFilters(),
	// 	})
	// 	if (props.checked === true) {
	// 		props.arrayOnChange([])
	// 		handleChangeField(props.id, [])
	// 	}
	// }
	// const getFilters = () => {
	// 	var filters: any = {
	// 		app_id: props.dataObj.filters.app_id,
	// 		country: props.dataObj.filters.country,
	// 		advertiser_name: props.dataObj.filters.advertiser_name,
	// 		media_source_pid: props.dataObj.filters.media_source_pid,
	// 		publisher: props.dataObj.filters.publisher,
	// 		email: props.dataObj.filters.email,
	// 		campaign_name: props.dataObj.filters.campaign_name,
	// 	}
	// 	return filters
	// }
	// const handleChangeField = (id: keyof ReportFieldsInterface, value: any) => {
	// 	Object.defineProperty(props.dataObj.filters, id!, value)
	// }

	return (
		<div style={{ width: '260px' }}>
			{props.checked ? (
				props.label !== 'Country' ? (
					<div key={props.id} style={{ display: 'flex' }}>
						<ChipInputList
							{...props}
							onChange={props.arrayOnChange}
							options={props.array}
							value={
								extraFilter ? props.dataObj.filters[props.id] : props.value
							}
							openWithClick={true}
							style={{ width: '100%' }}
						/>
						{/* {props.remove ? <button
							style={{
								background: 'none',
								border: 'none',
								cursor: 'pointer',
								height: '30px',
								alignSelf: 'center',
								marginTop: '24px',
								marginLeft: '-10px',
								width: '50px'
							}}
							onClick={() => props.remove(props.id)}
						>
							<DeleteForeverIcon />
						</button> : <span style={{height: '30px', width: '40px'}}></span>} */}
					</div>
				) : (
					<div key={props.id} style={{ display: 'flex' }}>
						<CountryListSelector
							{...props}
							onChange={props.arrayOnChange}
							options={props.array}
							value={props.dataObj.filters.country}
							style={{ width: '100%' }}
						/>
						{/* {props.remove ? <button
							style={{
								background: 'none',
								border: 'none',
								cursor: 'pointer',
								height: '30px',
								alignSelf: 'center',
								marginTop: '24px',
								marginLeft: '-10px',
								width: '50px'
							}}
							onClick={() => props.remove(props.id)}
						>
							<DeleteForeverIcon />
						</button> : <span style={{height: '30px', width: '50px'}}></span>} */}
					</div>
				)
			) : (
				<></>
			)}
		</div>
	)
}
