import { UserInterface } from '../../models/model.interface'
import { ActionType } from '../action-types/index'
import { Action } from '../actions'

interface UsersReducerInterface {
	users: UserInterface[] | any
}

const initialState = {
	users: [],
}

const reducer = (
	state: UsersReducerInterface = initialState,
	action: Action,
): UsersReducerInterface => {
	switch (action.type) {
		case ActionType.GET_USERS: {
			return {
				...state,
				users: action.payload,
			}
		}
		case ActionType.LOGOUT: {
			return {
				...state,
				users: [],
			}
		}
		case ActionType.DELETE_USER: {
			const payload = {
				email: action.payload.email,
			}
			const new_state = state.users.filter(
				(v: UserInterface) => v.email !== payload.email,
			)
			return {
				users: new_state,
			}
		}
		case ActionType.INSERT_USER: {
			const payload: UserInterface = {
				name: action.payload.name,
				email: action.payload.email,
				blocked: action.payload.blocked,
				role: action.payload.role,
				department: action.payload.department,
				testaccess: action.payload.testaccess,
			}
			return {
				...state,
				users: [...state.users, payload],
			}
		}
		default:
			return state
	}
}

export default reducer
