import { HighlightOffOutlined, OndemandVideo } from '@mui/icons-material'
import { useTheme } from '@mui/material'
import React, { useState, useRef } from 'react'

const FileUploader = (props: {
	onSetFiles: any
	onRemoveFile: any
	onErrorMessage: any
}) => {
	const theme = useTheme()
	const [files, setFiles] = useState<any>([])
	const [isDragging, setIsDragging] = useState(false)
	const fileInputRef = useRef<any>(null)

	const selectFiles = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click()
		}
	}

	const validateAndFilterFiles = (uploadedFiles: any) => {
		let files = [...uploadedFiles]
		let allowedResolutions: any = {
			'300x50': null,
			'300x250': null,
			'320x480': null,
			icon: null,
		}
		let videoUploaded = false
		let errorMsg = ''

		for (let i = files.length - 1; i >= 0; i--) {
			const file = files[i]
			const resolutionKey = `${file.width}x${file.height}`
			const isSquare = file.width === file.height

			if (file.type.includes('image')) {
				if (allowedResolutions.hasOwnProperty(resolutionKey)) {
					if (allowedResolutions[resolutionKey] === null) {
						allowedResolutions[resolutionKey] = file
					} else {
						errorMsg += `**You already uploaded a ${resolutionKey} image. Please remove the current image before uploading ${
							file.name.split('.')[0]
						}**\n`
						files.splice(i, 1)
					}
				} else if (isSquare && allowedResolutions['icon'] === null) {
					allowedResolutions['icon'] = file
				} else if (isSquare) {
					errorMsg += `**You already uploaded an icon. Please remove the current icon before uploading ${
						file.name.split('.')[0]
					}**\n`
					files.splice(i, 1)
				} else {
					errorMsg +=
						'**Sorry, some of the images uploaded do not match any of the required resolutions. Please make sure the images you are uploading match the requirements**\n'
					files.splice(i, 1)
				}
			} else if (file.type === 'video/mp4') {
				if (!videoUploaded) {
					videoUploaded = true
				} else {
					errorMsg += `**You already uploaded a video. Please remove the current video before uploading ${
						file.name.split('.')[0]
					}**\n`
					files.splice(i, 1)
				}
			}
		}

		if (errorMsg) {
			props.onErrorMessage(errorMsg)
		}

		return files
	}

	const onFileSelect = (ev: any) => {
		const uploadedFiles = ev.target.files

		if (uploadedFiles.length === 0) return
		const filesToUpdate = [...files]

		const loadPromises = []

		for (let i = 0; i < uploadedFiles.length; i++) {
			const promise = new Promise((resolve, reject) => {
				const file = {
					id: Math.random(),
					name: uploadedFiles[i].name,
					url: URL.createObjectURL(uploadedFiles[i]),
					width: 1,
					height: 2,
					type: uploadedFiles[i].type,
				}

				if (file.type.startsWith('image/')) {
					let img = new Image()
					img.src = file.url

					img.onload = () => {
						file.width = img.width
						file.height = img.height
						resolve(file)
					}

					img.onerror = reject
				} else if (file.type.startsWith('video/')) {
					let video = document.createElement('video')
					video.src = file.url

					video.addEventListener('loadedmetadata', () => {
						file.width = video.videoWidth
						file.height = video.videoHeight
						resolve(file)
					})

					video.addEventListener('error', reject)

					video.style.display = 'none'
					document.body.appendChild(video)
				} else {
					resolve(file)
				}
			})

			loadPromises.push(promise)
		}

		Promise.all(loadPromises)
			.then((loadedFiles) => {
				const filteredFiles = validateAndFilterFiles([
					...loadedFiles,
					...filesToUpdate,
				])
				setFiles(filteredFiles)
				props.onSetFiles(filteredFiles)
			})
			.catch((error) => {
				console.error('Error loading files:', error)
			})
	}

	const deleteImage = (url: string) => {
		let updatedImages = [...files]
		updatedImages = updatedImages.filter((image: any) => image.url !== url)
		setFiles(updatedImages)
		props.onSetFiles(updatedImages)
		props.onRemoveFile(url, updatedImages)
	}

	const onDragOver = (ev: any) => {
		ev.preventDefault()
		setIsDragging(true)
		ev.dataTransfer.dropEffect = 'copy'
	}

	const onDragLeave = (ev: any) => {
		ev.preventDefault()
		setIsDragging(false)
	}

	const onDrop = (ev: any) => {
		ev.preventDefault()
		setIsDragging(false)
		const uploadedFiles = ev.dataTransfer.files

		if (uploadedFiles.length === 0) return
		const filesToUpdate = [...files]

		const loadPromises = []

		for (let i = 0; i < uploadedFiles.length; i++) {
			const promise = new Promise((resolve, reject) => {
				const file = {
					id: Math.random(),
					name: uploadedFiles[i].name,
					url: URL.createObjectURL(uploadedFiles[i]),
					width: 1,
					height: 2,
					type: uploadedFiles[i].type,
				}

				if (file.type.startsWith('image/')) {
					let img = new Image()
					img.src = file.url

					img.onload = () => {
						file.width = img.width
						file.height = img.height
						resolve(file)
					}

					img.onerror = reject
				} else if (file.type.startsWith('video/')) {
					let video = document.createElement('video')
					video.src = file.url

					video.addEventListener('loadedmetadata', () => {
						file.width = video.videoWidth
						file.height = video.videoHeight
						resolve(file)
					})

					video.addEventListener('error', reject)

					video.style.display = 'none'
					document.body.appendChild(video)
				} else {
					resolve(file)
				}
			})

			loadPromises.push(promise)
		}

		Promise.all(loadPromises)
			.then((loadedFiles) => {
				const filteredFiles = validateAndFilterFiles([
					...loadedFiles,
					...filesToUpdate,
				])
				setFiles(filteredFiles)
				props.onSetFiles(filteredFiles)
			})
			.catch((error) => {
				console.error('Error loading files:', error)
			})
	}

	return (
		<div
			style={{
				padding: '10px',
				boxShadow: '0 0 5px #ffdfdf',
				borderRadius: '5px',
				overflow: 'hidden',
				color: 'black',
			}}
		>
			<div
				onDragOver={onDragOver}
				onDragLeave={onDragLeave}
				onDrop={onDrop}
				style={{
					height: '275px',
					borderRadius: '5px',
					border: '2px dashed #9f9f9f',
					background: isDragging
						? theme.colors.base.white
						: theme.colors.base.white,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					userSelect: 'none',
					WebkitUserSelect: 'none',
					marginTop: '10px',
					color: theme.colors.text.titles,
					width: '180px',
					flexDirection: 'column',
				}}
			>
				<div
					style={{
						width: '100%',
						height: 'auto',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flexWrap: 'wrap',
						maxHeight: '200px',
						marginTop: '10px',
						overflow: 'auto',
					}}
				>
					{files.map((image: any, index: number) => {
						return (
							<div
								key={index}
								style={{
									margin: '10px',
									width: '25px',
									height: '25px',
									marginRight: '4px',
									position: 'relative',
									marginBottom: '8px',
									border: '1px solid #c6c5c5',
									borderRadius: '5px',
									padding: '4px',
								}}
							>
								<span
									style={{
										position: 'absolute',
										top: '-9px',
										right: '-10px',
										cursor: 'pointer',
										color: 'rgb(248, 141, 141)',
										borderRadius: '50%',
										width: '25px',
										textAlign: 'center',
									}}
									onClick={() => deleteImage(image.url)}
								>
									<HighlightOffOutlined
										style={{
											borderRadius: '50%',
											background: 'white',
											fontSize: 16,
										}}
									/>
								</span>
								{image.type.includes('image') ? (
									<img
										key={index}
										style={{
											width: '100%',
											height: '100%',
											objectFit: 'cover',
										}}
										src={image.url}
									/>
								) : (
									<OndemandVideo
										style={{
											width: '100%',
											height: '100%',
										}}
									/>
								)}
							</div>
						)
					})}
				</div>
				{isDragging ? (
					<span
						style={{ cursor: 'pointer', padding: '5px', textAlign: 'center' }}
					>
						Drop files here
					</span>
				) : (
					<div style={{ textAlign: 'center', padding: '5px' }}>
						Drop files here or{' '}
						<span
							style={{
								color: 'rgba(128, 128,128)',
								cursor: 'pointer',
								fontWeight: 500,
							}}
							onClick={selectFiles}
						>
							Browse
						</span>
					</div>
				)}

				<input
					className='file'
					type='file'
					multiple
					style={{ display: 'none' }}
					ref={fileInputRef}
					onChange={onFileSelect}
				/>
			</div>
		</div>
	)
}

export default FileUploader
