import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { SwitchComponent } from '../components/ToggleComponents'
import { useTheme } from '@mui/material'

const HandshakeEdit = ({
	selected,
	setHandshakeArr,
	handshakeArr,
	editablePublisher,
}: {
	selected: string
	setHandshakeArr: any
	handshakeArr: any
	editablePublisher?: any
}) => {
	const theme = useTheme()
	const { handshake } = useTypedSelector((state) => state)
	const [selectedHandshake, setSelectedHandshake] = useState<any>({})
	const [optionsToEdit, setOptionsToEdit] = useState<any>([])
	const [allActive, setAllActive] = useState(false)

	useEffect(() => {
		setOptionsToEdit([])
		let found = false
		for (const element of handshake.handshake) {
			if (element.pid.toLowerCase().trim() === selected) {
				setSelectedHandshake(element)
				const allActiveNow = Object.values(element.handshake).every(
					(val) => val === true,
				)
				found = true
				setAllActive(allActiveNow)
				break
			}
		}
		if (found === false) {
			const handShakeObj: any = {
				handshake: {},
			}
			const agencyAccounts = [
				'thingortwo',
				'smartassmeil951',
				'tatanka',
				'weknowil870',
				'appsadvil761',
			]
			for (const account of agencyAccounts) {
				handShakeObj.handshake[account] = false
			}
			const obj = { pid: selected, ...handShakeObj }
			setSelectedHandshake(obj)
		}
	}, [selected])
	useEffect(() => {
		if (editablePublisher && editablePublisher.publisher) {
			setOptionsToEdit([])

			// console.log(editablePublisher)
			const arr: any = []
			for (const element of editablePublisher.pid) {
				const allActiveNow = Object.values(element.handshake).every(
					(val) => val === true,
				)
				setAllActive(allActiveNow)
				arr.push(element)
			}
			setSelectedHandshake(arr)
		}
	}, [editablePublisher])

	useEffect(() => {
		if (Array.isArray(selectedHandshake)) {
			const options = []
			for (const key in selectedHandshake[0].handshake) {
				const option = {
					label: key,
					value: selectedHandshake.every(
						(el: any) => el.handshake[key] === true,
					),
				}
				options.push(option)
			}
			setOptionsToEdit(options)
			// console.log(handshakeArr, selectedHandshake)
			const map = new Map()
			// Add items from arr1
			handshakeArr.forEach((item: any) => {
				map.set(item.pid, item.handshake)
			})
			// Add items from arr2, overwriting existing items if necessary
			selectedHandshake.forEach((item) => {
				map.set(item.pid, item.handshake)
			})

			// Convert the map back to an array of objects
			const mergedArray = Array.from(map, ([pid, handshake]) => ({
				pid,
				handshake,
			}))
			if (mergedArray[0].pid === undefined) {
				mergedArray.shift()
			}
			setHandshakeArr(mergedArray)
		} else {
			const options = []
			for (const key in selectedHandshake.handshake) {
				const option = { label: key, value: selectedHandshake.handshake[key] }
				options.push(option)
			}
			setOptionsToEdit(options)
			const newArr = []
			let added = false
			for (const el of handshakeArr) {
				if (el.pid === selectedHandshake.pid) {
					added = true
					newArr.push(selectedHandshake)
				} else {
					newArr.push(el)
				}
			}
			if (!added) {
				newArr.push(selectedHandshake)
			}
			setHandshakeArr(newArr)
		}
	}, [selectedHandshake])

	useEffect(() => {
		// console.log(handshakeArr)
	}, [handshakeArr])

	const handleAllChange = (value: boolean) => {
		setAllActive(value)
		if (Array.isArray(selectedHandshake)) {
			const newArr: any = []
			for (const selectedPid of selectedHandshake) {
				const updatedHandshake = { ...selectedPid }
				for (const key in updatedHandshake.handshake) {
					updatedHandshake.handshake[key] = value
				}
				newArr.push(updatedHandshake)
			}
			setSelectedHandshake(newArr)
			setOptionsToEdit(
				Object.keys(newArr[0].handshake).map((key) => ({
					label: key,
					value: newArr[0].handshake[key],
				})),
			)
		} else {
			const updatedHandshake = { ...selectedHandshake }
			for (const key in updatedHandshake.handshake) {
				updatedHandshake.handshake[key] = value
			}
			setSelectedHandshake(updatedHandshake)
			setOptionsToEdit(
				Object.keys(updatedHandshake.handshake).map((key) => ({
					label: key,
					value: updatedHandshake.handshake[key],
				})),
			)
		}
	}
	useEffect(() => {
		// console.log(optionsToEdit, 'options')
	}, [optionsToEdit])

	const handleOptionChange = (label: string, value: any) => {
		if (Array.isArray(selectedHandshake)) {
			const newArr: any = []
			for (const selectedPid of selectedHandshake) {
				const updatedHandshake = { ...selectedPid }
				updatedHandshake.handshake[label] = value
				newArr.push(updatedHandshake)
			}
			setSelectedHandshake(newArr)

			const allActiveNow = newArr.every((el: any) =>
				Object.values(el.handshake).every((val) => val === true),
			)
			setAllActive(allActiveNow)
			// console.log('newarr', newArr[0])
			setOptionsToEdit(
				Object.keys(newArr[0].handshake).map((key) => ({
					label: key,
					value: newArr[0].handshake[key],
				})),
			)
		} else {
			const updatedHandshake = { ...selectedHandshake }
			updatedHandshake.handshake[label] = value
			setSelectedHandshake(updatedHandshake)

			const allActiveNow = Object.values(updatedHandshake.handshake).every(
				(val) => val === true,
			)
			setAllActive(allActiveNow)

			setOptionsToEdit(
				Object.keys(updatedHandshake.handshake).map((key) => ({
					label: key,
					value: updatedHandshake.handshake[key],
				})),
			)
		}
	}

	return (
		<div
			style={{
				padding: '4% 8%',
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				gap: 20,
			}}
		>
			{selectedHandshake.pid && (
				<span
					style={{
						fontSize: theme.font.size.normal,
						fontWeight: theme.font.weight.bold,
						color: theme.colors.base.grey900,
					}}
				>
					{selectedHandshake.pid}
				</span>
			)}
			{optionsToEdit && (
				<div style={{ display: 'flex', gap: 20, flexDirection: 'column' }}>
					<SwitchComponent
						key={'All Agencies'}
						onChange={(e) => handleAllChange(e as any)}
						value={allActive}
						label={'All'}
						disabled={false}
					></SwitchComponent>

					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							flexDirection: 'row',
							width: '100%',
						}}
					>
						{optionsToEdit.map((el: any) => {
							return (
								<HandshakeEditOption
									label={el.label}
									currentValue={el.value}
									key={el.label}
									onOptionChange={handleOptionChange}
									mainObj={selectedHandshake}
									allActive={allActive}
								/>
							)
						})}
					</div>
				</div>
			)}
		</div>
	)
}

const HandshakeEditOption = ({
	label,
	currentValue,
	onOptionChange,
	mainObj,
	allActive,
}: {
	label: string
	currentValue: boolean
	onOptionChange: (label: string, value: boolean) => void
	mainObj: any
	allActive: boolean
}) => {
	const [value, setValue] = useState(currentValue)

	useEffect(() => {
		setValue(currentValue)
	}, [currentValue])

	return (
		<SwitchComponent
			key={label}
			onChange={(e) => {
				onOptionChange(label, e as any)
			}}
			value={value}
			label={label}
			disabled={false}
		></SwitchComponent>
	)
}

export default HandshakeEdit
