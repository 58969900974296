import {
	Checkbox,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	MenuItem,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { format, isValid } from 'date-fns'
import { useEffect, useMemo, useState } from 'react'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import {
	findUsernameByEmail,
	generateRowsWithIds,
} from '../../utils/helpers/tableHelper'
import {
	PresetDialog,
	StyledDialog,
	StyledNameField,
} from '../components/reusableComponents'
import { StyledCheckboxFormControl } from '../reports/aggregated/FiltersStyled'
import { p360dashboardPayloadModel } from './p360dashboard'
import { InputFieldText } from '../components/Inputs'
import {
	SimpleActionsButton,
	SubmitButton,
	UnborderedButton,
} from '../components/Buttons'

const P360PopUp = (props: { popUpVisible: any; setPopUpVisible: any }) => {
	return (
		<StyledDialog open={props.popUpVisible}>
			<DialogContent>
				<DialogContentText>The Code is Sent to Your Email</DialogContentText>
			</DialogContent>
			<DialogActions>
				<UnborderedButton
					style={{ margin: 'auto' }}
					onClick={() => {
						props.setPopUpVisible(false)
					}}
					label={'Cancel'}
				></UnborderedButton>
				<SubmitButton
					disabled={!isValid}
					style={{ margin: 'auto' }}
					onClick={() => {
						props.setPopUpVisible(false)
					}}
				></SubmitButton>
			</DialogActions>
		</StyledDialog>
	)
}
export default P360PopUp
export const PresetsPopup = ({
	visible,
	setPresetVisible,
	setRow,
	fillReport,

	reportFields,

	setRetrieved,
	presetNameExt,
}: {
	visible: boolean
	setPresetVisible: any
	setRow: any
	fillReport: any

	reportFields: p360dashboardPayloadModel

	setRetrieved: any
	presetNameExt: string
}) => {
	const theme = useTheme()
	const rowCount = 4
	const { preset, login, users } = useTypedSelector((state) => state)
	const { getPresetsAction, updatePresetAction, deletePresetAction } =
		useActions()
	const [rowWidth, setRowWidth] = useState(Math.floor(600 / rowCount))
	const [presetName, setPresetName] = useState('')
	const [showOwnPresets, setShowOwnPresets] = useState(false)
	const [update, setUpdate] = useState(false)
	const [isSaving, setIsSaving] = useState(false)
	// const headers = useMemo(() => {
	// 	return {
	// 		Authorization: `Token ${login.user.token}`,
	// 	}
	// }, [login.user.token])

	const relativeTheDate = (preset: any) => {
		const a = JSON.parse(preset)
		const from: Date = new Date(a.dateFrom)
		const to: Date = new Date(a.dateTo)
		const createdAt = new Date(preset.createdAt)
		const result1 = createdAt.getTime() - from.getTime()
		const result2 = createdAt.getTime() - to.getTime()
		const now = new Date().getTime()
		console.log(a)
		let dataResult = {
			breakdownFields: a.breakdowns,
			filters: a.filters,
			dateFrom: a.dateFrom,
			dateTo: a.dateTo,
			graphs: a.graphs,
		}

		setRetrieved(dataResult)
		// return result
	}

	const trySubmit = async () => {
		await trySavePreset()
		setIsSaving(false)
		setPresetVisible(false)
		// await fetchData(login, getPresetsAction, preset)
	}
	const columns: GridColDef[] = [
		{
			field: 'name',
			headerName: 'Name',
			width: rowWidth,
			renderCell: ({ value }) => (
				<Tooltip title={value}>
					<Typography sx={{ fontWeight: 500, fontSize: 14 }}>
						{value}
					</Typography>
				</Tooltip>
			),
		},
		{
			field: 'email',
			headerName: 'Owner',
			width: rowWidth / 2,
			//align: 'center',
			valueFormatter: ({ value }) =>
				value ? findUsernameByEmail(value.toString(), users.users) : 'User',
		},
		{
			field: 'createdAt',
			headerName: 'Created at',
			width: rowWidth / 1.5,
			valueFormatter: ({ value }) =>
				value ? format(new Date(value.toString()), 'MM/dd/yyyy') : 'Now',
		},
		{
			field: 'actions',
			headerName: 'Actions',
			width: rowWidth / 2,
			renderCell: (row) => (
				<SimpleActionsButton
					label={'Actions'}
					// width={rowWidth}
					// row={row}
					options={actionOptions(row)}
					// style={{ width: '50px', height: '30px', fontSize: '30px' }}
				/>
			),
		},
	]
	const actionOptions = (row: any) => {
		return (
			<div>
				<MenuItem
					onClick={() => {
						if (row.row.report) {
							relativeTheDate(row.row)
						}
						fillReport()
						setPresetVisible(false)
					}}
				>
					View
				</MenuItem>
				<MenuItem
					onClick={() => {
						const data = {
							id: row.row.id,
							name: presetName,
							email: login.user.email,
							report: JSON.stringify(reportFields),
						}
						deletePresetAction(data)
						//setIsWarningPromptOpened(true)
					}}
				>
					Delete
				</MenuItem>
			</div>
		)
	}
	const trySavePreset = async () => {
		console.log(reportFields)
		const index = preset.find((e: any) => e.name === presetName)
		const reportToSave = {
			breakdowns: reportFields.breakdownFields,
			filters: reportFields.filters,
			dateTo: reportFields.dateTo,
			dateFrom: reportFields.dateFrom,
			graphs: reportFields.graphs,
		}

		const data = {
			id: index ? (index as any).id : preset.length + 1,
			name: presetName,
			email: login.user.email,
			report: JSON.stringify(reportToSave),
			report_type: 'p360Dashboard',
		}
		console.log(data)

		updatePresetAction(data)
	}
	const handleUpdate = async () => {
		await getPresetsAction(showOwnPresets ? login.user.email : '')
		setUpdate(!update)
	}
	useEffect(() => {
		handleUpdate()
	}, [visible, showOwnPresets])
	return visible ? (
		<PresetDialog open={visible}>
			<DialogTitle>
				<IconButton
					aria-label='close'
					onClick={() => {
						setPresetVisible(false)
					}}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
						fontFamily: theme.typography.fontFamily,
						fontSize: '13px',
						fontWeight: 500,
					}}
				>
					Cancel
				</IconButton>
			</DialogTitle>

			<DialogContent>
				<StyledCheckboxFormControl
					key={'timeframeControl'}
					control={
						<Checkbox
							onChange={() => setShowOwnPresets(!showOwnPresets)}
							checked={showOwnPresets}
							key={'timeframe'}
							inputProps={{
								'aria-label': 'controlled',
							}}
							style={{ marginLeft: '25px', color: '#ee1d4a' }}
							color='primary'
						/>
					}
					label={
						<Typography fontSize='13px' fontWeight={500} color={'secondary'}>
							{'Show my own presets'}
						</Typography>
					}
				/>

				{!isSaving ? (
					<DataGrid
						columns={columns}
						rows={generateRowsWithIds(preset)}
						pageSizeOptions={[15, 25, 50]}
						showColumnVerticalBorder={true}
						onRowClick={(row: any) => {
							console.log(row.row.report)

							if (row.row.report) {
								relativeTheDate(row.row.report)
							}

							setPresetVisible(false)
						}}
						style={{ height: '400px' }}
					></DataGrid>
				) : (
					<InputFieldText
						label='Preset Name'
						type='text'
						value={presetName}
						onChange={(e: string) => {
							setPresetName(e)
							presetNameExt = e
						}}
					/>
				)}
			</DialogContent>

			<DialogActions>
				<UnborderedButton
					style={{ margin: 'auto' }}
					onClick={() => {
						setPresetVisible(false)
						setIsSaving(false)
					}}
					label={'Cancel'}
				></UnborderedButton>

				{isSaving ? (
					<SubmitButton
						//disabled={!isValid}
						style={{ margin: 'auto' }}
						onClick={() => trySubmit()}
					>
						Save
					</SubmitButton>
				) : (
					<SubmitButton
						//disabled={!isValid}
						style={{ margin: 'auto' }}
						onClick={() => setIsSaving(true)}
					>
						Create new
					</SubmitButton>
				)}
			</DialogActions>
		</PresetDialog>
	) : (
		<></>
	)
}
