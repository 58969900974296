import React, { useEffect, useState } from 'react'
import AdvertiserPreviewPage from '../advertisers/AdvertiserPreviewPage'
import AdvertiserCompanyPreviewPage from '../advertisers/AdvertiserCompanyPreviewPage'
import PublisherPreviewPage from '../publishers/PublisherPreviewPage'
import PublisherCompanyPreviewPage from '../publishers/PublisherCompanyPreviewPage'
import CreatorPreviewPage from '../publishers/CreatorPreviewPage'
import CreatorCompanyPreviewPage from '../publishers/CreatorCompanyPreviewPage'
import {
	useActions,
	useAdvertiserActions,
	useCreatorActions,
} from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'

type CompanySettingsProps = {
	setLoading: (loading: boolean) => void
	setPopUpVisible: (visible: boolean) => void
	companyData: any
}

type CompanyType = string // 'advertiser' | 'publisher' | 'creator' | 'agency'

const companySettingsComponents: Record<
	CompanyType,
	React.ComponentType<CompanySettingsProps>
> = {
	advertiser: AdvertiserCompanyPreviewPage,
	publisher: PublisherCompanyPreviewPage,
	creator: CreatorCompanyPreviewPage,
	// agency: () => <div>Agency settings</div>,
}

type Props = {
	companyType: CompanyType
	setLoading: (loading: boolean) => void
	companyId?: number
	setPopUpVisible: (visible: boolean) => void
}

const CompanyPersonalPage: React.FC<Props> = ({
	companyType,
	setLoading,
	companyId,
	setPopUpVisible,
}) => {
	const [currentCompany, setCurrentCompany] = useState<any>(null)
	const { getCreatorsAction } = useCreatorActions()
	const { getAdvertiserByCompanyId } = useAdvertiserActions()
	const { publisher, creator } = useTypedSelector((state) => state)

	useEffect(() => {
		fetchData()
	}, [companyType])

	const fetchData = async () => {
		try {
			setLoading(true)
			switch (companyType) {
				case 'advertiser':
					const data = await getAdvertiserByCompanyId(companyId!)
					setCurrentCompany(data)
					break
				case 'creator':
					getCreatorsAction()
					const currCreator = creator.creator.find(
						(item: any) => item.company_id === companyId,
					)
					setCurrentCompany(currCreator)
					break
				case 'publisher':
					const currPublisher = publisher.publisher.find(
						(item: any) => item.company_id === companyId,
					)
					setCurrentCompany(currPublisher)
					break
				case 'agency':
					// we need to handle agency case
					break
				default:
					console.error('Invalid company type')
					return null
			}
		} catch (error) {
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	const Component = companySettingsComponents[companyType]
	return (
		currentCompany && (
			<Component
				setLoading={setLoading}
				setPopUpVisible={setPopUpVisible}
				companyData={currentCompany}
			/>
		)
	)
}

export default CompanyPersonalPage
