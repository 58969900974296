import { ActionType } from '../action-types/index'
import { Action } from '../actions'

export interface handshakeReducerInterface {
	handshake: any
}

const initialState: handshakeReducerInterface = {
	handshake: [],
}

const reducer = (
	state: handshakeReducerInterface | any = initialState,
	action: Action,
): handshakeReducerInterface => {
	switch (action.type) {
		case ActionType.GETHANDSHAKE: {
			return {
				...state,
				handshake: action.payload,
			}
		}

		default:
			return state
	}
}

export default reducer
