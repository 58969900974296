import React, { useState, useEffect } from 'react'
import {
	Checkbox,
	Typography,
	Paper,
	Grid,
	Button,
	List,
	ListItem,
	ListItemText,
	useTheme,
} from '@mui/material'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { useActions } from '../../hooks/useActions'
import {
	sumPermissions,
	hasPermission,
} from '../../utils/helpers/permissionsHelper'

const gradientColors = {
	primary: 'rgba(98, 239, 141, 1)', //green
	secondary: 'rgba(233, 219, 45, 1)', //yellow
	primarySecondaryMix: 'rgba(182, 227, 82, 1)', //mix light
	primarySecondaryMixDarker: 'rgba(152, 231, 105, 1)', //mix darker
}

interface Permission {
	id: string
	name: string
	value: number // Assuming value is a number that contributes to the total permission value
	type: string
}

interface Role {
	id: string
	name: string
	description: string
	default_permission: string // Assuming it's a string to hold bitmask or permission values
}

interface PermissionsTableProps {
	role: Role
	onSave: (role: Role) => void
}

const PermissionsTable: React.FC<PermissionsTableProps> = ({
	role,
	onSave,
}) => {
	const theme = useTheme()
	const { permissions } = useTypedSelector((state) => state)
	const [selectedPermissions, setSelectedPermissions] = useState<string[]>([])
	const [expandedType, setExpandedType] = useState<string | null>(null)

	useEffect(() => {
		const userPermissions = BigInt(role.default_permission)
		const initialSelected: string[] = []
		permissions.permissions.forEach((permission: Permission) => {
			if (hasPermission(userPermissions, BigInt(permission.value))) {
				initialSelected.push(permission.name)
			}
		})
		setSelectedPermissions(initialSelected)
	}, [role, permissions])

	const updatePermissionValue = (name: string, isChecked: boolean) => {
		setSelectedPermissions((prevSelected) =>
			isChecked
				? [...prevSelected, name]
				: prevSelected.filter((permName) => permName !== name),
		)
	}

	const handleSave = () => {
		const newPermissionsValue = sumPermissions(
			selectedPermissions,
			permissions.permissions,
		)
		const updatedRole = {
			...role,
			default_permission: newPermissionsValue.toString(),
		}
		onSave(updatedRole)
	}

	const groupPermissionsByType = (permissions: Permission[]) => {
		return permissions.reduce(
			(acc: Record<string, Permission[]>, permission: Permission) => {
				if (!acc[permission.type]) {
					acc[permission.type] = []
				}
				acc[permission.type].push(permission)
				return acc
			},
			{},
		)
	}

	const capitalizeWords = (str: string): string => {
		return str
			.toLowerCase()
			.split('_')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ')
	}

	const groupedPermissions = groupPermissionsByType(permissions.permissions)

	return (
		<div>
			<List>
				{Object.keys(groupedPermissions).map((type) => (
					<div key={type}>
						<ListItem
							button
							onClick={() =>
								setExpandedType(expandedType === type ? null : type)
							}
							style={{
								background: theme.colors.base.white,
								color: theme.colors.base.green300,
								marginBottom: '5px',
								borderRadius: '4px',
								textAlign: 'center',
								width: '100%',
								alignSelf: 'center',
							}}
						>
							<ListItemText
								primary={capitalizeWords(type)}
								primaryTypographyProps={{
									style: { fontSize: '20px', fontWeight: 'bold' },
								}}
							/>
						</ListItem>
						{expandedType === type && (
							<Grid container direction='column' alignItems='center'>
								<Grid item xs={12}>
									<div
										style={{
											display: 'grid',
											gridTemplateColumns: 'repeat(3, 1fr)',
											gap: '10px',
											justifyItems: 'center',
											alignItems: 'left',
											// border: '1px solid red',
										}}
									>
										{groupedPermissions[type].map((permission) => (
											<div
												key={permission.id}
												style={{
													display: 'flex',
													alignItems: 'center',
													width: '100%',
													justifyContent: 'flex-start',
													gap: 'between',
												}}
											>
												<Checkbox
													checked={selectedPermissions.includes(
														permission.name,
													)}
													onChange={(e) =>
														updatePermissionValue(
															permission.name,
															e.target.checked,
														)
													}
													style={{
														color: theme.colors.base.green200,
														marginRight: '10px',
													}}
												/>
												<Typography
													fontSize='17px'
													fontWeight={500}
													style={{ color: theme.colors.text.titles }}
												>
													{capitalizeWords(permission.name.replace(/_/g, ' '))}
												</Typography>
											</div>
										))}
									</div>
								</Grid>
							</Grid>
						)}
					</div>
				))}
			</List>
			<Button
				variant='contained'
				onClick={handleSave}
				style={{
					background: gradientColors.primarySecondaryMixDarker,
					width: '20%',
					color: 'black',
					marginTop: '20px',
					borderRadius: '25px',
					display: 'block',
					marginLeft: 'auto',
					marginRight: 'auto',
				}}
			>
				{`Save ${role.name} role type`}
			</Button>
		</div>
	)
}

export default PermissionsTable
