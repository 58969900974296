import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	useTheme,
} from '@mui/material'
import { SubmitButton } from '../../pages/components/Buttons'

export const UserMessagePrompt = ({
	PopUpVisible,
	setPopUpVisible,
	userMessage,
	setUserMessage,
}: {
	PopUpVisible: boolean
	setPopUpVisible: any
	userMessage: any
	setUserMessage: any
}) => {
	const theme = useTheme()
	return (
		<Dialog open={PopUpVisible}>
			<DialogTitle
				style={{
					background: theme.colors.base.white,
					color: theme.colors.text.titles,
				}}
			>
				{userMessage?.title}
			</DialogTitle>
			<DialogContent
				style={{
					background: theme.colors.base.white,
					color: theme.colors.text.titles,
				}}
			>
				<DialogContentText style={{ whiteSpace: 'pre-line' }}>
					{userMessage?.text || ''}
				</DialogContentText>
			</DialogContent>
			<DialogActions
				style={{
					background: theme.colors.base.white,
					color: theme.colors.text.titles,
				}}
			>
				<SubmitButton
					style={{ margin: 'auto' }}
					onClick={() => {
						setUserMessage(null)
						setPopUpVisible(false)
					}}
				>
					Close
				</SubmitButton>
			</DialogActions>
		</Dialog>
	)
}
