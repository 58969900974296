import { Dispatch } from 'redux'
import { ActionType } from '../action-types'
import { Action } from '../actions/index'
import AxiosCustom from '../../utils/Axios'
import { permissionNames } from '../../utils/helpers/permissionsHelper'
import { endpoints } from '../../config'

export const getLearningAction = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.DOCUMENTS, {
				headers: {
					'x-permission-name': permissionNames.VIEW_DOCUMENTS,
				},
			})
			// const learning = {
			// 	allowedPlatforms: JSON.parse(data.payload[0].allowedPlatforms),
			// 	vertical: JSON.parse(data.payload[0].vertical),
			// 	capabilities: JSON.parse(data.payload[0].capabilities),
			// 	salesRepresentative: JSON.parse(data.payload[0].salesRepresentative),
			// 	platforms: JSON.parse(data.payload[0].platforms),
			// 	contactRole: JSON.parse(data.payload[0].contactRole),
			// }
			if (!data.successful) {
				return
			}

			dispatch({
				type: ActionType.GET_DOCUMENTS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
export const addLearningRow = (
	// headers: any,
	obj: any,
	setErrorMessage: any,
	setIsSuccessPromptOpened: any,
	setLoading: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const learning = {
				learning: obj,
			}
			const { data } = await AxiosCustom.post(endpoints.DOCUMENTS, learning, {
				headers: {
					'x-permission-name': permissionNames.ADD_EDIT_DOCUMENTS,
				},
			})
			if (!data.successful) {
				setErrorMessage(data.payload)
				setLoading(false)
				return
			}
			setIsSuccessPromptOpened(true)
			setLoading(false)
		} catch (err) {
			console.error(err)
		}
	}
}
export const updateLearningRow = (
	// headers: any,
	obj: any,
	setErrorMessage: any,
	setIsSuccessPromptOpened: any,
	setLoading: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const learning = {
				learning: obj,
			}
			const { data } = await AxiosCustom.put(endpoints.DOCUMENTS, learning, {
				headers: {
					'x-permission-name': permissionNames.ADD_EDIT_DOCUMENTS,
				},
			})
			if (!data.successful) {
				setErrorMessage(data.payload)
				setLoading(false)
				return
			}
			setIsSuccessPromptOpened(true)
			setLoading(false)
		} catch (err) {
			console.error(err)
		}
	}
}
export const removeRow = (
	// headers: any,
	obj: any,
	setErrorMessage: any,
	setIsSuccessPromptOpened: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const dataSend = { learning: obj }

			const { data } = await AxiosCustom.delete(
				endpoints.DOCUMENTS + `/${obj.id}`,
				{
					data: dataSend,
					headers: {
						'x-permission-name': permissionNames.ADD_EDIT_DOCUMENTS,
					},
				},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			setIsSuccessPromptOpened(true)
			dispatch({
				type: ActionType.REMOVE_DOCUMENTS,
				payload: obj,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
