import axios from 'axios'
import { devUrl } from '../config'
import { store } from '../state'

const Instance = axios.create({
	baseURL: window.location.href.includes('stage')
		? 'https://stage.varys.io/api/'
		: window.location.href.includes('varys')
		? 'https://varys.io/api/'
		: devUrl,
	headers: {
		Accept: 'application/json',
		'Content-type': 'application/json',
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
		'X-Requested-With': '*',
	},
	withCredentials: true,
})

Instance.interceptors.request.use(
	(config) => {
		const token = store.getState().login.user.token
		if (token) {
			config.headers = config.headers || {}
			config.headers.Authorization = `Bearer ${token}`
		}
		return config
	},
	(error) => {
		return Promise.reject(error)
	},
)

Instance.interceptors.response.use(
	(response) => {
		return response
	},
	(error) => {
		// If a 401 (UNAUTHORIZED) error occurs, redirect to /notlogged
		if (error.response && error.response.status === 401) {
			window.location.href = '/notlogged'
		}
		return Promise.reject(error)
	},
)

export default Instance
