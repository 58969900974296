import { ThemeProvider } from '@emotion/react'
import '@inovua/reactdatagrid-community/index.css'
import { ButtonProps, MenuItem, Typography, useTheme } from '@mui/material'
import {
	DataGridProProps,
	GridActionsCellItem,
	GridColDef,
	GridRenderCellParams,
	gridFilteredDescendantCountLookupSelector,
	useGridApiContext,
	useGridSelector,
} from '@mui/x-data-grid-pro'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { AppInterface } from '../../models/model.interface'
import { fetchData } from '../../utils/helpers/navigationHelper'
import { generateRowsWithIds, searchFor } from '../../utils/helpers/tableHelper'
import { WarningPrompt } from '../../utils/notifications/WarningPrompt'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import highlighterIcon from '../../assets/highlighter.png'
import trashIcon from '../../assets/trash.png'
import { default as VisibilityIcon } from '@mui/icons-material/Visibility'

import { ReactComponent as EditIcon } from '../../assets/svg/edit-icon.svg'
import { ReactComponent as DeleteIcon } from '../../assets/svg/delete-icon.svg'
import { PageWrapper, TableWrapper } from '../components/reusableComponents'
import AppPopUp from './AppsAdd'
import _ from 'lodash'
import './Styles.css'
import { getApplicableRules } from '../../utils/helpers/helperFuncs'
import {
	ActionsButton,
	SubmitButton,
	UnborderedButton,
} from '../components/Buttons'
import { TableComponent } from '../components/TableComponents'
import { useIsAuthorized } from '../../hooks/useIsAuthorized'
import { permissionNames } from '../../utils/helpers/permissionsHelper'
import { HeaderFilterComponent } from '../components/TableHelperComponents'

const P360Page = (props: {
	children?: any
	setLoading?: any
	errorMessage: null | string
	setErrorMessage: any
	isErrorPromptOpened: boolean
	setIsErrorPromptOpened: any
	isSuccessPromptOpened: any
	setIsSuccessPromptOpened: any
	viewRecord: any
	setViewRecord: any
	PopUpVisible: boolean
	setPopUpVisible: any
	search: string
	setSearch: Dispatch<SetStateAction<string>>
	presetSearch: string
	setPresetSearch: Dispatch<SetStateAction<string>>
}) => {
	const navigate = useNavigate()
	const params = useParams()
	const theme = useTheme()

	const rowCount = 9
	const { advertiser, users, settings } = useTypedSelector((state) => state)
	const { updateAppsAction, getAppsAction, removeP360Rule } = useActions()
	const sessionKey = 'filters-P360-table'
	const sessionSerializedFilters = sessionStorage.getItem(sessionKey)
	let sessionFilters: any = {}
	if (sessionSerializedFilters && sessionSerializedFilters.length > 4) {
		sessionFilters = JSON.parse(sessionSerializedFilters)
	}
	const [found, setFound] = useState<any[]>([])
	const [activeCount, setActiveCount] = useState(0)
	const [rowWidth, setRowWidth] = useState(
		Math.floor((window.innerWidth - theme.dimentions.drawerWidth) / rowCount),
	)
	const [isWarningPromptOpened, setIsWarningPromptOpened] = useState(false)
	const [sortModel, setSortModel] = useState([
		{ field: 'advertiser_name', sort: 'asc' },
	])
	const [row, setRow] = useState()
	const [update, setUpdate] = useState(false)
	const [openFilter, setOpenFilter] = useState(false)
	const [currentFilters, setCurrentFilters] = useState('')
	const [filterLabel, setFilterLabel] = useState('')
	const [customFilter, setCustomFilter] = useState<any>(
		sessionFilters.customFilter
			? sessionFilters.customFilter
			: {
					advertiser_name: [],
					publisher: [],
					pid: [],
			  },
	)
	const { app, login } = useTypedSelector((state) => state)
	const [pageSize, setPageSize] = useState(15)
	// const headers = useMemo(() => {
	// 	return {
	// 		Authorization: `Token ${login.user.token}`,
	// 	}
	// }, [login.user.token])

	const permissions = {
		edit: useIsAuthorized(permissionNames.ADD_EDIT_APP_P360),
		delete: useIsAuthorized(permissionNames.DELETE_APP_P360),
	}

	const onlyP360rows = app.app.filter((el: any) => {
		return (
			el.emailGroup !== null &&
			el.emailGroup !== undefined &&
			(el.emailGroup.length > 10 || typeof el.emailGroup === 'object')
		)
	})
	const [filteredApps, setFilteredApps] = useState<any>(
		Array.isArray(onlyP360rows) ? createP360Table(onlyP360rows) : [],
	)
	const [tableRows, setTableRows] = useState<any>(
		generateRowsWithIds(filteredApps),
	)
	const [optionsFilters, setOptionsFilter] = useState({
		advertiser_name: new Set(
			tableRows
				.map((element: any) => element.advertiser_name)
				.filter((el: any) => el && el.length > 0),
		),
		publisher: new Set(
			onlyP360rows
				.flatMap((element: any) =>
					element.emailGroup.flatMap((group: any) => group.publisher),
				)
				.filter((publisher: any) => publisher && publisher.length > 0),
		),
	})
	const [applyFilter, setApplyFilter] = useState(false)

	const handleApplyFilter = () => {
		setApplyFilter(!applyFilter)
	}
	useEffect(() => {
		//generate tables for p360 reports
		const onlyP360rows = app.app.filter((el: any) => {
			return (
				el.emailGroup !== null &&
				el.emailGroup !== undefined &&
				el.emailGroup.length > 10
			)
		})
		setFilteredApps(
			Array.isArray(onlyP360rows) ? createP360Table(onlyP360rows) : [],
		)
		// const newTable = generateTable(onlyP360rows)
	}, [app.app])

	useEffect(() => {
		fetchData(getAppsAction, app.app)
		const sessionSerializedFilters = sessionStorage.getItem(sessionKey)
		if (sessionSerializedFilters && sessionSerializedFilters.length > 4) {
			const sessionFilters = JSON.parse(sessionSerializedFilters)
			setCurrentFilters('advertiser')
			sessionFilters.customFilter &&
				setCustomFilter(sessionFilters.customFilter)
		}
		props.setLoading(false)
	}, [])
	useEffect(() => {
		if (filterLabel) {
			const filters = {
				customFilter,
			}
			const serializedFilters = JSON.stringify(filters)
			sessionStorage.setItem(sessionKey, serializedFilters)
		}
	}, [applyFilter])

	const columns: GridColDef[] = [
		// {
		// 	field: 'advertiser_name',
		// 	headerName: 'Advertiser Name',
		// 	width: rowWidth * 0.7,
		// },
		{
			field: 'app_id',
			headerName: 'App Id',
			width: rowWidth * 1.1,
			renderCell: ({ value }) => (
				<Typography
					sx={{ fontWeight: 600, fontSize: 14 }}
					style={{
						width: '100%',
						textOverflow: 'ellipsis',
						overflowWrap: 'break-word',
						overflow: 'hidden',
						whiteSpace: 'nowrap',
						height: 'auto',
						boxSizing: 'border-box',
					}}
				>
					{value}
				</Typography>
			),
		},
		{
			field: 'agency_account',
			headerName: 'Agency Accounts',
			width: rowWidth,
			renderCell: ({ value }) => {
				return (
					<ul>
						{value?.map((element: string) => (
							<li key={element}>{element}</li>
						))}
					</ul>
				)
			},
		},
		{
			field: 'publisher',
			headerName: 'Publisher',
			width: rowWidth,
			renderCell: ({ value }) => {
				return <Typography sx={{ fontSize: 14 }}>{value}</Typography>
			},
			renderHeader: () => {
				return (
					<HeaderFilterComponent
						label={'Publisher'}
						width={rowWidth * 0.9}
						openFilter={openFilter}
						setOpenFilter={setOpenFilter}
						field={'publisher'}
						customFilter={customFilter}
						currentFilters={currentFilters}
						setCurrentFilters={setCurrentFilters}
						setFilterLabel={setFilterLabel}
						optionsFilters={optionsFilters}
					/>
				)
			},
		},
		{
			field: 'pids',
			headerName: 'Pids',
			width: rowWidth * 1.4,
			renderCell: ({ value }) => {
				if (value) {
					return <P360DataList elements={value}></P360DataList>
				}
				return <></>
			},
			align: 'center',
			headerAlign: 'center',
		},
		{
			field: 'report',
			headerName: 'Report',
			width: rowWidth * 1.4,
			headerAlign: 'center',
			renderCell: ({ value }) => {
				if (value) {
					return value.length > 1 ? (
						<P360DataList elements={value}></P360DataList>
					) : (
						<li>{value}</li>
					)
				}
				return <></>
			},
			align: 'center',
		},
		{
			field: 'repeat',
			headerName: 'Repeat',
			width: rowWidth * 0.6,
		},
		{
			field: 'timeframe',
			headerName: 'Timeframe',
			width: rowWidth * 0.6,
		},
		(permissions.edit || (permissions.delete as any)) && {
			field: 'actions',
			headerName: 'Actions',
			width: 100,
			cellClassName: 'actions',
			renderCell: (row) => {
				const id = row.id
				//9999999999 this id is the hardcoded id we use for the bottom pinned row.
				if (!row.row.publisher) {
					return []
				} else return <ActionsButton options={actionOptions(row)} />
			},
		},
	]

	const actionOptions = (row: any) => {
		return [
			{
				label: '',
				icon: (
					<EditIcon
						style={{ color: theme.colors.text.titles, scale: '6.4' }}
						className={'action-button'}
					/>
				),
				onClick: () => {
					handleEditClick(row.row)
				},
				rule: permissions.edit,
				key: 'edit',
			},
			{
				label: '',
				icon: (
					<DeleteIcon
						style={{ color: theme.colors.text.titles, scale: '6.4' }}
						className={'action-button'}
					/>
				),
				onClick: () => {
					handleDeleteClick(row.row)
				},
				rule: permissions.delete,
				key: 'delete',
			},
		]
	}

	const handleEditClick = async (row: any) => {
		const id = row.hierarchy[row.hierarchy.length - 1]
		handleEdit(row.hierarchy[0], id)
	}
	const handleDeleteClick = async (row: any) => {
		const userConfirms = window.confirm(
			'Are you sure you want to delete this P360 rule?',
		)
		if (userConfirms) {
			removeP360Rule(row, props.setErrorMessage)
			//what we do here is:
			// 1.- getting rid of the row deleted
			let mainUuid = ''
			const arr = tableRows.filter((el: any) => {
				if (row.id === el.id) {
					mainUuid = el.hierarchy[0]
					return false
				}
				return true
			})
			// 2.- going over the new array and getting the mainUuid and the amount of "grouped elements" we have
			// 3.- If we have only 2 (Main and 1 grouped) then we remove the Main and turn the grouped into an ungrouped row.
			let count = 0
			let indx = 0
			let mainIndx = 0
			for (let i = 0; i < arr.length; i++) {
				const el = arr[i]
				if (el.hierarchy) {
					if (el.hierarchy[0] === mainUuid) {
						if (count === 0) {
							mainIndx = i
						}
						count++
						indx = i
					}
				}
			}
			if (count === 2) {
				arr[indx].hierarchy = [mainUuid]
				arr.splice(mainIndx, 1)
			}

			setTableRows(arr)
		}
	}

	const handleEdit = (mainUuid: any, uuid: string = '') => {
		navigate('/p360apps/' + mainUuid + '/' + uuid)
		props.setPopUpVisible(!props.PopUpVisible)
	}
	// if (PopUpVisible && params.id) {
	//  handleEdit(params.id)
	// }
	const updateAppsHandler = async (row: AppInterface) => {
		await updateAppsAction(
			{
				app_id: row.app_id,
				status: !row.status,
				email: login.user.email,
				created_by: row.created_by,
			},
			row,
			props.setErrorMessage,
		)
		await fetchData(getAppsAction, app.app)
		setUpdate(!update)
		props.setLoading(false)
	}

	const handlePopUp = async () => {
		props.setPopUpVisible(!props.PopUpVisible)
		if (props.PopUpVisible) {
			await fetchData(getAppsAction, app.app)
			setUpdate(!update)
			setRow(undefined)
		}
	}
	useEffect(() => {
		if (props.search.length > 0) {
			const result = searchFor(props.search, createP360Table(onlyP360rows), [
				...columns,
				{ field: 'advertiser_name' },
			])
			setFound(result)
		} else {
			setFound(onlyP360rows)
		}
	}, [props.search, app.app])
	useEffect(() => {
		let filtered = app.app.filter((el: any) => {
			return (
				el.emailGroup !== null &&
				el.emailGroup !== undefined &&
				(el.emailGroup.length > 10 || typeof el.emailGroup === 'object')
			)
		})
		if (props.search.length > 0) {
			filtered = found.filter((el: any) => {
				return (
					el.emailGroup !== null &&
					el.emailGroup !== undefined &&
					el.emailGroup.length > 0
				)
			})
		}
		let arr = []
		let anyfilters = false
		let additionalFiltersApplied = []
		//this is used to filter by the buttons of the columns headers.
		for (let [index, data] of filtered.entries()) {
			// let found = false
			let foundWrong = false

			for (const key in customFilter) {
				// let wrongKey = false
				let foundByKey = false

				if (customFilter[key].length > 0) {
					anyfilters = true
					if (key === 'publisher') {
						let pushGroup = false
						let dataToPush = _.cloneDeep(data)
						let uuids: any = []
						for (const rule of data.emailGroup) {
							if (customFilter[key].includes(rule.publisher[0])) {
								pushGroup = true
								uuids.push(rule.id)
							}
						}
						if (pushGroup) {
							const filteredRules = dataToPush.emailGroup.filter((rule: any) =>
								uuids.includes(rule.id),
							)

							dataToPush.emailGroup = filteredRules
							additionalFiltersApplied.push(dataToPush)
						}
					} else {
						foundByKey = customFilter[key].includes(data[key])
					}
					if (!foundByKey) {
						foundWrong = true
						break
					}
				}
			}
			if (!foundWrong) {
				additionalFiltersApplied.push(data)
				continue
			}
		}
		if (!anyfilters) {
			arr = filtered
		} else if (additionalFiltersApplied.length > 0 && anyfilters) {
			arr = additionalFiltersApplied
		} else {
			arr = []
		}
		const test = createP360Table(arr, props.search.length > 0)
		const newTable = generateRowsWithIds(test)
		setTableRows(newTable)
	}, [found, applyFilter, filteredApps])

	useEffect(() => {
		if (!props.PopUpVisible && params.id) {
			handleEdit(params.id)
		}
	}, [])
	// useEffect(() => {
	// 	const countActiveApps = () => {
	// 		let count = 0
	// 		app.app.forEach((a: AppInterface) => {
	// 			if (a.status) {
	// 				count++
	// 			}
	// 		})
	// 		setActiveCount(count)
	// 	}
	// 	countActiveApps()
	// 	props.setLoading(false)
	// }, [update, app.app, props])

	const getTreeDataPath: DataGridProProps['getTreeDataPath'] = (row) =>
		row.hierarchy
	//here we define the column that is grouping.
	const groupingColDef: DataGridProProps['groupingColDef'] = {
		headerName: 'Advertiser',
		width: rowWidth,
		cellClassName: (params: any) =>
			params.row.hierarchy && params.row.hierarchy.length > 1
				? ''
				: 'finance-campaign-title',
		renderCell: (params) => {
			if (params.rowNode.type === 'group') {
				return <CustomGridTreeDataGroupingCell {...params} />
			} else {
				if (params.row.hierarchy.length > 1) {
					return <span></span>
				}
				return <span>{params.row.advertiser_name}</span>
			}
		},
		renderHeader: () => {
			return (
				<HeaderFilterComponent
					label={'Advertiser'}
					width={rowWidth * 1.2}
					openFilter={openFilter}
					setOpenFilter={setOpenFilter}
					field={'advertiser_name'}
					customFilter={customFilter}
					currentFilters={currentFilters}
					setCurrentFilters={setCurrentFilters}
					setFilterLabel={setFilterLabel}
					optionsFilters={optionsFilters}
				/>
			)
		},
	}

	return !props.PopUpVisible ? (
		<ThemeProvider theme={theme}>
			<PageWrapper>
				<TableWrapper
					theme={theme}
					rowCount={rowCount}
					setRowWidth={setRowWidth}
					style={{ paddingBottom: '60px', width: '90%' }}
				>
					<TableComponent
						//style={{ top: '100px' }}
						columns={columns}
						rows={generateRowsWithIds(tableRows)}
						setPageSize={setPageSize}
						pageSize={pageSize}
						rowsPerPageOptions={[15, 25, 50]}
						setLoading={props.setLoading}
						handlePopUp={permissions.edit ? handlePopUp : undefined}
						sortModel={sortModel}
						rowHeight={'auto'}
						onSortModelChange={(model: any) => setSortModel(model)}
						treeData={true}
						getTreeDataPath={getTreeDataPath}
						groupingColDef={groupingColDef}
						filterHandler={openFilter}
						setFilterHander={setOpenFilter}
						customFilter={customFilter}
						setCustomFilter={setCustomFilter}
						currentFilters={currentFilters}
						applyFilter={handleApplyFilter}
						filterLabel={filterLabel}
						optionsFilters={optionsFilters}
					></TableComponent>

					<WarningPrompt
						message={'App data will NOT be fetched anymore'}
						PopUpVisible={isWarningPromptOpened}
						setPopUpVisible={setIsWarningPromptOpened}
						onAgree={updateAppsHandler}
						onCancel={() => props.setLoading(false)}
						obj={row}
					/>
				</TableWrapper>
			</PageWrapper>
		</ThemeProvider>
	) : (
		<AppPopUp
			PopUpVisible={props.PopUpVisible}
			setPopUpVisible={handlePopUp}
			setLoading={props.setLoading}
			advertisers={advertiser.advertiser}
			errorMessage={props.errorMessage}
			setErrorMessage={props.setErrorMessage}
			isErrorPromptOpened={props.isErrorPromptOpened}
			setIsErrorPromptOpened={props.setIsErrorPromptOpened}
			setIsSuccessPromptOpened={props.setIsSuccessPromptOpened}
			isSuccessPromptOpened={props.isSuccessPromptOpened}
		/>
	)
}

const P360DataList = (props: { elements: string[] }) => {
	const [open, setOpen] = useState(false)

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				marginBottom: open ? '30px' : '0px',
				maxWidth: '100%',
			}}
		>
			<UnborderedButton
				onClick={() => setOpen(!open)}
				style={{ display: 'flex', padding: 12 }}
				icon={<VisibilityIcon />}
			>
				{open ? 'Close' : 'View'} ({props.elements.length})
			</UnborderedButton>
			{open && (
				<div
					className={'p360-open-box-view'}
					style={{
						width: '95%',
						maxHeight: '300px',
						overflowY: 'auto',
						border: '1px solid rgb(224,224,224)',
						borderRadius: '20px',
						padding: '10px',
						maxWidth: '95%',
					}}
				>
					{props.elements
						.sort((a: string, b: string) => a.localeCompare(b))
						.map((el: string, index: number) => (
							<div
								key={index}
								style={{
									width: '100%',
									textOverflow: 'ellipsis',
									overflowWrap: 'break-word',
									overflow: 'hidden',
									whiteSpace: 'normal',
									height: 'auto',
									boxSizing: 'border-box',
								}}
							>
								<li>{el}</li>
							</div>
						))}
				</div>
			)}
		</div>
	)
}

export const createP360Table = (
	array: any,
	alreadyGenerated: boolean = false,
) => {
	if (alreadyGenerated) {
		const alreadyIn: string[] = []
		const result: any = []
		let previousMain = ''
		for (const row of array) {
			previousMain = row.hierarchy[0]

			if (row.hierarchy.length > 1) {
				if (
					row.hierarchy[0] === previousMain &&
					!alreadyIn.includes(row.hierarchy[0])
				) {
					const newRow: any = {}
					newRow.hierarchy = [row.hierarchy[0]]
					newRow.advertiser_name = row.advertiser_name
					newRow.app_id = row.app_id
					alreadyIn.push(previousMain)
					result.push(newRow)
					result.push(row)
				} else {
					result.push(row)
				}
			} else {
				if (!alreadyIn.includes(row.hierarchy[0])) {
					result.push(row)
					alreadyIn.push(row.hierarchy[0])
				}
			}
		}
		return result
	}
	const arrayCopy = [...array]
	const key = 'uuid'
	const alreadyRegistered: any = []
	const result = []
	for (const element of array) {
		let row = element
		if (element.emailGroup && typeof element.emailGroup === 'string') {
			const json = JSON.parse(element.emailGroup)
			row.emailGroup = json
		}
		//for loop based on emailgroups
		for (const rule of row.emailGroup) {
			let newRow: any = {}
			newRow.hierarchy = [row[key], rule.id]
			newRow.agency_account = rule.agency_account
			newRow.publisher = rule.publisher
			newRow.pids = rule.PID
			newRow.report = rule.report
			newRow.repeat = rule.repeat
			newRow.timeframe = rule.timeframe
			newRow.email = rule.email
			newRow.events = rule.events
			newRow.advertiser_name = row.advertiser_name
			newRow.app_id = row.app_id
			newRow.emailGroup = row.emailGroup
			if (!alreadyRegistered.includes(row[key])) {
				let mainRow: any = {}
				let multipleRows = false
				for (const savedElement of arrayCopy) {
					if (savedElement.emailGroup.length > 1) {
						for (const savedGroup of savedElement.emailGroup) {
							if (row[key] === savedElement[key]) {
								if (row.uuid !== savedGroup.uuid) {
									multipleRows = true
								}
							}
						}
					}
				}
				mainRow.advertiser_name = row.advertiser_name
				mainRow.app_id = row.app_id
				if (multipleRows) {
					mainRow.hierarchy = [row[key]]
					alreadyRegistered.push(row[key])
					result.push(mainRow)
					newRow.hierarchy = [row[key], rule.id]
				} else {
					newRow.hierarchy = [row[key]]
				}
			}
			result.push(newRow)
		}
	}
	if (result[0] && !result[0].app_id) {
		result.shift()
	}
	const sortAlphaNum = (a: any, b: any) =>
		a.advertiser_name.localeCompare(b.advertiser_name, 'en', { numeric: true })
	return result.sort(sortAlphaNum)
}

export function CustomGridTreeDataGroupingCell(props: GridRenderCellParams) {
	const { id, field, rowNode } = props
	const theme = useTheme()
	const apiRef = useGridApiContext()
	const [expanded, setExpanded] = useState(false)
	const filteredDescendantCountLookup = useGridSelector(
		apiRef,
		gridFilteredDescendantCountLookupSelector,
	)
	const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0

	const handleClick: ButtonProps['onClick'] = (event) => {
		if (rowNode.type !== 'group') {
			return
		}
		apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded)
		apiRef.current.setCellFocus(id, field)
		event.stopPropagation()
	}
	useEffect(() => {
		const currentRowStatus = apiRef.current.getRowNode(id)
		if (currentRowStatus) {
			setExpanded((currentRowStatus as any).childrenExpanded)
		}
	}, [apiRef.current.getRowNode(id)])

	return (
		<div>
			<div>
				{filteredDescendantCount > 0 ? (
					<button
						onClick={handleClick}
						tabIndex={-1}
						style={{
							border: 'none',
							display: 'flex',
							alignItems: 'flex-start',
							marginLeft: '-12px',
							cursor: 'pointer',
						}}
					>
						{expanded ? (
							<KeyboardArrowDownIcon
								style={{
									color: theme.colors.base.red300,
									fontSize: '20px',
									marginTop: '2px',
								}}
							/>
						) : (
							<KeyboardArrowRightIcon
								style={{
									color: theme.colors.base.red300,
									fontSize: '20px',
									marginTop: '2px',
								}}
							/>
						)}
						<span
							style={{
								fontWeight: '600',
								fontSize: '14px',
								display: 'flex',
								justifyContent: 'flex-start',
								textAlign: 'start',
								marginLeft: '2px',
								color: theme.colors.text.titles,
							}}
						>
							{props.row.advertiser_name}
						</span>
					</button>
				) : (
					<span />
				)}
			</div>
		</div>
	)
}

export default P360Page
