import React from 'react'
import {
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	Box,
	Typography,
	DialogActions,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

interface CustomModalProps {
	title: string
	open: boolean
	onClose: () => void
	children: React.ReactNode
	actions?: React.ReactNode
}

const CustomModal: React.FC<CustomModalProps> = ({
	title,
	open,
	onClose,
	children,
	actions,
}) => {
	return (
		<Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
			<DialogTitle>
				{title}
				<IconButton
					onClick={onClose}
					style={{ position: 'absolute', right: '8px', top: '8px' }}
					aria-label='close'
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>{children}</DialogContent>
			{actions && <DialogActions>{actions}</DialogActions>}
		</Dialog>
	)
}

export default CustomModal
