import { AutoSwitchInterface, CampaignInterface } from '../../models/model.interface'
import { ActionType } from '../action-types/index'
import { Action } from '../actions'

export interface AutoSwitchReducerInterface {
	rules: AutoSwitchInterface[] | any
}

const initialState: AutoSwitchReducerInterface = {
	rules: [],
}

const reducer = (
	state: AutoSwitchReducerInterface = initialState,
	action: Action,
): AutoSwitchReducerInterface => {
	switch (action.type) {
		case ActionType.GETAUTOSWITCH: {
			return {
				...state,
				rules: action.payload,
			}
		}
		case ActionType.PUTAUTOSWITCH: {
			state.rules.unshift(action.payload)
			return {
				rules: state.rules,
			}
		}
		case ActionType.DELETEAUTOSWITCH: {
			const arr = state.rules.filter(
				(rule: any) => rule.uuid !== action.payload.uuid,
			)
			return {
				rules: arr,
			}
		}
		case ActionType.LOGOUT: {
			return {
				...state,
				rules: [],
			}
		}
		default: {
			return state
		}
	}
}

export default reducer