import React, {
	useRef,
	useState,
	useEffect,
	useMemo,
	useLayoutEffect,
} from 'react'
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TablePagination,
	TableSortLabel,
	useTheme,
	TableCellProps,
	TableContainer,
	Tooltip,
	TextField,
	IconButton,
	InputAdornment,
} from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList'
import ClearIcon from '@mui/icons-material/Clear'
import styled from '@emotion/styled'
import { TotalRow } from './TableComponents'
import {
	formatColumnName,
	getComparator,
	getIntegerAndFloatColumns,
	roundColumnValuesNumbers,
	stableSort,
} from '../../utils/helpers/helperFuncs'
import {
	reorderReportColumns,
	reportColumnMap,
	sanitizeReason,
} from '../../utils/helpers/reportHelperFuncs'
import { SmallLoading } from '../../assets/svg/loading'
import { generatePaginationOptions } from '../../utils/helpers/tableHelper'

interface CustomReportTableProps {
	reports: any[]
	reportNames: string[]
	totalRow?: { [key: string]: string | number | null } // Total row data
	reportType: string
}

export type Order = 'asc' | 'desc'

interface StyledTableCellProps extends TableCellProps {
	isTotalRevenue?: boolean
	isTotalRow?: boolean
}

// Styled Table Body Cell
export const StyledTableBodyCell: React.FC<StyledTableCellProps> = ({
	isTotalRevenue,
	isTotalRow,
	...props
}) => {
	const theme = useTheme()
	const cellRef = useRef<HTMLDivElement>(null)
	const [isTruncated, setIsTruncated] = useState(false)

	useEffect(() => {
		if (cellRef.current) {
			setIsTruncated(cellRef.current.scrollWidth > cellRef.current.clientWidth)
		}
	}, [props.children])

	const style = {
		backgroundColor: isTotalRow
			? theme.colors.base.grey300
			: isTotalRevenue
			? theme.colors.base.grey200
			: 'inherit',
		color: theme.colors.text.titles,
		fontWeight: isTotalRow ? 'bold' : 'normal',
		width: 'auto',
		textAlign: 'left' as 'left',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		padding: '8px',
	}

	return (
		<Tooltip title={isTruncated ? props.children : ''} arrow>
			<TableCell style={style} {...props}>
				<div
					ref={cellRef}
					style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
				>
					{props.children}
				</div>
			</TableCell>
		</Tooltip>
	)
}

// Styled Table Row
export const StyledTableRow = styled(TableRow)<{ isTotalRow?: boolean }>`
	&& {
		font-weight: ${({ isTotalRow }) => (isTotalRow ? 'bold' : 'normal')};
		font-size: ${({ isTotalRow }) => (isTotalRow ? '1rem' : 'inherit')};
		width: 100%;
	}
`

// Utility function to highlight text (optional, can be removed)
const highlightText = (text: string, highlight: string) => {
	if (!highlight) return text
	const regex = new RegExp(`(${highlight})`, 'gi')
	const parts = text.split(regex)
	return (
		<>
			{parts.map((part, index) =>
				part.toLowerCase() === highlight.toLowerCase() ? (
					<span key={index} style={{ backgroundColor: 'yellow' }}>
						{part}
					</span>
				) : (
					part
				),
			)}
		</>
	)
}

const CustomReportTable: React.FC<CustomReportTableProps> = ({
	reports,
	totalRow,
	reportType,
}) => {
	const theme = useTheme()

	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(() => {
		const paginationOptions = generatePaginationOptions(reports.length)
		return typeof paginationOptions[0] === 'number' ? paginationOptions[0] : 4
	})
	const [order, setOrder] = useState<Order>('asc')
	const [orderBy, setOrderBy] = useState<string>('')
	const [visibleRows, setVisibleRows] = useState<any[]>([])
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [filters, setFilters] = useState<{ [key: string]: string }>({})
	const [filterVisibility, setFilterVisibility] = useState<{
		[key: string]: boolean
	}>({})

	const isAllRows = rowsPerPage === -1

	console.log(
		`%cDebug:reportp0[] in the CUisotmReporttable compoannt:  %c${JSON.stringify(
			reports[0],
		)}`,
		'color:red',
		'color:red',
	)

	useLayoutEffect(() => {
		setPage(0)
	}, [reports])

	// Get dynamic integer and float columns from the reportColumnMap
	const { integerColumns, floatColumns } =
		getIntegerAndFloatColumns(reportColumnMap)

	const sortedColumns = useMemo(() => {
		const columns = Object.keys(reports[0] || {})
		return reorderReportColumns(columns, reportType)
	}, [reports, reportType])

	// Apply filters to reports
	const filteredReports = useMemo(() => {
		return reports.filter((report) => {
			return sortedColumns.every((columnKey: any) => {
				const filterValue = filters[columnKey]
				if (!filterValue) return true // No filter for this column
				const cellValue = report[columnKey]
				if (cellValue === null || cellValue === undefined) return false
				return String(cellValue)
					.toLowerCase()
					.includes(filterValue.toLowerCase())
			})
		})
	}, [reports, filters, sortedColumns])

	// Step 1: Sort the filtered reports based on order and orderBy
	const sortedReports = useMemo(() => {
		if (orderBy) {
			return stableSort(filteredReports, getComparator(order, orderBy))
		}
		return filteredReports
	}, [filteredReports, order, orderBy])

	// Step 2: Update visibleRows based on page, rowsPerPage, and sortedReports
	useEffect(() => {
		if (!isAllRows) {
			const start = page * rowsPerPage
			const end = start + rowsPerPage
			setVisibleRows(sortedReports.slice(start, end))
		} else {
			setVisibleRows(sortedReports)
		}
	}, [page, rowsPerPage, sortedReports, isAllRows])

	const memoizedRows = useMemo(() => {
		return visibleRows
	}, [visibleRows])

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => {
		const newRowsPerPage = parseInt(event.target.value, 10)
		setRowsPerPage(newRowsPerPage)
		setPage(0)
		if (newRowsPerPage !== -1) {
			// For specific page sizes
			setVisibleRows(sortedReports.slice(0, newRowsPerPage))
		} else {
			// When "All" is selected
			setIsLoading(true)
			setVisibleRows([]) // Clear current rows
			loadAllDataInChunks()
		}
	}

	const loadAllDataInChunks = () => {
		const chunkSize = 100 // Adjust as needed
		let loadedRows: any[] = []
		let currentIndex = 0

		const loadChunk = () => {
			if (currentIndex < sortedReports.length) {
				const nextChunk = sortedReports.slice(
					currentIndex,
					currentIndex + chunkSize,
				)
				loadedRows = [...loadedRows, ...nextChunk]
				setVisibleRows([...loadedRows]) // Update visible rows
				currentIndex += chunkSize
				setTimeout(loadChunk, 0) // Load next chunk asynchronously
			} else {
				setIsLoading(false) // All data loaded
			}
		}

		loadChunk()
	}

	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: string,
	) => {
		const isAsc = orderBy === property && order === 'asc'
		setOrder(isAsc ? 'desc' : 'asc')
		setOrderBy(property)
	}

	const toggleFilterVisibility = (columnKey: string) => {
		setFilterVisibility((prev) => ({
			...prev,
			[columnKey]: !prev[columnKey],
		}))
	}

	const clearFilter = (columnKey: string) => {
		setFilters((prev) => ({
			...prev,
			[columnKey]: '',
		}))
		setFilterVisibility((prev) => ({
			...prev,
			[columnKey]: false,
		}))
	}

	return (
		<Box
			sx={{
				width: '100%',
				background: theme.colors.base.white,
				color: theme.colors.text.titles + ' !important',
			}}
		>
			{isLoading && (
				<Box sx={{ textAlign: 'center', padding: '1rem' }}>
					<SmallLoading />
				</Box>
			)}

			{!isLoading && (
				<>
					<TableContainer
						sx={{
							width: '100%',
							overflowX: 'auto',
							background: theme.colors.base.white,
							color: theme.colors.text.titles,
						}}
					>
						<Table
							sx={{
								minWidth: 'max-content',
							}}
						>
							<TableHead>
								<TableRow>
									{sortedColumns.map((columnKey: any) => (
										<TableCell
											key={columnKey}
											sortDirection={orderBy === columnKey ? order : false}
											sx={{
												width: `${100 / sortedColumns.length}%`,
												minWidth: '150px',
												whiteSpace: 'nowrap',
												overflow: 'hidden',
												textOverflow: 'ellipsis',
												position: 'relative', // For positioning the filter icon
												// Hover effect to show filter icon
												'&:hover .filter-icon': {
													opacity: 1,
													pointerEvents: 'auto',
												},
											}}
										>
											<Box
												sx={{
													display: 'flex',
													alignItems: 'center',
													gap: '4px', // Adjust the gap as needed
												}}
											>
												<TableSortLabel
													active={orderBy === columnKey}
													direction={orderBy === columnKey ? order : 'asc'}
													onClick={(event) =>
														handleRequestSort(event, columnKey)
													}
													style={{ color: theme.colors.text.titles }}
												>
													{formatColumnName(columnKey)}
												</TableSortLabel>

												{/* Filter Icon */}
												<IconButton
													size='small'
													onClick={(e) => {
														e.stopPropagation() // Prevent sort on icon click
														toggleFilterVisibility(columnKey)
													}}
													className='filter-icon'
													sx={{
														opacity:
															filterVisibility[columnKey] || filters[columnKey]
																? 1
																: 0,
														pointerEvents:
															filterVisibility[columnKey] || filters[columnKey]
																? 'auto'
																: 'none',
														transition: 'opacity 0.3s',
													}}
												>
													<FilterListIcon fontSize='small' />
												</IconButton>
											</Box>

											{/* Filter Input */}
											{filterVisibility[columnKey] && (
												<TextField
													variant='standard'
													value={filters[columnKey] || ''}
													onChange={(e) =>
														setFilters((prev) => ({
															...prev,
															[columnKey]: e.target.value,
														}))
													}
													placeholder='Filter'
													size='small'
													sx={{
														marginTop: '4px',
														width: '100%',
														input: { fontSize: '0.8rem' },
													}}
													InputProps={{
														endAdornment: filters[columnKey] ? (
															<InputAdornment position='end'>
																<IconButton
																	size='small'
																	onClick={() => clearFilter(columnKey)}
																>
																	<ClearIcon fontSize='small' />
																</IconButton>
															</InputAdornment>
														) : null,
													}}
												/>
											)}
										</TableCell>
									))}
								</TableRow>
							</TableHead>

							<TableBody>
								{memoizedRows.map((report, rowIndex) => (
									<StyledTableRow
										key={rowIndex}
										isTotalRow={report['date'] === reportColumnMap.total}
									>
										{sortedColumns.map((columnKey: any) => (
											<StyledTableBodyCell
												key={columnKey}
												isTotalRevenue={
													columnKey === reportColumnMap.totalRevenue
												}
												isTotalRow={report['date'] === reportColumnMap.total}
												sx={{
													minWidth: '150px',
												}}
											>
												{columnKey === reportColumnMap.reason
													? sanitizeReason(report[columnKey])
													: columnKey === reportColumnMap.cr
													? `${roundColumnValuesNumbers(
															reportColumnMap.cr,
															report[columnKey],
															integerColumns,
															floatColumns,
													  )}%`
													: roundColumnValuesNumbers(
															columnKey,
															report[columnKey],
															integerColumns,
															floatColumns,
													  )}
											</StyledTableBodyCell>
										))}
									</StyledTableRow>
								))}

								{totalRow && (
									<TotalRow
										totalRow={totalRow}
										columns={sortedColumns}
										reports={sortedReports}
									/>
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</>
			)}

			<TablePagination
				rowsPerPageOptions={generatePaginationOptions(sortedReports.length)}
				component='div'
				count={sortedReports.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</Box>
	)
}

export default CustomReportTable
