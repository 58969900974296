// reducers/rolesReducer.ts
import { Role } from '../actions/index'
import { ActionType } from '../action-types'
import { Action } from '../actions'

export interface RolesState {
	roles: Role[]
	error: string | null
}

const initialState: RolesState = {
	roles: [],
	error: null,
}

const rolesReducer = (
	state: RolesState = initialState,
	action: Action,
): RolesState => {
	switch (action.type) {
		case ActionType.FETCH_ROLES:
			return {
				...state,
				roles: action.payload,
				error: null,
			}
		case ActionType.CREATE_ROLE:
			return {
				...state,
				roles: [...state.roles, action.payload],
				error: null,
			}
		case ActionType.UPDATE_ROLE:
			return {
				...state,
				roles: state.roles.map((role) =>
					role.id === action.payload.id ? action.payload : role,
				),
				error: null,
			}
		case ActionType.DELETE_ROLE:
			return {
				...state,
				roles: state.roles.filter((role) => role.id !== action.payload),
				error: null,
			}
		case ActionType.FETCH_ROLES_ERROR:
			return {
				...state,
				error: action.payload,
			}
		default:
			return state
	}
}

export default rolesReducer
