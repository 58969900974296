import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router'
import { emailToUserConvertor } from '../../utils/helpers/helperFuncs'
import { ItemTitle } from '../components/reusableComponents'

const NewPublisherItem = (props: { item: any; title: any }) => {
	const [capabilitiesArr, setCapabilitiesArr] = useState<string[]>([])
	const [nameOfUser, setNameOfUser] = useState('')
	const navigate = useNavigate()
	useEffect(() => {
		if (props.item.capabilities) {
			const arr: string[] = []
			props.item.capabilities.forEach((cap: any) => {
				if (cap.value === true) arr.push(cap.title)
			})
			setCapabilitiesArr(arr)
		}
		setNameOfUser(emailToUserConvertor(props.item.email || props.item.created_by))
	}, [])

	return (
		<div
			style={{ marginBottom: '24px', cursor: 'pointer' }}
			onClick={() =>
				navigate(
					'/' +
						props.title.toLowerCase() +
						'/' +
						props.item.id +
						'/preview',
				)
			}
		>
			<div
				style={{
					fontWeight: '800',
					fontSize: '18px',
					justifyContent: 'flex-start',
					display: 'flex',
				}}
			>
				{props.item.publisher_name}
				<div style={{ fontSize: '15px', marginTop: '1px', marginLeft: '3px' }}>
					{props.item.type ? '(' + props.item.type + ')' : ''}
				</div>
			</div>
			{props.item.email && (
				<div>
					<ItemTitle>Publisher Manager: </ItemTitle> {nameOfUser}
				</div>
			)}
			{capabilitiesArr.length > 0 && (
				<div>
					<ItemTitle>Capabilities: </ItemTitle> {capabilitiesArr.join(', ')}
				</div>
			)}
		</div>
	)
}

export default NewPublisherItem
