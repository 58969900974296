export interface Alert {
	id: string
	message: string
	isError: boolean
	seen: boolean
	link?: string
	extra_data?: string | undefined
	status?: AlertStatus
	minimized?: boolean
}

export const ALERT_STATUS_PENDING = 'pending'
export const ALERT_STATUS_SUCCESS = 'success'
export const ALERT_STATUS_ERROR = 'error'

export type AlertStatus =
	| typeof ALERT_STATUS_PENDING
	| typeof ALERT_STATUS_SUCCESS
	| typeof ALERT_STATUS_ERROR

export interface AlertState {
	alerts: Alert[]
	show: boolean
}
