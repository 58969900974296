// alertReducer.ts
import { ActionType } from '../action-types'
import { Action } from '../actions'
import {
	ALERT_STATUS_PENDING,
	Alert,
	AlertState,
} from '../../models/alert.interface'

const initialState: AlertState = {
	alerts: [],
	show: false,
}

const alertReducer = (
	state: AlertState = initialState,
	action: Action,
): AlertState => {
	switch (action.type) {
		case ActionType.ADD_ALERT: {
			const updatedAlerts = [...state.alerts, action.payload]

			if (updatedAlerts.length > 4) {
				// Allow up to 4 alerts
				updatedAlerts.shift() // Remove the oldest alert
			}
			return {
				...state,
				alerts: updatedAlerts,
				show: true,
			}
		}

		case ActionType.REMOVE_ALERT: {
			const newState = state.alerts.filter(
				(alert) => alert.id !== action.payload,
			)

			return {
				...state,
				alerts: newState,
				show: newState.length > 0, // Hide if no alerts remain
			}
		}
		case ActionType.MARK_ALERTS_AS_SEEN: {
			const updatedAlerts = state.alerts.map((alert) =>
				action.payload.some((seenAlert: Alert) => seenAlert.id === alert.id)
					? { ...alert, seen: true }
					: alert,
			)

			return {
				...state,
				alerts: updatedAlerts,
			}
		}

		case ActionType.UPDATE_ALERT: {
			const existingAlertIndex = state.alerts.findIndex(
				(alert) => alert.id === action.payload.id,
			)
			let updatedAlerts: Alert[]

			if (existingAlertIndex !== -1) {
				// Alert exists; update it
				updatedAlerts = state.alerts.map((alert) => {
					if (alert.id === action.payload.id) {
						return {
							...alert,
							...action.payload,
						}
					}
					return alert
				})
			} else {
				// Alert does not exist; re-add it
				const newAlert: Alert = {
					id: action.payload.id,
					message: action.payload.message || '',
					isError: action.payload.isError || false,
					seen: false, // Reset seen status
					link: action.payload.link,
					extra_data: action.payload.extra_data,
					status: action.payload.status || ALERT_STATUS_PENDING,
				}
				updatedAlerts = [...state.alerts, newAlert]

				if (updatedAlerts.length > 4) {
					// Allow up to 4 alerts
					updatedAlerts.shift() // Remove the oldest alert
				}
			}

			return {
				...state,
				alerts: updatedAlerts,
				show: true,
			}
		}

		case ActionType.MINIMIZE_ALERT: {
			const updatedAlerts = state.alerts.map((alert) => {
				if (alert.id === action.payload) {
					return { ...alert, minimized: true }
				}
				return alert
			})
			return {
				...state,
				alerts: updatedAlerts,
			}
		}

		case ActionType.RESTORE_ALL_ALERTS: {
			const updatedAlerts = state.alerts.map((alert) => ({
				...alert,
				minimized: false,
			}))
			return { ...state, alerts: updatedAlerts }
		}

		case ActionType.OPEN:
			return {
				...state,
				show: true,
			}
		case ActionType.CLOSE:
			return {
				...state,
				show: false,
			}

		default:
			return state
	}
}

export default alertReducer
