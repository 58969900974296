import AdapterDateFns from '@date-io/date-fns'
import { Checkbox, Grid, Typography, useTheme } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { DateRangePicker } from '@mui/x-date-pickers-pro'
import { subDays } from 'date-fns'
import { useEffect, useState } from 'react'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { P360ReducerInterface } from '../../state/reducers/p360reducer'
import { formatDate } from '../../utils/helpers/tableHelper'
import { PageWrapper } from '../components/reusableComponents'
import { LoginButton } from '../login/loginPageComponents'
import { StyledCheckboxFormControl } from '../reports/aggregated/FiltersStyled'
import { StyledTextField } from '../reports/aggregated/ReportsStyled'
import { GraphWrapper } from './GraphCreator'
import { PresetsPopup } from './p360PresetPopup'
import { ReportsTable360 } from './P360ReportsTable'
import { DropList } from '../components/SelectableInputs'
import { SubmitButton } from '../components/Buttons'
import { TitleComponent } from '../components/Informative'
import { ChipInputList } from '../components/ChipInput'
interface FiltersSelectedModel {
	os: string
	orderBy: string
	accountManager: string[]
	publisherManager: string[]
	appsflyerAccount: string[]
}
export interface p360dashboardPayloadModel {
	dateFrom: Date
	dateTo: Date
	breakdownFields: string[]
	filters: FiltersSelectedModel
	graphs: any
}

const P360Dashboard = () => {
	const theme = useTheme()
	const { preset, login, users, p360aggregated, publisher, advertiser } =
		useTypedSelector((state) => state)

	const [popUpVisible, setPopUpVisible] = useState(false)
	const [retrieved, setRetrieved] = useState<any>({})
	const [refresh, setRefresh] = useState(false)
	const [breakdownFields, setBreakdownFields] = useState<string[]>([])
	const [dateFrom, setDateFrom] = useState(new Date(subDays(new Date(), 1)))
	const [dateTo, setDateTo] = useState(new Date(subDays(new Date(), 1)))
	const [aggregatedReport, setAggregatedReport] = useState<any>([])
	const [accountManagers, setAccountManagers] = useState<string[]>([])
	const [publisherManager, setPublisherManager] = useState<string[]>([])
	const [appsflyerAccount, setAppsflyerAccount] = useState<string[]>([])
	const [addGraph, setAddGraph] = useState(false)
	const [graphEditorPopup, setGraphEditorPopup] = useState(false)
	const [os, setOs] = useState('')
	const [orderBy, setOrderBy] = useState('')
	const [graphs, setGraphs] = useState([
		{
			metric: 'Media source',
			value: 'Installs',
			type: 'pie',
			depth: 5,
			title: 'Title',
		},
		{
			metric: 'App ID',
			value: 'Installs',
			type: 'line',
			depth: 5,
			title: 'Title',
		},
	])
	const filtersSelected: FiltersSelectedModel = {
		os: os,
		orderBy: orderBy,
		accountManager: accountManagers,
		publisherManager: publisherManager,
		appsflyerAccount: appsflyerAccount,
	}

	let presetName = ''
	// let graphParameterObject = useMemo(
	// 	() => [
	// 		{
	// 			metric: 'Media source',
	// 			value: 'Installs',
	// 			type: 'pie',
	// 			depth: 5,
	// 			title: 'Title',
	// 		},
	// 		{
	// 			metric: 'App ID',
	// 			value: 'Installs',
	// 			type: 'line',
	// 			depth: 5,
	// 			title: 'Title',
	// 		},
	// 	],
	// 	[],
	// )
	let payloadObject: p360dashboardPayloadModel = {
		dateFrom: dateFrom,
		dateTo: dateTo,
		breakdownFields: breakdownFields,
		filters: filtersSelected,
		graphs: graphs,
	}

	useEffect(() => {
		if (retrieved.filters) {
			// console.log(retrieved)
			setAccountManagers(retrieved.filters.accountManager)
			setPublisherManager(retrieved.filters.publisherManager)
			setOs(retrieved.filters.os)
			setOrderBy(retrieved.filters.orderBy)
			setDateFrom(retrieved.dateFrom)
			setDateTo(retrieved.dateTo)
			setBreakdownFields(retrieved.breakdownFields)
			setGraphs(retrieved.graphs)
			setRefresh(!refresh)
		}
	}, [retrieved])

	let graphsComponents: any[] = graphs.map((paramObj, index) => (
		<GraphWrapper
			data={p360aggregated}
			filters={payloadObject}
			users={users}
			paramObjectReference={graphs}
			setRefresh={setRefresh}
			index={index}
			optionsBreakdowns={[
				'Media source',
				'App ID',
				'account_manager',
				'publisher_manager',
				'Date',
			]}
			optionsStatistics={[
				'Post-attribution fraud',
				'Total fraudulent attribution',
				'Installs',
				'Fraud trend',
				'Real-time blocks',
				'Post-attribution fraud percent',
				'Total fraudulent attribution percent',
			]}
		></GraphWrapper>
	))

	useEffect(() => {
		let recursiveResult: any[] = []
		let cleared: any[] = []

		const allBreakdowns = [
			...breakdownFields.map((e: string) => e.replace('%', 'percent')),
			'App ID',
			'Post-attribution fraud',
			'Post-attribution fraud percent',
			'Installs',
		]
		// const allBreakdowns = [
		// 	...breakdownFields.map((e: string) => e.replace('%', 'percent')),
		// 	'Media source',
		// 	'Installs',
		// ]
		aggregateForDashboard(
			p360aggregated,
			cleared,
			dateFrom,
			dateTo,
			accountManagers,
			users,
			publisherManager,
			appsflyerAccount,
			allBreakdowns,
		)

		reduceRecursive(cleared, ['App ID'], 0, recursiveResult)

		setAggregatedReport(recursiveResult)
	}, [
		breakdownFields,
		accountManagers,
		publisherManager,
		appsflyerAccount,
		os,
		orderBy,
		dateFrom,
		dateTo,
		refresh,
	])

	return (
		<PageWrapper
			style={{
				color: theme.colors.black,
				display: 'flex',
				marginTop: '6%',
				flexDirection: 'column',
				gap: '30px',
				paddingBottom: '30px',
			}}
		>
			<>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '65px',
						alignItems: 'flex-start',
						width: '100%',
					}}
				>
					P360 Dashboard
					<TimeframeComponent
						filtersObject={payloadObject}
						retrieved={retrieved}
						dateFrom={dateFrom}
						dateTo={dateTo}
						setDateFrom={setDateFrom}
						setDateTo={setDateTo}
					/>
					<div
						style={{
							height: '1px',
							width: '100%',
							borderBottom: '1px solid ' + 'rgba(175,175,175,1)',
						}}
					></div>
					<FiltersList
						filtersObject={payloadObject}
						retrieved={retrieved}
						accountManagers={accountManagers}
						publisherManager={publisherManager}
						os={os}
						orderBy={orderBy}
						setAccountManagers={setAccountManagers}
						setPublisherManager={setPublisherManager}
						setOs={setOs}
						setOrderBy={setOrderBy}
						setAppsflyerAccount={setAppsflyerAccount}
						appsflyerAccount={appsflyerAccount}
					/>
					<div
						style={{
							height: '1px',
							width: '100%',
							borderBottom: '1px solid ' + 'rgba(175,175,175,1)',
						}}
					></div>
					<BreakdownFieldsComp
						filtersObject={payloadObject}
						retrieved={retrieved}
						setBreakdownFields={setBreakdownFields}
						breakdownFields={breakdownFields}
					/>
					<div
						style={{
							height: '1px',
							width: '100%',
							borderBottom: '1px solid ' + 'rgba(175,175,175,1)',
						}}
					></div>
				</div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'flex-start',
						alignItems: 'baseline',
					}}
				>
					<SubmitButton
						style={{ width: '12vw ' }}
						onClick={() => setPopUpVisible(true)}
						children={'Presets'}
					/>
					<LoginButton
						style={{ width: '12vw ' }}
						onClick={() => setGraphEditorPopup(!graphEditorPopup)}
						children='Add Graph'
					/>
				</div>
				<Grid container spacing={10}>
					{graphsComponents}
				</Grid>

				<ReportsTable360
					report={aggregatedReport}
					setIsReportOpen={true}
					reportFields={[
						'App ID',
						...breakdownFields.map((e: string) => e.replace('%', 'percent')),
						'Post-attribution fraud',
						'Post-attribution fraud percent',
						'Installs',
					]}
					isReportOpen={true}
					customReportFields={breakdownFields}
				/>
				<PresetsPopup
					visible={popUpVisible}
					setPresetVisible={setPopUpVisible}
					reportFields={payloadObject}
					setRow={undefined}
					fillReport={undefined}
					setRetrieved={setRetrieved}
					presetNameExt={presetName}
				/>
				{/* <GraphEditPopUp
					popUpVisible={graphEditorPopup}
					setPopUpVisible={setGraphEditorPopup}
					object={undefined}
					setMetric={undefined}
					setValue={undefined}
				/> */}
			</>
		</PageWrapper>
	)
}

const TimeframeComponent = (props: {
	filtersObject: p360dashboardPayloadModel
	retrieved: boolean
	dateFrom: Date
	dateTo: Date
	setDateFrom: any
	setDateTo: any
}) => {
	const [value, setValue] = useState<any>([props.dateFrom, props.dateTo])
	const [viewDataFor, setViewDataFor] = useState('Today')
	useEffect(() => {
		props.setDateFrom(value[0])
		props.setDateTo(value[1])
		props.filtersObject.dateFrom = value[0]
		props.filtersObject.dateTo = value[1]
	}, [value])
	// useEffect(() => {
	// 	if (props.updatedTimeframe) {
	// 		setValue([dateFrom, dateTo])
	// 		props.setUpdatedTimeframe(false)
	// 	}
	// }, [props.updatedTimeframe])

	const handleViewDataForChange = (e: any) => {
		const date = new Date()
		setViewDataFor(e)
		switch (e) {
			case 'Today':
				props.setDateFrom(date)
				props.setDateTo(date)
				setValue([date, date])
				break
			case 'Yesterday':
				props.setDateFrom(subDays(new Date(date), 1))
				props.setDateTo(subDays(new Date(date), 1))
				setValue([subDays(new Date(date), 1), subDays(new Date(date), 1)])
				break
			case 'Last 7 Days':
				props.setDateFrom(subDays(new Date(date), 7))
				props.setDateTo(date)
				setValue([subDays(new Date(date), 7), date])
				break
			case 'From Start of the Month':
				props.setDateFrom(new Date(date.getFullYear(), date.getMonth(), 1))
				props.setDateTo(new Date())
				setValue([new Date(date.getFullYear(), date.getMonth(), 1), new Date()])
				break
			default:
				break
		}
	}
	useEffect(() => {
		setValue([props.dateFrom, props.dateTo])
	}, [props.dateFrom, props.dateTo])

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<DateRangePicker
				value={value}
				inputFormat='dd/MM/yyyy'
				onChange={(newValue) => {
					setValue(newValue)
					setViewDataFor('Custom')
				}}
				className='datePicker'
				renderInput={(startProps, endProps) => (
					<Grid
						container
						direction={'row'}
						spacing={5}
						style={{ width: '100vw', height: '100px' }}
					>
						<Grid item xs={2}>
							<TitleComponent
								style={{
									fontSize: '2vh',
									cursor: 'pointer',
									color: 'rgba(175,175,175,1)',
									alignSelf: 'flex-start',
									marginTop: '19px',
									width: '190px',
								}}
							>
								Timeframe
							</TitleComponent>
						</Grid>
						<Grid item>
							<DropList
								onChange={(e: any) => handleViewDataForChange(e)}
								label={'View Data For'}
								value={viewDataFor}
								options={[
									'Today',
									'Yesterday',
									'From Start of the Month',
									'Last 7 Days',
									'Custom',
								]}
								fontSize='13px'
								style={{ width: '190px' }}
							></DropList>
						</Grid>
						<Grid item>
							<StyledTextField
								{...startProps}
								label='Start Date'
								focused
								style={{ width: '190px', marginTop: '19px' }}
								InputLabelProps={{
									style: { top: '-8px', fontSize: '15px' },
								}}
							/>
						</Grid>

						<Grid item>
							<StyledTextField
								{...endProps}
								label='End Date'
								focused
								style={{ width: '190px', marginTop: '19px' }}
								InputLabelProps={{
									style: { top: '-8px', fontSize: '15px' },
								}}
							/>
						</Grid>
					</Grid>
				)}
			/>
		</LocalizationProvider>
	)
}
const FiltersList = (props: {
	filtersObject: p360dashboardPayloadModel
	retrieved: any
	accountManagers: any
	publisherManager: any
	os: any
	orderBy: any
	setAccountManagers: any
	setPublisherManager: any
	setOs: any
	setOrderBy: any
	setAppsflyerAccount: any
	appsflyerAccount: any
}) => {
	const { users, accounts } = useTypedSelector((state) => state)

	useEffect(() => {
		props.filtersObject.filters.accountManager = props.accountManagers
		props.filtersObject.filters.publisherManager = props.publisherManager
		props.filtersObject.filters.orderBy = props.orderBy
		props.filtersObject.filters.os = props.os
		props.filtersObject.filters.appsflyerAccount = props.appsflyerAccount
	}, [
		props.accountManagers,
		props.publisherManager,
		props.os,
		props.orderBy,
		props.appsflyerAccount,
	])
	useEffect(() => {
		if (props.retrieved.filters) {
			props.setAccountManagers(props.retrieved.filters.accountManager)
			props.setPublisherManager(props.retrieved.filters.publisherManager)
			props.setOs(props.retrieved.filters.os)
			props.setOrderBy(props.retrieved.filters.orderBy)
			props.setAppsflyerAccount(props.retrieved.filters.appsflyerAccount)
		}
	}, [props.retrieved])

	return (
		<Grid
			container
			direction={'row'}
			spacing={5}
			style={{ width: '100vw', height: '100px' }}
		>
			<Grid item xs={2}>
				<TitleComponent
					style={{
						fontSize: '2vh',
						cursor: 'pointer',
						color: 'rgba(175,175,175,1)',
						alignSelf: 'flex-start',
						marginTop: '19px',
					}}
				>
					Filters
				</TitleComponent>
			</Grid>
			<Grid item>
				<DropList
					onChange={(e: any) => {
						props.setOs(e)
						props.filtersObject.filters.os = e
					}}
					options={['iOS', 'Android']}
					value={props.os}
					label={'OS'}
					style={{ width: '190px' }}
				/>
			</Grid>
			<Grid item>
				<DropList
					onChange={props.setOrderBy}
					options={['iOS', 'Android']}
					value={props.orderBy}
					label={'Order By'}
					style={{ width: '190px' }}
				/>
			</Grid>
			<Grid item>
				<ChipInputList
					onChange={props.setAccountManagers}
					options={users.users
						.filter(
							(e: any) =>
								e.position &&
								e.position.toLowerCase().includes('account manager') &&
								!e.blocked,
						)
						.map((e: any) => e.name)}
					value={props.accountManagers}
					openWithClick={true}
					style={{ width: '190px', marginTop: '-10px' }}
					label={'Account Manager'}
				/>
			</Grid>
			<Grid item>
				<ChipInputList
					onChange={props.setPublisherManager}
					options={users.users
						.filter(
							(e: any) =>
								e.position &&
								e.position.toLowerCase().includes('publisher manager') &&
								!e.blocked,
						)
						.map((e: any) => e.name)}
					value={props.publisherManager}
					openWithClick={true}
					style={{ width: '190px', marginTop: '-10px' }}
					label={'Publisher Manager'}
				/>
			</Grid>
			<Grid item>
				<ChipInputList
					onChange={props.setAppsflyerAccount}
					options={accounts.accounts.map((e: any) => e.name)}
					value={props.appsflyerAccount}
					openWithClick={true}
					style={{ width: '190px', marginTop: '-10px' }}
					label={'Appsflyer Account'}
				/>
			</Grid>
		</Grid>
	)
}

const BreakdownFieldsComp = (props: {
	filtersObject: p360dashboardPayloadModel
	retrieved: any
	setBreakdownFields: any
	breakdownFields: string[]
}) => {
	const updateCheckedValue = (title: string) => {
		let newBreakdownFields: string[] = []
		if (props.breakdownFields.includes(title)) {
			newBreakdownFields = props.breakdownFields.filter(
				(e: string) => e !== title,
			)
		} else {
			props.breakdownFields.push(title)
			Object.assign(newBreakdownFields, props.breakdownFields)
		}
		props.setBreakdownFields(newBreakdownFields)
	}

	return (
		<Grid
			container
			direction={'row'}
			spacing={5}
			style={{ width: '100vw', height: '100px' }}
		>
			<Grid item xs={2}>
				<TitleComponent
					style={{
						fontSize: '2vh',
						cursor: 'pointer',
						color: 'rgba(175,175,175,1)',
						alignSelf: 'flex-start',
					}}
				>
					Breakdown Fields
				</TitleComponent>
			</Grid>
			<Grid item>
				<StyledCheckboxFormControl
					key={'revenueData'}
					control={
						<Checkbox
							onChange={(e) => {
								const title = 'Total fraudulent attribution'
								updateCheckedValue(title)
							}}
							checked={props.breakdownFields.includes(
								'Total fraudulent attribution',
							)}
							key={'revenueData_checkbox'}
							style={{ marginLeft: '0px', color: '#ee1d4a' }}
						/>
					}
					label={
						<Typography fontSize='13px' fontWeight={500} color={'secondary'}>
							{'Total fraudulent attribution'}
						</Typography>
					}
					style={{ width: '190px' }}
				/>
			</Grid>
			<Grid item>
				<StyledCheckboxFormControl
					key={'revenueData'}
					control={
						<Checkbox
							onChange={(e) => {
								const title = 'Fraudulent attribution %'
								updateCheckedValue(title)
							}}
							checked={
								props.breakdownFields &&
								props.breakdownFields.includes('Fraudulent attribution %')
							}
							key={'revenueData_checkbox'}
							style={{ marginLeft: '0px', color: '#ee1d4a' }}
						/>
					}
					label={
						<Typography fontSize='13px' fontWeight={500} color={'secondary'}>
							{'Fraudulent attribution %'}
						</Typography>
					}
					style={{ width: '190px' }}
				/>
			</Grid>
			<Grid item>
				<StyledCheckboxFormControl
					key={'revenueData'}
					control={
						<Checkbox
							onChange={(e) => {
								const title = 'Fraud trend'
								updateCheckedValue(title)
							}}
							checked={
								props.breakdownFields &&
								props.breakdownFields.includes('Fraud trend')
							}
							key={'revenueData_checkbox'}
							style={{ marginLeft: '0px', color: '#ee1d4a' }}
						/>
					}
					label={
						<Typography fontSize='13px' fontWeight={500} color={'secondary'}>
							{'Fraud trend'}
						</Typography>
					}
					style={{ width: '190px' }}
				/>
			</Grid>
			<Grid item>
				<StyledCheckboxFormControl
					key={'revenueData'}
					control={
						<Checkbox
							onChange={(e) => {
								const title = 'Real-time blocks'
								updateCheckedValue(title)
							}}
							checked={
								props.breakdownFields &&
								props.breakdownFields.includes('Real-time blocks')
							}
							key={'revenueData_checkbox'}
							style={{ marginLeft: '0px', color: '#ee1d4a' }}
						/>
					}
					label={
						<Typography fontSize='13px' fontWeight={500} color={'secondary'}>
							{'Real-time blocks'}
						</Typography>
					}
					style={{ width: '190px' }}
				/>
			</Grid>
			<Grid item>
				<StyledCheckboxFormControl
					key={'revenueData'}
					control={
						<Checkbox
							onChange={(e) => {
								const title = 'Real-time blocks %'
								updateCheckedValue(title)
							}}
							checked={
								props.breakdownFields &&
								props.breakdownFields.includes('Real-time blocks %')
							}
							key={'revenueData_checkbox'}
							style={{ marginLeft: '0px', color: '#ee1d4a' }}
						/>
					}
					label={
						<Typography fontSize='13px' fontWeight={500} color={'secondary'}>
							{'Real-time blocks %'}
						</Typography>
					}
					style={{ width: '190px' }}
				/>
			</Grid>
		</Grid>
	)
}
export default P360Dashboard
export function aggregateForDashboard(
	p360aggregated: P360ReducerInterface,
	cleared: any[],
	dateFrom: Date,
	dateTo: Date,
	accountManagers: string[],
	users: any,
	publisherManager: string[],
	appsflyerAccount: string[],
	allBreakdowns: string[],
) {
	for (let [index, entry] of p360aggregated.P360.entries()) {
		cleared[index] = {}

		// Deleting dates outside of daterange selected
		if (
			dateFrom > new Date(formatDate(new Date(entry.Date))) ||
			dateTo < new Date(formatDate(new Date(entry.Date)))
		) {
			delete cleared[index]
			continue
		}
		// Deleting app ids not connected to selected account managers
		let allowedAccountManagerEmails = []

		if (accountManagers.length > 0) {
			for (let manager of accountManagers) {
				const foundUserInfo = users.users.find((e: any) => e.name === manager)
				allowedAccountManagerEmails.push(foundUserInfo.email)
			}
			if (!allowedAccountManagerEmails.includes(entry.account_manager)) {
				delete cleared[index]
				continue
			}
		}

		// Deleting media sources not connected to selected publisher managers
		let allowedPublisherManagerEmails = []

		if (publisherManager.length > 0) {
			for (let manager of publisherManager) {
				const foundUserInfo = users.users.find((e: any) => e.name === manager)
				allowedPublisherManagerEmails.push(foundUserInfo.email)
			}
			if (!allowedPublisherManagerEmails.includes(entry.publisher_manager)) {
				delete cleared[index]
				continue
			}
		}
		// Deleting accounts different from selection
		if (appsflyerAccount.length > 0) {
			if (!appsflyerAccount.includes(entry.name)) {
				delete cleared[index]
				continue
			}
		}

		// Deleting apps from OS different from selected
		for (let objKey of Object.keys(entry)) {
			if (allBreakdowns.includes(objKey) && entry[objKey] !== undefined) {
				cleared[index][objKey] = entry[objKey]
			}
		}
	}
}
let groupByKey = (array: any, key: any) => {
	return array.reduce((hash: any, obj: any) => {
		if (obj[key] === undefined) return hash
		return Object.assign(hash, {
			[obj[key]]: (hash[obj[key]] || []).concat(obj),
		})
	}, {})
}
let mergeUniques = (finalOutput: any, breakdownsList: any) => {
	let {
		impressions,
		clicks,
		installs,
		level_2_event,
		revenue,
		cost,
		profit,
		role,
		c_pid,
		media_source_pid,
		c_country,
		c_parameter,
		campaign_c,
		...summedObject
	} = finalOutput[0]

	for (let [index, e] of finalOutput.entries()) {
		if (e && summedObject) {
			for (let field of breakdownsList) {
				if (summedObject[field] === undefined) {
					summedObject[field] = e[field]
				}
			}

			for (let key of Object.keys(summedObject)) {
				if (index > 0) {
					if (breakdownsList.includes(key)) {
						summedObject[key] = e[key]
					} else {
						summedObject[key] =
							parseFloat(summedObject[key] || 0) + parseFloat(e[key] || 0)
					}
				} else {
					if (breakdownsList.includes(key)) {
						summedObject[key] = e[key]
					} else {
						summedObject[key] = parseFloat(e[key] || 0)
					}
				}
			}
		}
	}
	summedObject['Fraudulent attribution percent'] =
		summedObject['Fraudulent attribution percent'] / finalOutput.length
	summedObject['Real-time blocks percent'] =
		summedObject['Real-time blocks percent'] / finalOutput.length
	summedObject['Post-attribution fraud percent'] =
		summedObject['Post-attribution fraud percent'] / finalOutput.length
	return summedObject
}
export let reduceRecursive = (
	finalOutput: any,
	breakdownsList: any,
	index: any,
	recursiveResult: any,
) => {
	if (!breakdownsList[index]) {
		const recursiveRow = mergeUniques(finalOutput, breakdownsList)

		recursiveResult.push(recursiveRow)
		return recursiveResult
	}

	let groupedRes = groupByKey(finalOutput, breakdownsList[index])
	let groupedResKeys = Object.keys(groupedRes)
	for (let key of groupedResKeys) {
		reduceRecursive(groupedRes[key], breakdownsList, index + 1, recursiveResult)
	}
}
