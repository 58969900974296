import ClearIcon from '@mui/icons-material/Clear'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { useEffect, useRef, useState } from 'react'
import uploadFolderIcon from '../../assets/upload.png'
import { useActions, useFinanceActions } from '../../hooks/useActions'
import { ReactComponent as UploadIcon } from '../../assets/svg/upload-icon-2.svg'
import './finance.css'
import { useTheme } from '@mui/material'

export const UploadFinanceImage = (props: {
	handleUploadProof: any
	row: any
	mainRow: any
	setProofToView: any
	setOpenImagePreview: any
	setErrorMessage: any
	setLoading: any
	permissions: boolean
	forceRefresh: boolean
	setForceRefresh: any
	type?: string
}) => {
	const theme = useTheme()
	const [file, setFile] = useState(null)
	const { getProofImageFinance, removeProofImageFinance } = useFinanceActions()
	const fileInputRef = useRef(null)

	const handleButtonClick = () => {
		;(fileInputRef.current as any).click()
	}
	const handleImageChange = (event: any) => {
		const selectedFile = event.target.files[0]
		setFile(selectedFile)
	}
	const handleDelete = async () => {
		const userAgrees = window.confirm(
			'This will remove the Proof image from our database. Are you sure?',
		)
		if (userAgrees) {
			const data = {
				row: props.row.row,
				main_row: props.mainRow,
				type: props.type,
			}
			await removeProofImageFinance(
				data,
				props.setErrorMessage,
				props.setLoading,
			)
			props.setForceRefresh(!props.forceRefresh)
		}
	}
	const handleOpen = async () => {
		props.setLoading(true)
		if (props.type === 'publisher' && props.row.row.proof_publisher) {
			// console.log(props.row.row.proof_publisher)
			await getProofImageFinance(
				props.row.row,
				props.type,
				props.setProofToView,
				props.setErrorMessage,
			)
		} else if (props.type === 'demand' && props.row.row.proof) {
			await getProofImageFinance(
				props.row.row,
				props.type,
				props.setProofToView,
				props.setErrorMessage,
			)
		}
		props.setOpenImagePreview(true)
		props.setLoading(false)
	}

	useEffect(() => {
		if (file) {
			props.handleUploadProof(props.row, file)
		}
	}, [file])

	return (props.type === 'demand' && props.row.row.proof) ||
		(props.type === 'publisher' && props.row.row.proof_publisher) ? (
		<div style={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
			<button
				style={{
					display: 'flex',
					alignItems: 'center',
					border: 'none',
					height: '28px',
					width: '28px',
					fontSize: '16px',
					cursor: 'pointer',
					color: theme.colors.text.titles,
				}}
				className={'finance-action-button'}
				onClick={handleOpen}
			>
				<VisibilityOutlinedIcon
					fontSize={'inherit'}
					style={{ color: 'inherit' }}
				/>
			</button>
			{!props.permissions && (
				<button
					style={{
						display: 'flex',
						alignItems: 'center',
						border: 'none',
						height: '28px',
						width: '28px',
						fontSize: '16px',
						cursor: 'pointer',
						color: theme.colors.text.titles,
					}}
					onClick={handleDelete}
					className={'finance-action-button'}
				>
					<ClearIcon fontSize={'inherit'} />
				</button>
			)}
		</div>
	) : (
		<>
			<input
				type='file'
				style={{ display: 'none' }}
				ref={fileInputRef}
				onChange={handleImageChange}
				accept='.png,.pdf'
			></input>
			<button
				style={{
					display: 'flex',
					alignItems: 'center',
					border: 'none',
					height: '28px',
					width: '28px',
					fontSize: '16px',
					cursor: !!props.permissions ? 'not-allowed' : 'pointer',
				}}
				onClick={handleButtonClick}
				disabled={!!props.permissions}
				className={'finance-action-button'}
			>
				<UploadIcon
					style={{ color: theme.colors.text.titles, scale: '0.8' }}
					className={'action-button'}
				/>
			</button>
		</>
	)
}
