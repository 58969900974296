import { ThemeProvider, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
	useActions,
	useLogActions,
	useUserActions,
} from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { loginParams } from '../../state/action-creators/userActions'
import jwt_decode from 'jwt-decode'
import hero from '../../assets/login-hero.png'
import logoGreen from '../../assets/varys-logo-colored.png'
import Login from './Login'
import SignUp from './SignUp'
import tiktok from '../../assets/tiktok.svg'
import facebook from '../../assets/facebook.svg'
import instagram from '../../assets/instagram.svg'
import linkedin from '../../assets/linkedin.svg'
import ResetPassword from './ResetPassword'
import { SubmitButton, UnborderedButton } from '../components/Buttons'
import { InfoField } from '../advertisers/AdvertiserPreviewPage'
import SignUpFinished from './SignUpFinished'
import TermsAndConditions from './TermsAndConditions'
import { SYSTEM_DARK_MODE_QUERY } from '../../config'
import CustomThemeProvider from '../../utils/CustomThemeProvider'

const RepositoriesList = (props: {
	setFirstLoadingDone: any
	maintenance: boolean
}) => {
	const themeLocalStorage = 'theme'
	let initialTheme = false
	const theme = useTheme()
	const [currentView, setCurrentView] = useState('login')
	const [hoveredOption, setHoveredOption] = useState('')
	const [opacity, setOpacity] = useState(1)
	const [isVisible, setIsVisible] = useState(false)
	const [popupOpen, setPopupOpen] = useState(false)
	const [termsPopUp, setTermsPopUp] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const [isEmailSubmitted, setIsEmailSubmitted] = useState(false)
	const [signUpValues, setSignUpValues] = useState<any>({})
	const { insertLog } = useLogActions()
	const { signUpAction, loginAction, resetPasswordAction, getLoggedInUser } =
		useUserActions()
	const navigate = useNavigate()
	const { login } = useTypedSelector((state) => state)
	// useEffect(() => {
	// 	const checkUserOnAppStart = async () => {
	// 		const loggedInUser = await getLoggedInUser()
	// 		if (!!loggedInUser) {
	// 			navigate('/news')
	// 		}
	// 	}
	// 	checkUserOnAppStart()
	// }, [])

	// const localStorageThemeValue = localStorage.getItem(themeLocalStorage)
	// if (localStorageThemeValue && localStorageThemeValue.length > 4) {
	// 	const data = JSON.parse(localStorageThemeValue)
	// 	initialTheme = data.value
	// } else {
	// 	// Use system preference if no localStorage value
	// 	const systemPrefersDark = window.matchMedia(SYSTEM_DARK_MODE_QUERY).matches
	// 	initialTheme = systemPrefersDark
	// }

	// const [darkTheme, setDarkTheme] = useState<boolean>(initialTheme)

	// useEffect(() => {
	// 	const mediaQuery = window.matchMedia(SYSTEM_DARK_MODE_QUERY)

	// 	const handleSystemThemeChange = (e: MediaQueryListEvent) => {
	// 		// If no user preference, update based on system
	// 		if (!localStorage.getItem(themeLocalStorage)) {
	// 			setDarkTheme(e.matches)
	// 		}
	// 	}

	// 	mediaQuery.addEventListener('change', handleSystemThemeChange)
	// 	return () =>
	// 		mediaQuery.removeEventListener('change', handleSystemThemeChange)
	// }, [])

	const tryLogin = async (email: string, password: string) => {
		setErrorMessage('')
		const user: loginParams = {
			email: email,
			password: password,
			maintenance: props.maintenance,
		}
		const loginRes: any = await loginAction(user, setErrorMessage, navigate)
		if (!loginRes) {
			props.setFirstLoadingDone(true)
			navigate('/news')
			// const headers = {
			// 	Authorization: `Token ${login.user.token}`,
			// }
			await insertLog({
				email: user.email,
				change: 'Login',
				object: {},
			})
		}
	}

	const trySignUp = async (newUser: any) => {
		setErrorMessage('')
		const user = {
			email: newUser.email,
			// password: newUser.password,
			name: newUser.name,
			type: newUser.clientType,
			company: newUser.companyName,
			country: newUser.geo[0],
			notes: newUser.notes,
		}
		setSignUpValues(user)
		setPopupOpen(true)
		// console.log('triggered', user)
		// const res = await signUpAction(user, setErrorMessage)
	}
	const signUp = async (newUser: any) => {
		setErrorMessage('')
		const user = signUpValues

		// console.log('triggeredsecond', user)
		const res = await signUpAction(user, setErrorMessage)
		setPopupOpen(false)
		setCurrentView('signupfinished')
	}
	useEffect(() => {
		if (errorMessage) {
			setIsVisible(false)
		}
	}, [errorMessage])

	const tryResetPassword = async (email: string) => {
		setErrorMessage('')
		const res = await resetPasswordAction(email, setErrorMessage)
		if (res === undefined) {
			setIsEmailSubmitted(true)
		} else {
			setIsEmailSubmitted(false)
		}
	}
	// TODO: MATAN -> change this logic to check using the backend and auth middleware
	// useEffect(() => {
	// 	if (login.user.token && !login.user.blocked) {
	// 		const decodedToken = jwt_decode(login.user.token) as any

	// 		const currentTime = Date.now() / 1000

	// 		if (decodedToken.exp > currentTime) {
	// 			navigate('/news')
	// 		}
	// 	}
	// }, [])

	const socialMedia = [
		{
			id: '1',
			title: 'TikTok',
			src: tiktok,
			link: 'https://www.tiktok.com/@thingortwo_agency',
		},
		{
			id: '2',
			title: 'Facebook',
			src: facebook,
			link: 'https://www.tiktok.com/@thingortwo_agency',
		},
		{
			id: '3',
			title: 'Instagram',
			src: instagram,
			link: 'https://www.instagram.com/thing.or.two/',
		},
		{
			id: '4',
			title: 'Linkedin',
			src: linkedin,
			link: 'https://www.linkedin.com/company/thingortwo',
		},
	]

	const loginSignUpDisplayHandler = (view: string) => {
		setOpacity(0)
		setCurrentView(view)
		setTimeout(() => {
			setOpacity(1)
		}, 250)
	}

	const handleTermsPopUp = () => {
		setTermsPopUp(!termsPopUp)
	}

	const renderForm = () => {
		switch (currentView) {
			case 'login':
				return (
					<Login
						errorMsg={errorMessage}
						onLogin={tryLogin}
						setCurrentView={setCurrentView}
					/>
				)
			case 'signup':
				return (
					<SignUp
						setCurrentView={setCurrentView}
						errorMsg={errorMessage}
						onSignUp={trySignUp}
						handleTermsPopUp={handleTermsPopUp}
					/>
				)
			case 'resetPassword':
				return (
					<ResetPassword
						isSubmit={isEmailSubmitted}
						errorMsg={errorMessage}
						onResetPassword={tryResetPassword}
						setCurrentView={setCurrentView}
					/>
				)
			case 'signupfinished':
				return <SignUpFinished setCurrentView={setCurrentView} />
			default:
				return (
					<Login
						errorMsg={errorMessage}
						onLogin={tryLogin}
						setCurrentView={setCurrentView}
					/>
				)
		}
	}

	const handleTextColor = (option: string) => {
		if (currentView === option) {
			return theme.colors.base.grey900
		}
		if (hoveredOption === option) {
			return theme.colors.base.green200
		}
		return theme.colors.text.inactive
	}

	return (
		<>
			{popupOpen && (
				<>
					<div
						style={{
							display: 'flex',
							position: 'absolute',
							height: '100%',
							width: '100%',
							background: 'rgba(0,0,0,0.2)',

							zIndex: 15,
						}}
					></div>
					<div
						style={{
							display: 'flex',
							position: 'absolute',
							height: '25vh',
							width: '20vw',
							background: theme.colors.base.white,
							// border: '1px solid ' + theme.colors.base.grey900,
							// boxShadow: '2px 2px 0px 3px rgba(0,0,0,0.2)',
							zIndex: 16,
							top: '20vh',
							left: '34vw',
							borderRadius: '20px',
							color: theme.colors.base.grey900,
							flexDirection: 'column',
							padding: '40px 30px',
							justifyContent: 'flex-start',
							alignItems: 'center',
						}}
					>
						<UnborderedButton
							style={{
								// border: '1px solid ' + theme.colors.base.grey900,
								borderRadius: '10px',
								position: 'absolute',
								top: 12,
								right: 20,
							}}
							onClick={() => setPopupOpen(false)}
						>
							X
						</UnborderedButton>
						<div
							style={{
								width: '100%',
								height: '100%',
								display: 'flex',
								// alignItems: 'center',
								flexDirection: 'column',
							}}
						>
							<span style={{ marginLeft: 38 }}>
								Please, confirm your details
							</span>
							{signUpValues && signUpValues.name && (
								<ul
									style={{
										display: 'flex',
										flexDirection: 'column',
										listStyle: 'none',
										gap: '10px',
										width: '20vw',
										// border: '1px solid red',
									}}
								>
									<li>
										<InfoField
											label={'Point of Contact'}
											value={signUpValues.name}
										/>
									</li>
									<li>
										<InfoField label={'Email'} value={signUpValues.email} />
									</li>
									<li>
										<InfoField
											label={'Company Name'}
											value={signUpValues.company}
										/>
									</li>
									<li>
										<InfoField
											label={'Head Office Country'}
											value={signUpValues.country}
											isCountry
										/>
									</li>
									<li>
										<InfoField label={'Type'} value={signUpValues.type} />
									</li>
								</ul>
							)}
						</div>
						<SubmitButton
							onClick={signUp}
							style={{
								width: '40%',
								borderRadius: '4px',
								margin: 0,
								// background: theme.colors.base.green200,
								// border: '2px solid ' + theme.colors.base.green200,
								textTransform: 'none',
								fontSize: theme.font.size.body,
								color: theme.colors.text.titles,
							}}
							sx={{
								'&.Mui-disabled': {
									background: theme.colors.base.grey100,
								},
								'&:hover': {
									background: theme.colors.base.green20,
									border: '2px solid ' + theme.colors.base.green200,
									color: theme.colors.base.grey900,
								},
								'&.Mui-focused': {
									background: theme.colors.base.green100,
								},
							}}
						>
							Confirm
						</SubmitButton>
					</div>
				</>
			)}
			{termsPopUp && <TermsAndConditions handleTermsPopUp={handleTermsPopUp} />}
			<div
				style={{
					width: '100%',
					height: '100vh',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					color: theme.colors.base.grey900,
					overflow: 'hidden',
				}}
			>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						position: 'absolute',
						top: '5%',
						left: !(currentView === 'signup') ? '3%' : '88%',
						transition: 'left 0.5s ease-in-out',
					}}
				>
					<div style={{ width: '3.75rem' }}>
						<img src={logoGreen} alt='logo' style={{ width: '100%' }} />
					</div>
					<span style={{ fontSize: '1.75rem' }}>Varys</span>
				</div>
				<div
					style={{
						width: '50%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						flexDirection: 'column',
						position: 'absolute',
						backgroundColor: theme.colors.base.white, // Use theme colors for background
						color: theme.colors.base.black, // Use theme colors for text
						top: 0,
						height: '100vh',
						transform: `translateX(${
							!(currentView === 'signup') ? '-50%' : '50%'
						})`,
						opacity: opacity,
						transition: 'transform 0.5s ease-in-out',
					}}
				>
					<div
						style={{
							width: '50%',
							height: '80%',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							gap: '1.875rem',
							marginTop: 100,
						}}
					>
						{(currentView === 'login' || currentView === 'signup') && (
							<div
								style={{
									marginBlockEnd: '2.813rem',
									position: 'relative',
									display: 'flex',
									width: '20rem',
									alignItems: 'center',
								}}
							>
								<div
									style={{
										position: 'absolute',
										width: '5.313rem',
										height: '0.563rem',
										borderTopLeftRadius: '1rem',
										borderTopRightRadius: '1rem',
										top: '110%',
										left: !(currentView === 'signup') ? '8%' : '65%',
										background: theme.colors.base.green200,
									}}
								></div>
								<button
									onMouseEnter={() => setHoveredOption('login')}
									onMouseLeave={() => setHoveredOption('')}
									onClick={() => loginSignUpDisplayHandler('login')}
									style={{
										padding: '0',
										textAlign: 'center',
										width: '28.125rem',
										height: '2.5rem',
										fontSize: '2rem',
										fontWeight: theme.font.weight.bold,
										marginRight: '3.125rem',
										color: handleTextColor('login'),
										transition: 'color .2s ease-in-out',
										cursor: !(currentView === 'signup') ? '' : 'pointer',
										border: '0',
									}}
								>
									Log In
								</button>
								<button
									onMouseEnter={() => setHoveredOption('signup')}
									onMouseLeave={() => setHoveredOption('')}
									onClick={() => loginSignUpDisplayHandler('signup')}
									style={{
										padding: '0',
										border: '0',
										textAlign: 'center',
										width: '28.125rem',
										height: '2.5rem',
										fontSize: '2rem',
										fontWeight: theme.font.weight.bold,
										color: handleTextColor('signup'),
										transition: 'color .2s ease-in-out',
										cursor: currentView === 'signup' ? '' : 'pointer',
									}}
								>
									Sign Up
								</button>
							</div>
						)}
						{renderForm()}
						{/* {errorMessage && <span>{errorMessage}</span>} */}
						{(currentView === 'login' || currentView === 'signup') && (
							<div
								style={{
									display: 'flex',
									width: '100%',
									height: '100%',

									justifyContent: 'flex-end',
									flexDirection: 'column',
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										gap: '1.25rem',

										alignItems: 'center',
									}}
								>
									<span style={{ textAlign: 'center' }}>Follow us!</span>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											gap: '0.938rem',
										}}
									>
										{socialMedia.map((s: any) => {
											return (
												<div
													onClick={() => window.open(s.link, '_blank')}
													key={s.id}
													style={{
														width: '2.25rem',
														cursor: 'pointer',
													}}
												>
													<img
														src={s.src}
														alt={s.title}
														style={{
															width: '100%',
															objectFit: 'contain',
														}}
													/>
												</div>
											)
										})}
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
				<div
					style={{
						width: '50%',
						height: '100vh',
						backgroundImage: theme.colors.gradientLoginPage,
						color: theme.colors.base.white,
						position: 'absolute',
						top: 0,
						// transition: 'left 0.5s ease-in-out',
						// left: position === 'left' ? '50%' : '0%',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						gap: '2.5rem',
						transform: `translateX(${
							!(currentView === 'signup') ? '50%' : '-50%'
						})`,
						transition: 'transform 0.5s ease-in-out',
					}}
				>
					<span
						style={{
							textAlign: 'center',
							width: '28.125rem',
							height: '6.25rem',
							fontSize: '2.6rem',
							fontWeight: theme.font.weight.bold,
							color: theme.colors.base.white,
						}}
					>
						Unlock Your Data, Unleash Your Potential
					</span>
					<div style={{ width: '24.375rem' }}>
						<img src={hero} alt='hero' style={{ width: '100%' }} />
					</div>
				</div>
			</div>
		</>
	)
}

export default RepositoriesList
