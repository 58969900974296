import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	useTheme,
} from '@mui/material'
import { SubmitButton } from '../../pages/components/Buttons'

export const ErrorPrompt = ({
	PopUpVisible,
	setPopUpVisible,
	errorMessage,
	setErrorMessage,
	handleLogout,
}: {
	PopUpVisible: boolean
	setPopUpVisible: any
	errorMessage?: null | string
	setErrorMessage: any
	handleLogout: any
}) => {
	// if (errorMessage?.toString().includes('401')) {
	// 	handleLogout()
	// }
	const theme = useTheme()
	const handleClientError = () => {
		handleLogout()
	}
	return (
		<Dialog open={PopUpVisible}>
			<div
				style={{
					border: '1px solid ' + theme.colors.base.grey300,
					borderRadius: '15px',
					padding: '20px 0px 20px 20px',
				}}
			>
				<DialogTitle
					style={{
						// background: theme.colors.base.white,
						color: theme.colors.text.titles,
					}}
				>
					ERROR
				</DialogTitle>
				<DialogContent
					style={{
						// background: theme.colors.base.white,
						color: theme.colors.text.titles,
					}}
				>
					<DialogContentText
						style={{
							// background: theme.colors.base.white,
							color: theme.colors.text.titles,
						}}
					>
						{errorMessage?.toString().includes('br>') ? (
							<div
								dangerouslySetInnerHTML={{
									__html: errorMessage || 'Unknown Error',
								}}
							/>
						) : (
							errorMessage?.toString() || 'Unknown Error'
						)}
					</DialogContentText>
				</DialogContent>
				<DialogActions
					style={{
						// background: theme.colors.base.white,
						color: theme.colors.text.titles,
					}}
				>
					<SubmitButton
						style={{ margin: 'auto' }}
						onClick={() => {
							setErrorMessage(null)
							setPopUpVisible(false)
							errorMessage?.toString().includes('CLIENT VERSION')
								? handleClientError()
								: setPopUpVisible(false)
						}}
					>
						Cancel
					</SubmitButton>
				</DialogActions>
			</div>
		</Dialog>
	)
}
