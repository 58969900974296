import React from 'react'
import { Grid } from '@mui/material'
import { InputFieldText } from '../components/Inputs'
import { ChipInputList } from '../components/ChipInput'
import { SwitchComponent } from '../components/ToggleComponents'

interface CreatorCompanyInfoComponentProps {
	name: string
	setName: (name: string) => void
	userName: string
	setUserName: (userName: string) => void
	campaign: string[]
	setCampaign: (campaign: string[]) => void
	type: string
	setType: (type: string) => void
	email: string
	setEmail: (email: string) => void
	gender: string
	setGender: (gender: string) => void
	geo: string[]
	setGeo: (geo: string[]) => void
	portfolio: string
	setPortfolio: (portfolio: string) => void
	address: string
	setAddress: (address: string) => void
	instagram: string
	setInstagram: (instagram: string) => void
	tiktok: string
	setTiktok: (tiktok: string) => void
	youtube: string
	setYoutube: (youtube: string) => void
	linkedin: string
	setLinkedin: (linkedin: string) => void
	fiverr: string
	setFiverr: (fiverr: string) => void
	hasAgent: boolean
	setHasAgent: any
}

const CreatorCompanyInfoComponent: React.FC<
	CreatorCompanyInfoComponentProps
> = ({
	name,
	setName,
	userName,
	setUserName,
	campaign,
	setCampaign,
	type,
	setType,
	email,
	setEmail,
	gender,
	setGender,
	geo,
	setGeo,
	portfolio,
	setPortfolio,
	address,
	setAddress,
	instagram,
	setInstagram,
	tiktok,
	setTiktok,
	youtube,
	setYoutube,
	linkedin,
	setLinkedin,
	fiverr,
	setFiverr,
	hasAgent,
	setHasAgent,
}) => {
	return (
		<Grid container spacing={2}>
			{/* <Grid item xs={12} md={6} lg={4}>
				<SwitchComponent
					label='Agent'
					value={hasAgent}
					onChange={setHasAgent}
					disabled={false}
				/>
			</Grid> */}
			<Grid item xs={12} md={6} lg={4}>
				<InputFieldText
					label='Full Name'
					value={name}
					type='text'
					onChange={(e: string) => setName(e)}
					// error={!stringIsValidName(name)}
					// helperText={!stringIsValidName(name) ? 'Invalid name format' : ''}
					// required
				/>
			</Grid>
			<Grid item xs={12} md={6} lg={4}>
				<InputFieldText
					label='Username'
					value={userName}
					type='text'
					onChange={(e: string) => setUserName(e)}
					// error={!stringIsValidName(userName)}
					// helperText={
					// !stringIsValidName(userName) ? 'Invalid username format' : ''
					// }
					// required
				/>
			</Grid>
			{/* <Grid item xs={6}>
				<ChipInputList
					label='Campaign'
					value={campaign}
					onChange={(value: string[]) => setCampaign(value)}
					options={['Campaign 1', 'Campaign 2', 'Campaign 3']} // Replace with actual campaign options
				/>
			</Grid> */}
			<Grid item xs={12} md={6} lg={4}>
				<ChipInputList
					label='Type'
					value={[type]}
					onChange={(e: string) => setType(e)}
					options={['Influencer', 'Content Creator', 'Other']} // Replace with actual type options
					// required
				/>
			</Grid>
			{/* <Grid item xs={6}>
				<InputFieldText
					label='Email'
					value={email}
					onChange={(e: string) => setEmail(e)}
					type='email'
					// required
				/>
			</Grid> */}
			<Grid item xs={12} md={6} lg={4}>
				<ChipInputList
					label='Gender'
					value={[gender]}
					onChange={(e: string) => setGender(e)}
					options={['Male', 'Female', 'Other']}
					// required
				/>
			</Grid>
			<Grid item xs={12} md={6} lg={4}>
				<ChipInputList
					label='Country'
					value={geo}
					onChange={(value: string[]) => setGeo(value)}
					options={['USA', 'UK', 'Canada', 'Australia']}
				/>
			</Grid>
			<Grid item xs={12} md={6} lg={4}>
				<InputFieldText
					label='Portfolio'
					value={portfolio}
					onChange={(e: string) => setPortfolio(e)}
					type='text'
				/>
			</Grid>
			<Grid item xs={12} md={6} lg={4}>
				<InputFieldText
					label='Address'
					value={address}
					onChange={(e: string) => setAddress(e)}
					multiline
					type='text'
				/>
			</Grid>
			<Grid item xs={12} md={6} lg={4}>
				<InputFieldText
					label='Instagram'
					value={instagram}
					onChange={(e: string) => setInstagram(e)}
					type='text'
				/>
			</Grid>
			<Grid item xs={12} md={6} lg={4}>
				<InputFieldText
					label='TikTok'
					value={tiktok}
					onChange={(e: string) => setTiktok(e)}
					type='text'
				/>
			</Grid>
			<Grid item xs={12} md={6} lg={4}>
				<InputFieldText
					label='YouTube'
					value={youtube}
					onChange={(e: string) => setYoutube(e)}
					type='text'
				/>
			</Grid>
			<Grid item xs={12} md={6} lg={4}>
				<InputFieldText
					label='LinkedIn'
					value={linkedin}
					onChange={(e: string) => setLinkedin(e)}
					type='text'
				/>
			</Grid>
			<Grid item xs={12} md={6} lg={4}>
				<InputFieldText
					label='Fiverr'
					value={fiverr}
					onChange={(e: string) => setFiverr(e)}
					type='text'
				/>
			</Grid>
		</Grid>
	)
}

export default CreatorCompanyInfoComponent
