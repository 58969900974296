import type {} from '@mui/lab/themeAugmentation'
import {
	createTheme,
	responsiveFontSizes,
	Theme2024,
	ThemeOptions2024,
} from '@mui/material'
import {
	biggerRadius,
	colors,
	customComponents,
	darkBaseColors,
	darkColors,
	darkCustomComponents,
	font,
	smallerRadius,
} from './themeValues'

const getThemeColors = (isDarkTheme: boolean) => {
	if (isDarkTheme) {
		return {
			colors: darkColors,
			customComponents: darkCustomComponents,
		}
	} else {
		return { colors, customComponents }
	}
}

export const createCustomTheme = (isDarkTheme: boolean): Theme2024 => {
	const themeColors = getThemeColors(isDarkTheme)

	return responsiveFontSizes(
		createTheme({
			palette: {
				mode: isDarkTheme ? 'dark' : 'light',
			},
			colors: themeColors.colors,
			font: { ...font },
			customComponents: { ...themeColors.customComponents },
			shadow: {
				boxShadow: '-8px 8px 17px 0px rgb(0 0 0 / 21%)',
				lightShadow: '-8px 8px 17px 0px rgb(0 0 0 / 10%)',
			},
			dimentions: {
				drawerWidth: 40,
				smallerRadius: smallerRadius,
				biggerRadius: biggerRadius,
			},
			components: {
				MuiCheckbox: {
					styleOverrides: {
						colorPrimary: {
							color: 'inherit',
						},
						colorSecondary: {
							color: 'inherit',
						},

						root: {
							'&.Mui-checked': {
								color: 'inherit',
								backgroundColor: 'inherit',
							},
							'&.Mui-checked:hover': {
								color: 'inherit',
								backgroundColor: 'inherit',
							},
							'&.Mui-checked:active': {
								color: 'inherit',
								backgroundColor: 'inherit',
							},
							'&.Mui-checked:focus': {
								color: 'inherit',
								backgroundColor: 'inherit',
							},
						},
						// disabled: { color: 'gray', backgroundColor: 'gray' },
					},
				},
				MuiTablePagination: {
					styleOverrides: {
						root: {
							display: 'block',
							color: themeColors.colors.text.titles,
							'& .MuiTablePagination-selectLabel': {
								display: 'block !important',
							},
							'& .MuiTablePagination-input': {
								display: 'inline-flex !important',
							},
							'& .MuiTablePagination-input svg': {
								color: themeColors.colors.text.titles,
							},
						},
					},
				},
				MuiOutlinedInput: {
					styleOverrides: {
						root: {
							borderRadius: smallerRadius,
							padding: '0px',
							'& .MuiOutlinedInput-notchedOutline': {
								border: 'none',
								maxHeight: '14px',
							},
							'& .MuiOutlinedInput-input': {
								padding: '0px',
							},
						},
					},
				},

				MuiFormLabel: {
					styleOverrides: {
						asterisk: {
							color: '#ee1d4a',
						},
						root: {
							// color: '#808080',
							// fontWeight: 'bolder',
							// top: '25%',
							'&.Mui-focused': {
								color: themeColors.colors.base.grey600,
								// fontWeight: 'bolder',
								// top: '0%',
							},
						},
					},
				},
				MuiSwitch: {
					styleOverrides: {
						root: {
							width: 46,
							height: 26,
							padding: 0,
							'& .MuiSwitch-switchBase': {
								padding: 0,
								margin: 2,
								transitionDuration: '200ms',
								'&.Mui-checked': {
									transform: 'translateX(20px)',

									'& + .MuiSwitch-track': {
										opacity: 1,
										border: 0,
									},
									'&.Mui-disabled + .MuiSwitch-track': {
										opacity: 0.5,
									},
								},
								'&.Mui-focusVisible .MuiSwitch-thumb': {
									color: themeColors.colors.base.green200,
									border: '6px solid #fff',
								},
								'&.Mui-disabled .MuiSwitch-thumb': {
									color: themeColors.colors.base.grey200,
								},
								'&.Mui-disabled + .MuiSwitch-track': {
									opacity: 0.7,
								},
							},
							'& .MuiSwitch-thumb': {
								boxSizing: 'border-box',
								width: 22,
								height: 22,
								marginLeft: '2px',
								backgroundColor: themeColors.colors.switch.white,
							},
							'& .MuiSwitch-track': {
								borderRadius: 13,
								backgroundColor: themeColors.colors.base.grey600,
								opacity: 1,
							},
						},
						switchBase: {
							'&.Mui-checked': {
								color: '#d0d0d0',
							},
							'&.Mui-checked+.MuiSwitch-track': {
								background: themeColors.colors.base.green200,
								opacity: '1',
							},
						},
					},
				},

				// MuiGrid: {
				// 	styleOverrides: {
				// 		item: {
				// 			padding: '0px',
				// 		},
				// 		root: {
				// 			'&.MuiGrid-item': {
				// 				paddingTop: '0px',
				// 				paddingLeft: '0px',
				// 			},
				// 			'&&': {
				// 				paddingLeft: '0px',
				// 			},
				// 		},
				// 	},
				// },
				// MuiFormControl: {
				// 	styleOverrides: {
				// 		root: {
				// 			margin: '0px',
				// 			padding: '0px',
				// 		},
				// 	},
				// },
				// MuiFormControlLabel: {
				// 	styleOverrides: {
				// 		root: {
				// 			color: '#ffffff',
				// 			'&.MuiDisabled': {
				// 				color: '#aaaaaa',
				// 			},
				// 		},
				// 	},
				// },
				muiList: {
					styleOverrides: {
						root: {
							backgroundColor: themeColors.colors.base.white,
							color: themeColors.colors.text.titles,
						},
					},
				},
				MuiListItem: {
					styleOverrides: {
						root: {
							backgroundColor: themeColors.colors.base.white,
							color: themeColors.colors.text.titles,
							fontWeight: 600,
						},
					},
				},
				MuiPopover: {
					styleOverrides: {
						paper: {
							backgroundColor: themeColors.colors.base.white + ' !important',
						},
					},
				},
				MuiMenu: {
					styleOverrides: {
						paper: {
							backgroundColor: themeColors.colors.base.white + ' !important',
							color: themeColors.colors.text.titles + ' !important',
							border: '1px solid ' + themeColors.colors.text.titles,
						},
					},
				},

				MuiButton: {
					styleOverrides: {
						startIcon: {
							color: '#ee1d4a',
						},
						endIcon: {
							color: '#ee1d4a',
						},
						root: {
							'&:hover': {
								background: 'transparent',
								color: themeColors.colors.base.green300,
								// border: '0px !important'
								borderColor: 'transparent',
							},
						},
					},
				},
				MuiIconButton: {
					styleOverrides: {
						root: {
							'&:hover': {
								background: 'inherit',
								// color: colors.colors.base.green300,
								// border: '0px !important'
								borderColor: 'transparent',
							},
						},
					},
				},
				MuiTouchRipple: {
					styleOverrides: {
						root: {
							'&:active': {
								background: 'inherit',
								// color: colors.colors.base.green300,
								// border: '0px !important'
								backgroundColor: 'inherit',
							},
							'&:focus': {
								background: 'inherit',
								// color: colors.colors.base.green300,
								// border: '0px !important'
								backgroundColor: 'inherit',
							},
						},
					},
				},
				MuiPaper: {
					styleOverrides: {
						root: {
							borderRadius: smallerRadius,
							boxShadow: 'none',
						},
					},
				},

				MuiMenuItem: {
					styleOverrides: {
						root: {
							backgroundColor: themeColors.colors.base.white,
							'&:hover': {
								background: 'transparent',
								color: themeColors.colors.base.green300,
								borderRadius: smallerRadius,
							},
							'&.Mui-selected': {
								color: themeColors.colors.base.green300 + ' !important',
								background: 'transparent',
							},
						},
					},
				},
				MuiListItemButton: {
					styleOverrides: {
						root: {
							stroke: 'black',
							'&:hover': {
								background: 'transparent',
								color: themeColors.colors.base.green300,
								stroke: 'white',
							},
						},
					},
				},
				MuiLinearProgress: {
					styleOverrides: {
						bar: {
							// color: '#ee1d4a!important',
							// background: 'rgba(238,29,66,1)',
						},

						barColorPrimary: {
							color: themeColors.colors.base.green200 + '!important',
							background: `linear-gradient(90deg, ${themeColors.colors.base.green200} 0%, ${themeColors.colors.base.green400} 100%)`,
						},
						barColorSecondary: {
							color: themeColors.colors.base.green400 + '!important',
							background: `linear-gradient(90deg, ${themeColors.colors.base.green200} 0%, ${themeColors.colors.base.green400} 100%)`,
						},
					},
				},
				MuiPickersDay: {
					styleOverrides: {
						root: {
							'&:hover': {
								backgroundColor: themeColors.colors.base.grey100,
								color: themeColors.colors.text.titles,
							},
							'&[aria-selected=true]': {
								backgroundColor: themeColors.colors.base.green200,
							},
							'&[aria-selected=true]:hover': {
								backgroundColor: themeColors.colors.base.green200,
							},
							'&[aria-selected=true]:focus': {
								backgroundColor: themeColors.colors.base.green200,
							},
						},
					},
				},
				MuiDateRangePickerInput: {
					styleOverrides: {
						root: {
							width: '100%',
							'& .MuiFormControl-root.MuiTextField-root': {
								width: '100%',
							},
						},
					},
				},
				MuiDataGrid: {
					styleOverrides: {
						root: {
							'& .MuiDataGrid-columnHeaderTitleContainerContent': {
								color: themeColors.colors.base.grey900,
							},
							'& .MuiDataGrid-virtualScrollerRenderZone': {
								color: themeColors.colors.base.grey900,
							},
							'& .MuiDataGrid-row.Mui-selected': {
								backgroundColor: themeColors.colors.base.grey50,
							},
							'& .MuiDataGrid-row:hover': {
								backgroundColor: themeColors.colors.base.grey50,
							},
							'& .MuiDataGrid-row.Mui-selected:hover': {
								backgroundColor: themeColors.colors.base.grey50,
							},
							'& .MuiDataGrid-row.Mui-selected:focus-visible': {
								backgroundColor: themeColors.colors.base.grey50,
							},
							'& .MuiDataGrid-columnHeader:focus-within': {
								outline: 'none',
							},
							'& .MuiDataGrid-cell:focus-within': {
								outline: 'none',
							},
							'& .MuiDataGrid-iconButtonContainer .MuiBadge-badge': {
								fontSize: '0px', // Hides the number in the badge
							},
							'& .MuiDataGrid-iconButtonContainer svg': {
								color: themeColors.colors.text.titles, // Hides the number in the badge
							},
							'& [data-field="__tree_data_group__"].MuiDataGrid-cell--withRenderer svg':
								{
									color: themeColors.colors.base.green400 + ' !important',
								},
							'& [data-field="__tree_data_group__"].MuiDataGrid-cell--withRenderer span':
								{
									fontWeight: font.weight.normal + '!important',
								},
						},
					},
				},
				MuiChip: {
					styleOverrides: {
						deleteIcon: {
							color: themeColors.colors.text.titles,
						},
					},
				},
				MuiAutocomplete: {
					styleOverrides: {
						input: {
							fontSize: '14px',
							fontWeight: 500,
						},
						noOptions: {
							color: themeColors.colors.text.titles,
						},
						option: {
							background: themeColors.colors.base.white + ' !important',
							backgroundColor: 'none !important',
							fontSize: font.size.body,
							'&.Mui-focused': {
								background: themeColors.colors.base.white,
								backgroundColor: themeColors.colors.base.white,
								color: themeColors.colors.base.green300,
							},
							'&.Mui-focusVisible': {
								background: themeColors.colors.base.white,
								backgroundColor: themeColors.colors.base.white,
								color: themeColors.colors.base.green300,
							},
							'&[aria-selected=true]': {
								background: themeColors.colors.base.white + ' !important',
								backgroundColor: themeColors.colors.base.white + ' !important',
								// color: themeColors.colors.base.green300,
							},
							'&[aria-selected=true] li': {
								background: themeColors.colors.base.white + ' !important',
								backgroundColor: themeColors.colors.base.white + ' !important',
								color: themeColors.colors.base.green300,
							},
							'&[aria-selected=true].Mui-focused': {
								background: themeColors.colors.base.white,
								backgroundColor: themeColors.colors.base.white,
								color: themeColors.colors.base.green300,
							},
							'&[aria-selected=true] .MuiMenuItem-root': {
								background: themeColors.colors.base.white + ' !important',
								backgroundColor: themeColors.colors.base.white + ' !important',
								fontWeight: font.weight.normal + ' !important',
							},
						},

						paper: {
							background: themeColors.colors.base.white + ' !important',
							width: 'fit-content!important',
						},
						root: {
							padding: '0px',
							'& .MuiOutlinedInput-root': {
								padding: '0px 65px 0px 8px',
								minHeight: '40px',
								fontSize: '14px',
							},
						},
					},
				},
				MuiTextField: {
					styleOverrides: {
						root: {
							// background: themeColors.colors.base.white,
							color: themeColors.colors.text.titles,
							'& .MuiOutlinedInput-notchedOutline legend': { display: 'none' },
							'& .MuiOutlinedInput-notchedOutline': {
								top: '0px',
							},
						},
					},
				},
				MuiCalendarPicker: {
					styleOverrides: {
						root: {
							background: themeColors.colors.base.white,
							border: '2px solid ' + themeColors.colors.base.grey600,
							color: themeColors.colors.text.titles,
							width: 'auto',
							margin: 0,
						},
						'& .MuiDayPicker-header': {
							color: themeColors.colors.text.titles + ' !important',
						},
					},
				},
				MuiCalendarOrClockPicker: {
					styleOverrides: {
						root: {
							width: 'auto !important',
						},
					},
				},
				MuiDayPicker: {
					styleOverrides: {
						weekDayLabel: {
							color: themeColors.colors.text.titles,
						},
					},
				},
				MuiMonthPicker: {
					styleOverrides: {
						root: {
							'& .MuiTypography-root.PrivatePickersMonth-root:disabled': {
								color: themeColors.colors.base.grey300,
							},
							'& .MuiTypography-root.PrivatePickersMonth-root.Mui-selected': {
								backgroundColor: themeColors.colors.base.green200,
							},
							color: themeColors.colors.base.green200,
						},
					},
				},
				MuiYearPicker: {
					styleOverrides: {
						root: {
							'& .PrivatePickersYear-yearButton.Mui-selected': {
								backgroundColor: themeColors.colors.base.green200,
							},
							'& .PrivatePickersYear-yearButton.Mui-selected:focus': {
								backgroundColor: themeColors.colors.base.green200,
							},
							color: themeColors.colors.base.green200,
						},
					},
				},

				// MuiListItemText: {
				// 	styleOverrides: {
				// 		root: {
				// 			color: 'inherit',
				// 		},
				// 	},
				// },
				MuiSvgIcon: {
					styleOverrides: {
						root: {
							color: isDarkTheme
								? darkBaseColors.arrows.white
								: darkBaseColors.arrows.black, // White in dark mode, black in light mode
						},
						colorDisabled: {
							color: themeColors.colors.base.grey300,
						},
						colorPrimary: {
							color: themeColors.colors.text.titles,
						},
						colorSecondary: {
							color: 'white',
						},
					},
				},
				MuiSelect: {
					styleOverrides: {
						icon: { color: '#000000' },
						nativeInput: {
							padding: '0px',
						},
					},
				},
				MuiDialog: {
					styleOverrides: {
						paper: {
							borderRadius: biggerRadius,
							width: '500px',
							overflowY: 'inherit',
						},
					},
				},
			},
		} as ThemeOptions2024),
	)
}
