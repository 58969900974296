import { CSSProperties } from 'styled-components'

export const editPageTopPanelStyle: CSSProperties = {
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	alignItems: 'center',
	marginTop: '3vh',
	paddingLeft: '6%',
	paddingRight: '6%',
}
