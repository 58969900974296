import { CampaignHistoryInterface } from '../../models/model.interface'
import { ActionType } from '../action-types/index'
import { Action } from '../actions'

export interface campaignHistoryReducerInterface {
	campaign_history: CampaignHistoryInterface | any
}

const initialState: campaignHistoryReducerInterface = {
	campaign_history: undefined,
}

const reducer = (
	state: campaignHistoryReducerInterface | any = initialState,
	action: Action,
): campaignHistoryReducerInterface => {
	switch (action.type) {
		case ActionType.GET_CAMPAIGN_HISTORY:
			return {
				...state,
				campaign_history: action.payload,
			}
		default:
			return state
	}
}

export default reducer
