import {
	DialogActions,
	DialogContent,
	DialogContentText,
	useTheme,
} from '@mui/material'
import { sha256 } from 'js-sha256'
import React, { useEffect, useState } from 'react'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { GetPasswordsInterface } from '../../models/model.interface'
import { StyledDialog, StyledNameField } from '../components/reusableComponents'
import { InputFieldText } from '../components/Inputs'
import { SubmitButton, UnborderedButton } from '../components/Buttons'

const PasswordsPopUp = (props: {
	PopUpVisible: boolean
	setPopUpVisible: any
	row?: GetPasswordsInterface
	setLoading: any
	errorMessage: null | string
	setErrorMessage: any
	isErrorPromptOpened: boolean
	setIsErrorPromptOpened: any
	advertisers: any
	setIsSuccessPromptOpened: any
	isSuccessPromptOpened: any
	codeHash: any
	setBlockPasswords: any
	sessionKey: string
	search: string
	setShowPassword?: any
}): JSX.Element => {
	const theme = useTheme()
	const { login } = useTypedSelector((state) => state)

	const [code, setCode] = useState('')
	const [isValid, setIsValid] = useState(false)

	useEffect(() => {
		if (sha256(code) === props.codeHash) {
			setIsValid(true)
		}
	}, [props.codeHash, code])

	return (
		<StyledDialog open={props.PopUpVisible}>
			<DialogContent
				style={{
					background: theme.colors.base.white,
					color: theme.colors.text.titles,
				}}
			>
				<DialogContentText
					style={{
						background: theme.colors.base.white,
						color: theme.colors.text.titles,
					}}
				>
					The Code is Sent to Your Email
				</DialogContentText>

				<InputFieldText
					label='Code'
					hint='Code is sent to your email'
					type='number'
					value={code}
					onChange={setCode}
				/>
			</DialogContent>
			<DialogActions
				style={{
					background: theme.colors.base.white,
					color: theme.colors.text.titles,
				}}
			>
				<UnborderedButton
					style={{ margin: 'auto' }}
					onClick={() => {
						props.setPopUpVisible(false)
					}}
					label={'Cancel'}
				></UnborderedButton>
				<SubmitButton
					disabled={!isValid}
					style={{ margin: 'auto' }}
					onClick={() => {
						const filters = {
							blockPasswords: false,
							search: props.search,
						}
						const serializedFilters = JSON.stringify(filters)
						sessionStorage.setItem(props.sessionKey, serializedFilters)
						props.setBlockPasswords(false)
						props.setPopUpVisible(false)
						if (props.setShowPassword) {
							props.setShowPassword(true)
						}
					}}
				>
					{props.row ? 'Update' : 'Save'}
				</SubmitButton>
			</DialogActions>
		</StyledDialog>
	)
}
export default PasswordsPopUp
