import { Grid, Paper, TextField, Typography } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { DesktopDatePicker } from '@mui/x-date-pickers'

export const ReportItem = styled(Paper)(() => {
	const theme = useTheme()
	return {
		backgroundColor: theme.colors.base.white,
		border: 'none',
		boxShadow: 'none',
		textAlign: 'left',
	}
})

export const StyledBox = styled(Paper)(() => {
	const theme = useTheme()
	return {
		overflow: 'auto',
		backgroundColor: theme.colors.base.white,
		border: '10px',
		borderRadius: theme.dimentions.smallerRadius,
		textAlign: 'left',
		padding: '10px',
	}
})

export const ReportGrid = styled(Grid)(({ theme }) => ({
	textAlign: 'left',
	minHeight: '100px',
	padding: '20px',
}))

export const DateComponent = styled(DesktopDatePicker)(({ theme }) => ({
	minHeight: '100px',
	width: '80%',
	marginRight: '0px',
}))

export const ReportItemHeading = styled(Typography)(() => {
	const theme = useTheme()
	return {
		fontWeight: 'bold',
		color: theme.colors.base.black,
		marginBottom: '1rem',
	}
})

export const StyledTextField = styled(TextField)(({ theme }) => ({
	backgroundColor: 'rgba(238, 238, 238, 0.6)',
	height: '40px',
	fontWeight: 'bold',
	borderRadius: theme.dimentions.smallerRadius,
	marginTop: '40px',
	'& .MuiOutlinedInput-input': {
		backgroundColor: 'transparent',
		borderRadius: theme.dimentions.smallerRadius,
		height: '40px',
		fontSize: '13px',
		fontWeight: 'bold',
		paddingTop: '1px',
		paddingBottom: '1px',
		paddingRight: '6px',
	},
}))
