import React from 'react'
import { AppFilter } from './AppFilter'
import { useTheme } from '@mui/material'

interface Props {
	previewApps: any
}

export const AppFilterContainer: React.FC<Props> = ({ previewApps }) => {
	const theme = useTheme()
	const previewAppsHandler = (filteredApps: any) => {
		previewApps(filteredApps)
	}
	return (
		<div
			style={{
				height: '450px',
				width: '100%',
			}}
		>
			<div
				style={{
					background: theme.colors.base.white,
					width: '100%',
					height: '100%',
					borderRadius: '100px',
					// boxShadow: '0px 2px 1px 2px #c5c5c5',
					display: 'flex',
					alignItems: 'center',
					flexDirection: 'column',
				}}
			>
				<div
					style={{
						paddingBlock: '50px',
						width: '100%',
						marginInlineStart: '115px',
						display: 'flex',
						alignItems: 'start',
						flexDirection: 'column',
						gap: '45px',
						marginBlockStart: '-20px',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							gap: '3px',
						}}
					>
						<p
							style={{
								color: theme.colors.text.titles,
								opacity: '0.4',
								margin: '0',
								marginInlineStart: '3px',
							}}
						>
							Choose the filter you need and find the ideal apps for your sales
							plan
						</p>
						<hr
							style={{
								position: 'relative',
								width: '600px',
								color: '#EDEDED',
								opacity: '0.4',
							}}
						></hr>
					</div>

					<div style={{ width: '100%', marginBlockStart: '-20px' }}>
						<AppFilter previewApps={previewAppsHandler} />
					</div>
				</div>
			</div>
		</div>
	)
}
