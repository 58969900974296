import { applyMiddleware, createStore, compose } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistStore } from 'redux-persist'
import thunk from 'redux-thunk'
import reducers from './reducers'

const composeSystem: any =
	(process.env.REACT_APP_ENVIORMENT as string) === 'dev' ? composeWithDevTools : compose

export const store: any = createStore(
	reducers,
	composeSystem(applyMiddleware(thunk)),
)

export const persistor = persistStore(store)

// eslint-disable-next-line import/no-anonymous-default-export
export default { store, persistor }
