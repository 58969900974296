import AdapterDateFns from '@date-io/date-fns'
// import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import DownloadIcon from '@mui/icons-material/Download'
import { Grid, MenuItem, ThemeProvider, useTheme } from '@mui/material'

import { DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro'
import '@tremor/react/dist/esm/tremor.css'
import { subDays } from 'date-fns/esm'
import { saveAs } from 'file-saver'
import {
	HTMLInputTypeAttribute,
	ReactNode,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { useActions, useReportActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { getPresetsAction } from '../../state/action-creators'
import {
	capitalizeFirstLetter,
	formatDate,
} from '../../utils/helpers/tableHelper'
import { FiltersComponentList } from './FiltersComponent'
import { PresetsPopup } from './PresetsComponent'
import {
	ReportGrid,
	ReportItem,
	ReportItemHeading,
	StyledBox,
	StyledTextField,
} from './ReportsStyled'
import { AdjustReportsTable } from './ReportsTable'
import TransferList from './TransferListComponent'
import { DropList } from '../components/SelectableInputs'
import { SimpleActionsButton, SubmitButton } from '../components/Buttons'

export interface ReportFieldsInterface {
	from_date: Date
	to_date: Date
	breakdowns: string[]
	statistics: string[]
	filters: FilterInterface
}
export interface FilterInterface {
	app_id: string[]
	advertiser_name: string[]
	media_source_pid: string[]
	publisher: string[]
	email: string[]
	campaign_name: string[]
	campaign_id: string[]
	country: string[]
}
export const DateInputField = (props: {
	label?: string | ReactNode
	type?: string | HTMLInputTypeAttribute
	value?: unknown
	onChange?: any
	required?: boolean
	disabled?: boolean
}) => {
	return (
		<StyledTextField
			focused
			key='InputName'
			required={props.required}
			label={props.label}
			type={props.type}
			value={props.value}
			margin='dense'
			disabled={props.disabled}
			onChange={(e: any) => props.onChange(e.target.value)}
			variant='outlined'
			InputLabelProps={{
				style: { top: '-20%' },
			}}
		/>
	)
}

const TimeframeComponent = (props: {
	viewDataFor: string
	setViewDataFor: any
	startDay: Date
	setStartDay: any
	endDay: Date
	setEndDay: any
}) => {
	const [value, setValue] = useState<any>([props.startDay, props.endDay])

	useEffect(() => {
		props.setStartDay(value[0])
		props.setEndDay(value[1])
	}, [value])

	const handleViewDataForChange = (e: any) => {
		const date = new Date()
		props.setViewDataFor(e)
		switch (e) {
			case 'Today':
				props.setStartDay(date)
				props.setEndDay(date)
				setValue([date, date])
				break
			case 'Yesterday':
				props.setStartDay(subDays(new Date(date), 1))
				props.setEndDay(subDays(new Date(date), 1))
				setValue([subDays(new Date(date), 1), subDays(new Date(date), 1)])
				break
			case 'Last 7 Days':
				props.setStartDay(subDays(new Date(date), 7))
				props.setEndDay(date)
				setValue([subDays(new Date(date), 7), date])
				break
			case 'From Start of the Month':
				props.setStartDay(new Date(date.getFullYear(), date.getMonth(), 1))
				props.setEndDay(new Date())
				setValue([new Date(date.getFullYear(), date.getMonth(), 1), new Date()])
				break
			default:
				break
		}
	}
	return (
		<ReportItem style={{ height: '350px' }}>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<ReportItemHeading>Timeframe</ReportItemHeading>
				<StyledBox style={{ overflowX: 'hidden', height: '600px' }}>
					<DropList
						onChange={(e: any) => handleViewDataForChange(e)}
						label={'View Data For'}
						value={props.viewDataFor}
						options={[
							'Today',
							'Yesterday',
							'From Start of the Month',
							'Last 7 Days',
							'Custom',
						]}
						fontSize='13px'
					></DropList>
					{/* <DateComponent
						label='Start Date'
						onChange={(e: any) => {
							props.setStartDay(e)
							props.setViewDataFor('Custom')
						}}
						value={props.startDay}
						inputFormat='dd/MM/yyyy'
						OpenPickerButtonProps={{ style: { paddingRight: '20px' } }}
						renderInput={(params) => (
							<StyledTextField
								{...params}
								focused

								InputLabelProps={{
									style: { top: '-20%' },
								}}
							/>
						)}
					/> */}
					<DateRangePicker
						value={value}
						inputFormat='dd/MM/yyyy'
						onChange={(newValue) => {
							setValue(newValue)
							props.setViewDataFor('Custom')
						}}
						className='datePicker'
						renderInput={(startProps, endProps) => (
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									width: '100%',
									justifyContent: 'space-around',
									marginTop: '26px',
								}}
							>
								<StyledTextField
									{...startProps}
									label='Start Date'
									focused
									style={{ width: '120px' }}
									InputLabelProps={{
										style: { top: '-8px', fontSize: '15px' },
									}}
								/>
								<StyledTextField
									{...endProps}
									label='End Date'
									focused
									style={{ width: '120px' }}
									InputLabelProps={{
										style: { top: '-8px', fontSize: '15px' },
									}}
								/>
							</div>
						)}
					/>
					{/* <DateComponent
						label='End Date'
						onChange={(e: any) => {
							props.setEndDay(e)
							props.setViewDataFor('Custom')
						}}
						value={props.endDay}
						inputFormat='dd/MM/yyyy'
						OpenPickerButtonProps={{ style: { paddingRight: '20px' } }}
						renderInput={(params) => (
							<StyledTextField
								{...params}
								focused

								InputLabelProps={{
									style: { top: '-20%' },
								}}
							/>
						)}
					/> */}
				</StyledBox>
			</LocalizationProvider>
		</ReportItem>
	)
}

const BreakdownComponent = (props: {
	breakdownFields: string[]
	setBreakdownFields: any
	statisticsFields: string[]
	setStatisticsFields: any
	dataObj: ReportFieldsInterface
}) => {
	return (
		<ReportItem
			style={{
				display: 'flex',
				justifyContent: 'center',
				flexDirection: 'row',
			}}
		>
			<div>
				<ReportItemHeading paddingLeft={'0%'}>
					Breakdown Fields
				</ReportItemHeading>
				<TransferList
					listName={'Breakdown'}
					dataObj={props.dataObj}
					key={'breakdownlistkey'}
					fieldsArray={props.breakdownFields}
					setFieldsArray={props.setBreakdownFields}
					options={[
						'Date',
						'Month',
						'Day',
						'Day of the Week',
						'Country',
						'App Token',
						'OS',

						'App Name',
						'Partner',
					]}
					style={{ paddingLeft: '0%', width: '300px' }}
				/>
				<div style={{ margin: '14px' }}></div>
			</div>
			<div>
				<ReportItemHeading paddingLeft={'0%'}>
					{' '}
					Statistic Fields
				</ReportItemHeading>
				<TransferList
					listName={'Statistic'}
					dataObj={props.dataObj}
					key={'statisticlistkey'}
					fieldsArray={props.statisticsFields}
					setFieldsArray={props.setStatisticsFields}
					disabled={props.breakdownFields && props.breakdownFields.length === 0}
					options={['Impressions', 'Clicks', 'Installs']}
					style={{ paddingLeft: '0%', width: '300px' }}
				/>
			</div>
		</ReportItem>
	)
}

const FiltersComponent = (props: {
	setAppIds: any
	setAdvertisers: any
	setUser: any
	setPid: any
	setPublishers: any
	setCampaignNames: any
	setCampaignIds: any
	setCountries: any
	presetVisible: boolean
	dataObj: ReportFieldsInterface
	trySubmit: any
	user: any
	tryDownload: any
	isReportOpen: any
	setDataObj: any
	setPresetVisible: any
	setIsSaving: any
	tryGetRealtime: any
	setIsWarningPromptOpened: any
	lastUpdatedAt: string | undefined
}) => {
	const { login } = useTypedSelector((state) => state)
	// const headers = useMemo(() => {
	// 	return {
	// 		Authorization: `Token ${login.user.token}`,
	// 	}
	// }, [login.user.token])
	// const dateArr = props.lastUpdatedAt?.split('T')
	// let timeString = ''
	// if (dateArr !== undefined && dateArr[1] !== undefined) {
	// 	const timeStringArr = dateArr[1].split(':')
	// 	timeString = timeStringArr[0] + ':' + timeStringArr[1]
	const presetOptions = (
		<div>
			<MenuItem
				onClick={() => {
					props.setIsSaving(true)
					props.setPresetVisible(true)
				}}
			>
				Save a preset
			</MenuItem>
			<MenuItem
				onClick={async () => {
					await getPresetsAction(login.user.email)
					props.setIsSaving(false)
					props.setPresetVisible(true)
				}}
			>
				Open presets
			</MenuItem>
		</div>
	)
	// }
	return (
		<ReportItem>
			<ReportItemHeading>Filters</ReportItemHeading>
			<StyledBox style={{ width: '260px' }}>
				<FiltersComponentList {...props} setDataObj={props.setDataObj} />
			</StyledBox>
			<Grid
				container
				spacing={2}
				style={{
					position: 'absolute',
					right: '73%',
					bottom: '-10%',
					flexDirection: 'column-reverse',
					width: '250px',
					overflow: 'hidden',
					marginTop: '20px',
					paddingBottom: '60px',
				}}
			>
				{/* <Grid item xs={3} order={1}>
					<AddButton
						onClick={async () => {
							await getPresetsAction(headers, login.user.email)
							props.setIsSaving(false)
							props.setPresetVisible(true)
						}}
						sx={{
							position: 'relative',
							height: '30px',
							width: '180px',
						}}
					>
						Presets
					</AddButton>
				</Grid> */}
				<Grid item xs={2} order={1}>
					<SimpleActionsButton
						// width={0}
						// row={undefined}
						label={'Presets'}
						options={presetOptions}
						// style={{
						// 	position: 'relative',
						// 	height: '30px',
						// 	width: '180px',
						// 	marginLeft: '10px',
						// }}
					></SimpleActionsButton>
					{/* <AddButton
						onClick={() => {
							props.setIsSaving(true)
							props.setPresetVisible(true)
						}}
						sx={{
							position: 'relative',
							height: '30px',
							width: '180px',
						}}
					>
						Save a preset
					</AddButton> */}
				</Grid>
				<Grid item xs={2} order={3}>
					<SubmitButton
						onClick={props.trySubmit}
						sx={{
							position: 'relative',
							height: '30px',
							width: '180px',
						}}
					>
						Run
					</SubmitButton>
				</Grid>
				<Grid item xs={2} order={2}>
					<SubmitButton
						onClick={props.tryDownload}
						disabled={props.isReportOpen}
						sx={{
							position: 'relative',
							height: '30px',
							width: '180px',
						}}
					>
						Download
						<DownloadIcon
							style={{ marginLeft: '8px' }}
							color={props.isReportOpen ? 'disabled' : 'inherit'}
						/>
					</SubmitButton>
				</Grid>
				{/* <Grid item xs={12} order={4}>
					{dateArr !== undefined
						? 'Last: ' + dateArr[0] + ' ' + timeString
						: ''}
				</Grid> */}
			</Grid>
		</ReportItem>
	)
}

const AdjustReportsPage = (props: {
	children?: any
	setLoading?: any
	errorMessage: null | string
	setErrorMessage: any
	isErrorPromptOpened: boolean
	setIsErrorPromptOpened: any
	setIsWarningPromptOpened: any
	viewRecord: any
	setViewRecord: any
}) => {
	const theme = useTheme()
	const [report, setReport] = useState<any>(null)
	const [reportFields, setReportFields] = useState<ReportFieldsInterface>({
		from_date: new Date(formatDate(subDays(new Date(), 1))),
		to_date: new Date(formatDate(subDays(new Date(), 1))),
		breakdowns: [],
		statistics: [],
		filters: {
			app_id: [],
			country: [],
			advertiser_name: [],
			media_source_pid: [],
			publisher: [],
			email: [],
			campaign_name: [],
			campaign_id: [],
		},
	})
	const [customReportFields, setCustomReportFields] = useState<any>({})

	const [viewDataFor, setViewDataFor] = useState<string>('Yesterday')
	const [startDay, setStartDay] = useState<Date>(subDays(new Date(), 1))
	const [endDay, setEndDay] = useState<Date>(subDays(new Date(), 1))
	const [breakdownFields, setBreakdownFields] = useState<any>([
		{
			Date: false,
			Month: false,
			Day: false,
			'Day of the Week': false,
			Country: false,
			'App Token': false,
			OS: false,
		},
	])
	const [statisticsFields, setStatisticsFields] = useState<any>([
		{
			Impressions: false,
			Clicks: false,
			Installs: false,
		},
	])
	const [appIds, setAppIds] = useState<string[]>([])
	const [advertisers, setAdvertisers] = useState<string[]>([])
	const [user, setUser] = useState<string[]>([])
	const [pid, setPid] = useState<string[]>([])
	const [publishers, setPublishers] = useState<string[]>([])
	const [campaignNames, setCampaignNames] = useState<string[]>([])
	const [campaignIds, setCampaignIds] = useState<string[]>([])
	const [countries, setCountries] = useState<string[]>([])
	const { login } = useTypedSelector((state) => state)
	const [lastUpdatedAt, setLastUpdatedAt] = useState(undefined)
	const [isReportOpen, setIsReportOpen] = useState<boolean>(false)
	const [fileDownload, setFileDownload] = useState<any>()
	const [presetVisible, setPresetVisible] = useState(false)
	const [graph, setGraph] = useState(null)
	const [isSaving, setIsSaving] = useState(false)
	const [chartData, setChartData] = useState<any>(null)
	const fillReport = () => {
		if (reportFields) {
			setBreakdownFields(
				reportFields.breakdowns.map((a: string) =>
					a
						.split('_')
						.map((word) => capitalizeFirstLetter(word))
						.join(' '),
				),
			)
			setStatisticsFields(
				reportFields.statistics.map((a: string) =>
					a
						.split('_')
						.map((word) => capitalizeFirstLetter(word))
						.join(' '),
				),
			)

			setStartDay(reportFields.from_date)
			setEndDay(reportFields.to_date)
			setAppIds(reportFields.filters.app_id)
			setAdvertisers(reportFields.filters.advertiser_name)
			setUser(reportFields.filters.email)
			setPid(reportFields.filters.media_source_pid)
			setPublishers(reportFields.filters.publisher)
			setCampaignNames(reportFields.filters.campaign_name)
			setCampaignIds(reportFields.filters.campaign_id)
			setCountries(reportFields.filters.country)
		}
	}
	// const headers = useMemo(() => {
	// 	return {
	// 		Authorization: `Token ${login.user.token}`,
	// 	}
	// }, [login.user.token])
	const {
		saveAppsflyerAction,
		updatePresetAction,
		getRefreshAction,
		getUpdatedAtAction,
	} = useActions()
	const { getFiltersAction, getAdjustFiltersAction } = useReportActions()
	const getLastRefreshed = () => {
		getUpdatedAtAction('aggregated', setLastUpdatedAt)
	}

	const goToBottom = () => {
		window.scrollTo({
			top: 700,
			behavior: 'smooth',
		})
	}

	const tryDownload = async () => {
		await saveAppsflyerAction(setFileDownload, report, login.user.email)
	}

	const tryGetRealtime = async () => {
		props.setLoading(true)
		props.setIsWarningPromptOpened(true)
		await getRefreshAction(props.setErrorMessage)
		props.setLoading(false)
	}
	// const getList = (list: string[], setList: any) => {
	// 	setList(list)
	// }
	const trySubmit = async () => {
		props.setLoading(true)
		setIsReportOpen(false)
		const breakdowns: string[] = []
		Object.keys(breakdownFields).forEach((e: string) => {
			if (breakdownFields[e] === true) {
				breakdowns.push(e.toLowerCase().split(' ').join('_'))
			}
		})

		const statistics: string[] = []
		Object.keys(statisticsFields).forEach((e: string) => {
			if (statisticsFields[e] === true) {
				statistics.push(e.toLowerCase().split(' ').join('_'))
			}
		})

		const data: ReportFieldsInterface = {
			from_date: startDay,
			to_date: endDay,
			breakdowns: breakdowns,
			statistics: statistics,
			filters: {
				app_id: appIds,
				country: countries,
				advertiser_name: advertisers,
				media_source_pid: pid,
				publisher: publishers,
				email: user,
				campaign_name: campaignNames,
				campaign_id: campaignIds,
			},
		}
		const customFields = addCustomFields(data)
		// data.breakdowns.map((e) => e.toLowerCase().split(' ').join('_'))
		// data.statistics.map((e) => e.toLowerCase().split(' ').join('_'))
		setCustomReportFields(customFields)

		await getAdjustFiltersAction(
			customFields,
			setReport,
			props.setErrorMessage,
			props.setLoading,
			setIsReportOpen,
			report,
			setGraph,
		)
		setIsReportOpen(report !== null)
		props.setLoading(false)
	}

	const addCustomFields = (currentReportField: any) => {
		const result = structuredClone(currentReportField)
		// if (!currentReportField.breakdowns.includes('date')) {
		// 	result.breakdowns.push('date')
		// }
		// if (!currentReportField.statistics.includes('revenue')) {
		// 	result.statistics.push('revenue')
		// }
		// if (!currentReportField.statistics.includes('cost')) {
		// 	result.statistics.push('cost')
		// }
		// if (!currentReportField.statistics.includes('profit')) {
		// 	result.statistics.push('profit')
		// }
		return result
	}

	// useEffect(() => {
	// props.setLoading(false)
	// }, [props])

	useEffect(() => {
		if (fileDownload) {
			saveAs(fileDownload, '*.csv')
		}
	}, [fileDownload])
	useEffect(() => {
		if (report !== null) {
			setIsReportOpen(true)
			goToBottom()
		}
		// console.log(graph)
		//fillReport()
	}, [report])
	useEffect(() => {
		if (!presetVisible) {
			fillReport()
		}
	}, [presetVisible])

	useEffect(() => {
		const data: ReportFieldsInterface = {
			from_date: startDay,
			to_date: endDay,
			breakdowns: breakdownFields,
			statistics: statisticsFields,
			filters: {
				app_id: appIds,
				country: countries,
				advertiser_name: advertisers,
				media_source_pid: pid,
				publisher: publishers,
				email: user,
				campaign_name: campaignNames,
				campaign_id: campaignIds,
			},
		}
		setReportFields(data)
		getLastRefreshed()
	}, [
		viewDataFor,
		startDay,
		endDay,
		// breakdownFields,
		// statisticsFields,
		appIds,
		advertisers,
		user,
		pid,
		publishers,
		campaignNames,
		campaignIds,
		countries,
	])
	let dateArr
	let timeString = ''
	if (lastUpdatedAt) {
		dateArr = (lastUpdatedAt as unknown as string).split('T')
		if (dateArr !== undefined && dateArr[1] !== undefined) {
			const timeStringArr = dateArr[1].split(':')
			timeString = timeStringArr[0] + ':' + timeStringArr[1]
		}
	}
	const handleGraphicData = (report: any) => {
		const data: any = []
		if (report === null) {
			for (let i = 7; i >= 1; i--) {
				const date = subDays(new Date(new Date()), i).toLocaleDateString(
					undefined,
					{ year: 'numeric', month: '2-digit', day: '2-digit' },
				)
				data.push({
					date: date,
					Revenue: 0,
					Profit: 0,
					Cost: 0,
				})
			}
			// console.log(data)
			setGraph(data)
			setChartData(data)
		}
		// if (
		// 	!report ||
		// 	!report[0].date ||
		// 	!report[0].revenue ||
		// 	!report[0].profit ||
		// 	!report[0].cost
		// ) {
		// 	for (let i = 7; i >= 0; i--) {
		// 		const date = subDays(new Date(new Date()), i).toLocaleDateString(
		// 			undefined,
		// 			{ year: 'numeric', month: '2-digit', day: '2-digit' },
		// 		)
		// 		data.push({
		// 			date: date,
		// 			Revenue: 0,
		// 			Profit: 0,
		// 			Cost: 0,
		// 		})
		// 	}
		// } else {
		// 	for (const field of report) {
		// 		const savedDate = new Date(field.date).toLocaleDateString(undefined, {
		// 			year: 'numeric',
		// 			month: '2-digit',
		// 			day: '2-digit',
		// 		})
		// 		if (data.length === 0) {
		// 			data.push({
		// 				date: savedDate,
		// 				Revenue: field.revenue,
		// 				Profit: field.profit,
		// 				Cost: field.cost,
		// 			})
		// 		} else {
		// 			let updated = false
		// 			for (const alreadyIn of data) {
		// 				if (alreadyIn.date === savedDate) {
		// 					alreadyIn.Revenue += field.revenue
		// 					alreadyIn.Profit += field.profit
		// 					alreadyIn.Cost += field.cost
		// 					updated = true
		// 					break
		// 				}
		// 			}
		// 			if (!updated) {
		// 				data.push({
		// 					date: savedDate,
		// 					Revenue: field.revenue,
		// 					Profit: field.profit,
		// 					Cost: field.cost,
		// 				})
		// 			}
		// 		}
		// 	}
		// }
		// data.sort((a: any, b: any) => Date.parse(a.date) - Date.parse(b.date)).pop()
		// data.forEach((el: any) => {
		// 	el.Profit = Math.floor(el.Profit)
		// 	el.Revenue = Math.floor(el.Revenue)
		// 	el.Cost = Math.floor(el.Cost)
		// })
		// console.log(report)

		setChartData(report)
	}
	useEffect(() => {
		handleGraphicData(graph)
	}, [graph])
	const breakdownsDiv = document.getElementById('List1Breakdown')
	const graphHeight = (breakdownsDiv?.clientHeight as number) + 160
	return (
		<ThemeProvider theme={theme}>
			<ReportGrid
				container
				spacing={'20px'}
				style={{
					marginTop: '20px',
					position: 'relative',
					marginBottom: report ? '20px' : '90px',
				}}
			>
				<Grid
					item
					xs={12}
					style={{
						color: 'black',
						marginTop: '-50px',
						display: 'flex',
						justifyContent: 'flex-end',
					}}
				>
					{dateArr !== undefined
						? 'Last: ' + dateArr[0] + ' ' + timeString
						: ''}
				</Grid>
				<Grid item xs={3} style={{ marginTop: '-20px' }}>
					<TimeframeComponent
						viewDataFor={viewDataFor}
						setViewDataFor={setViewDataFor}
						startDay={startDay}
						setStartDay={setStartDay}
						endDay={endDay}
						setEndDay={setEndDay}
					/>
				</Grid>
				<Grid item xs={6} style={{ marginTop: '-20px' }}>
					<BreakdownComponent
						dataObj={reportFields}
						breakdownFields={breakdownFields}
						setBreakdownFields={setBreakdownFields}
						statisticsFields={statisticsFields}
						setStatisticsFields={setStatisticsFields}
					/>
				</Grid>
				<Grid item xs={3} style={{ marginTop: '-20px' }}>
					<FiltersComponent
						setAppIds={setAppIds}
						setAdvertisers={setAdvertisers}
						setUser={setUser}
						setPid={setPid}
						setPublishers={setPublishers}
						setCampaignNames={setCampaignNames}
						setCampaignIds={setCampaignIds}
						setCountries={setCountries}
						dataObj={reportFields}
						setDataObj={setReportFields}
						trySubmit={trySubmit}
						tryDownload={tryDownload}
						isReportOpen={!isReportOpen}
						user={user}
						presetVisible={presetVisible}
						setPresetVisible={setPresetVisible}
						setIsSaving={setIsSaving}
						tryGetRealtime={tryGetRealtime}
						setIsWarningPromptOpened={props.setIsWarningPromptOpened}
						lastUpdatedAt={lastUpdatedAt}
					/>
				</Grid>
			</ReportGrid>
			{/* <div
				style={{
					width: '43%',
					display: 'flex',
					position: 'absolute',
					// top: graphHeight,
					left: '28%',
				}}
			>
				{chartData && (
					<AreaChart
						data={chartData}
						categories={['Revenue', 'Cost', 'Profit']}
						dataKey={'date'}
						height='h-72'
						colors={['indigo', 'yellow', 'green']}
						yAxisWidth='w-14'
						valueFormatter={(number: number) => {
							return '$ ' + Intl.NumberFormat('us').format(number).toString()
						}}
					></AreaChart>
				)}
			</div> */}

			<PresetsPopup
				visible={presetVisible}
				setPresetVisible={setPresetVisible}
				setRow={setReportFields}
				fillReport={fillReport}
				isSaving={isSaving}
				setPreview={props.setViewRecord}
				reportFields={reportFields}
				setIsSaving={setIsSaving}
			/>
			{report ? (
				<AdjustReportsTable
					report={report}
					setIsReportOpen={setIsReportOpen}
					reportFields={reportFields}
					isReportOpen={isReportOpen}
					customReportFields={customReportFields}
				/>
			) : (
				<></>
			)}
		</ThemeProvider>
	)
}

export default AdjustReportsPage
