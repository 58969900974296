import outReducer from '.'
import { UserInterface } from '../../models/model.interface'
import { ActionType } from '../action-types/index'
import { Action } from '../actions'

export interface loginReducerInterface {
	user: UserInterface | any
	companyType: string 
}

const initialState: loginReducerInterface = {
	user: {},
	companyType: "",
}

const reducer = (
	state: loginReducerInterface = initialState,
	action: Action,
): loginReducerInterface => {
	switch (action.type) {
		case ActionType.LOGIN:
			return action.payload
		case ActionType.LOGOUT:
			outReducer(undefined, ActionType.LOGOUT)
			return initialState
		default:
			return state
	}
}

export default reducer
