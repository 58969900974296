import React from 'react'
import { Grid, useTheme } from '@mui/material'
import { useNavigate } from 'react-router'
import { Property } from 'csstype'
import ReactCountryFlag from 'react-country-flag'
import { PageWrapper } from '../components/reusableComponents'
import { UnborderedButton } from '../components/Buttons'
import EditIcon from '@mui/icons-material/Edit'
import CompanyInformationHeader from '../components/CompanyInformationHeader'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import defaultIcon from '../../assets/default-icon.webp'

const InfoField = (props: {
	label: string
	value: string | string[]
	isArray?: boolean
	isCountry?: boolean
	whiteSpace?: Property.WhiteSpace
	textDecoration?: Property.TextDecoration
}) => {
	const theme = useTheme()
	return (
		<div
			style={{
				display: 'flex',
				gap: '6px',
				// fontWeight: '500',
				whiteSpace: props.whiteSpace ? props.whiteSpace : 'nowrap',
				alignItems: props.isCountry ? 'center' : 'flex-start',
				flexWrap: 'wrap',
				flexDirection: props.isArray ? 'column' : 'row',
			}}
		>
			<span style={{ color: theme.colors.base.grey600 }}>{props.label}:</span>
			{props.isCountry ? (
				Array.isArray(props.value) ? (
					props.value.map((country: string) => {
						return (
							<>
								<ReactCountryFlag
									style={{
										height: '9px',
										borderRadius: '100px',
										border: `1px solid ${theme.colors.base.grey900}`,
									}}
									key={'flags'}
									className='roundedFlag'
									countryCode={country === 'UK' ? 'GB' : country}
									svg
								/>
								<span style={{ overflowWrap: 'anywhere' }}>{country}</span>
							</>
						)
					})
				) : (
					<>
						<ReactCountryFlag
							style={{
								height: '9px',
								borderRadius: '100px',
								border: `1px solid ${theme.colors.base.grey900}`,
							}}
							key={'flags'}
							className='roundedFlag'
							countryCode={props.value === 'UK' ? 'GB' : props.value}
							svg
						/>
						<span style={{ overflowWrap: 'anywhere' }}>{props.value}</span>
					</>
				)
			) : props.isArray ? (
				props.value?.length > 0 && (
					<div
						style={{
							display: 'flex',
							flexWrap: 'wrap',
							width: '100%',
							maxHeight: '11rem',
							overflow: 'auto',
							gap: '4px',
							// border: '1px solid ' + theme.colors.base.grey200,
							borderRadius: '10px',
							padding: '1rem',
							marginInlineStart: -18,
							marginTop: -10,
						}}
					>
						{(props.value as string[]).map((element: string) => {
							return (
								<span
									key={element + 'key'}
									style={{
										padding: '6px',
										color: theme.colors.base.grey900,
										border: '1px solid ' + theme.colors.base.grey300,
										// background: theme.colors.base.green200,
										borderRadius: '10px',
										fontSize: theme.font.size.body,
										gridColumnStart:
											element && element.length < 16 ? 'span 1' : 'span 2',
										whiteSpace: 'nowrap',
										textAlign: 'center',
									}}
								>
									{element}
								</span>
							)
						})}
					</div>
				)
			) : props.value &&
			  typeof props.value === 'string' &&
			  ((props.value as string).startsWith('https://') ||
					(props.value as string).startsWith('http://')) ? (
				<a
					href={props.value as string}
					target={'_blank'}
					rel='noreferrer'
					style={{
						overflowWrap: 'anywhere',
						textDecoration: props.textDecoration,
					}}
				>
					Click to Open
				</a>
			) : (
				<span
					style={{
						overflowWrap: 'anywhere',
						textDecoration: props.textDecoration,
					}}
				>
					{props.value}
				</span>
			)}
		</div>
	)
}

const CreatorInformationContainer = (props: {
	creator: any
	closeDetailedView: any
	handleEdit: any
}) => {
	const theme = useTheme()
	return (
		<div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					width: '100%',
					justifyContent: 'space-between',
					padding: '0 20px',
				}}
			>
				<UnborderedButton
					style={{
						display: 'flex',
						justifyContent: 'flex-start',
					}}
					onClick={props.closeDetailedView}
					icon={<ArrowBackIosIcon style={{ height: '16px' }} />}
				>
					Back
				</UnborderedButton>
				<UnborderedButton
					style={{
						display: 'flex',
						justifyContent: 'flex-end',
						marginRight: '10rem',
					}}
					onClick={props.handleEdit}
					icon={<EditIcon />}
				>
					Edit
				</UnborderedButton>
			</div>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					gap: '10px',
					marginBottom: '0.5rem',
				}}
			>
				<CompanyInformationHeader
					name={props.creator?.name}
					iconUrl={props.creator?.creator_icon || defaultIcon}
				/>
			</div>
			<Grid container spacing={2} style={{ fontSize: theme.font.size.body }}>
				<Grid item xs={3}>
					<InfoField label='Full Name' value={props.creator?.name} />
				</Grid>
				<Grid item xs={3}>
					<InfoField label='Username' value={props.creator?.username} />
				</Grid>
				<Grid item xs={3}>
					<InfoField label='Type' value={props.creator?.type} />
				</Grid>
				<Grid item xs={3}>
					<InfoField label='Creator ID' value={props.creator?.creator_id} />
				</Grid>
				<Grid item xs={3}>
					<InfoField label='Gender' value={props.creator?.gender} />
				</Grid>
				<Grid item xs={3}>
					<InfoField
						label='Country'
						value={props.creator?.geo.length > 0 ? props.creator?.geo[0] : ''}
						isCountry
					/>
				</Grid>
				<Grid item xs={3}>
					<InfoField label='Portfolio' value={props.creator?.portfolio} />
				</Grid>
				<Grid item xs={3}>
					<InfoField label='Email' value={props.creator?.email} />
				</Grid>
				<Grid item xs={12}>
					<InfoField label='Campaign' value={props.creator?.campaign} isArray />
				</Grid>
			</Grid>
		</div>
	)
}

const CapabilitiesInfoContainer = (props: { capabilities: any }) => {
	const theme = useTheme()
	const { capabilities } = props

	return (
		<React.Fragment key={capabilities.uuid}>
			<Grid
				container
				spacing={2}
				style={{
					fontSize: theme.font.size.body,
					width: '90%',
					marginTop: '20px',
				}}
			>
				<Grid item xs={12}>
					<InfoField
						label='Type'
						value={capabilities.type}
						textDecoration='underline'
					/>
				</Grid>
				<Grid item xs={4}>
					<InfoField
						label='Gender'
						value={`Male: ${capabilities.gender.male}%, Female: ${capabilities.gender.female}%`}
					/>
				</Grid>
				<Grid item xs={8}>
					<InfoField
						label='Age'
						value={`18-24: ${capabilities.age.age18to24}%, 25-34: ${capabilities.age.age25to34}%, 35-44: ${capabilities.age.age35to44}%, 45-54: ${capabilities.age.age45to54}%, 55+: ${capabilities.age.age55toInfinity}%`}
					/>
				</Grid>
				<Grid item xs={12}>
					<InfoField
						label='Geo'
						value={capabilities.geo
							.map((el: any) => `${el.geo[0].substring(0, 2)}: ${el.value}%`)
							.join(', ')}
					/>
				</Grid>
				<Grid item xs={6}>
					<InfoField
						label='Engagement Rate'
						value={`${capabilities.engagement_rate}%`}
					/>
				</Grid>
				<Grid item xs={6}>
					<InfoField
						label='Rate Per Video'
						value={`${capabilities.rate_per_video}%`}
					/>
				</Grid>
				<Grid item xs={12}>
					<InfoField label='Vertical' value={capabilities.vertical} isArray />
				</Grid>
				<Grid item xs={12}>
					<InfoField label='Notes' value={capabilities.notes} />
				</Grid>
			</Grid>
			<div
				style={{
					borderBottom: '1px solid ' + theme.colors.base.grey300,
					width: '95%',
					marginTop: '20px',
					marginBottom: '20px',
				}}
			></div>
		</React.Fragment>
	)
}

const CreatorCompanyPreviewPage = (props: {
	setLoading: (loading: boolean) => void
	setPopUpVisible: (visible: boolean) => void
	companyData: any
}) => {
	const theme = useTheme()
	const navigate = useNavigate()
	const row = props.companyData

	const closeDetailedView = () => {
		navigate('/companySettings')
		props.setPopUpVisible(false)
	}

	const handleEdit = () => {
		// console.log('handleEdit')
		navigate('/companySettings/edit')
	}

	return (
		<PageWrapper
			style={{
				color: theme.colors.text.titles,
				display: 'flex',
				flexDirection: 'column',
				gap: '30px',
				paddingBottom: '30px',
			}}
		>
			<CreatorInformationContainer
				creator={row}
				closeDetailedView={closeDetailedView}
				handleEdit={handleEdit}
			/>
			<div
				style={{
					borderBottom: '1px solid ' + theme.colors.base.grey300,
					width: '100%',
				}}
			></div>
			{row &&
				row.capabilities &&
				row.capabilities.length > 0 &&
				row.capabilities.map((capability: any) => (
					<CapabilitiesInfoContainer
						key={capability.uuid}
						capabilities={capability}
					/>
				))}
		</PageWrapper>
	)
}

export default CreatorCompanyPreviewPage
