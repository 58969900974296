import { ActionType } from '../action-types/index';
import { Action } from '../actions';

interface Company {
    name: string;
    id: number;
    type: string;
    agency_access: boolean
}

interface CompaniesState {
    companies: Company[];
}

const initialState: CompaniesState = {
    companies: [],
};

const reducer = (state: CompaniesState = initialState, action: Action): CompaniesState => {
    switch (action.type) {
        case ActionType.GET_ALL_COMPANIES:
            return {
                ...state,
                companies: action.payload,
            };
        case ActionType.UPDATE_COMPANY:
            return {
               ...state,
                companies: state.companies.map((company) =>
                    company.id === action.payload.id? action.payload : company,
                ),
            };
        default:
            return state;
    }
}

export default reducer;
