import { Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import { checkInputStartsWithHttp } from '../../utils/helpers/tableHelper'
import { countryList } from '../components/countryList'
import {
	ChipInputList,
	CountryListSelector,
	OwnerSelect,
	StyledNameField,
} from '../components/reusableComponents'
import { stringIsValidName } from '../../utils/helpers/helperFuncs'
import moment from 'moment'
import { DropList } from '../components/SelectableInputs'
import { StyledTextAreaField } from '../components/Inputs'

//this component will render and allow to edit / save a new publisher. It's the main file for all the inputs.
export const AdvertiserFinanceInfo = (props: {
	paymentTerms: string
	setPaymentTerms: any
	vat: string
	setVat: any
	branch: string
	setBranch: any
	invoiceInstructions: string
	setInvoiceInstructions: any
	settings: any
	loginRole: any
	mandatoryFields?: any
	fieldValidations?: any
	permissions: any
}) => {
	const permissions = props.permissions
	return (
		<Grid
			container
			columnSpacing={2}
			direction='row'
			style={{
				display: 'flex',
				gap: '18px',
				marginLeft: '6px',
				width: '95%',
				marginBottom: 260,
			}}
		>
			<Grid item xs>
				<DropList
					onChange={(e: any) => {
						props.setBranch(e)
					}}
					options={props.settings.settings.branches}
					label={'Internal Legal Entity'}
					placeholder={'Internal Legal Entity'}
					value={props.branch}
					errored={!props.branch}
					mandatory={!props.fieldValidations.branch}
					disabled={!permissions.edit}
				/>
			</Grid>
			<Grid item xs>
				<DropList
					onChange={(e: string) => props.setPaymentTerms(e)}
					options={
						props.settings.settings.paymentTerms
							? ['Prepayment', ...props.settings.settings.paymentTerms].sort()
							: []
					}
					label={'Payment Term'}
					placeholder={'Payment Term'}
					value={props.paymentTerms}
					errored={!props.paymentTerms}
					mandatory={!props.fieldValidations.paymentTerms}
					disabled={!permissions.edit}
				/>
			</Grid>

			<Grid item xs>
				<DropList
					onChange={(e: string) => props.setVat(e)}
					options={
						props.settings.settings.vatValues
							? props.settings.settings.vatValues.sort()
							: []
					}
					label={'VAT (%)'}
					placeholder={'VAT (%)'}
					value={props.vat}
					disabled={!permissions.edit}
				/>
			</Grid>
			<Grid item xs={12}>
				<StyledTextAreaField
					label='Invoice Instructions'
					value={props.invoiceInstructions}
					onChange={props.setInvoiceInstructions}
					disabled={!permissions.edit}
				/>
			</Grid>
		</Grid>
	)
}
