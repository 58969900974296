import React, { useState } from 'react'
import { TitleComponent } from '../components/Informative'

const NewsletterTitle = (props: {
	value: string
	setTitle: any
	mainObj: any
	editMode: boolean
}) => {
	const [isEditing, setIsEditing] = useState(false)

	const handleInputChange = (e: any) => {
		props.setTitle(e.target.value)
		props.mainObj.title = e.target.value
	}
	const handleEnter = (e: any) => {
		if (e.key === 'Enter') {
			setIsEditing(false)
		}
	}
	return (
		<React.Fragment>
			{isEditing ? (
				<input
					type='text'
					onChange={handleInputChange}
					onKeyDown={handleEnter}
					defaultValue={props.value ? props.value : 'Default title'}
				/>
			) : props.editMode ? (
				<TitleComponent
					component='span'
					style={{
						fontSize: '22px',
						fontWeight: '1000',
						letterSpacing: '-1px',
					}}
					onDoubleClick={() => setIsEditing(true)}
				>
					{props.value ? props.value : 'Default title'}
				</TitleComponent>
			) : (
				<TitleComponent
					component='span'
					style={{
						fontSize: '22px',
						fontWeight: '1000',
						letterSpacing: '-1px',
						marginTop: '22px',
					}}
				>
					{props.value}
				</TitleComponent>
			)}
		</React.Fragment>
	)
}

export default NewsletterTitle
