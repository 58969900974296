import { Box, DialogActions, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { useActions, useLogActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { AppInterface } from '../../models/model.interface'
import { fetchData } from '../../utils/helpers/navigationHelper'
import { shallowEqual } from '../../utils/helpers/tableHelper'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { PageWrapper, StyledNameField } from '../components/reusableComponents'
import P360Component from './P360Component'
import { DropList } from '../components/SelectableInputs'
import { SubmitButton, UnborderedButton } from '../components/Buttons'

const AppPopUp = (props: {
	PopUpVisible: boolean
	setPopUpVisible: any
	setLoading: any
	errorMessage: null | string
	setErrorMessage: any
	isErrorPromptOpened: boolean
	setIsErrorPromptOpened: any
	advertisers: any
	setIsSuccessPromptOpened: any
	isSuccessPromptOpened: any
}): JSX.Element => {
	const navigate = useNavigate()
	const theme = useTheme()
	const { insertP360Rule, updateAppInfo, getAppsAction } = useActions()
	const { insertLog } = useLogActions()
	const { login, app, campaign } = useTypedSelector((state) => state)
	const location = useLocation()
	const [name, setName] = useState<string | null>(null)
	const [appId, setAppId] = useState(null)
	const [isValid, setIsValid] = useState(false)
	const [advertisersArray, setAdvertisersArray] = useState([])
	const [appIdsArray, setAppIdsArray] = useState([])
	const [prevState, setPrevState] = useState(null)
	const [selectedCampaign, setSelectedCampaign] = useState<any>(null)
	const params = useParams()
	const row = app.app.find((e: any) => e.uuid === params.id)
	const [emailGroup, setEmailGroup] = useState<any>(row ? row.emailGroup : [])

	const preparePrevState = (prevState: any) => {
		const { id, count, event_order, status, uuid, ...result } = prevState
		return result
	}
	// const prepareNewState = (prevState: any) => {
	// 	const { id, user_login, ...result } = prevState
	// 	return result
	// }

	const nullifyFields = () => {
		setAppId(null)
		setName(null)
	}

	// const handleLog = async (headers: any, payload: any) => {
	// 	const shallow = shallowEqual(prevState, prepareNewState(payload))
	// 	if (shallow.length > 0) {
	// 		const log = {
	// 			email: login.user.email,
	// 			object: appId! + ' in Apps',
	// 			change: JSON.stringify(shallow),
	// 		}
	// 		await insertLog(headers, log)
	// 	}
	// // }
	// useEffect(() => {
	// 	// console.log('emailgroup inside apps add', emailGroup)
	// }, [emailGroup])
	const trySubmit = async () => {
		if (isValid) {
			// console.log(emailGroup)
			let payload: any = {
				advertiser_name: name!,
				email: login.user.email,
				app_id: appId!,
				created_by: login.user.email,
				emailGroup: emailGroup ? JSON.stringify(emailGroup) : null,
			}
			// console.log('payload', payload)
			// const headers = {
			// 	Authorization: `Token ${login.user.token}`,
			// }

			props.setLoading(true)
			let failed: any = false
			if (!row) {
				failed = await insertP360Rule(payload, props.setErrorMessage)
			} else {
				failed = await updateAppInfo(payload, props.setErrorMessage)
			}
			if (!failed) {
				await fetchData(getAppsAction, app.app)

				// handleLog(headers, payload)
				// nullifyFields()
				// navigate('/apps')
				props.setPopUpVisible(false)
				if (row) {
					props.setIsSuccessPromptOpened(true)
				}
			}
			props.setLoading(false)
		}
	}

	useEffect(() => {
		const fillFields = (row: any, appList: string[]) => {
			if (row) {
				setAppId(row.app_id)
				setName(row.advertiser_name)
				const prev = preparePrevState(row)
				setPrevState({ ...prev, apps: appList })
				if (row.emailGroup && typeof row.emailGroup !== 'object') {
					setEmailGroup(JSON.parse(row.emailGroup))
				} else {
					setEmailGroup(row.emailGroup)
				}
			}
		}
		setAdvertisersArray(
			props.advertisers.length > 0
				? props.advertisers
						.map((e: any) => {
							return e.platform.includes('Appsflyer') && e.advertiser_name
						})
						.filter((el: string | boolean) => el)
				: ['test'],
		)
		if (row) {
			const appList = app.app.filter(
				(a: AppInterface) => a.advertiser_name === row!.advertiser_name,
			)

			fillFields(
				row,
				appList.map((a: AppInterface) => a.app_id),
			)
		} else {
			nullifyFields()
		}
	}, [row, props.PopUpVisible, app.app, props.advertisers])

	useEffect(() => {
		const selectedAdvertiser = app.app.filter(
			(adv: any) => adv.advertiser_name === name,
		)
		const appList = selectedAdvertiser.map((el: any) => el.app_id)
		setAppIdsArray(appList)
	}, [name])
	useEffect(() => {
		setIsValid(!!name && !!appId)
	}, [name, appId])

	const checkEmailGroupElement = (emailGroup: any) => {
		if (emailGroup) {
			for (const dataset of emailGroup) {
				for (const key in dataset) {
					if (dataset[key].length === 0) {
						if (key === 'events') {
							if (dataset.report.includes('Raw - inapp events postback')) {
								return false
							} else {
								continue
							}
						}
						return false
					}
				}
			}
		}
		return true
	}
	useEffect(() => {
		setIsValid(checkEmailGroupElement(emailGroup))
	}, [emailGroup])

	useEffect(() => {
		if (row) {
			const filteredCampaign = campaign.campaign.filter(
				(el: any) => el.app_id === row!.app_id,
			)
			setSelectedCampaign(filteredCampaign)
		}
	}, [])

	const closeDetailedView = () => {
		nullifyFields()
		navigate('/p360apps')
		props.setPopUpVisible(false)
	}

	return (
		<PageWrapper
			style={{
				width: '80%',
				display: 'flex',
				alignItems: 'flex-start',
				flexDirection: 'column',
				marginTop: '20px',
			}}
		>
			<UnborderedButton
				style={{
					margin: 'auto',
					width: '80px',
					display: 'flex',
					position: 'absolute',
					left: 86,
				}}
				onClick={closeDetailedView}
				icon={<ArrowBackIcon />}
			>
				Back
			</UnborderedButton>
			<Box
				// sx={{ borderBottom: 1, borderColor: 'divider' }}
				style={{
					width: '100%',
					paddingBottom: '12px',
					// marginLeft: '10%',
					marginTop: 40,
					display: 'flex',
					gap: 40,
					flexDirection: 'row',
				}}
			>
				<DropList
					onChange={(e: any) => setName(e)}
					options={advertisersArray}
					label={'Advertiser Name'}
					value={name}
				/>
				<DropList
					onChange={(e: any) => setAppId(e)}
					options={appIdsArray.length > 0 ? appIdsArray : []}
					label={'App Id'}
					value={appId}
				/>
			</Box>
			<hr
				style={{
					width: '101%',
					// marginLeft: 40,
					height: 1,
					background: theme.colors.base.grey300,
					border: 'none',
				}}
			></hr>

			{(row || (appId && name)) && (
				<P360Component
					emailGroup={emailGroup}
					setEmailGroup={setEmailGroup}
					app={row || { app_id: appId, advertiser_name: name }}
					params={params}
				/>
			)}

			<DialogActions
				style={{
					width: '100%',
					marginTop: '12px',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					gap: 80,
				}}
			>
				<UnborderedButton
					// style={{ margin: 'auto' }}
					label={'Cancel'}
					onClick={() => {
						nullifyFields()
						navigate('/p360apps')
						props.setPopUpVisible(false)
					}}
				/>
				{login.user.role.toLowerCase() !== 'view' &&
					login.user.role.toLowerCase() !== 'designer' && (
						<SubmitButton
							disabled={!isValid}
							// style={{ margin: 'auto' }}
							onClick={() => {
								// nullifyFields()
								trySubmit()
								// navigate('/apps')
								// props.setPopUpVisible(false)
							}}
						>
							{row ? 'Update' : 'Save'}
						</SubmitButton>
					)}
			</DialogActions>
		</PageWrapper>
	)
}
export default AppPopUp
