import { ThemeProvider, useTheme } from '@mui/material'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import TransferListElement from '../../components/TransferListElement'
import { StyledList, StyledListContainer } from '../aggregated/FiltersStyled'

// const not = (a: readonly string[], b: readonly string[]) => {
// 	return a.filter((value) => b.indexOf(value) === -1)
// }

// const checkDisabled = (value: string, statistics: any) => {
// 	try {
// 		switch (value) {
// 			// case 'Day':
// 			// 	if (
// 			// 		dataObj.breakdowns.includes('Month') ||
// 			// 		dataObj.breakdowns.includes('Country')
// 			// 	)
// 			// 		return true
// 			// 	break
// 			// case 'Month':
// 			// 	if (
// 			// 		dataObj.breakdowns.includes('Country') ||
// 			// 		dataObj.breakdowns.includes('Day') ||W
// 			// 		dataObj.breakdowns.includes('Day of the Week')
// 			// 	)
// 			// 		return true
// 			// 	break
// 			// case 'App ID':
// 			// 	if (dataObj.filters.advertiser_name.length === 0) return true
// 			// 	break
// 			case 'CR':
// 				if (statistics.includes('clicks') && statistics.includes('installs')) {
// 					return false
// 				} else {
// 					return false
// 				}

// 			default:
// 				return false
// 		}
// 	} catch (e) {
// 		console.error(e)
// 	}
// }
export const TransferList = (props: {
	fieldsArray: any[]
	setFieldsArray: any
	options: string[]
	dataObj: any
	disabled?: boolean
	listName?: string
	style?: any
	// getData: any
}) => {
	const theme = useTheme()
	const handleClick = (value: any) => {
		props.setFieldsArray((prevFields: any) => ({
			...prevFields,
			[value]: !prevFields[value],
		}))
	}
	const right = props.options.map((value: string) => {
		// const labelId = `transfer-list-all-item-${value}-label`

		return (
			<TransferListElement
				value={value}
				key={value}
				dataObj={props.dataObj}
				handleClick={handleClick}
				// checkDisabled={() => checkDisabled(value, props.fieldsArray)}
				checkedArr={props.fieldsArray}
				listName={'List1' + props.listName}
			/>
		)
	})

	return (
		<Grid
			container
			spacing={0.2}
			justifyContent='flex-start'
			alignItems='center'
			key={props.options[0]}
			style={props.style}
		>
			<Grid item xs={10}>
				{/* {customList('Chosen', right, 'right', 'List1')} */}
				<ThemeProvider theme={theme}>
					<StyledListContainer position={'right'} key={'List1' + 'right'}>
						<Divider />
						<StyledList
							sx={{
								border: 0,
								borderColor: theme.colors.base.grey300,
								background: theme.colors.base.white,
							}}
							id={'List1' + props.listName}
							style={{}}
							disablePadding
							dense
							role='list'
							key={'Chosen' + 'right' + 'list'}
						>
							{right}
						</StyledList>
					</StyledListContainer>
				</ThemeProvider>
			</Grid>
		</Grid>
	)
}
export default TransferList
