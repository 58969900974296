export const adTemplates = [
	{
		id: '0',
		category: 'DSP & SDK',
		type: 'Banner',
		width: 300,
		height: 50,
		vertical: 'Sports',
		horizontal: false,
		format: 'image/png',
		templateUrl:
			'https://ads-placement-bucket.s3.eu-central-1.amazonaws.com/template_sdk_banner_300x50_sports.png',
		smallBannerPreview: '',
		bigBannerPreview: '',
		iconPreview: '',
		videoPreview: '',
		interstitialPreview: '',
	},
	{
		id: '1',
		category: 'DSP & SDK',
		type: 'Banner',
		width: 300,
		height: 50,
		vertical: 'Shopping',
		horizontal: false,
		format: 'image/png',
		templateUrl:
			'https://ads-placement-bucket.s3.eu-central-1.amazonaws.com/template_sdk_banner_300x50_shopping.png',
		smallBannerPreview: '',
		bigBannerPreview: '',
		iconPreview: '',
		videoPreview: '',
		interstitialPreview: '',
	},
	{
		id: '2',
		category: 'DSP & SDK',
		type: 'Banner',
		width: 300,
		height: 50,
		vertical: 'Gaming',
		horizontal: false,
		format: 'image/png',
		templateUrl:
			'https://ads-placement-bucket.s3.eu-central-1.amazonaws.com/template_sdk_banner_300x50_gaming.png',
		smallBannerPreview: '',
		bigBannerPreview: '',
		iconPreview: '',
		videoPreview: '',
		interstitialPreview: '',
	},
	{
		id: '3',
		category: 'DSP & SDK',
		type: 'Video',
		width: 'full',
		height: 'full',
		horizontal: true,
		vertical: 'All',
		format: 'image/png',
		templateUrl:
			'https://ads-placement-bucket.s3.eu-central-1.amazonaws.com/template_sdk_video.png',
		smallBannerPreview: '',
		bigBannerPreview: '',
		iconPreview: '',
		videoPreview: '',
		interstitialPreview: '',
	},
	{
		id: '4',
		category: 'DSP & SDK',
		type: 'Interstitial',
		width: 'full',
		height: 'full',
		horizontal: false,
		vertical: 'All',
		format: 'image/png',
		templateUrl:
			'https://ads-placement-bucket.s3.eu-central-1.amazonaws.com/template_sdk_interstitial.png',
		smallBannerPreview: '',
		bigBannerPreview: '',
		iconPreview: '',
		videoPreview: '',
		interstitialPreview: '',
	},
	{
		id: '5',
		category: 'OEM',
		type: 'Search Page',
		width: 'full',
		height: 'full',
		horizontal: false,
		vertical: 'All',
		format: 'image/png',
		templateUrl:
			'https://ads-placement-bucket.s3.eu-central-1.amazonaws.com/template_oem_search_page.png',
		smallBannerPreview: '',
		bigBannerPreview: '',
		iconPreview: '',
		videoPreview: '',
		interstitialPreview: '',
	},
	{
		id: '6',
		category: 'OEM',
		type: 'Home Page',
		width: 'full',
		height: 'full',
		horizontal: false,
		vertical: 'All',
		format: 'image/png',
		templateUrl:
			'https://ads-placement-bucket.s3.eu-central-1.amazonaws.com/template_oem_home_page.png',
		smallBannerPreview: '',
		bigBannerPreview: '',
		iconPreview: '',
		videoPreview: '',
		interstitialPreview: '',
	},
	{
		id: '7',
		category: 'OEM',
		type: 'Banner',
		width: 300,
		height: 250,
		horizontal: false,
		vertical: 'All',
		format: 'image/png',
		templateUrl:
			'https://ads-placement-bucket.s3.eu-central-1.amazonaws.com/template_oem_banner_300x200.png',
		smallBannerPreview: '',
		bigBannerPreview: '',
		iconPreview: '',
		videoPreview: '',
		interstitialPreview: '',
	},
	{
		id: '8',
		category: 'Social',
		type: 'Influencer',
		width: 'full',
		height: 'full',
		horizontal: false,
		vertical: 'All',
		format: 'image/gif',
		templateUrl:
			'https://ads-placement-bucket.s3.eu-central-1.amazonaws.com/template_social_influencer.gif',
		smallBannerPreview: '',
		bigBannerPreview: '',
		iconPreview: '',
		videoPreview: '',
		interstitialPreview: '',
	},
	{
		id: '9',
		category: 'Social',
		type: 'Sponsored',
		width: 'full',
		height: 'full',
		horizontal: false,
		vertical: 'All',
		format: 'image/gif',
		templateUrl:
			'https://ads-placement-bucket.s3.eu-central-1.amazonaws.com/template_social_sponsored.gif',
		smallBannerPreview: '',
		bigBannerPreview: '',
		iconPreview: '',
		videoPreview: '',
		interstitialPreview: '',
	},
	{
		id: '10',
		category: 'Social',
		type: 'Video',
		width: 'full',
		height: 'full',
		vertical: 'Gaming',
		horizontal: false,
		format: 'image/png',
		templateUrl:
			'https://ads-placement-bucket.s3.eu-central-1.amazonaws.com/template_social_video_gaming.png',
		smallBannerPreview: '',
		bigBannerPreview: '',
		iconPreview: '',
		videoPreview: '',
		interstitialPreview: '',
	},
	{
		id: '11',
		category: 'Social',
		type: 'Video',
		width: 'full',
		height: 'full',
		vertical: 'Shopping',
		horizontal: false,
		format: 'image/png',
		templateUrl:
			'https://ads-placement-bucket.s3.eu-central-1.amazonaws.com/template_social_video_shopping.png',
		smallBannerPreview: '',
		bigBannerPreview: '',
		iconPreview: '',
		videoPreview: '',
		interstitialPreview: '',
	},
	{
		id: '12',
		category: 'Social',
		type: 'Video',
		width: 'full',
		height: 'full',
		vertical: 'Sports',
		horizontal: false,
		format: 'image/png',
		templateUrl:
			'https://ads-placement-bucket.s3.eu-central-1.amazonaws.com/template_social_video_sports.png',
		smallBannerPreview: '',
		bigBannerPreview: '',
		iconPreview: '',
		videoPreview: '',
		interstitialPreview: '',
	},
]
