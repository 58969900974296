import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router'
import { emailToUserConvertor } from '../../utils/helpers/helperFuncs'
import { ItemTitle } from '../components/reusableComponents'

const NewAdvertiserItem = (props: { item: any; title: any }) => {
	const [nameOfAccountManager, setNameOfAccountManager] = useState('')
	const [nameOfSalesRep, setNameOfSalesRep] = useState('')
	const navigate = useNavigate()
	useEffect(() => {
		setNameOfAccountManager(emailToUserConvertor(props.item.email || props.item.created_by))
		setNameOfSalesRep(emailToUserConvertor(props.item.sales_rep))
	}, [])

	const verticalsHandler = (verticals: string[]) => {
		if (!verticals || !Array.isArray(verticals) || verticals.length === 0) {
			return ''
		}
		if (verticals.length === 1) {
			return verticals[0]
		} else if (verticals.length > 1) {
			return verticals.join(', ')
		}
	}
	return (
		<div
			style={{ marginBottom: '24px', overflowX: 'hidden', cursor: 'pointer' }}
			onClick={() =>
				navigate(
					'/' +
						props.title.toLowerCase() +
						'/' +
						props.item.id +
						'/preview',
				)
			}
		>
			<div
				style={{
					fontWeight: '900',
					fontSize: '18px',
					justifyContent: 'flex-start',
					display: 'flex',
				}}
			>
				{props.item.advertiser_name}
				<div style={{ fontSize: '15px', marginTop: '1px', marginLeft: '4px' }}>
					{props.item.vertical?.length > 0
						? '(' + props.item.vertical + ')'
						: ''}
				</div>
			</div>
			{props.item.sales_rep && (
				<div>
					<ItemTitle>Sales Representative: </ItemTitle>
					{nameOfSalesRep}
				</div>
			)}
			{props.item.platform && (
				<div>
					<ItemTitle>MMP: </ItemTitle>
					{props.item.platform}
				</div>
			)}
			{props.item.email && (
				<div>
					<ItemTitle>Account Manager: </ItemTitle>
					{nameOfAccountManager}
				</div>
			)}
		</div>
	)
}

export default NewAdvertiserItem
