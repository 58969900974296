import React, { SyntheticEvent } from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import VisibilityIcon from '@mui/icons-material/Visibility'
import FileUploadOutlined from '@mui/icons-material/FileUploadOutlined'
import { TabContainer } from './TabComponent'
import { UnborderedButton } from './Buttons'

interface EditPageHeaderProps {
	onBack: () => void
	onPreview?: () => void
	onUpload?: () => void
	tabValue: number
	handleChangeTab: (event: SyntheticEvent, newValue: number) => void
	hasPreview?: boolean
	options: Array<{ label: string; tabIndex: number }>
	customStyles?: React.CSSProperties
	arialabel: string
	canEditMasterData?: boolean
	showBackButton?: boolean
	showUploadButton?: boolean
}

const EditPageHeader: React.FC<EditPageHeaderProps> = ({
	onBack,
	onPreview,
	onUpload,
	tabValue,
	handleChangeTab,
	hasPreview = true,
	options,
	customStyles = {},
	arialabel,
	canEditMasterData = true,
	showBackButton = true,
	showUploadButton = true,
}) => {
	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				...customStyles,
			}}
		>
			{showBackButton && (
				<UnborderedButton
					onClick={onBack}
					icon={<ArrowBackIosIcon />}
					label='Back'
				/>
			)}
			{canEditMasterData && (
				<TabContainer
					tab={tabValue}
					handleChangeTab={handleChangeTab}
					options={options}
					tabProps={{ arialabel }}
				/>
			)}
			<div style={{ display: 'flex', alignItems: 'center' }}>
				{hasPreview && (
					<UnborderedButton
						onClick={onPreview}
						icon={<VisibilityIcon />}
						label='Preview'
					/>
				)}
				{showUploadButton && onUpload && (
					<UnborderedButton
						onClick={onUpload}
						icon={<FileUploadOutlined />}
						label='Upload Icon'
					/>
				)}
			</div>
		</div>
	)
}

export default EditPageHeader
