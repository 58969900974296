import React, { useEffect, useState } from 'react'
import { InputFieldText } from '../components/Inputs'
import { Checkbox, useTheme } from '@mui/material'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { SubmitButton } from '../components/Buttons'

const Login = (props: { setCurrentView: any; onLogin: any; errorMsg: any }) => {
	const theme = useTheme()
	const [email, setEmail] = useState<string>('')
	const [password, setPassword] = useState<string>('')
	const [isRememberMe, setIsRememberMe] = useState<boolean>(false)
	const [errorMessage, setErrorMessage] = useState('')

	const handleLogin = () => {
		props.onLogin(email, password)
	}

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			handleLogin()
		}
	}

	useEffect(() => {
		if (props.errorMsg) {
			if (typeof props.errorMsg === 'string') {
				setErrorMessage(props.errorMsg)
			} else if (props.errorMsg?.response?.data) {
				setErrorMessage(props.errorMsg?.response?.data)
			}
		} else setErrorMessage('')
	}, [props.errorMsg])

	useEffect(() => {
		setErrorMessage('')
	}, [email, password])

	return (
		<div
			style={{
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				gap: '0.938rem',
				alignItems: 'center',
			}}
		>
			<InputFieldText
				errormessage={errorMessage ? errorMessage : undefined}
				onChange={(e: string) => setEmail(e)}
				type='text'
				label='Email'
				value={email}
				style={{ width: '100%' }}
				onKeyDown={handleKeyDown}
			/>
			<InputFieldText
				errormessage={errorMessage ? errorMessage : undefined}
				onChange={(e: string) => setPassword(e)}
				type='password'
				label='Password'
				value={password}
				style={{ width: '100%' }}
				viewpasswordicon={errorMessage ? false : true}
				onKeyDown={handleKeyDown}
			/>
			<div
				style={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'flex-end',
				}}
			>
				<button
					onClick={() => props.setCurrentView('resetPassword')}
					style={{
						color: theme.colors.text.colored,
						cursor: 'pointer',
						border: 'none',
						fontSize: theme.font.size.caption,
					}}
				>
					Forgot Password
				</button>
			</div>
			<SubmitButton
				disabled={!email || !password}
				onClick={handleLogin}
				style={{
					width: '101%',
					borderRadius: '4px',
					marginLeft: 16,
				}}
			>
				Log In
			</SubmitButton>
		</div>
	)
}

export default Login
