import { ActionType } from '../action-types/index'
import { Action } from '../actions'

export interface presearchReducerInterface {
	presearch: string
}

const initialState: presearchReducerInterface = {
	presearch: '',
}

const reducer = (
	state: presearchReducerInterface | any = initialState,
	action: Action
): presearchReducerInterface => {
	switch (action.type) {
	case ActionType.PRESEARCH:
		return action.payload
	default:
		return state
	}
}

export default reducer
