import {
	Dialog,
	DialogContent,
	DialogContentText,
	DialogActions,
	useTheme,
} from '@mui/material'
import React from 'react'
import CheckIcon from '../../assets/svg/checkIcon'
import { SubmitButton } from '../../pages/components/Buttons'

export const SuccessPrompt = ({
	PopUpVisible,
	setPopUpVisible,
	message,
	setMessage,
}: {
	PopUpVisible: boolean
	setPopUpVisible: any
	message: string
	setMessage: any
	icon?: any
}) => {
	const theme = useTheme()
	return (
		<Dialog open={PopUpVisible}>
			<div
				style={{
					border: '1px solid ' + theme.colors.base.grey300,
					borderRadius: '15px',
					padding: 0,
				}}
			>
				<DialogContent
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						// width: 400,
						color: theme.colors.text.titles,
						padding: 20,
					}}
				>
					<CheckIcon />
					<DialogContentText
						style={{
							display: 'flex',
							wordBreak: 'break-word',
							color: theme.colors.text.titles,
						}}
					>
						{message.toString()}
					</DialogContentText>
				</DialogContent>
				<DialogActions
					style={{
						color: theme.colors.text.titles,
						paddingBottom: 30,
					}}
				>
					<SubmitButton
						style={{ margin: 'auto', width: 'auto' }}
						onClick={() => {
							setPopUpVisible(false)
							setMessage(undefined)
						}}
					>
						OK
					</SubmitButton>
				</DialogActions>
			</div>
		</Dialog>
	)
}
