import { SalesPlanApp } from "../../models/model.interface"
import { ActionType } from "../action-types"
import { Action } from "../actions"


export interface SalesPlanReducer {
    apps: SalesPlanApp[] | any
}

const initialState = {
    apps: []
}

const reducer = (
    state: SalesPlanReducer = initialState,
    action: Action | any,
): SalesPlanReducer => {
    if (action.type === ActionType.GET_SALES_PLAN_APPS) {
        return {
            ...state,
            apps: action.payload
        }
    }
    else return state
}

export default reducer