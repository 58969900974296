import { Grid, useTheme } from '@mui/material'
import React from 'react'
import { DropList, OwnerSelect } from '../components/SelectableInputs'
import { InputFieldText, StyledTextAreaField } from '../components/Inputs'
import { TitleComponent } from '../components/Informative'

const AdvertiserMetaEdit = (props: {
	permissions: any
	handlePastOwner: any
	handlePastOwnerFinance: any
	settings: any
	ownerEmail: string | null
	setOwnerEmail: any
	salesRep: any
	setSalesRep: any
	status: boolean
	setStatus: any
	setStatusChanged: any
	pauseReason?: string
	setPauseReason?: (value: string) => void
	pauseExplanation?: string
	setPauseExplanation?: (value: string) => void
	ViewPauseBox?: boolean
	setViewPauseBox?: (value: boolean) => void
	paymentTerms: string
	setPaymentTerms: any
	vat: string
	setVat: any
	branch: any
	setBranch: any
	invoiceInstructions: string
	setInvoiceInstructions: any
	fieldValidations: any
}) => {
	const permissions = props.permissions
	const theme = useTheme()
	return (
		<Grid
			container
			columns={12}
			columnSpacing={0}
			rowSpacing={0}
			direction='row'
			style={{ width: '100%', gap: 20, marginBottom: 20 }}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					width: '100%',
					gap: 12,
				}}
			>
				<TitleComponent
					style={{ fontSize: '14px', color: theme.colors.text.titles }}
				>
					Company Info
				</TitleComponent>
				<Grid
					container
					columns={12}
					columnSpacing={6}
					rowSpacing={4}
					direction='row'
					style={{ width: '98%' }}
				>
					<Grid item xs={3}>
						<OwnerSelect
							onChange={(e: any) => {
								props.handlePastOwner(e)
								props.handlePastOwnerFinance(e)
								props.setOwnerEmail?.(e)
							}}
							label={'Owner'}
							value={props.ownerEmail}
							disabled={!permissions.edit}
							style={{ background: theme.colors.base.white }}
						/>
					</Grid>
					<Grid item xs={3}>
						<DropList
							onChange={(e: any) => props.setSalesRep?.(e)}
							options={props.settings?.settings?.salesRepresentative || []}
							label={'Sales Representative'}
							value={props.salesRep || ''}
							disabled={!permissions.edit}
							noNull={true}
						/>
					</Grid>
					<Grid item xs={3}>
						<DropList
							onChange={(e: any) => {
								props.setStatus?.(e === 'Active')
								props.setStatusChanged?.(true)
							}}
							options={['Active', 'Disabled']}
							label={'Status'}
							value={props.status === true ? 'Active' : 'Disabled'}
							disabled={!permissions.edit}
						/>
					</Grid>
					{props.status === false ? (
						<>
							<Grid item xs={3}>
								<DropList
									onChange={(e: any) => props.setPauseReason?.(e)}
									options={props.settings?.settings?.pauseReason?.sort() || []}
									label={'Pause Reason'}
									value={props.pauseReason || ''}
									multiple={false}
									disabled={!permissions.edit}
									noNull={true}
								/>
							</Grid>
							<Grid item xs={6}>
								<InputFieldText
									label='Explanation'
									type='text'
									value={props.pauseExplanation || ''}
									onChange={(e: string) => props.setPauseExplanation?.(e)}
									disabled={!permissions.edit}
									style={{ width: '100%' }}
								/>
							</Grid>
						</>
					) : (
						<Grid item xs={6} style={{ minHeight: 89 }} />
					)}
				</Grid>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					width: '100%',
					gap: 12,
				}}
			>
				<TitleComponent
					style={{ fontSize: '14px', color: theme.colors.text.titles }}
				>
					Finance
				</TitleComponent>
				<Grid
					container
					columns={12}
					columnSpacing={6}
					rowSpacing={4}
					direction='row'
					style={{ width: '98%' }}
				>
					<Grid item xs={4}>
						<DropList
							onChange={(e: any) => {
								props.setBranch(e)
							}}
							options={props.settings.settings.branches}
							label={'Internal Legal Entity'}
							placeholder={'Internal Legal Entity'}
							value={props.branch}
							errored={!props.branch}
							mandatory={!props.fieldValidations.branch}
							disabled={!permissions.edit}
						/>
					</Grid>
					<Grid item xs={4}>
						<DropList
							onChange={(e: string) => props.setPaymentTerms(e)}
							options={
								props.settings.settings.paymentTerms
									? [
											'Prepayment',
											...props.settings.settings.paymentTerms,
									  ].sort()
									: []
							}
							label={'Payment Term'}
							placeholder={'Payment Term'}
							value={props.paymentTerms}
							errored={!props.paymentTerms}
							mandatory={!props.fieldValidations.paymentTerms}
							disabled={!permissions.edit}
						/>
					</Grid>

					<Grid item xs={4}>
						<DropList
							onChange={(e: string) => props.setVat(e)}
							options={
								props.settings.settings.vatValues
									? props.settings.settings.vatValues.sort()
									: []
							}
							label={'VAT (%)'}
							placeholder={'VAT (%)'}
							value={props.vat}
							disabled={!permissions.edit}
						/>
					</Grid>
					<Grid item xs={12}>
						<StyledTextAreaField
							label='Invoice Instructions'
							value={props.invoiceInstructions}
							onChange={props.setInvoiceInstructions}
							disabled={!permissions.edit}
						/>
					</Grid>
				</Grid>
			</div>
		</Grid>
	)
}

export default AdvertiserMetaEdit
