import { useTheme } from '@mui/material'
import React from 'react'
import coloredLogo from '../../assets/varys-logo-colored.png'
import hero from '../../assets/locked-out-hero.png'
import maintenanceHero from '../../assets/svg/hero-maintenance2.png'

interface StatusPagesCommonLayoutProps {
	title?: string
	description?: string
	children?: React.ReactNode
	maintenance?: boolean
}

const StatusPagesCommonLayout: React.FC<StatusPagesCommonLayoutProps> = ({
	title,
	description,
	children,
	maintenance,
}) => {
	const theme = useTheme()
	const isDarkMode = theme.palette.mode === 'dark'

	return (
		<div
			style={{
				display: 'flex',
				height: '100vh',
			}}
		>
			{/* Left side - gradient + Image container */}
			<div
				style={{
					flex: 1,
					backgroundImage: theme.colors.gradientLoginPage,
					backgroundSize: 'cover',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<div
					style={{
						width: '50%',
						height: '50%',
					}}
				>
					<img
						src={maintenance ? maintenanceHero : hero}
						alt='secondary-hero'
						style={{
							width: '100%',
							height: '100%',
							objectFit: 'contain',
						}}
					/>
				</div>
			</div>

			{/* Right side - component */}
			<div
				style={{
					flex: 1,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					backgroundColor: theme.colors.base.white,
					color: theme.colors.base.grey900,
				}}
			>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						position: 'absolute',
						top: '5%',
						left: '88%',
						transition: 'left 0.5s ease-in-out',
					}}
				>
					<div style={{ width: '3.75rem' }}>
						<img src={coloredLogo} alt='logo' style={{ width: '100%' }} />
					</div>
					<span
						style={{
							fontSize: '1.75rem',
							color: isDarkMode ? theme.colors.white : theme.colors.black,
						}}
					>
						Varys
					</span>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						gap: '10px',
					}}
				>
					{title && (
						<h1
							style={{
								color: isDarkMode ? theme.colors.white : theme.colors.black,
								fontSize: '46px',
								fontWeight: '600',
								fontFamily: 'Lato',
							}}
						>
							{title}
						</h1>
					)}
					{description && (
						<p
							style={{
								color: isDarkMode ? theme.colors.white : theme.colors.black,
								fontSize: '20px',
								fontWeight: '400',
								fontFamily: 'Lato',
							}}
						>
							{description}
						</p>
					)}
					{children}
				</div>
			</div>
		</div>
	)
}

export default StatusPagesCommonLayout
