import React, { useEffect, useState } from 'react'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { Checkbox, useTheme } from '@mui/material'
import { ChipInputList } from '../components/ChipInput'
interface Props {
	previewApps: any
}

export const AppFilter: React.FC<Props> = ({ previewApps }) => {
	const theme = useTheme()
	const { apps } = useTypedSelector((state) => state.salesplan)

	const devicesOptions = Array.from(new Set(apps.flatMap((app: any) => app.os)))
	const [isAgeCompliance, setIsAgeCompliance] = useState<boolean>(false)
	const verticalsOptions = Array.from(
		new Set(apps.flatMap((app: any) => app.vertical)),
	)
	const geosOptions = Array.from(new Set(apps.flatMap((app: any) => app.geo)))

	const [selectedDevices, setSelectedDevices] = useState<string[]>(
		[] as string[],
	)
	const [selectedVerticals, setSelectedVerticals] = useState<string[]>(
		[] as string[],
	)
	const [selectedGeos, setSelectedGeos] = useState<string[]>([] as string[])
	const [filteredApps, setFilteredApps] = useState<any[]>([])

	useEffect(() => {
		setFilteredApps(filterApps())
	}, [selectedDevices, selectedGeos, selectedVerticals, isAgeCompliance])

	const filterApps = () => {
		let filtered = [...apps]
		if (selectedDevices.length > 0) {
			filtered = filtered.filter((app) =>
				selectedDevices.some((d) => app.os.includes(d)),
			)
		}

		if (selectedVerticals.length > 0) {
			filtered = filtered.filter((app) =>
				selectedVerticals.some((v) => app.vertical.includes(v)),
			)
		}

		if (selectedGeos.length > 0) {
			filtered = filtered.filter((app) =>
				selectedGeos.some((g) => app.geo.includes(g)),
			)
		}
		if (isAgeCompliance) {
			filtered = filtered.filter((app) => app.age_compliance)
		}
		previewApps(filtered)
		return filtered
	}

	const updateApps = (selectedNames: string[]) => {
		const updatedFilteredApps = apps.filter((app: any) =>
			selectedNames.includes(app.app_name),
		)
		previewApps(updatedFilteredApps)
		setFilteredApps(updatedFilteredApps)
	}

	return (
		<div
			style={{
				display: 'flex',
				width: '100%',
				justifyItems: 'center',
				alignItems: 'center',
				gap: '100px',
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '45px',
					justifyContent: 'space-between',
					width: '45%',
				}}
			>
				<ChipInputList
					label={'Device'}
					options={devicesOptions}
					openWithClick
					onChange={(e: string[]) => setSelectedDevices(e)}
					onlyOptionsAllowed
					singular
					// inputStyle={{ borderRadius: '14px' }}
					// style={{ maxHeight: '90px', overflow: 'scroll' }}
					ShowSelected
					seeAll={false}
				/>
				<ChipInputList
					label={'Verticals'}
					options={verticalsOptions}
					openWithClick
					onChange={(e: string[]) => setSelectedVerticals(e)}
					onlyOptionsAllowed
					singular
					// inputStyle={{ borderRadius: '14px' }}
					// style={{ maxHeight: '90px', overflow: 'scroll' }}
					ShowSelected
				/>
				<ChipInputList
					label={'Geos'}
					options={geosOptions}
					openWithClick
					onChange={(e: string[]) => setSelectedGeos(e)}
					onlyOptionsAllowed
					singular
					// inputStyle={{ borderRadius: '14px' }}
					// style={{ maxHeight: '90px', overflow: 'scroll' }}
					ShowSelected
				/>
				<div
					style={{
						display: 'flex',
						gap: '4px',
						alignItems: 'center',
						marginBlockStart: '-30px',
					}}
				>
					<Checkbox
						style={{ marginRight: '-10px' }}
						checked={isAgeCompliance}
						key={'hi'}
						onChange={() => setIsAgeCompliance(!isAgeCompliance)}
						inputProps={{
							'aria-label': 'controlled',
						}}
						icon={
							<CheckBoxOutlineBlankIcon
								style={{ scale: '0.8', color: theme.colors.text.titles }}
							/>
						}
						checkedIcon={
							<CheckBoxIcon
								style={{ scale: '0.8', color: theme.colors.base.green300 }}
							/>
						}
					/>
					<span
						style={{
							color: theme.colors.base.grey600,
							fontWeight: theme.font.weight.normal,
							fontSize: theme.font.size.caption,
						}}
					>
						Age Compliance
					</span>
				</div>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					width: '36%',
					marginBlockStart: '-125px',
				}}
			>
				<span
					style={{
						color: theme.colors.text.titles,
						opacity: '0.4',
						height: '30px',
						fontSize: '16px',
						marginInlineStart: '10px',
					}}
				>
					The following Apps are relevant for you:
				</span>
				<div
					style={{
						overflowY: 'scroll',
						height: '300px',
						width: '500px',
						border: '1px solid #D1D3D3',
						borderRadius: '2.2rem',
					}}
				>
					<ChipInputList
						value={filteredApps.map((app) => app.app_name)}
						options={apps.map((app: any) => app.app_name)}
						openWithClick={true}
						ShowSelected
						onChange={(e: any) => updateApps(e)}
						onlyOptionsAllowed={true}
						inputStyle={{
							marginBlockStart: '-4px',
							background: theme.colors.base.white,
						}}
						chipStyle={{
							background: '#fff',
						}}
					/>
				</div>
			</div>
		</div>
	)
}
