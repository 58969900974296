import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
	Grid,
	ThemeProvider,
	DialogActions,
	useTheme,
	Box,
	Typography,
} from '@mui/material'
import { DropList, CountryListSelector } from '../components/SelectableInputs'
// import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { PageWrapper } from '../components/reusableComponents'
import { SubmitButton, UnborderedButton } from '../components/Buttons'
import CompanyInformationHeader from '../components/CompanyInformationHeader'
import EditPageHeader from '../components/EditPageHeader'
import CreatorCompanyInfoComponent from './CreatorCompanyInfoComponent'
import defaultIcon from '../../assets/default-icon.webp'
import { InputFieldText } from '../components/Inputs'
import { ChipInputList } from '../components/ChipInput'
import { countryList } from '../components/countryList'
import CustomModal from '../components/CustomModal'
import CustomFileUploader from '../components/CustomFileUploader'
import CustomDialog from '../components/CustomDialog'
import { useActions } from '../../hooks/useActions'
import {
	ArrowBack,
	ArrowBackIosOutlined,
	CheckCircle,
	FileUploadOutlined,
} from '@mui/icons-material'
import { ACCEPTED_FILES } from '../../config'
import HorizontalLine from '../components/HorizontalLine'

interface Capability {
	uuid: string
	type: string
	gender: { male: number; female: number }
	age: {
		age13to17: number
		age18to24: number
		age25to34: number
		age35to44: number
		age45to54: number
		age55toInfinity: number
	}
	geo: { country: string; value: number }[]
	engagement_rate: number
	rate_per_video: number
	vertical: string[]
	notes: string
}

interface CapabilitiesInfoContainerProps {
	capabilities: Partial<Capability>
	onUpdate: (updatedCapabilities: Partial<Capability>) => void
}

const CapabilitiesInfoContainer: React.FC<CapabilitiesInfoContainerProps> = ({
	capabilities = {},
	onUpdate,
}) => {
	const theme = useTheme()
	const { settings } = useTypedSelector((state) => state)

	const typeOptions = ['Instagram', 'TikTok', 'YouTube', 'Twitter', 'Facebook']
	const geoFields = Array(4).fill(null)

	const handleChange = (field: keyof Capability, value: any) => {
		onUpdate({ ...capabilities, [field]: value })
	}

	const handleGenderChange = (gender: 'male' | 'female', value: number) => {
		const newGender = { ...capabilities.gender, [gender]: value }
		handleChange('gender', newGender)
	}

	const handleAgeChange = (
		ageGroup: keyof Capability['age'],
		value: number,
	) => {
		const newAge = { ...capabilities.age, [ageGroup]: value }
		handleChange('age', newAge)
	}

	const handleGeoChange = (
		index: number,
		field: 'country' | 'value',
		value: any,
	) => {
		const newGeo = [...(capabilities.geo || [])]
		while (newGeo.length < 5) {
			// Ensure we always have 5 geo entries
			newGeo.push({ country: '', value: 0 })
		}
		newGeo[index] = {
			...newGeo[index],
			[field]: field === 'value' ? Number(value) : value,
		}
		onUpdate({ ...capabilities, geo: newGeo })
	}

	return (
		<React.Fragment key={capabilities.uuid || 'new'}>
			<Grid
				container
				spacing={2}
				style={{
					fontSize: theme.font.size.body,
					marginTop: '20px',
				}}
			>
				<Grid item xs={12} md={6} lg={4}>
					<DropList
						label='Type'
						options={typeOptions}
						value={capabilities.type}
						onChange={(value: any) => {
							handleChange('type', value)
						}}
					/>
				</Grid>

				<Grid item xs={12} md={6} lg={4}>
					<InputFieldText
						label='Male %'
						type='number'
						value={capabilities.gender?.male || ''}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							handleGenderChange('male', Number(e))
						}
						fullWidth
					/>
				</Grid>
				<Grid item xs={12} md={6} lg={4}>
					<InputFieldText
						label='Female %'
						type='number'
						value={capabilities.gender?.female || ''}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							handleGenderChange('female', Number(e))
						}
						fullWidth
					/>
				</Grid>
				{Object.entries(capabilities.age || {}).map(([key, value]) => (
					<Grid item xs={12} md={6} lg={4} key={key}>
						<InputFieldText
							label={`Age ${key
								.replace('age', '')
								.replace('to', '-')
								.replace('Infinity', '+')} %`}
							type='number'
							value={value || ''}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								handleAgeChange(key as keyof Capability['age'], Number(e))
							}
							fullWidth
						/>
					</Grid>
				))}
				{geoFields.map((_, index) => (
					<React.Fragment key={index}>
						<Grid item xs={12} md={6} lg={4}>
							<CountryListSelector
								onChange={(value: any) =>
									handleGeoChange(index, 'country', value[0])
								}
								value={
									capabilities.geo?.[index]?.country
										? [capabilities.geo[index].country]
										: []
								}
								label={`Geo ${index + 1}`}
								options={countryList}
								singular
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={4}>
							<InputFieldText
								label={`Geo ${index + 1} %`}
								type='number'
								value={capabilities.geo?.[index]?.value || ''}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
									handleGeoChange(index, 'value', e)
								}
								fullWidth
							/>
						</Grid>
					</React.Fragment>
				))}
				<Grid item xs={12} md={6} lg={4}>
					<InputFieldText
						label='Engagement Rate %'
						type='number'
						value={capabilities.engagement_rate || ''}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							handleChange('engagement_rate', Number(e))
						}
						fullWidth
					/>
				</Grid>
				<Grid item xs={12} md={6} lg={4}>
					<InputFieldText
						label='Rate Per Video %'
						type='number'
						value={capabilities.rate_per_video || ''}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							handleChange('rate_per_video', Number(e))
						}
						fullWidth
					/>
				</Grid>
				<Grid item xs={12} md={6} lg={4}>
					<ChipInputList
						label='Vertical'
						value={capabilities.vertical || []}
						onChange={(value: string[]) => handleChange('vertical', value)}
						options={settings.settings.vertical}
					/>
				</Grid>
				<Grid item xs={12} md={6} lg={4}>
					<InputFieldText
						type='string'
						label='Notes'
						value={capabilities.notes || ''}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							handleChange('notes', e)
						}
						multiline
					/>
				</Grid>
			</Grid>
			<HorizontalLine />
		</React.Fragment>
	)
}

const CreatorCompanyEdit = (props: {
	setLoading: (loading: boolean) => void
	setPopUpVisible: (visible: boolean) => void
	companyData: any
	onSave: (data: any) => void
	setErrorMessage?: any
}) => {
	const theme = useTheme()

	const { uploadIcon, deleteIcon } = useActions()
	// const { insertCreatorAction } = useActions()
	const navigate = useNavigate()
	// const { login } = useTypedSelector((state) => state)

	const [name, setName] = useState(props.companyData.name)
	const [userName, setUserName] = useState(props.companyData.username)
	const [campaign, setCampaign] = useState(props.companyData.campaign)
	const [type, setType] = useState(props.companyData.type)
	const [email, setEmail] = useState(props.companyData.email)
	const [gender, setGender] = useState(props.companyData.gender)
	const [geo, setGeo] = useState(props.companyData.geo)
	const [portfolio, setPortfolio] = useState(props.companyData.portfolio)
	const [address, setAddress] = useState(props.companyData.address)
	const [status, setStatus] = useState(props.companyData.status)
	const [instagram, setInstagram] = useState(props.companyData.instagram)
	const [tiktok, setTiktok] = useState(props.companyData.tiktok)
	const [youtube, setYoutube] = useState(props.companyData.youtube)
	const [linkedin, setLinkedin] = useState(props.companyData.linkedin)
	const [fiverr, setFiverr] = useState(props.companyData.fiverr)
	const [hasAgent, setHasAgent] = useState(props.companyData.has_agent)
	const [capabilities, setCapabilities] = useState(
		props.companyData.capabilities,
	)

	const [iconUrl, setIconUrl] = useState<string>(
		props.companyData.creator_icon || defaultIcon,
	)

	const [isUploadModalOpen, setIsUploadModalOpen] = useState(false)

	const [uploadStatus, setUploadStatus] = useState<{
		message: string
		success: boolean
	} | null>(null)
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

	const [isValid, setIsValid] = useState(true)
	const isDarkMode = theme.palette.mode === 'dark'

	// console.log(props.companyData)

	useEffect(() => {
		setIsValid(true)
	}, [name, userName, type, email, gender, geo, address])

	const handleSubmit = async () => {
		if (isValid) {
			const payload = {
				name,
				username: userName,
				campaign,
				type,
				email,
				gender,
				geo,
				portfolio,
				address,
				status,
				instagram,
				tiktok,
				youtube,
				linkedin,
				fiverr,
				has_agent: hasAgent,
				creator_id: props.companyData.id, // we should remove this once creator_id is removed
				capabilities,
			}

			props.setLoading(true)
			try {
				// console.log(payload)
				props.onSave(payload)
				// await insertCreatorAction(payload, ()=> {})
				// // props.onSave(payload)
				// navigate('/companySettings')
			} catch (error) {
				console.error('Error updating creator:', error)
			} finally {
				props.setLoading(false)
			}
		}
	}

	const closeDetailedView = () => {
		navigate('/companySettings')
		props.setPopUpVisible(false)
	}

	// Handlers for icon upload functionality
	const handleOpenUploadModal = () => setIsUploadModalOpen(true)

	const handleCloseUploadModal = () => setIsUploadModalOpen(false)

	const handleIconClick = () => {
		if (iconUrl && iconUrl !== defaultIcon) {
			setIsDeleteModalOpen(true)
		}
	}

	const handleCloseDeleteModal = () => {
		setIsDeleteModalOpen(false)
	}

	const handleDeleteIcon = () => {
		if (iconUrl !== defaultIcon) {
			deleteIcon('CREATOR', props.companyData.name, props.companyData.id)
			setIconUrl(defaultIcon)
		}
		setIsDeleteModalOpen(false)
	}

	const handleFileUpload = async (files: File[]) => {
		const creatorId = props.companyData.id
		if (!creatorId) {
			props.setErrorMessage(
				'Please save the creator first before uploading an icon.',
			)
			return
		}

		if (files.length > 0) {
			const file = files[0]
			if (!isValidImageType(file)) {
				props.setErrorMessage(
					'Unsupported file type. Please upload JPEG, PNG, or SVG files.',
				)
				return
			}

			const uploadResult = await uploadIcon(
				'CREATOR',
				file,
				props.companyData.name || name,
				creatorId,
			)
			setIconUrl(uploadResult.toString())
		}
	}

	const handleUploadSuccess = (filename: string) => {
		setUploadStatus({
			message: `${filename} uploaded successfully`,
			success: true,
		})
		setTimeout(() => {
			setIsUploadModalOpen(false)
			setUploadStatus(null)
		}, 3000)
	}

	const handleUploadError = (filename: string) => {
		setUploadStatus({ message: `Error uploading ${filename}`, success: false })
		setTimeout(() => {
			setIsUploadModalOpen(false)
			setUploadStatus(null)
		}, 3000)
	}

	const isValidImageType = (file: File) => {
		const validTypes = ['image/jpeg', 'image/png', 'image/svg+xml']
		return validTypes.includes(file.type)
	}

	return (
		<ThemeProvider theme={theme}>
			<EditPageHeader
				onBack={closeDetailedView}
				showBackButton={false}
				showUploadButton={false}
				tabValue={0}
				handleChangeTab={() => {}}
				hasPreview={false}
				options={[{ label: 'Company Information', tabIndex: 0 }]}
				arialabel='creator edit tabs'
				customStyles={{
					display: 'flex',
					justifyContent: 'center',
					padding: '1rem',
				}}
			/>

			{/* Back Button and Upload Icon Button */}
			<Grid
				container
				justifyContent='space-between' // Align items to the left
				alignItems='center'
				style={{ paddingLeft: '8vh', paddingRight: '8vh' }}
			>
				{/* Back Button */}
				<Grid item>
					<UnborderedButton
						onClick={closeDetailedView}
						icon={<ArrowBackIosOutlined />}
						label='Back'
					/>
				</Grid>
				{/* Upload Icon Button */}
				<Grid item style={{ marginLeft: '20px' }}>
					<UnborderedButton
						onClick={handleOpenUploadModal}
						icon={<FileUploadOutlined />}
						label='Upload Icon'
					/>
				</Grid>
			</Grid>

			{/* Creator's Name and Icon */}
			<Grid
				container
				alignItems='center'
				style={{ paddingLeft: '8vh', marginTop: '20px' }}
			>
				<Grid item>
					<CompanyInformationHeader
						name={name}
						iconUrl={iconUrl || defaultIcon}
						onClickIcon={
							iconUrl !== defaultIcon && iconUrl ? handleIconClick : undefined
						}
						tooltipText={
							iconUrl === defaultIcon || !iconUrl
								? 'No icon to delete'
								: 'Click to delete icon'
						}
						cursorStyle={
							iconUrl === defaultIcon || !iconUrl ? 'default' : 'pointer'
						}
					/>
				</Grid>
			</Grid>

			{/* Modals */}
			<CustomModal
				title='Upload Icon'
				open={isUploadModalOpen}
				onClose={handleCloseUploadModal}
			>
				{uploadStatus ? (
					<Box
						display='flex'
						flexDirection='column'
						alignItems='center'
						justifyContent='center'
						p={2}
					>
						<Typography variant='subtitle1'>{uploadStatus.message}</Typography>
						<CheckCircle style={{ color: 'green', fontSize: 40 }} />
					</Box>
				) : (
					<CustomFileUploader
						onSetFiles={handleFileUpload}
						onSuccess={handleUploadSuccess}
						onError={handleUploadError}
						acceptedFiles={ACCEPTED_FILES.IMAGES_ONLY}
					/>
				)}
			</CustomModal>
			<CustomDialog
				open={isDeleteModalOpen}
				onClose={handleCloseDeleteModal}
				onConfirm={handleDeleteIcon}
				imageUrl={iconUrl || defaultIcon}
				message='Click delete to remove the icon.'
			/>

			{/* Main Content */}
			<PageWrapper
				style={{
					margin: '2vh 8vh',
					maxHeight: '200%',
					overflow: 'visible',
					height: 'fit-content',
				}}
			>
				<CreatorCompanyInfoComponent
					name={name}
					setName={setName}
					userName={userName}
					setUserName={setUserName}
					campaign={campaign}
					setCampaign={setCampaign}
					type={type}
					setType={setType}
					email={email}
					setEmail={setEmail}
					gender={gender}
					setGender={setGender}
					geo={geo}
					setGeo={setGeo}
					portfolio={portfolio}
					setPortfolio={setPortfolio}
					address={address}
					setAddress={setAddress}
					instagram={instagram}
					setInstagram={setInstagram}
					tiktok={tiktok}
					setTiktok={setTiktok}
					youtube={youtube}
					setYoutube={setYoutube}
					linkedin={linkedin}
					setLinkedin={setLinkedin}
					fiverr={fiverr}
					setFiverr={setFiverr}
					hasAgent={hasAgent}
					setHasAgent={setHasAgent}
				/>
				<HorizontalLine />

				{/* Capabilities Section */}
				<Typography
					variant='h5'
					style={{
						fontSize: theme.font.size.h3,
						color: isDarkMode ? theme.colors.white : theme.colors.black,
						marginTop: '40px',
						marginBottom: '20px',
					}}
				>
					Capabilities
				</Typography>
				<CapabilitiesInfoContainer
					capabilities={capabilities}
					onUpdate={setCapabilities}
				/>

				{/* Action Buttons */}
				<DialogActions
					style={{
						paddingBottom: '80px',
						display: 'flex',
						justifyContent: 'center',
						gap: 160,
					}}
				>
					<UnborderedButton onClick={closeDetailedView} label='Cancel' />
					<SubmitButton disabled={!isValid} onClick={handleSubmit}>
						Update
					</SubmitButton>
				</DialogActions>
			</PageWrapper>
		</ThemeProvider>
	)
}

export default CreatorCompanyEdit
