import { AdvertiserInterface } from '../../models/model.interface'
import { ActionType } from '../action-types/index'
import { Action } from '../actions'

export interface AdevertiserReducerInterface {
	advertiser: AdvertiserInterface[] | any
}

const initialState = {
	advertiser: [],
}

const reducer = (
	state: AdevertiserReducerInterface = initialState,
	action: Action | any,
): AdevertiserReducerInterface => {
	switch (action.type) {
		case ActionType.GET_ADVERTISER:
			return {
				...state,
				advertiser: action.payload,
			}
		case ActionType.GET_ADVERTISER_REPORT:
			return {
				...state,
				advertiser: action.payload,
			}
		case ActionType.GET_ADVERTISER_CONTACTS:
			return {
				...state,
				advertiser: action.payload,
			}
		case ActionType.INSERT_ADVERTISER: {
			const newState = []
			for (const row of state.advertiser) {
				if (row.advertiser_name === action.payload.advertiser_name) {
					newState.push(action.payload)
				} else {
					newState.push(row)
				}
			}
			return {
				advertiser: newState,
			}
		}
		case ActionType.INSERT_ADVERTISER_META: {
			const newState = []
			for (const row of state.advertiser) {
				if (row.id === action.payload.id) {
					const newRow = { ...row, ...action.payload }
					newState.push(newRow)
				} else {
					newState.push(row)
				}
			}
			return {
				advertiser: newState,
			}
		}
		case ActionType.DELETE_ADVERTISER: {
			const newState = []
			for (const row of state.advertiser) {
				if (row.advertiser_name !== action.payload.advertiser_name) {
					newState.push(row)
				}
			}
			return {
				advertiser: newState,
			}
		}
		case ActionType.DELETE_ADVERTISER_ICON:
			return {
				...state,
				advertiser: state.advertiser.map((ad: AdvertiserInterface) => {
					if (ad.advertiser_name === action.payload.name) {
						return { ...ad, iconUrl: action.payload.iconUrl }
					}
					return ad
				}),
			}

		case ActionType.UPDATE_ADVERTISER_ICON:
			return {
				...state,
				advertiser: state.advertiser.map((ad: AdvertiserInterface) =>
					ad.advertiser_name === action.payload.name
						? { ...ad, iconUrl: action.payload.iconUrl }
						: ad,
				),
			}

		case ActionType.LOGOUT:
			return {
				...state,
				advertiser: [],
			}
		default:
			return state
	}
}

export default reducer
