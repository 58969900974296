import { Dispatch } from 'redux'
import { ActionType } from '../action-types'
import { Action } from '../actions/index'
import AxiosCustom from '../../utils/Axios'
import { permissionNames } from '../../utils/helpers/permissionsHelper'
import { endpoints } from '../../config'
import { PublisherInterface } from '../../models/model.interface'

export const getPublisherAction = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.PUBLISHER, {
				headers: {
					'x-permission-name': permissionNames.VIEW_PUBLISHER,
				},
			})
			if (!data.successful) {
				return
			}

			// No decryption here, just dispatch the data as it is
			dispatch({
				type: ActionType.GET_PUBLISER,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

export const insertPublisherAction = (
	// headers: any,
	obj: PublisherInterface,
	handshakeArr: any,
	setErrorMessage: any,
	navigateToNewPublisher: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const publisher = { publisher: obj, handshake: handshakeArr }
			const { data } = await AxiosCustom.post(endpoints.PUBLISHER, publisher, {
				headers: {
					'x-permission-name': permissionNames.ADD_EDIT_PUBLISHER,
				},
			})
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}
			const newPayload = { ...obj, id: data.payload.id }
			dispatch({
				type: ActionType.INSERT_PUBLIHSER,
				payload: newPayload,
			})
			navigateToNewPublisher(data.payload.id)
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}

export const getPublisherReport = (
	setDownloadFile: any,
	rows: any,
	skypeGroupUserAllowed: boolean,
	// headers: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const publisher = {
				publisher: {
					skype_private_allowed: skypeGroupUserAllowed,
					report: rows,
				},
			}
			const { data } = await AxiosCustom.post(
				endpoints.PUBLISHER + '/report',
				publisher,
				{
					headers: {
						'x-permission-name': permissionNames.DOWNLOAD_PUBLISHERS_LIST,
					},
				},
			)
			if (!data.successful) {
				return
			}
			dispatch({
				type: ActionType.GET_PUBLISHER_REPORT,
				payload: data.payload,
			})
			setDownloadFile(data.payload)
		} catch (err) {
			console.error(err)
		}
	}
}

export const getPublisherReportPid = (
	setDownloadFile: any,
	rows: any,
	skypeGroupUserAllowed: boolean,
	// headers: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const publisher = {
				publisher: {
					skype_private_allowed: skypeGroupUserAllowed,
					report: rows,
				},
			}
			const { data } = await AxiosCustom.post(
				endpoints.PUBLISHER + '/reportpid',
				publisher,
				{
					headers: {
						'x-permission-name': permissionNames.DOWNLOAD_PUBLISHERS_LIST,
					},
				},
			)
			if (!data.successful) {
				return
			}
			dispatch({
				type: ActionType.GET_PUBLISHER_REPORT,
				payload: data.payload,
			})
			setDownloadFile(data.payload)
		} catch (err) {
			console.error(err)
		}
	}
}

export const getPublisherContacts = (setDownloadFile: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(
				endpoints.PUBLISHER + '/contacts',
				{
					headers: {
						'x-permission-name': permissionNames.VIEW_PUBLISHER_CONTACTS,
					},
				},
			)
			if (!data.successful) {
				return
			}
			// dispatch({
			// 	type: ActionType.GET_PUBLISHER_CONTACTS,
			// 	payload: data.payload,
			// })
			setDownloadFile(data.payload)
		} catch (err) {
			console.error(err)
		}
	}
}

export const updatePublisherAction = (
	// headers: any,
	obj: PublisherInterface,
	handshakeArr: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const objCopy = { ...obj }
			const publisher = { publisher: obj, handshake: handshakeArr }
			const { data } = await AxiosCustom.put(endpoints.PUBLISHER, publisher, {
				headers: {
					'x-permission-name': permissionNames.ADD_EDIT_PUBLISHER,
				},
			})
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}

			dispatch({
				type: ActionType.INSERT_PUBLIHSER,
				payload: objCopy,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}

export const getPasswordsByPublisherId = (
	id: number,
	setCurrentPasswords: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(
				endpoints.PUBLISHER + `/password/${id}`,
				{
					headers: {
						'x-permission-name': permissionNames.VIEW_PUBLISHER_PASSWORDS,
					},
				},
			)
			setCurrentPasswords(data)
			return data
		} catch (err) {
			console.error('err', err)
			setCurrentPasswords([])
			return []
		}
	}
}

export const updatePublisherMasterAction = (
	// headers: any,
	obj: PublisherInterface,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const objCopy = { ...obj }
			const publisher = { publisher: obj }
			const { data } = await AxiosCustom.put(
				endpoints.PUBLISHER_MASTER,
				publisher,
				{},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}

			dispatch({
				type: ActionType.INSERT_PUBLIHSER_MASTER,
				payload: objCopy,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}

export const updatePublisherMetaAction = (
	// headers: any,
	obj: PublisherInterface,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const objCopy = { ...obj }
			const publisher = { publisher: obj }
			const { data } = await AxiosCustom.put(
				endpoints.PUBLISHER_META,
				publisher,
				{},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}

			dispatch({
				type: ActionType.INSERT_PUBLIHSER_META,
				payload: objCopy,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}

export const deletePublisher = (
	// headers: any,
	obj: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.delete(endpoints.PUBLISHER, {
				data: {
					publisher: obj,
				},
				headers: {
					'x-permission-name': permissionNames.DELETE_PUBLISHER,
				},
			})
			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}

			// dispatch({
			// 	type: ActionType.DELETE_PUBLISHER,
			// 	payload: obj,
			// })
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}

export const getPublisherWishList = (setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.PUBLISHER_WISHLIST) // TODO: MATAN -> what is the permission name for wishlist
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}
			dispatch({
				type: ActionType.GETWISHLIST,
				payload: data.payload,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}

export const addPublisherWishList = (
	// headers: any,
	obj: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const dataToUpload = { data: obj }
			const { data } = await AxiosCustom.post(
				endpoints.PUBLISHER_WISHLIST,
				dataToUpload,
			)

			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}
			if (obj.uuid) {
				dispatch({
					type: ActionType.UPDATEWISHLIST,
					payload: obj,
				})
			} else {
				dispatch({
					type: ActionType.ADDWISHLIST,
					payload: obj,
				})
			}
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}

export const deletePublisherWishList = (
	// headers: any,
	obj: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.post(
				endpoints.PUBLISHER_WISHLIST_DELETE,
				obj,
			)

			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}
			dispatch({
				type: ActionType.DELETEWISHLIST,
				payload: obj,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}
