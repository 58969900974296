import React from 'react'
import { SubmitButton } from '../components/Buttons'
import success from '../../assets/success-icon.svg'
import { useTheme } from '@mui/material'

const SignUpFinished = (props: { setCurrentView?: any }) => {
	const theme = useTheme()
	const handleGoBack = () => {
		props.setCurrentView('login')
	}

	return (
		<>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					gap: '1.25rem',
				}}
			>
				<img
					style={{
						textAlign: 'center',
						width: '5.375rem',
						color: theme.colors.base.green200,
					}}
					src={success}
					alt='success'
				/>
				<span
					style={{
						textAlign: 'center',
						color: theme.colors.base.green200,
						fontSize: '4rem',
						fontWeight: theme.font.weight.bold,
					}}
				>
					Success!
				</span>
				<span style={{ color: theme.colors.base.grey900, textAlign: 'center' }}>
					we have received all of your information, we will review and our team
					will get back to you shortly.
				</span>
				<SubmitButton
					onClick={handleGoBack}
					style={{
						width: '11.75rem',
						borderRadius: '4px',
						marginTop: '1.25rem',
						background: theme.colors.base.green200,
						border: '2px solid ' + theme.colors.base.green200,
						textTransform: 'none',
						fontSize: theme.font.size.body,
						color: theme.colors.text.titles,
						'&.Mui-disabled': {
							background: theme.colors.base.grey100,
						},
						'&:hover': {
							background: theme.colors.base.green20,
							border: '2px solid ' + theme.colors.base.green200,
							color: theme.colors.base.grey900,
						},
						'&.Mui-focused': {
							background: theme.colors.base.green100,
						},
					}}
				>
					Back to home
				</SubmitButton>
			</div>
		</>
	)
}

export default SignUpFinished
