export const countryContinentList = [
	{
		value: 'Antartica',
		label: 'Antartica',
		children: [
			{
				value: 'AQ - Antarctica',
				label: 'AQ - Antarctica',
			},
			{
				value: 'BV - Bouvet Island',
				label: 'BV - Bouvet Island',
			},
			{
				value: 'GS - South Georgia and the South Sandwich Islands',
				label: 'GS - South Georgia and the South Sandwich Islands',
			},
			{
				value: 'HM - Heard Island and McDonald Islands',
				label: 'HM - Heard Island and McDonald Islands',
			},
			{
				value: 'TF - French Southern Territories',
				label: 'TF - French Southern Territories',
			},
		],
	},
	{
		value: 'Africa',
		label: 'Africa',
		children: [
			{
				value: 'AO - Angola',
				label: 'AO - Angola',
			},
			{
				value: 'BF - Burkina Faso',
				label: 'BF - Burkina Faso',
			},
			{
				value: 'BI - Burundi',
				label: 'BI - Burundi',
			},
			{
				value: 'BJ - Benin',
				label: 'BJ - Benin',
			},
			{
				value: 'BW - Botswana',
				label: 'BW - Botswana',
			},
			{
				value: 'CD - Congo',
				label: 'CD - Congo',
			},
			{
				value: 'CF - Central African Republic',
				label: 'CF - Central African Republic',
			},
			{
				value: 'CG - Congo',
				label: 'CG - Congo',
			},
			{
				value: "CI - Côte d'Ivoire",
				label: "CI - Côte d'Ivoire",
			},
			{
				value: 'CM - Cameroon',
				label: 'CM - Cameroon',
			},
			{
				value: 'CV - Cabo Verde',
				label: 'CV - Cabo Verde',
			},
			{
				value: 'DJ - Djibouti',
				label: 'DJ - Djibouti',
			},
			{
				value: 'DZ - Algeria',
				label: 'DZ - Algeria',
			},
			{
				value: 'EG - Egypt',
				label: 'EG - Egypt',
			},
			{
				value: 'EH - Western Sahara',
				label: 'EH - Western Sahara',
			},
			{
				value: 'ER - Eritrea',
				label: 'ER - Eritrea',
			},
			{
				value: 'ET - Ethiopia',
				label: 'ET - Ethiopia',
			},
			{
				value: 'GA - Gabon',
				label: 'GA - Gabon',
			},
			{
				value: 'GH - Ghana',
				label: 'GH - Ghana',
			},
			{
				value: 'GM - Gambia',
				label: 'GM - Gambia',
			},
			{
				value: 'GN - Guinea',
				label: 'GN - Guinea',
			},
			{
				value: 'GQ - Equatorial Guinea',
				label: 'GQ - Equatorial Guinea',
			},
			{
				value: 'GW - Guinea-Bissau',
				label: 'GW - Guinea-Bissau',
			},
			{
				value: 'KE - Kenya',
				label: 'KE - Kenya',
			},
			{
				value: 'KM - Comoros',
				label: 'KM - Comoros',
			},
			{
				value: 'LR - Liberia',
				label: 'LR - Liberia',
			},
			{
				value: 'LS - Lesotho',
				label: 'LS - Lesotho',
			},
			{
				value: 'LY - Libya',
				label: 'LY - Libya',
			},
			{
				value: 'MA - Morocco',
				label: 'MA - Morocco',
			},
			{
				value: 'MG - Madagascar',
				label: 'MG - Madagascar',
			},
			{
				value: 'ML - Mali',
				label: 'ML - Mali',
			},
			{
				value: 'MR - Mauritania',
				label: 'MR - Mauritania',
			},
			{
				value: 'MU - Mauritius',
				label: 'MU - Mauritius',
			},
			{
				value: 'MW - Malawi',
				label: 'MW - Malawi',
			},
			{
				value: 'MZ - Mozambique',
				label: 'MZ - Mozambique',
			},
			{
				value: 'NA - Namibia',
				label: 'NA - Namibia',
			},
			{
				value: 'NE - Niger',
				label: 'NE - Niger',
			},
			{
				value: 'NG - Nigeria',
				label: 'NG - Nigeria',
			},
			{
				value: 'RE - Réunion',
				label: 'RE - Réunion',
			},
			{
				value: 'RW - Rwanda',
				label: 'RW - Rwanda',
			},
			{
				value: 'SC - Seychelles',
				label: 'SC - Seychelles',
			},
			{
				value: 'SD - Sudan',
				label: 'SD - Sudan',
			},
			{
				value: 'SH - Saint Helena, Ascension and Tristan da Cunha',
				label: 'SH - Saint Helena, Ascension and Tristan da Cunha',
			},
			{
				value: 'SL - Sierra Leone',
				label: 'SL - Sierra Leone',
			},
			{
				value: 'SN - Senegal',
				label: 'SN - Senegal',
			},
			{
				value: 'SO - Somalia',
				label: 'SO - Somalia',
			},
			{
				value: 'ST - Sao Tome and Principe',
				label: 'ST - Sao Tome and Principe',
			},
			{
				value: 'SZ - Eswatini',
				label: 'SZ - Eswatini',
			},
			{
				value: 'TD - Chad',
				label: 'TD - Chad',
			},
			{
				value: 'TG - Togo',
				label: 'TG - Togo',
			},
			{
				value: 'TN - Tunisia',
				label: 'TN - Tunisia',
			},
			{
				value: 'TZ - Tanzania',
				label: 'TZ - Tanzania',
			},
			{
				value: 'UG - Uganda',
				label: 'UG - Uganda',
			},
			{
				value: 'YT - Mayotte',
				label: 'YT - Mayotte',
			},
			{
				value: 'ZA - South Africa',
				label: 'ZA - South Africa',
			},
			{
				value: 'ZM - Zambia',
				label: 'ZM - Zambia',
			},
			{
				value: 'ZW - Zimbabwe',
				label: 'ZW - Zimbabwe',
			},
		],
	},
	{
		value: 'Asia',
		label: 'Asia',
		children: [
			{
				value: 'AE - Arab Emirates',
				label: 'AE - Arab Emirates',
			},
			{
				value: 'AF - Afghanistan',
				label: 'AF - Afghanistan',
			},
			{
				value: 'AM - Armenia',
				label: 'AM - Armenia',
			},
			{
				value: 'AZ - Azerbaijan',
				label: 'AZ - Azerbaijan',
			},
			{
				value: 'BD - Bangladesh',
				label: 'BD - Bangladesh',
			},
			{
				value: 'BH - Bahrain',
				label: 'BH - Bahrain',
			},
			{
				value: 'BN - Brunei Darussalam',
				label: 'BN - Brunei Darussalam',
			},
			{
				value: 'BT - Bhutan',
				label: 'BT - Bhutan',
			},
			{
				value: 'CC - Cocos Islands',
				label: 'CC - Cocos Islands',
			},
			{
				value: 'CN - China',
				label: 'CN - China',
			},
			{
				value: 'CX - Christmas Island',
				label: 'CX - Christmas Island',
			},
			{
				value: 'CY - Cyprus',
				label: 'CY - Cyprus',
			},
			{
				value: 'GE - Georgia',
				label: 'GE - Georgia',
			},
			{
				value: 'HK - Hong Kong',
				label: 'HK - Hong Kong',
			},
			{
				value: 'ID - Indonesia',
				label: 'ID - Indonesia',
			},
			{
				value: 'IL - Israel',
				label: 'IL - Israel',
			},
			{
				value: 'IN - India',
				label: 'IN - India',
			},
			{
				value: 'IO - British Indian Ocean',
				label: 'IO - British Indian Ocean',
			},
			{
				value: 'IQ - Iraq',
				label: 'IQ - Iraq',
			},
			{
				value: 'IR - Iran',
				label: 'IR - Iran',
			},
			{
				value: 'JO - Jordan',
				label: 'JO - Jordan',
			},
			{
				value: 'JP - Japan',
				label: 'JP - Japan',
			},
			{
				value: 'KG - Kyrgyzstan',
				label: 'KG - Kyrgyzstan',
			},
			{
				value: 'KH - Cambodia',
				label: 'KH - Cambodia',
			},
			{
				value: 'KP - North Korea',
				label: 'KP - North Korea',
			},
			{
				value: 'KR - Korea',
				label: 'KR - Korea',
			},
			{
				value: 'KW - Kuwait',
				label: 'KW - Kuwait',
			},
			{
				value: 'KZ - Kazakhstan',
				label: 'KZ - Kazakhstan',
			},
			{
				value: 'LA - Lao',
				label: 'LA - Lao',
			},
			{
				value: 'LB - Lebanon',
				label: 'LB - Lebanon',
			},
			{
				value: 'LK - Sri Lanka',
				label: 'LK - Sri Lanka',
			},
			{
				value: 'MM - Myanmar',
				label: 'MM - Myanmar',
			},
			{
				value: 'MN - Mongolia',
				label: 'MN - Mongolia',
			},
			{
				value: 'MO - Macao',
				label: 'MO - Macao',
			},
			{
				value: 'MV - Maldives',
				label: 'MV - Maldives',
			},
			{
				value: 'MY - Malaysia',
				label: 'MY - Malaysia',
			},
			{
				value: 'NP - Nepal',
				label: 'NP - Nepal',
			},
			{
				value: 'OM - Oman',
				label: 'OM - Oman',
			},
			{
				value: 'PH - Philippines',
				label: 'PH - Philippines',
			},
			{
				value: 'PK - Pakistan',
				label: 'PK - Pakistan',
			},
			{
				value: 'PS - Palestine',
				label: 'PS - Palestine',
			},
			{
				value: 'QA - Qatar',
				label: 'QA - Qatar',
			},
			{
				value: 'SA - Saudi Arabia',
				label: 'SA - Saudi Arabia',
			},
			{
				value: 'SG - Singapore',
				label: 'SG - Singapore',
			},
			{
				value: 'SY - Syria',
				label: 'SY - Syria',
			},
			{
				value: 'TH - Thailand',
				label: 'TH - Thailand',
			},
			{
				value: 'TJ - Tajikistan',
				label: 'TJ - Tajikistan',
			},
			{
				value: 'TL - Timor-Leste',
				label: 'TL - Timor-Leste',
			},
			{
				value: 'TM - Turkmenistan',
				label: 'TM - Turkmenistan',
			},
			{
				value: 'TW - Taiwan',
				label: 'TW - Taiwan',
			},
			{
				value: 'UZ - Uzbekistan',
				label: 'UZ - Uzbekistan',
			},
			{
				value: 'VN - Viet Nam',
				label: 'VN - Viet Nam',
			},
			{
				value: 'YE - Yemen',
				label: 'YE - Yemen',
			},
		],
	},
	{
		value: 'Europe',
		label: 'Europe',
		children: [
			{
				value: 'AD - Andorra',
				label: 'AD - Andorra',
			},
			{
				value: 'AL - Albania',
				label: 'AL - Albania',
			},
			{
				value: 'AT - Austria',
				label: 'AT - Austria',
			},
			{
				value: 'AX - Åland Islands',
				label: 'AX - Åland Islands',
			},
			{
				value: 'BA - Bosnia',
				label: 'BA - Bosnia',
			},
			{
				value: 'BE - Belgium',
				label: 'BE - Belgium',
			},
			{
				value: 'BG - Bulgaria',
				label: 'BG - Bulgaria',
			},
			{
				value: 'BY - Belarus',
				label: 'BY - Belarus',
			},
			{
				value: 'CH - Switzerland',
				label: 'CH - Switzerland',
			},
			{
				value: 'CZ - Czechia',
				label: 'CZ - Czechia',
			},
			{
				value: 'DE - Germany',
				label: 'DE - Germany',
			},
			{
				value: 'DK - Denmark',
				label: 'DK - Denmark',
			},
			{
				value: 'EE - Estonia',
				label: 'EE - Estonia',
			},
			{
				value: 'ES - Spain',
				label: 'ES - Spain',
			},
			{
				value: 'FI - Finland',
				label: 'FI - Finland',
			},
			{
				value: 'FO - Faroe Islands',
				label: 'FO - Faroe Islands',
			},
			{
				value: 'FR - France',
				label: 'FR - France',
			},
			{
				value: 'GG - Guernsey',
				label: 'GG - Guernsey',
			},
			{
				value: 'GI - Gibraltar',
				label: 'GI - Gibraltar',
			},
			{
				value: 'GR - Greece',
				label: 'GR - Greece',
			},
			{
				value: 'HR - Croatia',
				label: 'HR - Croatia',
			},
			{
				value: 'HU - Hungary',
				label: 'HU - Hungary',
			},
			{
				value: 'IE - Ireland',
				label: 'IE - Ireland',
			},
			{
				value: 'IM - Isle of Man',
				label: 'IM - Isle of Man',
			},
			{
				value: 'IS - Iceland',
				label: 'IS - Iceland',
			},
			{
				value: 'IT - Italy',
				label: 'IT - Italy',
			},
			{
				value: 'JE - Jersey',
				label: 'JE - Jersey',
			},
			{
				value: 'LI - Liechtenstein',
				label: 'LI - Liechtenstein',
			},
			{
				value: 'LT - Lithuania',
				label: 'LT - Lithuania',
			},
			{
				value: 'LU - Luxembourg',
				label: 'LU - Luxembourg',
			},
			{
				value: 'LV - Latvia',
				label: 'LV - Latvia',
			},
			{
				value: 'MC - Monaco',
				label: 'MC - Monaco',
			},
			{
				value: 'MD - Moldova',
				label: 'MD - Moldova',
			},
			{
				value: 'ME - Montenegro',
				label: 'ME - Montenegro',
			},
			{
				value: 'MK - North Macedonia',
				label: 'MK - North Macedonia',
			},
			{
				value: 'MT - Malta',
				label: 'MT - Malta',
			},
			{
				value: 'NL - Netherlands',
				label: 'NL - Netherlands',
			},
			{
				value: 'NO - Norway',
				label: 'NO - Norway',
			},
			{
				value: 'PL - Poland',
				label: 'PL - Poland',
			},
			{
				value: 'PT - Portugal',
				label: 'PT - Portugal',
			},
			{
				value: 'RO - Romania',
				label: 'RO - Romania',
			},
			{
				value: 'RS - Serbia',
				label: 'RS - Serbia',
			},
			{
				value: 'RU - Russia',
				label: 'RU - Russia',
			},
			{
				value: 'SE - Sweden',
				label: 'SE - Sweden',
			},
			{
				value: 'SI - Slovenia',
				label: 'SI - Slovenia',
			},
			{
				value: 'SJ - Svalbard and Jan Mayen',
				label: 'SJ - Svalbard and Jan Mayen',
			},
			{
				value: 'SK - Slovakia',
				label: 'SK - Slovakia',
			},
			{
				value: 'SM - San Marino',
				label: 'SM - San Marino',
			},
			{
				value: 'TR - Turkey',
				label: 'TR - Turkey',
			},
			{
				value: 'UA - Ukraine',
				label: 'UA - Ukraine',
			},
			{
				value: 'UK - United Kingdom',
				label: 'UK - United Kingdom',
			},
			{
				value: 'VA - Holy See',
				label: 'VA - Holy See',
			},
		],
	},
	{
		value: 'North America',
		label: 'North America',
		children: [
			{
				value: 'AG - Antigua and Barbuda',
				label: 'AG - Antigua and Barbuda',
			},
			{
				value: 'AI - Anguilla',
				label: 'AI - Anguilla',
			},
			{
				value: 'AW - Aruba',
				label: 'AW - Aruba',
			},
			{
				value: 'BB - Barbados',
				label: 'BB - Barbados',
			},
			{
				value: 'BL - Saint Barthélemy',
				label: 'BL - Saint Barthélemy',
			},
			{
				value: 'BM - Bermuda',
				label: 'BM - Bermuda',
			},
			{
				value: 'BS - Bahamas',
				label: 'BS - Bahamas',
			},
			{
				value: 'BZ - Belize',
				label: 'BZ - Belize',
			},
			{
				value: 'CA - Canada',
				label: 'CA - Canada',
			},
			{
				value: 'CR - Costa Rica',
				label: 'CR - Costa Rica',
			},
			{
				value: 'CU - Cuba',
				label: 'CU - Cuba',
			},
			{
				value: 'DM - Dominica',
				label: 'DM - Dominica',
			},
			{
				value: 'DO - Dominican Republic',
				label: 'DO - Dominican Republic',
			},
			{
				value: 'GD - Grenada',
				label: 'GD - Grenada',
			},
			{
				value: 'GL - Greenland',
				label: 'GL - Greenland',
			},
			{
				value: 'GP - Guadeloupe',
				label: 'GP - Guadeloupe',
			},
			{
				value: 'GT - Guatemala',
				label: 'GT - Guatemala',
			},
			{
				value: 'HN - Honduras',
				label: 'HN - Honduras',
			},
			{
				value: 'HT - Haiti',
				label: 'HT - Haiti',
			},
			{
				value: 'JM - Jamaica',
				label: 'JM - Jamaica',
			},
			{
				value: 'KN - Saint Kitts and Nevis',
				label: 'KN - Saint Kitts and Nevis',
			},
			{
				value: 'KY - Cayman Islands',
				label: 'KY - Cayman Islands',
			},
			{
				value: 'LC - Saint Lucia',
				label: 'LC - Saint Lucia',
			},
			{
				value: 'MF - Saint Martin (French part)',
				label: 'MF - Saint Martin (French part)',
			},
			{
				value: 'MQ - Martinique',
				label: 'MQ - Martinique',
			},
			{
				value: 'MS - Montserrat',
				label: 'MS - Montserrat',
			},
			{
				value: 'MX - Mexico',
				label: 'MX - Mexico',
			},
			{
				value: 'NI - Nicaragua',
				label: 'NI - Nicaragua',
			},
			{
				value: 'PA - Panama',
				label: 'PA - Panama',
			},
			{
				value: 'PM - Saint Pierre and Miquelon',
				label: 'PM - Saint Pierre and Miquelon',
			},
			{
				value: 'PR - Puerto Rico',
				label: 'PR - Puerto Rico',
			},
			{
				value: 'SV - El Salvador',
				label: 'SV - El Salvador',
			},
			{
				value: 'TC - Turks and Caicos Islands',
				label: 'TC - Turks and Caicos Islands',
			},
			{
				value: 'TT - Trinidad and Tobago',
				label: 'TT - Trinidad and Tobago',
			},
			{
				value: 'US - United States of America',
				label: 'US - United States of America',
			},
			{
				value: 'VC - Saint Vincent and the Grenadines',
				label: 'VC - Saint Vincent and the Grenadines',
			},
			{
				value: 'VG - Virgin Islands (British)',
				label: 'VG - Virgin Islands (British)',
			},
			{
				value: 'VI - Virgin Islands (U.S.)',
				label: 'VI - Virgin Islands (U.S.)',
			},
		],
	},
	{
		value: 'Oceania',
		label: 'Oceania',
		children: [
			{
				value: 'AS - American Samoa',
				label: 'AS - American Samoa',
			},
			{
				value: 'AU - Australia',
				label: 'AU - Australia',
			},
			{
				value: 'CK - Cook Islands',
				label: 'CK - Cook Islands',
			},
			{
				value: 'FJ - Fiji',
				label: 'FJ - Fiji',
			},
			{
				value: 'FM - Micronesia',
				label: 'FM - Micronesia',
			},
			{
				value: 'GU - Guam',
				label: 'GU - Guam',
			},
			{
				value: 'KI - Kiribati',
				label: 'KI - Kiribati',
			},
			{
				value: 'MH - Marshall Islands',
				label: 'MH - Marshall Islands',
			},
			{
				value: 'MP - Northern Mariana Islands',
				label: 'MP - Northern Mariana Islands',
			},
			{
				value: 'NC - New Caledonia',
				label: 'NC - New Caledonia',
			},
			{
				value: 'NF - Norfolk Island',
				label: 'NF - Norfolk Island',
			},
			{
				value: 'NR - Nauru',
				label: 'NR - Nauru',
			},
			{
				value: 'NU - Niue',
				label: 'NU - Niue',
			},
			{
				value: 'NZ - New Zealand',
				label: 'NZ - New Zealand',
			},
			{
				value: 'PF - French Polynesia',
				label: 'PF - French Polynesia',
			},
			{
				value: 'PG - Papua New Guinea',
				label: 'PG - Papua New Guinea',
			},
			{
				value: 'PN - Pitcairn',
				label: 'PN - Pitcairn',
			},
			{
				value: 'PW - Palau',
				label: 'PW - Palau',
			},
			{
				value: 'SB - Solomon Islands',
				label: 'SB - Solomon Islands',
			},
			{
				value: 'TK - Tokelau',
				label: 'TK - Tokelau',
			},
			{
				value: 'TO - Tonga',
				label: 'TO - Tonga',
			},
			{
				value: 'TV - Tuvalu',
				label: 'TV - Tuvalu',
			},
			{
				value: 'UM - United States Minor Outlying Islands',
				label: 'UM - United States Minor Outlying Islands',
			},
			{
				value: 'VU - Vanuatu',
				label: 'VU - Vanuatu',
			},
			{
				value: 'WF - Wallis and Futuna',
				label: 'WF - Wallis and Futuna',
			},
			{
				value: 'WS - Samoa',
				label: 'WS - Samoa',
			},
		],
	},
	{
		value: 'South America',
		label: 'South America',
		children: [
			{
				value: 'AR - Argentina',
				label: 'AR - Argentina',
			},
			{
				value: 'BO - Bolivia',
				label: 'BO - Bolivia',
			},
			{
				value: 'BR - Brazil',
				label: 'BR - Brazil',
			},
			{
				value: 'CL - Chile',
				label: 'CL - Chile',
			},
			{
				value: 'CO - Colombia',
				label: 'CO - Colombia',
			},
			{
				value: 'EC - Ecuador',
				label: 'EC - Ecuador',
			},
			{
				value: 'FK - Falkland Islands [Malvinas]',
				label: 'FK - Falkland Islands [Malvinas]',
			},
			{
				value: 'GF - French Guiana',
				label: 'GF - French Guiana',
			},
			{
				value: 'GY - Guyana',
				label: 'GY - Guyana',
			},
			{
				value: 'PE - Peru',
				label: 'PE - Peru',
			},
			{
				value: 'PY - Paraguay',
				label: 'PY - Paraguay',
			},
			{
				value: 'SR - Suriname',
				label: 'SR - Suriname',
			},
			{
				value: 'UY - Uruguay',
				label: 'UY - Uruguay',
			},
			{
				value: 'VE - Venezuela',
				label: 'VE - Venezuela',
			},
		],
	},
]
