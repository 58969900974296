import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

interface CustomDialogProps {
	open: boolean
	onClose: () => void
	onConfirm: () => void
	imageUrl?: string
	imageAlt?: string
	message: string
	confirmText?: string
	cancelText?: string
}

const CustomDialog: React.FC<CustomDialogProps> = ({
	open,
	onClose,
	onConfirm,
	imageUrl,
	imageAlt = 'Image',
	message,
	confirmText = 'Delete',
	cancelText = 'Cancel',
}) => {
	return (
		<Dialog
			open={open}
			onClose={onClose}
			aria-labelledby='delete-dialog-title'
			aria-describedby='delete-dialog-description'
		>
			<DialogContent>
				<Box
					display='flex'
					flexDirection='column'
					alignItems='center'
					justifyContent='center'
				>
					{imageUrl && (
						<img
							src={imageUrl}
							alt={imageAlt}
							style={{ maxWidth: '100%', maxHeight: '300px' }}
						/>
					)}
					<Typography id='delete-dialog-description' sx={{ mt: 2 }}>
						{message}
					</Typography>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>{cancelText}</Button>
				<Button onClick={onConfirm} color='error'>
					{confirmText}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default CustomDialog
