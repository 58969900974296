import { ActionType } from '../action-types/index'
import { Action } from '../actions'

export interface appsflyerAccountsReducerInterface {
	accounts: any
}

const initialState: appsflyerAccountsReducerInterface = {
	accounts: [],
}

const reducer = (
	state: appsflyerAccountsReducerInterface | any = initialState,
	action: Action,
): appsflyerAccountsReducerInterface => {
	switch (action.type) {
		case ActionType.GETACCOUNTS:
			return {
				...state,
				accounts: action.payload,
			}
		case ActionType.LOGOUT:
			return {
				...state,
				accounts: [],
			}
		default:
			return state
	}
}

export default reducer
