import { ActionType } from '../action-types'
import { Action } from '../actions'

export interface Permission {
	id: number
	name: string
	value: string
	type: string
	company_type: string[]
}

export interface PermissionsReducerInterface {
	permissions: Permission[] | any
}

const initialState: PermissionsReducerInterface = {
	permissions: [],
}

const permissionsReducer = (
	state: PermissionsReducerInterface | any = initialState,
	action: Action,
): PermissionsReducerInterface => {
	switch (action.type) {
		case ActionType.GETPERMISSIONS:
			return {
				...state,
				permissions: action.payload,
			}
		case ActionType.SETPERMISSIONS:
			return {
				...state,
				permissions: action.payload,
			}
		case ActionType.LOGOUT:
			return {
				...state,
				permissions: [],
			}
		default:
			return state
	}
}

export default permissionsReducer
