import { ActionType } from '../action-types/index'
import { Action } from '../actions'

interface AvatarsReducerInterface {
	avatars: any
}

const initialState = {
	avatars: [],
}

const reducer = (
	state: AvatarsReducerInterface = initialState,
	action: Action,
): AvatarsReducerInterface => {
	switch (action.type) {
		case ActionType.AVATAR: {
			return {
				...state,
				avatars: action.payload,
			}
		}
		case ActionType.LOGOUT: {
			return {
				...state,
				avatars: {},
			}
		}

		default:
			return state
	}
}

export default reducer
