import React, { useEffect, useState } from 'react'
import { InputFieldText, StyledTextAreaField } from '../components/Inputs'
import { Checkbox, useTheme } from '@mui/material'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { CountryListSelector, DropList } from '../components/SelectableInputs'
import { SubmitButton } from '../components/Buttons'
import success from '../../assets/success-icon.svg'
import ReCAPTCHA from 'react-google-recaptcha'
import { countryList } from '../components/countryList'
import { endpoints } from '../../config'
import AxiosCustom from '../../utils/Axios'
import { useNavigate } from 'react-router'

const SignUp = (props: {
	setCurrentView: any
	onSignUp: any
	errorMsg?: string
	handleTermsPopUp?: any
}) => {
	const theme = useTheme()
	const [email, setEmail] = useState<string>('')
	const [password, setPassword] = useState<string>('')
	const [confirmPassword, setConfirmPassword] = useState<string>('')
	const [name, setName] = useState<string>('')
	const [companyName, setCompanyName] = useState<string>('')
	const [geo, setGeo] = useState<string[]>([])
	const [clientType, setClientType] = useState<string>('')
	const [agreeToTerms, setAgreeToTerms] = useState<boolean>(false)
	const [notARobot, setNotARobot] = useState<boolean>(false)
	const [isSubmit, setIsSubmit] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const [notes, setNotes] = useState('')
	const [poc, setPoc] = useState('')
	const navigate = useNavigate()

	const signUpHandler = () => {
		const data = { email, name, clientType, companyName }
		const stringifiedData = JSON.stringify(data)
		const encodedData = btoa(stringifiedData)
		navigate(`/signupdetails?token=${encodedData}`)
		// props.onSignUp({ email, name, clientType, companyName, geo })
		// if (!props.errorMsg) {
		// 	setIsSubmit(true)
		// }
	}

	const isValid = () => {
		return (
		  name.trim() !== '' &&
		  clientType.trim() !== '' &&
		  companyName.trim() !== '' &&
		  email.trim() !== '' &&
		  agreeToTerms &&
		  !!notARobot 
		);
	  };

	useEffect(() => {
		if (props.errorMsg) {
			setErrorMessage(props.errorMsg)
		} else setErrorMessage('')
	}, [props.errorMsg])

	const handleSetEmail = (e: string) => {
		setErrorMessage('')
		setEmail(e)
	}
	const handleRecaptcha = async (token: any) => {
		try {
			const { request } = await AxiosCustom.post(
				'recaptcha',
				{ token: token },
				{
					headers: {
						'Content-Type': 'application/json',
					},
				},
			)

			const result = JSON.parse(request.response)
			console.log("handleRecaptcha: " , result)

			if (result.success) {
				setNotARobot(true)
			}
		} catch (error) {
			console.error('captcha failed', error)
		}
	}

	const isValidPassword = (password: string) => {
		const hasLetters = /[a-zA-Z]/.test(password)
		const hasNumbers = /\d/.test(password)
		const isLongEnough = password.length > 6
		return hasLetters && hasNumbers && isLongEnough
	}
	const workingOnIt = false
	return (
		<>
			{!workingOnIt ? (
				!isSubmit && (
					<div
						style={{
							width: '100%',
							display: 'flex',
							flexDirection: 'column',
							gap: '1rem',
							alignItems: 'center',
						}}
					>
						{/* First Row */}
						<div style={{ display: 'flex', width: '100%', gap: '1rem' }}>
							<InputFieldText
								onChange={(e: string) => setName(e)}
								type='text'
								label='Full Name'
								value={name}
								style={{ flex: 1, minWidth: '50%' }}
							/>
							<DropList
								onChange={(e: string) => setClientType(e)}
								label='Type of Client'
								value={clientType}
								options={['Advertiser', 'Publisher']}
								style={{ flex: 1, minWidth: '50%' }}
							/>
						</div>

						{/* Second Row */}
						<div style={{ display: 'flex', width: '100%', gap: '1rem' }}>
							<InputFieldText
								onChange={(e: string) => setCompanyName(e)}
								type='text'
								label='Company Name'
								value={companyName}
								style={{ flex: 1, width: '100%' }}
							/>
						</div>

						{/* Third Row with Full-Width Email Input */}
						<div style={{ width: '100%' }}>
							<InputFieldText
								errormessage={errorMessage ? errorMessage : undefined}
								onChange={(e: string) => handleSetEmail(e)}
								type='email'
								label='Email'
								value={email}
								style={{ width: '100%' }}
							/>
						</div>

						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								marginTop: 12,
							}}
						>
							<ReCAPTCHA
								sitekey={process.env.REACT_APP_RECAPTCHA as string}
								onChange={(token: any) => handleRecaptcha(token)}
							/>
						</div>

						<div
							style={{
								width: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}
						>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<Checkbox
									checked={agreeToTerms}
									onChange={() => setAgreeToTerms(!agreeToTerms)}
									inputProps={{
										'aria-label': 'controlled',
									}}
									icon={
										<CheckBoxOutlineBlankIcon
											style={{ scale: '0.8', color: theme.colors.base.grey900 }}
										/>
									}
									checkedIcon={
										<CheckBoxIcon
											style={{
												scale: '0.8',
												color: theme.colors.base.green200,
											}}
										/>
									}
								/>
								<span
									style={{
										color: theme.colors.base.grey600,
										display: 'flex',
										justifyContent: 'flex-start',
										alignItems: 'center',
									}}
								>
									I agree with the{' '}
									<button
										style={{
											border: 'none',
											display: 'flex',
											color: theme.colors.base.blue300,
											fontSize: 'inherit',
											marginLeft: -2,
											cursor: 'pointer',
										}}
										onClick={() => props.handleTermsPopUp()}
									>
										terms of use
									</button>
								</span>
							</div>
						</div>
						<SubmitButton
							onClick={signUpHandler}
							disabled={!isValid()}
							style={{
								width: '100%',
								borderRadius: '4px',
								margin: 0,
								textTransform: 'none',
								fontSize: theme.font.size.body,
								color: theme.colors.text.titles,
								padding: '12px',
							}}
							sx={{
								'&.Mui-disabled': {
									background: theme.colors.base.grey100,
								},
								'&:hover': {
									background: theme.colors.base.green20,
									border: '2px solid ' + theme.colors.base.green200,
									color: theme.colors.base.grey900,
								},
								'&.Mui-focused': {
									background: theme.colors.base.green100,
								},
							}}
						>
							Next
						</SubmitButton>
					</div>
				)
			) : (
				<span style={{ fontSize: theme.font.size.titles }}>Coming soon</span>
			)}
		</>
	)
}

export default SignUp
