import {
	FormControlLabel,
	Grid,
	Paper,
	TextField,
	Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { DesktopDatePicker } from '@mui/x-date-pickers'

export const ReportItem = styled(Paper)(({ theme }) => ({
	backgroundColor: 'inherit',
	border: 'none',
	boxShadow: 'none',
	textAlign: 'left',
	height: '600px',
}))

export const ReportDashItem = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.primary.main,
	border: 'none',
	boxShadow: 'none',
	textAlign: 'left',
	height: 'fit-content',
	width: '90vw',
}))

export const StyledBox = styled(Paper)(({ theme }) => ({
	overflow: 'auto',
	backgroundColor: 'inherit',
	border: '10px',
	borderRadius: theme.dimentions.smallerRadius,
	textAlign: 'left',
	height: '600px',
	padding: '10px',
}))

export const StyledDashBox = styled(Paper)(({ theme }) => ({
	overflow: 'auto',
	backgroundColor: theme.palette.primary.light,
	border: '10px',
	borderRadius: theme.dimentions.smallerRadius,
	textAlign: 'left',
	width: '87vw',
	height: 'fitContent',
}))

export const ReportGrid = styled(Grid)(({ theme }) => ({
	textAlign: 'left',
	minHeight: '100px',
	padding: '20px',
}))

export const DateComponent = styled(DesktopDatePicker)(({ theme }) => ({
	// minHeight: '100px',
	width: '100%',
	marginRight: '0px',
}))

export const ReportItemHeading = styled(Typography)(({ theme }) => ({
	fontWeight: 500,
	color: theme.palette.primary.dark,
}))

export const StyledTextField = styled(TextField)(({ theme }) => ({
	backgroundColor: 'rgba(238, 238, 238, 0.6)',
	height: '40px',
	fontWeight: 'bold',

	borderRadius: theme.dimentions.smallerRadius,
	marginTop: '40px',
	'& .MuiOutlinedInput-input': {
		backgroundColor: 'transparent',
		borderRadius: theme.dimentions.smallerRadius,
		height: '40px',
		fontSize: '13px',
		fontWeight: 'bold',
		paddingTop: '1px',
		paddingBottom: '1px',
		paddingRight: '6px',
	},
}))

export const StyledCheckboxFormControl = styled(FormControlLabel)(
	({ theme }) => ({
		color: theme.colors.gradient,
		fontSize: '13px',
		maxWidth: '280px',
		fontWeight: 600,
		'&.MuiTypography-root': {
			fontSize: '13px',
		},
	}),
)
