import { ENCRYPTION_KEY, AEC_ALGORITHM } from '../config'

async function getKey(algorithm: string) {
	if (!ENCRYPTION_KEY) {
		throw new Error('ENCRYPTION_KEY is not defined')
	}

	const rawKey = Uint8Array.from(atob(ENCRYPTION_KEY), (c) => c.charCodeAt(0))

	if (algorithm === AEC_ALGORITHM) {
		return await crypto.subtle.importKey(
			'raw',
			rawKey,
			{ name: algorithm },
			false,
			['encrypt', 'decrypt'],
		)
	} else {
		throw new Error(`Unsupported algorithm: ${algorithm}`)
	}
}

// Encrypt and Decrypt Functions for AES-GCM
export async function encryptMessageForWebSocket(
	message: string,
): Promise<string> {
	const cryptoKey = await getKey(AEC_ALGORITHM) // Pass AES-GCM algorithm
	const iv = crypto.getRandomValues(new Uint8Array(12))
	const encodedMessage = new TextEncoder().encode(message)

	const encryptedMessage = await crypto.subtle.encrypt(
		{ name: AEC_ALGORITHM, iv },
		cryptoKey,
		encodedMessage,
	)

	const ivStr = btoa(String.fromCharCode(...iv))
	const encryptedMessageStr = btoa(
		String.fromCharCode(...new Uint8Array(encryptedMessage)),
	)
	return `${ivStr}:${encryptedMessageStr}`
}

export async function decryptMessageForWebSocket(
	encryptedMessage: string,
): Promise<string> {
	const [ivStr, encryptedMessageStr] = encryptedMessage.split(':')
	const iv = Uint8Array.from(atob(ivStr), (c) => c.charCodeAt(0))
	const encryptedArray = Uint8Array.from(atob(encryptedMessageStr), (c) =>
		c.charCodeAt(0),
	)

	const cryptoKey = await getKey(AEC_ALGORITHM) // Pass AES-GCM algorithm
	const decryptedMessage = await crypto.subtle.decrypt(
		{ name: AEC_ALGORITHM, iv },
		cryptoKey,
		encryptedArray,
	)

	return new TextDecoder().decode(decryptedMessage)
}

async function getBackendKey() {
	if (!ENCRYPTION_KEY) {
		throw new Error('ENCRYPTION_KEY is not defined')
	}

	const rawKey = Uint8Array.from(atob(ENCRYPTION_KEY), (c) => c.charCodeAt(0))

	return await crypto.subtle.importKey(
		'raw',
		rawKey,
		{ name: AEC_ALGORITHM },
		false,
		['encrypt', 'decrypt'],
	)
}

// New Backend-Compatible Encrypt Function
export async function encryptMessageForDataSplit(
	message: string,
): Promise<string> {
	const cryptoKey = await getBackendKey() // Pass AES-GCM algorithm
	const iv = crypto.getRandomValues(new Uint8Array(12))

	const encodedMessage = new TextEncoder().encode(message)

	const encryptedMessage = await crypto.subtle.encrypt(
		{ name: AEC_ALGORITHM, iv },
		cryptoKey,
		encodedMessage,
	)

	const ivStr = btoa(String.fromCharCode(...iv))
	const encryptedMessageStr = btoa(
		String.fromCharCode(...new Uint8Array(encryptedMessage)),
	)
	const tagStr = '' // No tag in the frontend, but this placeholder can be used if needed

	const finalEncrypted = `${ivStr}:${encryptedMessageStr}:${tagStr}`

	return finalEncrypted
}
// New Backend-Compatible Decrypt Function
export async function decryptMessageForDataSplit(
	encryptedMessage: string | null | undefined,
): Promise<string> {
	if (!encryptedMessage) {
		return '' // Return empty string if the message is null or undefined
	}

	// Remove 'ENC:' prefix and split the remaining encrypted message
	const cleanedMessage = encryptedMessage.replace('ENC:', '').trim()
	const [ivStr, encryptedData, tagStr] = cleanedMessage.split(':')

	// Ensure all parts are present
	if (!ivStr || !encryptedData || !tagStr) {
		console.error('Invalid encrypted message format')
		throw new Error('Invalid encrypted message format')
	}

	try {
		// Base64 decode each part
		atob(ivStr)
		atob(encryptedData)
		// Only decode tagStr if it's not empty
		if (tagStr) {
			atob(tagStr)
		}
	} catch (error) {
		console.error('Invalid base64 encoding detected in one of the parts', error)
		throw new Error('Invalid base64 encoding')
	}

	const iv = Uint8Array.from(atob(ivStr), (c) => c.charCodeAt(0))
	const encryptedArray = Uint8Array.from(atob(encryptedData), (c) =>
		c.charCodeAt(0),
	)
	let tagArray = new Uint8Array()

	// Only create tagArray if tagStr is not empty
	if (tagStr) {
		tagArray = Uint8Array.from(atob(tagStr), (c) => c.charCodeAt(0))
	}

	const cryptoKey = await getBackendKey() // Get AES-GCM key

	try {
		// Decrypt the combined encryptedArray and tagArray if present
		const decryptedMessage = await crypto.subtle.decrypt(
			{
				name: AEC_ALGORITHM,
				iv,
				tagLength: 128,
			},
			cryptoKey,
			// Combine encryptedArray and tagArray if tagStr is not empty
			new Uint8Array([...encryptedArray, ...tagArray]).buffer,
		)
		const decodedMessage = new TextDecoder().decode(decryptedMessage)
		return decodedMessage
	} catch (error) {
		console.error('Decryption error:', error)
		throw error
	}
}
