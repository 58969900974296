import React, { useEffect, useState } from 'react'
import { createCustomTheme } from './themeGenerator'
import { Outlet } from 'react-router'
import { ThemeProvider } from '@mui/material'

const CustomThemeProvider: React.FC<{
	darkMode: boolean
	children?: React.ReactNode // Adding children as optional
}> = ({ darkMode, children }) => {
	const [isDarkTheme, setIsDarkTheme] = useState(darkMode)
	const [theme, setTheme] = useState<any>(() => createCustomTheme(isDarkTheme))

	useEffect(() => {
		setTheme(() => createCustomTheme(isDarkTheme))
	}, [isDarkTheme])

	useEffect(() => {
		setIsDarkTheme(darkMode)
	}, [darkMode])

	useEffect(() => {
		document.documentElement.style.setProperty(
			'--background-color',
			theme.colors.base.white,
		)
		document.documentElement.style.setProperty(
			'--text-color',
			theme.colors.text.titles,
		)
		document.documentElement.style.setProperty(
			'--svg-color',
			theme.colors.base.green200,
		)
		document.documentElement.style.setProperty(
			'--media-plan-container-grey',
			theme.colors.base.grey600,
		)
		document.documentElement.style.setProperty(
			'--text-grey',
			theme.colors.text.grey,
		)
	}, [theme])

	return <ThemeProvider theme={theme}>{children || <Outlet />}</ThemeProvider>
}

export default CustomThemeProvider
