import AdapterDateFns from '@date-io/date-fns'
import DownloadIcon from '@mui/icons-material/Download'
import { Grid, MenuItem, ThemeProvider, useTheme } from '@mui/material'
import { DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AreaChart } from '@tremor/react'
import '@tremor/react/dist/esm/tremor.css'
import { subDays } from 'date-fns/esm'
import { saveAs } from 'file-saver'
import {
	HTMLInputTypeAttribute,
	ReactNode,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { useActions, useReportActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { getPresetsAction } from '../../state/action-creators'
import { capitalizeFirstLetter } from '../../utils/helpers/tableHelper'
import TransferList from '../reports/common/TransferListComponent'
import { RawFiltersComponentList } from './FiltersComponent'
import { RawPresetsPopup } from './RawPresetsComponent'
import {
	ReportGrid,
	ReportItem,
	ReportItemHeading,
	StyledBox,
	StyledTextField,
} from './RawReportsStyled'
import { RawReportsTable } from './RawReportsTable'
import { DropList } from '../components/SelectableInputs'
import { SimpleActionsButton, SubmitButton } from '../components/Buttons'
import { InputFieldText } from '../components/Inputs'

export interface ReportFieldsInterface {
	from_date: Date
	to_date: Date
	breakdowns: string[]
	statistics: string[]
	filters: FilterInterface
}
export interface FilterInterface {
	app_id: string[]
	advertiser_name: string[]
	media_source_pid: string[]
	publisher: string[]
	email: string[]
	campaign_name: string[]
	campaign_id: string[]
	country: string[]
}
export const DateInputField = (props: {
	label?: string | ReactNode
	type?: string | HTMLInputTypeAttribute
	value?: unknown
	onChange?: any
	required?: boolean
	disabled?: boolean
}) => {
	return (
		<StyledTextField
			focused
			key='InputName'
			required={props.required}
			label={props.label}
			type={props.type}
			value={props.value}
			margin='dense'
			disabled={props.disabled}
			onChange={(e: any) => props.onChange(e.target.value)}
			variant='outlined'
			InputLabelProps={{
				style: { top: '-20%' },
			}}
		/>
	)
}

const TimeframeComponent = (props: {
	viewDataFor: string
	setViewDataFor: any
	startDay: Date
	setStartDay: any
	endDay: Date
	setEndDay: any
	updatedTimeframe: boolean
	setUpdatedTimeframe: any
}) => {
	const [value, setValue] = useState<any>([props.startDay, props.endDay])

	useEffect(() => {
		props.setStartDay(value[0])
		props.setEndDay(value[1])
	}, [value])
	useEffect(() => {
		if (props.updatedTimeframe) {
			setValue([props.startDay, props.endDay])
			props.setUpdatedTimeframe(false)
		}
	}, [props.updatedTimeframe])

	const handleViewDataForChange = (e: any) => {
		const date = new Date()
		props.setViewDataFor(e)
		switch (e) {
			case 'Today':
				props.setStartDay(date)
				props.setEndDay(date)
				setValue([date, date])
				break
			case 'Yesterday':
				props.setStartDay(subDays(new Date(date), 1))
				props.setEndDay(subDays(new Date(date), 1))
				setValue([subDays(new Date(date), 1), subDays(new Date(date), 1)])
				break
			case 'Last 7 Days':
				props.setStartDay(subDays(new Date(date), 7))
				props.setEndDay(date)
				setValue([subDays(new Date(date), 7), date])
				break
			case 'From Start of the Month':
				props.setStartDay(new Date(date.getFullYear(), date.getMonth(), 1))
				props.setEndDay(new Date())
				setValue([new Date(date.getFullYear(), date.getMonth(), 1), new Date()])
				break
			default:
				break
		}
	}
	return (
		<ReportItem style={{ height: '350px' }}>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<ReportItemHeading>Timeframe</ReportItemHeading>
				<StyledBox style={{ overflowX: 'hidden', height: '600px' }}>
					<DropList
						onChange={(e: any) => handleViewDataForChange(e)}
						label={'View Data For'}
						value={props.viewDataFor}
						options={[
							'Today',
							'Yesterday',
							'From Start of the Month',
							'Last 7 Days',
							'Custom',
						]}
						fontSize='13px'
					></DropList>
					{/* <DateComponent
						label='Start Date'
						onChange={(e: any) => {
							props.setStartDay(e)
							props.setViewDataFor('Custom')
						}}
						value={props.startDay}
						inputFormat='dd/MM/yyyy'
						OpenPickerButtonProps={{ style: { paddingRight: '20px' } }}
						renderInput={(params) => (
							<StyledTextField
								{...params}
								focused

								InputLabelProps={{
									style: { top: '-20%' },
								}}
							/>
						)}
					/> */}
					<DateRangePicker
						value={value}
						inputFormat='dd/MM/yyyy'
						onChange={(newValue) => {
							setValue(newValue)
							props.setViewDataFor('Custom')
						}}
						className='datePicker'
						renderInput={(startProps, endProps) => {
							return (
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										width: '100%',
										justifyContent: 'space-around',
										marginTop: '26px',
									}}
								>
									<InputFieldText
										{...startProps}
										label='Start Date'
										value={startProps.value as string}
										type={'text'}
										style={{
											width: '120px',
										}}
									/>
									<InputFieldText
										{...endProps}
										value={endProps.value as string}
										type={'text'}
										label='End Date'
										style={{ width: '120px' }}
									/>
								</div>
							)
						}}
					/>
					{/* <DateComponent
						label='End Date'
						onChange={(e: any) => {
							props.setEndDay(e)
							props.setViewDataFor('Custom')
						}}
						value={props.endDay}
						inputFormat='dd/MM/yyyy'
						OpenPickerButtonProps={{ style: { paddingRight: '20px' } }}
						renderInput={(params) => (
							<StyledTextField
								{...params}
								focused

								InputLabelProps={{
									style: { top: '-20%' },
								}}
							/>
						)}
					/> */}
				</StyledBox>
			</LocalizationProvider>
		</ReportItem>
	)
}

const BreakdownComponent = (props: {
	breakdownFields: string[]
	setBreakdownFields: any
	statisticsFields: string[]
	setStatisticsFields: any
	dataObj: ReportFieldsInterface
}) => {
	return (
		<ReportItem
			style={{
				display: 'flex',
				justifyContent: 'center',
				flexDirection: 'row',
			}}
		>
			<div>
				<ReportItemHeading paddingLeft={'0%'}>
					Breakdown Fields
				</ReportItemHeading>
				<TransferList
					listName={'Breakdown'}
					dataObj={props.dataObj}
					fieldsArray={props.breakdownFields}
					setFieldsArray={props.setBreakdownFields}
					options={[
						'Date',
						'Month',
						'Day',
						'Day of the Week',
						'Country',
						'App ID',
						'OS',
						'Advertiser Name',
						'Advertiser Owner',
						'Campaign Name',
						'Media Source Pid',
						'Publisher Name',
					]}
					style={{ paddingLeft: '0%', width: '300px' }}
				/>
				<div style={{ margin: '14px' }}></div>
			</div>
			<div>
				<ReportItemHeading paddingLeft={'0%'}>
					{' '}
					Statistic Fields
				</ReportItemHeading>
				<TransferList
					listName={'Statistic'}
					dataObj={props.dataObj}
					fieldsArray={props.statisticsFields}
					setFieldsArray={props.setStatisticsFields}
					disabled={props.breakdownFields && props.breakdownFields.length === 0}
					options={[
						'Impressions',
						'Clicks',
						'Installs',
						'Level 2 Event',
						'Level 3 Event',
						'Level 4 Event',
						'Level 5 Event',
						'Revenue',
						'Cost',
						'Profit',
						'CR',
					]}
					style={{ paddingLeft: '0%', width: '300px' }}
				/>
			</div>
		</ReportItem>
	)
}

const FiltersComponent = (props: {
	setAppIds: any
	setAdvertisers: any
	setUser: any
	setPid: any
	setPublishers: any
	setCampaignNames: any
	setCampaignIds: any
	setCountries: any
	presetVisible: boolean
	dataObj: ReportFieldsInterface
	trySubmit: any
	user: any
	tryDownload?: any
	isReportOpen: any
	setDataObj: any
	setPresetVisible: any
	setIsSaving: any
	tryGetRealtime: any
	setIsWarningPromptOpened: any
	lastUpdatedAt: string | undefined
}) => {
	const theme = useTheme()
	const { login } = useTypedSelector((state) => state)
	// const headers = useMemo(() => {
	// 	return {
	// 		Authorization: `Token ${login.user.token}`,
	// 	}
	// }, [login.user.token])
	// const dateArr = props.lastUpdatedAt?.split('T')
	// let timeString = ''
	// if (dateArr !== undefined && dateArr[1] !== undefined) {
	// 	const timeStringArr = dateArr[1].split(':')
	// 	timeString = timeStringArr[0] + ':' + timeStringArr[1]
	// }
	const presetOptions = (
		<div>
			<MenuItem
				onClick={() => {
					props.setIsSaving(true)
					props.setPresetVisible(true)
				}}
			>
				Save a preset
			</MenuItem>
			<MenuItem
				onClick={async () => {
					await getPresetsAction(login.user.email)
					props.setIsSaving(false)
					props.setPresetVisible(true)
				}}
			>
				Open presets
			</MenuItem>
		</div>
	)
	return (
		<ReportItem>
			<ReportItemHeading>Filters</ReportItemHeading>
			<StyledBox style={{ width: '260px' }}>
				<RawFiltersComponentList {...props} setDataObj={props.setDataObj} />
			</StyledBox>
			<Grid
				container
				spacing={2}
				direction={'row'}
				style={{
					position: 'absolute',
					left: 40,
					bottom: '30%',
					flexDirection: 'row',
					width: '50%',
					overflow: 'hidden',
					marginTop: '20px',
					paddingBottom: '60px',
					alignItems: 'center',
				}}
			>
				{/* <Grid item xs={3} order={1}>
					<SubmitButton
						onClick={async () => {
							await getPresetsAction(headers, login.user.email)
							props.setIsSaving(false)
							props.setPresetVisible(true)
						}}
						sx={{
							position: 'relative',
							height: '30px',
							width: '180px',
						}}
					>
						Presets
					</SubmitButton>
				</Grid> */}
				<Grid item xs={2}>
					<SubmitButton style={{ width: 80 }} onClick={props.trySubmit}>
						Run
					</SubmitButton>
				</Grid>
				<Grid item xs={2}>
					<SubmitButton
						style={{ width: 120, fontSize: theme.font.size.body }}
						onClick={props.tryDownload}
						disabled={props.isReportOpen}
						icon={
							<DownloadIcon
								color={props.isReportOpen ? 'disabled' : 'inherit'}
							/>
						}
					>
						Download
					</SubmitButton>
				</Grid>
				<Grid item xs={2}>
					<SimpleActionsButton
						label='Presets'
						options={presetOptions}
					></SimpleActionsButton>
				</Grid>
				{/* <Grid item xs={2} order={4}>
					{dateArr !== undefined
						? 'Last: ' + dateArr[0] + ' ' + timeString
						: ''}
				</Grid> */}
			</Grid>
		</ReportItem>
	)
}

const RawReportsPage = (props: {
	children?: any
	setLoading?: any
	errorMessage: null | string
	setErrorMessage: any
	isErrorPromptOpened: boolean
	setIsErrorPromptOpened: any
	setIsWarningPromptOpened: any
	setViewRecord: any
}) => {
	const theme = useTheme()
	const [report, setReport] = useState<Object | null>(null)
	const [reportFields, setReportFields] = useState<ReportFieldsInterface>({
		from_date: subDays(new Date(), 1),
		to_date: subDays(new Date(), 1),
		breakdowns: [],
		statistics: [],
		filters: {
			app_id: [],
			country: [],
			advertiser_name: [],
			media_source_pid: [],
			publisher: [],
			email: [],
			campaign_name: [],
			campaign_id: [],
		},
	})

	const [viewDataFor, setViewDataFor] = useState<string>('Yesterday')
	const [startDay, setStartDay] = useState<Date>(subDays(new Date(), 1))
	const [endDay, setEndDay] = useState<Date>(subDays(new Date(), 1))
	const [breakdownFields, setBreakdownFields] = useState<any>({
		Date: false,
		Month: false,
		Day: false,
		'Day of the Week': false,
		Country: false,
		'App ID': false,
		OS: false,
		'Advertiser Name': false,
		'Advertiser Owner': false,
		'Campaign Name': false,
		'Media Source Pid': false,
		'Publisher Name': false,
	})
	const [statisticsFields, setStatisticsFields] = useState<any>({
		Impressions: false,
		Clicks: false,
		Installs: false,
		'Level 2 Event': false,
		'Level 3 Event': false,
		'Level 4 Event': false,
		'Level 5 Event': false,
		Revenue: false,
		Cost: false,
		Profit: false,
		CR: false,
	})
	const [appIds, setAppIds] = useState<string[]>([])
	const [advertisers, setAdvertisers] = useState<string[]>([])
	const [user, setUser] = useState<string[]>([])
	const [pid, setPid] = useState<string[]>([])
	const [publishers, setPublishers] = useState<string[]>([])
	const [campaignNames, setCampaignNames] = useState<string[]>([])
	const [campaignIds, setCampaignIds] = useState<string[]>([])
	const [countries, setCountries] = useState<string[]>([])
	const { login } = useTypedSelector((state) => state)
	const [lastUpdatedAt, setLastUpdatedAt] = useState(undefined)
	const [isReportOpen, setIsReportOpen] = useState<boolean>(false)
	const [fileDownload, setFileDownload] = useState<any>()
	const [presetVisible, setPresetVisible] = useState(false)
	const [isSaving, setIsSaving] = useState(false)
	const [chartData, setChartData] = useState<any>(null)
	const [updatedTimeframe, setUpdatedTimeframe] = useState(false)
	const fillReport = () => {
		if (reportFields) {
			if (Array.isArray(reportFields.breakdowns)) {
				setBreakdownFields(
					reportFields.breakdowns.map((a: string) =>
						a
							.split('_')
							.map((word) => capitalizeFirstLetter(word))
							.join(' '),
					),
				)
			} else {
				const result: any = []
				for (const a in reportFields.breakdowns as any) {
					const key = a
						.split('_')
						.map((word) => capitalizeFirstLetter(word))
						.join(' ')
					result[key] = true
				}
				setBreakdownFields(result)
			}
			if (Array.isArray(reportFields.statistics)) {
				setStatisticsFields(
					reportFields.statistics.map((a: string) =>
						a
							.split('_')
							.map((word) => capitalizeFirstLetter(word))
							.join(' '),
					),
				)
			} else {
				const result: any = []
				for (const a in reportFields.statistics as any) {
					const key = a
						.split('_')
						.map((word) => capitalizeFirstLetter(word))
						.join(' ')
					result[key] = true
				}
				setStatisticsFields(result)
			}

			const fromDate = new Date(reportFields.from_date)
			const toDate = new Date(reportFields.to_date)
			setStartDay(fromDate)
			setEndDay(toDate)
			setAppIds(reportFields.filters.app_id)
			setAdvertisers(reportFields.filters.advertiser_name)
			setUser(reportFields.filters.email)
			setPid(reportFields.filters.media_source_pid)
			setPublishers(reportFields.filters.publisher)
			setCampaignNames(reportFields.filters.campaign_name)
			setCampaignIds(reportFields.filters.campaign_id)
			setCountries(reportFields.filters.country)
			setUpdatedTimeframe(true)
		}
	}
	const { getUpdatedAtAction } = useActions()
	const { getRawdataReportAction, getRawdataRefreshAction, getFiltersAction } =
		useReportActions()
	// const headers = useMemo(() => {
	// 	return {
	// 		Authorization: `Token ${login.user.token}`,
	// 	}
	// }, [login.user.token])
	const getLastRefreshed = () => {
		getUpdatedAtAction('rawdata', setLastUpdatedAt)
	}

	const goToBottom = () => {
		window.scrollTo({
			top: 700,
			behavior: 'smooth',
		})
	}

	const tryGetRealtime = async () => {
		props.setLoading(true)
		props.setIsWarningPromptOpened(true)
		await getRawdataRefreshAction(props.setErrorMessage)
		props.setLoading(false)
	}

	const trySubmit = async () => {
		props.setLoading(true)
		const breakdowns: string[] = []
		Object.keys(breakdownFields).forEach((e: string) => {
			if (breakdownFields[e] === true && e === 'advertiser_owner') {
				breakdowns.push('email')
			} else if (breakdownFields[e] === true) {
				breakdowns.push(e.toLowerCase().split(' ').join('_'))
			}
		})

		const statistics: string[] = []
		Object.keys(statisticsFields).forEach((e: string) => {
			if (statisticsFields[e] === true) {
				statistics.push(e.toLowerCase().split(' ').join('_'))
			}
		})

		const data: ReportFieldsInterface = {
			from_date: startDay,
			to_date: endDay,
			breakdowns: breakdowns,
			statistics: statistics,
			filters: {
				app_id: appIds,
				country: countries,
				advertiser_name: advertisers,
				media_source_pid: pid,
				publisher: publishers,
				email: user,
				campaign_name: campaignNames,
				campaign_id: campaignIds,
			},
		}
		setReportFields(data)
		setIsReportOpen(false)
		await getRawdataReportAction(
			data,
			setReport,
			props.setErrorMessage,
			props.setLoading,
			setIsReportOpen,
		)
		setIsReportOpen(report !== null)
		props.setLoading(false)
	}

	// useEffect(() => {
	// 	props.setLoading(false)
	// }, [props])
	useEffect(() => {
		props.setLoading(false)
	}, [])
	useEffect(() => {
		if (fileDownload) {
			saveAs(fileDownload, '*.csv')
		}
	}, [fileDownload])
	useEffect(() => {
		if (report !== null) {
			setIsReportOpen(true)
			goToBottom()
		}
		//fillReport()
	}, [report])
	useEffect(() => {
		if (!presetVisible) {
			fillReport()
		}
	}, [presetVisible])

	useEffect(() => {
		const data: any = {
			from_date: startDay,
			to_date: endDay,
			breakdowns: breakdownFields,
			statistics: statisticsFields,
			filters: {
				app_id: appIds,
				country: countries,
				advertiser_name: advertisers,
				media_source_pid: pid,
				publisher: publishers,
				email: user,
				campaign_name: campaignNames,
				campaign_id: campaignIds,
			},
		}
		setReportFields(data)
		getLastRefreshed()
	}, [
		viewDataFor,
		startDay,
		endDay,

		appIds,
		advertisers,
		user,
		pid,
		publishers,
		campaignNames,
		campaignIds,
		countries,
	])
	let dateArr
	let timeString = ''
	if (lastUpdatedAt) {
		dateArr = (lastUpdatedAt as unknown as string).split('T')
		if (dateArr !== undefined && dateArr[1] !== undefined) {
			const timeStringArr = dateArr[1].split(':')
			timeString = timeStringArr[0] + ':' + timeStringArr[1]
		}
	}
	const handleGraphicData = (report: any) => {
		if (!report || !report[0].date || !report[0].revenue || report[0].proft) {
			return null
		}
		const data: any = []
		for (const field of report) {
			if (data.length === 0) {
				data.push({
					date: field.date,
					Revenue: field.revenue,
					Profit: field.profit,
				})
			} else {
				let updated = false
				for (const alreadyIn of data) {
					if (alreadyIn.date === field.date) {
						alreadyIn.Revenue += field.revenue
						alreadyIn.Profit += field.profit
						updated = true
						break
					}
				}
				if (!updated) {
					data.push({
						date: field.date,
						Revenue: field.revenue,
						Profit: field.profit,
					})
				}
			}
		}
		data.sort((a: any, b: any) => Date.parse(a.date) - Date.parse(b.date)).pop()
		data.forEach((el: any) => {
			el.Profit = Math.floor(el.Profit)
			el.Revenue = Math.floor(el.Revenue)
		})
		setChartData(data)
	}
	useEffect(() => {
		handleGraphicData(report)
	}, [report])
	const breakdownsDiv = document.getElementById('List1Breakdown')
	const graphHeight = (breakdownsDiv?.clientHeight as number) + 240
	return (
		<ThemeProvider theme={theme}>
			<ReportGrid
				container
				spacing={'20px'}
				style={{
					marginTop: '20px',
					position: 'relative',
					marginBottom: report ? '20px' : '90px',
				}}
			>
				<Grid
					item
					xs={12}
					style={{
						color: 'black',
						marginTop: '-50px',
						display: 'flex',
						justifyContent: 'flex-end',
					}}
				>
					{dateArr !== undefined
						? 'Last: ' + dateArr[0] + ' ' + timeString
						: ''}
				</Grid>
				<Grid item xs={3} style={{ marginTop: '-20px' }}>
					<TimeframeComponent
						viewDataFor={viewDataFor}
						setViewDataFor={setViewDataFor}
						startDay={startDay}
						setStartDay={setStartDay}
						endDay={endDay}
						setEndDay={setEndDay}
						updatedTimeframe={updatedTimeframe}
						setUpdatedTimeframe={setUpdatedTimeframe}
					/>
				</Grid>
				<Grid item xs style={{ marginTop: '-20px' }}>
					<BreakdownComponent
						dataObj={reportFields}
						breakdownFields={breakdownFields}
						setBreakdownFields={setBreakdownFields}
						statisticsFields={statisticsFields}
						setStatisticsFields={setStatisticsFields}
					/>
				</Grid>

				<Grid item xs={3} style={{ marginTop: '-20px' }}>
					<FiltersComponent
						setAppIds={setAppIds}
						setAdvertisers={setAdvertisers}
						setUser={setUser}
						setPid={setPid}
						setPublishers={setPublishers}
						setCampaignNames={setCampaignNames}
						setCampaignIds={setCampaignIds}
						setCountries={setCountries}
						dataObj={reportFields}
						setDataObj={setReportFields}
						trySubmit={trySubmit}
						isReportOpen={!isReportOpen}
						user={user}
						presetVisible={presetVisible}
						setPresetVisible={setPresetVisible}
						setIsSaving={setIsSaving}
						tryGetRealtime={tryGetRealtime}
						setIsWarningPromptOpened={props.setIsWarningPromptOpened}
						lastUpdatedAt={lastUpdatedAt}
					/>
				</Grid>
			</ReportGrid>
			<div
				style={{
					width: '45%',
					display: 'flex',
					position: 'absolute',
					top: graphHeight,
					left: '28%',
				}}
			>
				{chartData && (
					<AreaChart
						data={chartData}
						categories={['Revenue', 'Profit']}
						dataKey={'date'}
						height='h-72'
						colors={['indigo', 'rose']}
						yAxisWidth='w-14'
						valueFormatter={(number: number) => {
							return '$ ' + Intl.NumberFormat('us').format(number).toString()
						}}
					></AreaChart>
				)}
			</div>
			<RawPresetsPopup
				visible={presetVisible}
				setPresetVisible={setPresetVisible}
				setRow={setReportFields}
				fillReport={fillReport}
				isSaving={isSaving}
				setPreview={props.setViewRecord}
				reportFields={reportFields}
				setIsSaving={setIsSaving}
			/>
			{report ? (
				<RawReportsTable
					report={report}
					setIsReportOpen={setIsReportOpen}
					reportFields={reportFields}
					isReportOpen={isReportOpen}
				/>
			) : (
				<></>
			)}
		</ThemeProvider>
	)
}

export default RawReportsPage
