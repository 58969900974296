import {
	DEFAULT_WS_URL,
	HTTPS_PROTOCOL,
	WSS_PROTOCOL_SECURE,
	WS_PROTOCOL,
	wsDevUrl,
} from '../config'

export class WebSocketManager {
	private socket?: WebSocket
	private messageCallback?: (message: any) => void
	private errorCallback?: (error: any) => void
	private closeCallback?: (closeEvent: CloseEvent) => void

	constructor(encryptedClientId: string) {
		const isSecure = window.location.protocol === HTTPS_PROTOCOL // Check if the page is loaded over HTTPS

		// Use WSS if over HTTPS, otherwise WS, and provide fallback if wsDevUrl is undefined
		const url =
			(isSecure
				? wsDevUrl?.replace(WS_PROTOCOL, WSS_PROTOCOL_SECURE)
				: wsDevUrl) || DEFAULT_WS_URL

		this.socket = new WebSocket(url)

		this.socket.onopen = () => {
			this.send({ clientId: encryptedClientId })
			if (this.onOpenCallback) {
				this.onOpenCallback()
			}
		}

		this.socket.onmessage = (event) => {
			const data = JSON.parse(event.data)
			if (this.messageCallback) {
				this.messageCallback(data)
			}
		}

		this.socket.onerror = (event) => {
			if (this.errorCallback) {
				this.errorCallback(event)
			}
		}

		this.socket.onclose = (event) => {
			if (this.closeCallback) {
				this.closeCallback(event)
			}
		}
	}

	private onOpenCallback?: () => void

	onOpen(callback: () => void) {
		this.onOpenCallback = callback
	}

	onMessage(callback: (message: any) => void) {
		this.messageCallback = callback
	}

	onError(callback: (error: any) => void) {
		this.errorCallback = callback
	}

	onClose(callback: (closeEvent: CloseEvent) => void) {
		this.closeCallback = callback
	}

	send(data: any) {
		this.socket?.send(JSON.stringify(data))
	}

	close() {
		this.socket?.close()
	}
}
