
import { VersionInterface } from '../../models/model.interface';
import { ActionType } from '../action-types/index'
import { Action } from '../actions'

export interface versionReducerInterface {
  version: VersionInterface | any;
}

const initialState: VersionInterface = {
	version: '',
}

const reducer = (
	state: versionReducerInterface = initialState,
	action: Action
): versionReducerInterface => {
	switch (action.type) {
	case ActionType.GETVERSION:
		return action.payload
	default:
		return state
	}
}

export default reducer