import {
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	useTheme,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useActions, useUserActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { UserInterface } from '../../models/model.interface'
import { StyledNameField } from '../components/reusableComponents'
import { UserPopUpForm } from './UserPopUpComponents'
import { InputFieldText } from '../components/Inputs'
import { SubmitButton, UnborderedButton } from '../components/Buttons'

const ResetPwdPopUp = (props: {
	PopUpVisible: boolean
	setPopUpVisible: any
	row?: UserInterface
	setLoading: any
	handleClose: any
	errorMessage: null | string
	setErrorMessage: any
	isErrorPromptOpened: boolean
	setIsErrorPromptOpened: any
}): JSX.Element => {
	const theme = useTheme()
	const { resetPassword } = useUserActions()
	const { login } = useTypedSelector((state) => state)
	const [password, setPassword] = useState<string | null>(null)
	const [passwordVerify, setPasswordVerify] = useState<string | null>(null)
	const [isValid, setIsValid] = useState<boolean>(false)
	const [strength, setStrength] = useState('weak')
	const nullifyFields = () => {
		setPassword(null)
	}
	const handlePasswordVerification = (password: string) => {
		let strongPassword = new RegExp(
			'(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})',
		)
		if (strongPassword.test(password)) {
			setStrength('strong')
		} else {
			setStrength('weak')
		}
	}
	const trySubmit = async () => {
		if (isValid) {
			const payload = {
				email: props.row!.email,
				newPassword: password!,
				user: login.user.email,
			}
			// const headers = {
			// 	Authorization: `Token ${login.user.token}`,
			// }
			props.setLoading(true)
			await resetPassword(payload, props.setErrorMessage)
			props.setLoading(false)

			nullifyFields()
			props.handleClose()
		}
	}

	useEffect(() => {
		handlePasswordVerification(password || '')
		setIsValid(
			password === passwordVerify &&
				!!passwordVerify &&
				!!password &&
				strength! !== 'weak',
		)
	}, [password, passwordVerify])

	return (
		<UserPopUpForm open={props.PopUpVisible}>
			<DialogTitle
				style={{
					background: theme.colors.base.white,
					color: theme.colors.text.titles,
				}}
			>
				Reset Password for {props.row?.name || 'username'}
			</DialogTitle>
			<DialogContent
				style={{
					background: theme.colors.base.white,
					color: theme.colors.text.titles,
				}}
			>
				<DialogContentText></DialogContentText>
				<InputFieldText
					label='Email Address'
					type='email'
					value={props.row?.email || login.user.email}
				/>
				<InputFieldText
					value={password}
					label={`New Password ${
						strength === 'weak' ? '(Weak 😖)' : '(Strong 🤩)'
					}`}
					type='password'
					color={strength === 'weak' ? 'error' : 'success'}
					onChange={setPassword}
					hint={`At least 8 Characters, Capital letters, Lower letters, Numbers, Symbols (%$#@!^&*)`}
				/>
				<InputFieldText
					value={passwordVerify}
					label='Verify Password'
					type='password'
					color={strength === 'weak' ? 'error' : 'success'}
					onChange={setPasswordVerify}
				/>
			</DialogContent>
			<DialogActions
				style={{
					background: theme.colors.base.white,
					color: theme.colors.text.titles,
				}}
			>
				<UnborderedButton
					style={{ margin: 'auto' }}
					onClick={() => {
						nullifyFields()
						props.handleClose()
					}}
					label={'Cancel'}
				></UnborderedButton>
				<SubmitButton
					onClick={() => trySubmit()}
					style={{ margin: 'auto' }}
					disabled={!isValid}
				>
					Save
				</SubmitButton>
			</DialogActions>
		</UserPopUpForm>
	)
}

export default ResetPwdPopUp
