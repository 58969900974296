import { ActionType } from '../action-types/index'
import { Action } from '../actions'

export interface navigationReducerInterface {
	page: string
	index: number
}

const initialState: navigationReducerInterface = {
	page: '',
	index: 0,
}

const reducer = (
	state: navigationReducerInterface | any = initialState,
	action: Action
): navigationReducerInterface => {
	switch (action.type) {
	case ActionType.NAVIGATION:
		return action.payload
	default:
		return state
	}
}

export default reducer
