import {
	Dialog,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	styled,
} from '@mui/material'
import React from 'react'

export const UserTypeSelect = (props: { onChange: any }) => {
	return (
		<FormControl fullWidth variant='standard'>
			<InputLabel required id='demo-simple-select-label'>
				User Type
			</InputLabel>
			<Select
				labelId='demo-simple-select-label'
				id='demo-simple-select'
				label='User Type'
				onChange={props.onChange}
			>
				<MenuItem value={'Admin'}>Admin (Full Access)</MenuItem>
				<MenuItem value={'Account Manager'}>Account Manager (Limited)</MenuItem>
				<MenuItem value={'Publisher Manager'}>
					Publisher Manager (Limited)
				</MenuItem>
				<MenuItem value={'Sales'}>Sales (Limited)</MenuItem>
			</Select>
		</FormControl>
	)
}

export const UserPopUpForm = styled(Dialog)(({ theme }) => ({
	margin: 'auto',
	width: '100%',
}))
