import CloseIcon from '@mui/icons-material/Close'
import {
	Checkbox,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	MenuItem,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { format } from 'date-fns'
import { useEffect, useMemo, useState } from 'react'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { fetchData } from '../../utils/helpers/navigationHelper'
import {
	findUsernameByEmail,
	generateRowsWithIds,
} from '../../utils/helpers/tableHelper'
import { PresetDialog } from '../components/reusableComponents'
import { StyledCheckboxFormControl } from './FiltersStyled'
import { InputFieldText } from '../components/Inputs'
import { SimpleActionsButton, SubmitButton } from '../components/Buttons'

export const PresetsPopup = ({
	visible,
	setPresetVisible,
	setRow,
	fillReport,
	isSaving,
	setIsSaving,
	reportFields,
	setPreview,
}: {
	visible: boolean
	setPresetVisible: any
	setRow: any
	fillReport: any
	isSaving?: boolean
	setIsSaving: any
	setPreview: any
	reportFields: any
}) => {
	const theme = useTheme()
	const rowCount = 4
	const { preset, login, users } = useTypedSelector((state) => state)
	const { getPresetsAction, updatePresetAction } = useActions()
	const [rowWidth, setRowWidth] = useState(Math.floor(600 / rowCount))
	const [presetName, setPresetName] = useState('')
	const [showOwnPresets, setShowOwnPresets] = useState(false)
	const [update, setUpdate] = useState(false)
	// const headers = useMemo(() => {
	// 	return {
	// 		Authorization: `Token ${login.user.token}`,
	// 	}
	// }, [login.user.token])
	const trySavePreset = async () => {
		const index = preset.find((e: any) => e.name === presetName)
		const data = {
			id: index ? (index as any).id : preset.length + 1,
			name: presetName,
			email: login.user.email,
			report: JSON.stringify(reportFields),
		}
		await updatePresetAction(data)
	}
	const relativeTheDate = (preset: any) => {
		const a = JSON.parse(preset.report)
		const from: Date = new Date(a.from_date)
		const to: Date = new Date(a.to_date)
		const createdAt = new Date(preset.createdAt)
		const result1 = createdAt.getTime() - from.getTime()
		const result2 = createdAt.getTime() - to.getTime()
		const now = new Date().getTime()
		const result = {
			...a,
			from_date: new Date(now - result1).toDateString(),
			to_date: new Date(now - result2).toDateString(),
		}
		setRow(result)
		return result
	}

	const trySubmit = async () => {
		if (!isSaving) {
			await getPresetsAction(login.user.email)
			return
		}
		await trySavePreset()
		setIsSaving(false)
		setPresetVisible(false)
		await fetchData(getPresetsAction, preset)
	}
	const columns: GridColDef[] = [
		{
			field: 'name',
			headerName: 'Name',
			width: rowWidth,
			renderCell: ({ value }) => (
				<Tooltip title={value}>
					<Typography sx={{ fontWeight: 500, fontSize: 14 }}>
						{value}
					</Typography>
				</Tooltip>
			),
		},
		{
			field: 'email',
			headerName: 'Owner',
			width: rowWidth / 2,
			//align: 'center',
			valueFormatter: ({ value }) =>
				value ? findUsernameByEmail(value.toString(), users.users) : 'User',
		},
		{
			field: 'createdAt',
			headerName: 'Created at',
			width: rowWidth / 1.5,
			valueFormatter: ({ value }) =>
				value ? format(new Date(value.toString()), 'MM/dd/yyyy') : 'Now',
		},
		{
			field: 'actions',
			headerName: 'Actions',
			width: rowWidth / 2,
			renderCell: (row) => (
				<SimpleActionsButton
					// width={rowWidth}
					// row={row}
					label={'Actions'}
					options={actionOptions(row)}
					// style={{ width: '50px', height: '30px', fontSize: '30px' }}
				/>
			),
		},
	]
	const actionOptions = (row: any) => {
		return (
			<div>
				<MenuItem
					onClick={() => {
						setPreview(JSON.parse(row.row.report))
						//setIsWarningPromptOpened(true)
					}}
				>
					View
				</MenuItem>
				<MenuItem
					onClick={() => {
						//setIsWarningPromptOpened(true)
					}}
				>
					Delete
				</MenuItem>
			</div>
		)
	}
	const handleUpdate = async () => {
		await getPresetsAction(showOwnPresets ? login.user.email : '')
		setUpdate(!update)
	}
	useEffect(() => {
		handleUpdate()
	}, [visible, showOwnPresets])
	return visible ? (
		<PresetDialog open={visible}>
			<DialogTitle>
				<IconButton
					aria-label='close'
					onClick={() => {
						setPresetVisible(false)
					}}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
						fontFamily: theme.typography.fontFamily,
						fontSize: '13px',
						fontWeight: 500,
					}}
				>
					Cancel
					<CloseIcon style={{ color: theme.colors.red }} />
				</IconButton>
			</DialogTitle>

			<DialogContent>
				<StyledCheckboxFormControl
					key={'timeframeControl'}
					control={
						<Checkbox
							onChange={() => setShowOwnPresets(!showOwnPresets)}
							checked={showOwnPresets}
							key={'timeframe'}
							inputProps={{
								'aria-label': 'controlled',
							}}
							style={{ marginLeft: '25px', color: '#ee1d4a' }}
							color='primary'
						/>
					}
					label={
						<Typography fontSize='13px' fontWeight={500} color={'secondary'}>
							{'Show my own presets'}
						</Typography>
					}
				/>

				{!isSaving ? (
					<DataGrid
						columns={columns}
						rows={generateRowsWithIds(preset)}
						pageSizeOptions={[15, 25, 50]}
						showColumnVerticalBorder={true}
						onRowClick={(row: any) => {
							if (row.row.report) {
								relativeTheDate(row.row)
							}
							//fillReport()
							setPresetVisible(false)
						}}
						style={{ height: '400px' }}
					></DataGrid>
				) : (
					<InputFieldText
						label='Preset Name'
						type='text'
						value={presetName}
						onChange={setPresetName}
					/>
				)}
			</DialogContent>

			<DialogActions>
				<SubmitButton
					style={{ margin: 'auto' }}
					onClick={() => {
						setPresetVisible(false)
					}}
				>
					Cancel
				</SubmitButton>
				<SubmitButton
					//disabled={!isValid}
					style={{ margin: 'auto' }}
					onClick={() => trySubmit()}
				>
					Save
				</SubmitButton>
			</DialogActions>
		</PresetDialog>
	) : (
		<></>
	)
}
