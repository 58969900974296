import { Dispatch } from 'redux'
import { ActionType } from '../action-types'
import { Action } from '../actions/index'
import AxiosCustom from '../../utils/Axios'
import { permissionNames } from '../../utils/helpers/permissionsHelper'
import {
	endpoints,
	reportEndpoints,
	reverseReportTypeMapping,
} from '../../config'
import { encryptMessageForWebSocket } from '../../crypto/cryptoUtils'
import {
	checkForExistingReport,
	fetchReportData,
	handleError,
	handleWebSocketMessage,
	openWebSocketConnection,
	postReportRequest,
	prepareDataForRequest,
} from '../../utils/helpers/reportHelperFuncs'
import { NetworkFieldsInterface } from '../../models/reports.interface'
import { subDays } from 'date-fns'
import { addAlert, removeAlert, setTabIndex } from './alertActions'
import { v4 as uuidv4 } from 'uuid'
import { ALERT_STATUS_PENDING } from '../../models/alert.interface'

interface ReportFieldsInterface {
	from_date: Date
	to_date: Date
	breakdowns: string[]
	statistics: string[]
	filters: any
	user_email?: string
}

export function delay(milliseconds: number) {
	return new Promise((resolve) => {
		setTimeout(resolve, milliseconds)
	})
}
/**
 * Action creator for generating a report based on the provided report fields.
 *
 * This function handles the entire process of generating a report. It checks
 * if a report with the same parameters has already been generated (to avoid duplication),
 * opens a WebSocket connection for report generation, and handles responses
 * received over WebSocket.
 *
 * @param {any} headers - The headers for the report request.
 * @param {any} reportFields - The fields used for generating the report (breakdowns, statistics).
 * @param {keyof typeof reportEndpoints} reportType - The type of report to be generated.
 * @param {function} setErrorPopup - Function to display an error popup message.
 * @param {function} setLoading - Function to set the loading state during report generation.
 * @param {function} setIsReportOpen - Function to control the visibility of the report modal.
 * @param {string} userEmail - The email of the user generating the report.
 *
 * @returns {function} A thunk function that handles the report generation process.
 */
export const generateReportAction = (
	headers: any,
	reportFields: any,
	reportType: keyof typeof reportEndpoints,
	setErrorPopup: any,
	setLoading: any,
	setIsReportOpen: any,
	userEmail: string,
	alertActions: { updateAlert: any; addAlert: any },
): ((dispatch: Dispatch<Action>, getState: () => any) => Promise<any>) => {
	// Thunk function that gets dispatched
	return async (dispatch: Dispatch<Action>, getState: () => any) => {
		return new Promise(async (resolve, reject) => {
			const alertId = uuidv4() // Generate a unique ID for the alert

			try {
				// Mapping report type and validating its existence
				const mappedReportType = reverseReportTypeMapping[reportType]
				if (!mappedReportType) {
					throw new Error(`Invalid report type: ${reportType}`)
				}

				setLoading(true)
				dispatch({ type: ActionType.GENERATE_REPORT_START })

				// Prepare data for request
				const breakdowns = reportFields.breakdowns.map((e: string) =>
					e.toLowerCase().split(' ').join('_'),
				)
				const statistics = reportFields.statistics.map((e: string) =>
					e.toLowerCase().split(' ').join('_'),
				)
				const clientId = `client_${Date.now()}`
				const encryptedClientId = await encryptMessageForWebSocket(clientId)
				const data = prepareDataForRequest(
					reportFields,
					breakdowns,
					statistics,
					encryptedClientId,
					userEmail,
				)

				const reportUuid = data.uuid

				dispatch(
					addAlert(
						'Generating report',
						false,
						undefined,
						reportUuid,
						alertId,
						ALERT_STATUS_PENDING,
					),
				)

				const state = getState()
				const userReports = state.reports.reports[userEmail] || {}
				const reports = userReports[mappedReportType] || []
				const existingReport = checkForExistingReport(
					state,
					userEmail,
					reportUuid,
				)

				if (existingReport) {
					// Remove the pending alert and resolve with existing report data
					dispatch(removeAlert(alertId))
					const existReportData = await fetchReportData(
						existingReport.s3_file_url,
					)
					const existingIndex = reports.findIndex(
						(report: any) => report.uuid === existingReport.uuid,
					)
					if (existingIndex !== -1) {
						dispatch<any>(
							setTabIndex(userEmail, mappedReportType, existingIndex),
						)
					}
					resolve({ data: existReportData, index: existingIndex })
					return
				}

				// Open WebSocket connection for report generation
				const ws = openWebSocketConnection(
					encryptedClientId,
					dispatch,
					setErrorPopup,
					setIsReportOpen,
					setLoading,
					resolve,
					reject,
					userEmail,
					reportType,
					reportUuid,
					alertActions,
					alertId,
				)
				ws.onOpen(() => {
					postReportRequest(
						data,
						reportType,
						setErrorPopup,
						setIsReportOpen,
						setLoading,
						reject,
						dispatch,
						alertActions,
						alertId,
					)
				})

				ws.onMessage(async (message: any) => {
					await handleWebSocketMessage(
						message,
						dispatch,
						setErrorPopup,
						setIsReportOpen,
						setLoading,
						resolve,
						reject,
						userEmail,
						reportType,
						alertActions,
						alertId,
					)
				})
			} catch (err: unknown) {
				handleError(
					dispatch,
					setErrorPopup,
					setIsReportOpen,
					setLoading,
					reject,
					(err as Error).message,
					reportType,
					alertActions,
					alertId, // Pass alertId here
				)
			}
		})
			.then(() => {
				dispatch(loadAllReportsAction(userEmail) as any)
			})
			.catch((error: any) => {
				dispatch({
					type: ActionType.GENERATE_REPORT_FAILURE,
					payload: error.message,
				})
				setLoading(false)
				setIsReportOpen(false)
			})
	}
}

export const loadAllReportsAction = (userEmail: string) => {
	return async (dispatch: Dispatch<Action>) => {
		dispatch({ type: ActionType.LOAD_ALL_REPORTS_START })
		try {
			const response = await AxiosCustom.get(
				`${endpoints.REPORTS}${reportEndpoints.ALL_REPORTS_PER_USER}`,
				{
					params: { user_email: userEmail },
				},
			)

			if (response.data.successful) {
				const allReports = response.data.payload

				const networkReports = allReports.filter(
					(report: any) => report.type === 'NETWORK',
				)
				const invalidReports = allReports.filter(
					(report: any) => report.type === 'INVALID',
				)
				const aggregatedReports = allReports.filter(
					(report: any) => report.type === 'AGGREGATED',
				)

				dispatch({
					type: ActionType.LOAD_ALL_REPORTS_SUCCESS,
					payload: {
						userEmail,
						reports: {
							network: networkReports,
							invalid: invalidReports,
							aggregated: aggregatedReports,
						},
					},
				})
			} else {
				throw new Error('Failed to load reports')
			}
		} catch (error: any) {
			dispatch({
				type: ActionType.LOAD_ALL_REPORTS_FAILURE,
				payload: error.message,
			})
		}
	}
}

export const updateReportNameAction = (
	headers: any,
	reportId: number,
	newName: string,
	setErrorPopup: (message: string) => void,
) => {
	return async (dispatch: Dispatch<Action>) => {
		dispatch({ type: ActionType.UPDATE_REPORT_NAME_START })
		try {
			const { data } = await AxiosCustom.put(
				`${endpoints.REPORTS}/${reportId}/name`,
				{ newName },
				{ headers },
			)

			if (!data.successful) {
				throw new Error(data.payload)
			}

			dispatch({
				type: ActionType.UPDATE_REPORT_NAME_SUCCESS,
				payload: { reportId, newName },
			})
		} catch (error: any) {
			setErrorPopup(error.message)
			dispatch({
				type: ActionType.UPDATE_REPORT_NAME_FAILURE,
				payload: error.message,
			})
		}
	}
}

export const deleteReportAction = (
	headers: any,
	reportId: number,
	setErrorPopup: (message: string) => void,
) => {
	return async (dispatch: Dispatch<Action>) => {
		dispatch({ type: ActionType.DELETE_REPORT_START })
		try {
			const { data } = await AxiosCustom.delete(
				`${endpoints.REPORTS}/${reportId}`,
				{ headers },
			)

			if (!data.successful) {
				throw new Error(data.payload)
			}

			dispatch({
				type: ActionType.DELETE_REPORT_SUCCESS,
				payload: { reportId },
			})
		} catch (error: any) {
			setErrorPopup(error.message)
			dispatch({
				type: ActionType.DELETE_REPORT_FAILURE,
				payload: error.message,
			})
		}
	}
}

export const getNetworkAction = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.NETWORK, {
				headers: {
					'x-permission-name': permissionNames.VIEW_NETWORK,
				},
			})

			if (!data.successful) {
				return
			}
			dispatch({
				type: ActionType.GET_NETWORK,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

export const postNetworkAction = (obj: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const payload = { network: obj }
			const { data } = await AxiosCustom.post(endpoints.NETWORK, payload, {
				headers: {
					'x-permission-name': permissionNames.ADD_EDIT_NETWORK,
				},
			})
			if (!data.successful) {
				return
			}
			dispatch({
				type: ActionType.POST_NETWORK,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

export const getNetworksFiltersAction = (
	// headers: any,
	obj: NetworkFieldsInterface,
	setData: any,
	setErrorPopup: any,
	setLoading: any,
	setIsReportOpen: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			setLoading(true)
			const filters = { network: obj }
			const { data } = await AxiosCustom.post(endpoints.NETWORK, filters, {
				headers: {
					'x-permission-name': permissionNames.VIEW_NETWORK,
				},
			})

			if (!data.successful) {
				setIsReportOpen(false)
				setData(null)
				setErrorPopup(data.payload)
				setLoading(false)
				return
			}
			const timer = Date.now()
			let resultStatus: any = {}
			const pl = { storage: { id: data.payload } }
			let result: any = {}
			while (resultStatus.successful !== true) {
				try {
					if ((Date.now() - timer) / 1000 > 300) {
						break
					}
					result = await AxiosCustom.post(endpoints.STORAGE, pl)

					if (result.data) {
						resultStatus = result.data
					}
				} catch (err) {
					console.error(err)
				}
				await delay(5000)
			}
			if (result.data.payload === 'pending') {
				setErrorPopup('Error, timeout.')
			}
			result = await AxiosCustom.post(endpoints.STORAGE + 'get', pl)
			dispatch({
				type: ActionType.POST_NETWORK,
				payload: result.data.payload.report,
			})

			setData(result.data.payload.report)
			setIsReportOpen(true)
			setLoading(false)
		} catch (err) {
			setIsReportOpen(false)
			setData(null)
			setErrorPopup(err)
			setLoading(false)
			console.error(err)
		}
	}
}

export const getRawdataReportAction = (
	// headers: any,
	obj: any,
	setData: any,
	setErrorPopup: any,
	setLoading: any,
	setIsReportOpen: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			setLoading(true)
			const filters = { rawdata: obj }
			const { data } = await AxiosCustom.post(endpoints.RAWDATA, filters, {
				headers: {
					'x-permission-name': permissionNames.VIEW_RAWDATA,
				},
			})

			const timer = Date.now()
			if (!data.successful) {
				setIsReportOpen(false)
				setData(null)
				setErrorPopup(data.payload)
				setLoading(false)
				return
			}

			let resultStatus: any = {}
			const pl = { storage: { id: data.payload } }
			let result: any = {}
			while (resultStatus.successful !== true) {
				try {
					if ((Date.now() - timer) / 1000 > 300) {
						break
					}
					result = await AxiosCustom.post(endpoints.STORAGE, pl) // TODO: MATAN -> storage permissions?

					if (result.data) {
						resultStatus = result.data
					}
				} catch (err) {
					console.error(err)
				}
				await delay(5000)
			}
			result = await AxiosCustom.post(endpoints.STORAGE + 'get', pl) // TODO: MATAN -> storage permissions?
			// await AxiosCustom.post(endpoints.STORAGE, filters, {
			// 	headers,
			// })
			dispatch({
				type: ActionType.POST_RAWDATA,
				payload: result.data.payload.report,
			})

			setData(result.data.payload.report)
			setIsReportOpen(true)
		} catch (err) {
			setIsReportOpen(false)
			setData(null)
			setErrorPopup(err)
			setLoading(false)
			console.error(err)
		}
	}
}

export const getRawdataRefreshAction = (setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const obf = {
				rawdata: {
					from_date: subDays(new Date(), 1),
					to_date: new Date(),
				},
			}
			const { data } = await AxiosCustom.post(
				endpoints.RAWDATA + '/update',
				obf,
				{
					headers: {
						'x-permission-name': permissionNames.VIEW_RAWDATA,
					},
				},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			dispatch({
				type: ActionType.GET_RAWDATA,
				payload: data.payload,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}

export const getInvalidAction = (
	// headers: any,
	obj: ReportFieldsInterface,
	setData?: any,
	setErrorPopup?: any,
	setLoading?: any,
	setIsReportOpen?: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			setLoading(true)
			const filters = { invalid: obj }
			const { data } = await AxiosCustom.post(endpoints.INVALID, filters, {
				headers: {
					'x-permission-name': permissionNames.VIEW_INVALID,
				},
			})
			const timer = Date.now()
			if (!data.successful) {
				setIsReportOpen(false)
				setData(null)
				setErrorPopup(data.payload)
				setLoading(false)
				return
			}

			let resultStatus: any = {}
			const pl = { storage: { id: data.payload } }
			let result: any = {}
			while (resultStatus.successful !== true) {
				try {
					if ((Date.now() - timer) / 1000 > 300) {
						break
					}
					result = await AxiosCustom.post(endpoints.STORAGE, pl)

					if (result.data) {
						resultStatus = result.data
					}
				} catch (err) {
					console.error(err)
				}
				await delay(5000)
			}
			if (result.data.payload === 'pending') {
				setErrorPopup('Error, timeout.')
			}
			result = await AxiosCustom.post(endpoints.STORAGE + 'get', pl)

			// await AxiosCustom.post(endpoints.STORAGE, filters, {
			// 	headers,
			// })
			dispatch({
				type: ActionType.SEND_APPSFLYER,
				payload: result.data.payload.report,
			})

			setData(result.data.payload.report)
			setIsReportOpen(true)

			// setData(data.payload)
			// setIsReportOpen(true)
			setLoading(false)
		} catch (err) {
			setIsReportOpen(false)
			setData(null)
			setErrorPopup(err)
			setLoading(false)
			console.error(err)
		}
	}
}

export const getFiltersAction = (
	// headers: any,
	obj: ReportFieldsInterface,
	setData?: any,
	setErrorPopup?: any,
	setLoading?: any,
	setIsReportOpen?: any,
	report?: any,
	setGraph?: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			setLoading(true)
			const filters = { appsflyer: obj }
			const { data } = await AxiosCustom.post(endpoints.APPSFLYER, filters, {
				headers: {
					'x-permission-name': permissionNames.VIEW_AGGREGATED,
				},
			})
			const timer = Date.now()
			if (!data.successful) {
				setIsReportOpen(false)
				setData(null)
				setGraph(null)
				setErrorPopup(data.payload)
				setLoading(false)
				return
			}

			let resultStatus: any = {}
			const pl = { storage: { id: data.payload } }
			let result: any = {}
			while (resultStatus.successful !== true) {
				try {
					if ((Date.now() - timer) / 1000 > 300) {
						break
					}
					result = await AxiosCustom.post(endpoints.STORAGE, pl) // TODO: matan -> what are we doing for the storage?

					if (result.data) {
						resultStatus = result.data
					}
					if (result.data.payload === 'failed') {
						setErrorPopup('Error')
					}
				} catch (err) {
					console.error(err)
				}
				await delay(5000)
			}
			result = await AxiosCustom.post(endpoints.STORAGE + 'get', pl) // TODO: matan -> what are we doing for the storage?
			if (result.data.payload === 'pending') {
				setErrorPopup('Error, timeout.')
			}

			// await AxiosCustom.post(endpoints.STORAGE, filters, {
			// 	headers,

			dispatch({
				type: ActionType.SEND_APPSFLYER,
				payload: result.data.payload.report.report,
			})
			setGraph(result.data.payload.report.graph)
			setData(result.data.payload.report.report)
			setIsReportOpen(true)

			// setData(data.payload)
			// setIsReportOpen(true)
			setLoading(false)
		} catch (err) {
			// setIsReportOpen(!!report)
			// setData(null)
			setErrorPopup(err)
			setLoading(false)
			console.error(err)
		}
	}
}

export const getAdjustFiltersAction = (
	// headers: any,
	obj: ReportFieldsInterface,
	setData: any,
	setErrorPopup: any,
	setLoading: any,
	setIsReportOpen: any,
	report: any,
	setGraph: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			setLoading(true)
			const filters = { adjust: obj }
			const { data } = await AxiosCustom.post(endpoints.ADJUST, filters)
			const timer = Date.now()
			if (!data.successful) {
				setIsReportOpen(false)
				setData(null)
				setGraph(null)
				setErrorPopup(data.payload)
				setLoading(false)
				return
			}

			let resultStatus: any = {}
			const pl = { storage: { id: data.payload } }
			let result: any = {}
			while (resultStatus.successful !== true) {
				try {
					if ((Date.now() - timer) / 1000 > 300) {
						break
					}
					result = await AxiosCustom.post(endpoints.STORAGE, pl)

					if (result.data) {
						resultStatus = result.data
					}
					if (result.data.payload === 'failed') {
						setErrorPopup('Error')
					}
				} catch (err) {
					console.error(err)
				}
				await delay(5000)
			}
			result = await AxiosCustom.post(endpoints.STORAGE + 'get', pl)
			if (result.data.payload === 'pending') {
				setErrorPopup('Error, timeout.')
			}

			// await AxiosCustom.post(endpoints.STORAGE, filters, {
			// 	headers,

			dispatch({
				type: ActionType.SEND_APPSFLYER,
				payload: result.data.payload.report.report,
			})

			setGraph(result.data.payload.report.graph)
			setData(result.data.payload.report)
			setIsReportOpen(true)

			// setData(data.payload)
			// setIsReportOpen(true)
			setLoading(false)
		} catch (err) {
			// setIsReportOpen(!!report)
			// setData(null)
			setErrorPopup(err)
			setLoading(false)
			console.error(err)
		}
	}
}
