import { ReactNode } from 'react'
import {
	ReportItem,
	ReportItemHeading,
} from '../reports/aggregated/ReportsStyled'
import { Typography, useTheme } from '@mui/material'

export const LabeledListSection = (props: {
	title: string
	children: ReactNode
}) => {
	const theme = useTheme()
	return (
		<ReportItem style={{ padding: '2rem 4rem' }}>
			<Typography
				variant='h6'
				sx={{
					color: theme.colors.text.titles,
					fontFamily: 'Roboto',
					marginBottom: '1rem',
				}}
			>
				{props.title}
			</Typography>
			{props.children}
		</ReportItem>
	)
}
