import { NewsInterface } from '../../models/model.interface'
import { ActionType } from '../action-types/index'
import { Action } from '../actions'

export interface GetSettingsInterface {
	settings: NewsInterface | any
}

const initialState: GetSettingsInterface = {
	settings: [],
}

const reducer = (
	state: GetSettingsInterface = initialState,
	action: Action,
): GetSettingsInterface => {
	switch (action.type) {
		case ActionType.GET_SETTINGS: {
			return {
				...state,
				settings: action.payload,
			}
		}
		case ActionType.UPDATE_SETTINGS:
			return {
				...state,
				settings: action.payload,
			}
		case ActionType.LOGOUT:
			return {
				...state,
				settings: [],
			}
		default: {
			return state
		}
	}
}

export default reducer
