import { useTheme } from '@mui/material'

const HorizontalLine = ({ style = {} }) => {
	const theme = useTheme()
	return (
		<div
			style={{
				borderBottom: '1px solid ' + theme.colors.base.grey300,
				width: '100%',
				marginTop: '20px',
				marginBottom: '20px',
				...style, // Override default styles with any custom styles passed in
			}}
		></div>
	)
}

export default HorizontalLine
