import React, {
	useEffect,
	ReactNode,
	useState,
	useMemo,
	useCallback,
} from 'react'
import { saveAs } from 'file-saver'
import styled, { ThemeProvider } from 'styled-components'
import { Box, Grid, Typography, useTheme } from '@mui/material'
import { RadioChipList } from '../../components/ChipInput'
import { LabeledListSection } from '../../components/Lists'
import { UnborderedButton, SubmitButton } from '../../components/Buttons'
import { ReactComponent as DownloadIcon } from '../../../assets/svg/download-icon.svg'
import { convertToCSV } from '../../../utils/helpers/helperFuncs'
import { LoadingOverlay } from '../../../assets/svg/loading'
import { reportTypeMapping } from '../../../config'
import CustomModal from '../../components/CustomModal'
import { useAlertsActions, useReportActions } from '../../../hooks/useActions'
import { ReportType } from '../../../models/reports.interface'
import { useDispatch } from 'react-redux'

const DownloadButtonContainer = styled(Box)(({ theme }) => ({
	position: 'absolute',
	top: '3rem',
	right: '13rem',
	[theme.breakpoints.down('sm')]: {
		position: 'relative',
		top: 'auto',
		right: 'auto',
		textAlign: 'center',
	},
}))

const LoaderContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	position: 'fixed',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '100%',
	height: '100%',
	zIndex: 9999,
}))

const BlurOverlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(10, 2, 9, 0.1);
	backdrop-filter: blur(6px);
	z-index: 1200;
`

interface ReportsPageLayoutProps {
	filtersComponentList: ReactNode
	breakdownOptions: string[]
	statisticOptions: string[]
	reportFields: any
	setReportFields: React.Dispatch<React.SetStateAction<any>>
	getReportAction: any
	saveAction: any
	refreshAction?: any
	setLoading: any
	setErrorMessage: any
	isErrorPromptOpened: boolean
	setIsErrorPromptOpened: any
	setIsWarningPromptOpened: any
	viewRecord: any
	setViewRecord: any
	breakdownFields: string[]
	setBreakdownFields: React.Dispatch<React.SetStateAction<string[]>>
	statisticsFields: string[]
	setStatisticsFields: React.Dispatch<React.SetStateAction<string[]>>
	presetVisible: boolean
	setPresetVisible: React.Dispatch<React.SetStateAction<boolean>>
	setReport: React.Dispatch<React.SetStateAction<any>>
	isSaving: boolean
	setIsSaving: React.Dispatch<React.SetStateAction<boolean>>
	isReportOpen: boolean
	setIsReportOpen: React.Dispatch<React.SetStateAction<boolean>>
	downloadButtonRef: React.RefObject<HTMLButtonElement>
	ReportGrid: any
	ReportStyles: any
	fileDownload: any
	setFileDownload: React.Dispatch<React.SetStateAction<any>>
	headers?: any
	login: any
	report?: any
	columns?: any
	loading?: boolean
	reportType: ReportType
	userEmail: string
	selectedReport?: any
	onRunReport?: () => Promise<void>
}

const ReportsPageLayout: React.FC<ReportsPageLayoutProps> = ({
	filtersComponentList,
	breakdownOptions,
	statisticOptions,
	reportFields,
	setReportFields,
	setLoading,
	setErrorMessage,
	breakdownFields,
	setBreakdownFields,
	statisticsFields,
	setStatisticsFields,
	setReport,
	setIsReportOpen,
	downloadButtonRef,
	ReportGrid,
	ReportStyles,
	fileDownload,
	headers,
	report,
	loading,
	reportType,
	userEmail,
	selectedReport,
	onRunReport,
}) => {
	const theme = useTheme()
	const { StyledBox } = ReportStyles
	const [isRunEnabled, setIsRunEnabled] = useState(false)
	const [isDownloadEnabled, setIsDownloadEnabled] = useState(false)
	const [isRunModalOpen, setIsRunModalOpen] = useState(false)
	const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
	const [isCancelEnabled, setIsCancelEnabled] = useState(false)
	const [currentReport, setCurrentReport] = useState<any>(null)
	const { generateReportAction } = useReportActions()
	const dispatch = useDispatch()
	const alertActions = useAlertsActions()

	const memoizedBreakdownFields = useMemo(
		() => breakdownFields,
		[breakdownFields],
	)
	const memoizedStatisticsFields = useMemo(
		() => statisticsFields,
		[statisticsFields],
	)

	useEffect(() => {
		// Check if date range is selected and valid
		const isDateRangeSelected =
			reportFields.from_date !== '' && reportFields.to_date !== ''

		// Check if any filter other than the date range is selected
		const isOtherFilterSelected = reportFields.filters
			? Object.values(reportFields.filters).some(
					(filterArray: any) => filterArray.length > 0 && filterArray[0] !== '',
			  )
			: false

		const isBreakdownSelected = breakdownFields.length > 0
		const isStatisticSelected = statisticsFields.length > 0

		// Enable the Run button only if the date range is valid and other required fields are selected
		setIsRunEnabled(
			isDateRangeSelected && isBreakdownSelected && isStatisticSelected,
		)

		// Enable the Cancel button if any of the fields are filled
		setIsCancelEnabled(
			isDateRangeSelected ||
				isOtherFilterSelected ||
				isBreakdownSelected ||
				isStatisticSelected,
		)
	}, [reportFields, breakdownFields, statisticsFields])

	useEffect(() => {
		setIsDownloadEnabled(report && report.length > 0)
	}, [report])

	useEffect(() => {
		if (selectedReport) {
			setCurrentReport(selectedReport)
		}
	}, [selectedReport])

	const handleDownload = () => {
		if (selectedReport && Array.isArray(selectedReport)) {
			const csv = convertToCSV(selectedReport)
			if (csv) {
				const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
				saveAs(blob, `${reportType}_report_${Date.now()}.csv`)
			} else {
				console.warn('CSV conversion returned null or undefined.')
			}
		} else {
			console.warn('No report data available to download.')
		}
	}

	const tryDownload = () => {
		if (isDownloadEnabled) {
			handleDownload()
		}
	}

	const handleDownloadClick = useCallback(() => {
		tryDownload()
	}, [tryDownload])

	const trySubmit = async () => {
		setIsRunModalOpen(true)
	}

	const handleConfirmRun = async () => {
		setIsRunModalOpen(false)
		setLoading(true) // Show the loader

		const breakdowns = breakdownFields.length > 0 ? breakdownFields : ['date']
		const statistics =
			statisticsFields.length > 0 ? statisticsFields : ['impressions']

		const filledFilters = Object.fromEntries(
			Object.entries(reportFields.filters).map(([key, value]) => [
				key,
				(value as string[]).filter((v) => v !== ''), // filter out empty strings
			]),
		)

		const data = {
			...reportFields,
			breakdowns,
			statistics,
			filters: filledFilters,
			from_date: reportFields.from_date,
			to_date: reportFields.to_date,
			user_email: userEmail,
		}

		setReportFields(data)

		try {
			// Dispatch the async action properly
			const generatedReport = await dispatch(
				generateReportAction(
					headers,
					data,
					reportTypeMapping[reportType],
					setErrorMessage,
					setLoading,
					setIsReportOpen,
					userEmail,
					alertActions,
				),
			)

			setReport(generatedReport)

			setLoading(false) // Set loading to false after report generation is complete
		} catch (error) {
			setLoading(false) // Set loading to false in case of error
		}
	}

	const handleCancel = () => {
		if (isCancelEnabled) {
			setIsCancelModalOpen(true)
		}
	}

	const handleConfirmCancel = () => {
		setIsCancelModalOpen(false)
		setReportFields({
			...reportFields,
			filters: Object.keys(reportFields.filters).reduce((acc, key) => {
				acc[key] = []
				return acc
			}, {} as any),
		})
		setBreakdownFields([])
		setStatisticsFields([])
		setErrorMessage(null)
		setIsReportOpen(false)
	}

	const handleCloseModal = () => {
		setIsRunModalOpen(false)
		setIsCancelModalOpen(false)
	}

	useEffect(() => {
		try {
			if (fileDownload && fileDownload instanceof Blob) {
				saveAs(fileDownload, 'report.csv')
			} else {
				console.warn('File download attempted with an invalid object.')
			}
		} catch (error) {
			console.error('Error executing saveAs:', error)
		}
	}, [fileDownload])

	return (
		<ThemeProvider theme={theme}>
			{loading && (
				<LoaderContainer>
					<LoadingOverlay loading={loading} />
				</LoaderContainer>
			)}
			{isRunModalOpen && <BlurOverlay />}
			<ReportGrid
				container
				spacing={3}
				style={{
					position: 'relative',
					marginBottom: reportFields ? '20px' : '90px',
				}}
			>
				<Grid
					container
					direction='row'
					justifyContent='space-between'
					alignItems='center'
				>
					<Grid item xs />
					<DownloadButtonContainer ref={downloadButtonRef}>
						<UnborderedButton
							label={'Download'}
							icon={<DownloadIcon />}
							style={{
								fontSize: theme.font.size.body,
								color: theme.colors.text.titles,
								display: 'flex',
								opacity: isDownloadEnabled ? 1 : 0.5,
								cursor: isDownloadEnabled ? 'pointer' : 'not-allowed',
							}}
							onClick={handleDownloadClick}
						/>
					</DownloadButtonContainer>
				</Grid>

				<Grid container direction='column' spacing={3}>
					<Grid item style={{ marginTop: '4rem' }}>
						<LabeledListSection title='Filters'>
							{filtersComponentList}
						</LabeledListSection>
					</Grid>

					<Grid item>
						<LabeledListSection title='Breakdown Fields'>
							<RadioChipList
								options={breakdownOptions}
								selectedOptions={memoizedBreakdownFields}
								setSelectedOptions={setBreakdownFields}
								chipStyle={{ minWidth: '8rem', maxWidth: '12rem' }}
							/>
						</LabeledListSection>
					</Grid>

					<Grid item>
						<LabeledListSection title='Statistic Fields'>
							<RadioChipList
								options={statisticOptions}
								selectedOptions={memoizedStatisticsFields}
								setSelectedOptions={setStatisticsFields}
								chipStyle={{ minWidth: '8rem', maxWidth: '12rem' }}
							/>
						</LabeledListSection>
					</Grid>
				</Grid>

				<Grid container justifyContent='center' spacing={2}>
					<Grid item>
						<UnborderedButton
							onClick={handleCancel}
							label={'Cancel'}
							style={{
								marginRight: '2rem',
								marginTop: '0.7rem',
								opacity: isCancelEnabled ? 1 : 0.5,
								cursor: isCancelEnabled ? 'pointer' : 'not-allowed',
							}}
							disabled={!isCancelEnabled}
						/>
					</Grid>
					<Grid item>
						<SubmitButton
							onClick={trySubmit}
							variant='contained'
							color='primary'
							disabled={!isRunEnabled}
						>
							Run
						</SubmitButton>
					</Grid>
				</Grid>
			</ReportGrid>
			<CustomModal
				title='Report Generation'
				open={isRunModalOpen}
				onClose={handleCloseModal}
				actions={
					<Box display='flex' justifyContent='flex-end' width='100%'>
						<SubmitButton onClick={handleConfirmRun}>OK</SubmitButton>
					</Box>
				}
			>
				<Typography variant='body1' gutterBottom>
					Keep browsing; we'll notify you when the report is ready.
				</Typography>
			</CustomModal>
			<CustomModal
				title='Confirm Cancel'
				open={isCancelModalOpen}
				onClose={handleCloseModal}
				actions={
					<Box display='flex' justifyContent='space-between' width='100%'>
						<SubmitButton
							variant='contained'
							style={{
								backgroundColor: theme.colors.base.green200,
								border: `2px solid ${theme.colors.base.green200}`,
								color: theme.colors.text.titles,
								marginRight: '1rem',
							}}
							onClick={handleConfirmCancel}
						>
							Yes
						</SubmitButton>
						<SubmitButton
							variant='outlined'
							style={{
								backgroundColor: theme.colors.base.white,
								border: `2px solid ${theme.colors.base.green200}`,
								color: theme.colors.base.black,
							}}
							onClick={handleCloseModal}
						>
							No
						</SubmitButton>
					</Box>
				}
			>
				<Typography variant='body1' gutterBottom>
					Are you sure you want to reset report parameters?
				</Typography>
			</CustomModal>
		</ThemeProvider>
	)
}

export default ReportsPageLayout
