import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router'
import {
	useActions,
	usePublisherActions,
	useCampaignsActions,
	useAdvertiserActions,
	useUserActions,
	useRoleActions,
	useSettingsActions,
	useLogActions,
	useLearningActions,
	useCreatorActions,
	useReportActions,
} from '../hooks/useActions'
import { useTypedSelector } from '../hooks/useTypedSelector'
import { fetchData } from '../utils/helpers/navigationHelper'
import {
	CLIENT_VERSION,
	MAX_PROGRESS_BEFORE_DATA,
	MIN_LOADING_TIME,
} from '../config'
import moment from 'moment'
import { getVersionAction } from '../state/action-creators'
import { FirstLoading, Loading } from '../assets/svg/loading'
import { getFiltersAction } from '../state/action-creators/reportActions'
import {
	defaultAggregatedFilters,
	defaultInvalidFilters,
} from '../utils/helpers/reportHelperFuncs'

export const LoadingComponent = ({
	setLoadingFromComponent,
	loadingFromComponent,
	setUnderMaintenance,
}: {
	setLoadingFromComponent: any
	loadingFromComponent: boolean
	setUnderMaintenance: any
}) => {
	const [isDownloading, setIsDownloading] = useState(false)
	const [isBackgroundLoading, setIsBackgroundLoading] = useState(false)
	const [firstDownloadDone, setFirstDownloadDone] = useState(false)
	const [progress, setProgress] = useState(0)
	const [dataReady, setDataReady] = useState(false)
	const [targetProgress, setTargetProgress] = useState(0)

	const [fetchLogs, setFetchLogs] = useState<
		{ name: string; duration: number }[]
	>([])

	const {
		getAppsAction,
		getPasswordsAction,
		getAvatars,
		getDashboardAction,
		getAccountsAction,
		getAppsIcons,
		getp360aggregated,
		getSalesPlanAppsAction,
		// getHandshakeAction,
		getPermissions,
		// getCompanySettings,
		getAllCompanies,
	} = useActions()

	const userEmail = useTypedSelector((state) => state.login.user?.email)

	const { getPublisherWishList, getPublisherAction } = usePublisherActions()
	const { getCampaignHistoryAction, getCampaignAction } = useCampaignsActions()
	const { getAdvertiserAction } = useAdvertiserActions()
	const { getLoggedInUser, logOutAction, usersAction } = useUserActions()
	const { fetchRoles } = useRoleActions()
	const { getSettingsAction } = useSettingsActions()
	const { getLogsAction } = useLogActions()
	const { getLearningAction } = useLearningActions()
	const { getCreatorsAction } = useCreatorActions()
	const { loadAllReportsAction, getNetworkAction, getInvalidAction } =
		useReportActions()
	const navigate = useNavigate()

	function updateProgress(completed: number, total: number) {
		const progressPercentage = (completed / total) * 100
		setTargetProgress((prevTarget) => Math.max(prevTarget, progressPercentage))
	}

	useEffect(() => {
		let intervalId: NodeJS.Timeout

		if (isDownloading) {
			intervalId = setInterval(() => {
				setProgress((prevProgress) => {
					if (prevProgress < targetProgress) {
						const increment = Math.min(
							targetProgress - prevProgress,
							Math.random() * 5,
						)
						return prevProgress + increment
					} else {
						return prevProgress
					}
				})
			}, 100)
		}

		return () => {
			clearInterval(intervalId)
		}
	}, [isDownloading, targetProgress])

	useEffect(() => {
		let animationFrameId: any

		const startTime = Date.now()

		const animateProgress = () => {
			const elapsedTime = Date.now() - startTime
			const progressPercentage = Math.min(
				(elapsedTime / MIN_LOADING_TIME) * 100,
				100,
			)
			setProgress(progressPercentage)

			if (progressPercentage < MAX_PROGRESS_BEFORE_DATA) {
				animationFrameId = requestAnimationFrame(animateProgress)
			} else {
				if (dataReady) {
					setFirstDownloadDone(true)
				}
			}
		}

		animateProgress()

		return () => {
			cancelAnimationFrame(animationFrameId)
		}
	}, [dataReady])

	// Helper function to log fetch duration
	const logFetch = async (fetchFunc: any, name: string) => {
		const startTime = performance.now()
		await fetchFunc()
		const endTime = performance.now()
		const duration = endTime - startTime

		setFetchLogs((prevLogs) => [...prevLogs, { name, duration }])
	}

	// Check if data needs to be reloaded
	const lastFetchTime = localStorage.getItem('lastFetchTime')
	const dateIsOneDayOld =
		!lastFetchTime || moment().diff(moment(lastFetchTime), 'hours') >= 24

	// Map of fetch functions
	const mapFetching: Record<string, Array<() => Promise<void>>> = {
		first_download: [
			() => logFetch(getLoggedInUser, 'getLoggedInUser'),
			() => logFetch(getSettingsAction, 'getSettingsAction'),
			() => logFetch(usersAction, 'usersAction'),
			() => logFetch(getPermissions, 'getPermissions'),
			() => logFetch(getAdvertiserAction, 'getAdvertiserAction'),
			() => logFetch(getPublisherAction, 'getPublisherAction'),
			() => logFetch(getCreatorsAction, 'getCreatorsAction'),
			() => logFetch(getCampaignAction, 'getCampaignAction'),
			() => logFetch(getAppsAction, 'getAppsAction'),
			() => logFetch(getLogsAction, 'getLogsAction'),
			() => logFetch(getPasswordsAction, 'getPasswordsAction'),
			() => logFetch(getAccountsAction, 'getAccountsAction'),
			() => logFetch(getAvatars, 'getAvatars'),
			() => logFetch(getPublisherWishList, 'getPublisherWishList'),
			() => logFetch(getAppsIcons, 'getAppsIcons'),
			() => logFetch(fetchRoles, 'fetchRoles'),
			() => logFetch(getLearningAction, 'getLearningAction'),
			() => logFetch(getAllCompanies, 'getAllCompanies'),
			() => logFetch(getDashboardAction, 'getDashboardAction'),
			() => logFetch(getSalesPlanAppsAction, 'getSalesPlanAppsAction'),
		],
		campaigns: [
			() => logFetch(getAppsIcons, 'getAppsIcons'),
			() => logFetch(getCampaignAction, 'getCampaignAction'),
			() => logFetch(getAppsAction, 'getAppsAction'),
		],
		advertisers: [
			() => logFetch(getAdvertiserAction, 'getAdvertiserAction'),
			() => logFetch(getAppsAction, 'getAppsAction'),
		],
		publishers: [
			() => logFetch(getPublisherAction, 'getPublisherAction'),
			// Add logFetch when using getHandshakeAction if required
			() => logFetch(getCreatorsAction, 'getCreatorsAction'),
		],
		companysettings: [
			() => logFetch(getPublisherAction, 'getPublisherAction'),
			() => logFetch(getAdvertiserAction, 'getAdvertiserAction'),
			() => logFetch(getAppsAction, 'getAppsAction'),
			() => logFetch(getCreatorsAction, 'getCreatorsAction'),
		],
		users: [
			() => logFetch(usersAction, 'usersAction'),
			() => logFetch(fetchRoles, 'fetchRoles'),
		],
		learning: [() => logFetch(getLearningAction, 'getLearningAction')],
		passwords: [() => logFetch(getPasswordsAction, 'getPasswordsAction')],
		apps: [() => logFetch(getAppsAction, 'getAppsAction')],
		settings: [
			() => logFetch(getSettingsAction, 'getSettingsAction'),
			() => logFetch(fetchRoles, 'fetchRoles'),
			// Add logFetch when using getCompanySettings if required
		],
		logs: [() => logFetch(getLogsAction, 'getLogsAction')],
		publisherwishlist: [
			() => logFetch(getPublisherWishList, 'getPublisherWishList'),
		],
		appsflyeraccounts: [() => logFetch(getAccountsAction, 'getAccountsAction')],
		network: [
			() => logFetch(getNetworkAction, 'getNetworkAction'),
			() => logFetch(loadAllReportsAction(userEmail), 'loadAllReportsAction'),
		],
		aggregated: [
			() =>
				logFetch(
					getFiltersAction.bind(null, defaultAggregatedFilters),
					'getFiltersAction',
				),
			() => logFetch(loadAllReportsAction(userEmail), 'loadAllReportsAction'),
		],
		invalid: [
			() =>
				logFetch(
					getInvalidAction.bind(null, defaultInvalidFilters),
					'getInvalidAction',
				),
			() => logFetch(loadAllReportsAction(userEmail), 'loadAllReportsAction'),
		],
	}

	// Essential data fetching with progress tracking
	useEffect(() => {
		const fetchEssentialData = async () => {
			const startTime = Date.now() // Record the start time

			try {
				console.time('Essential Data Loading')
				setIsDownloading(true)

				// Check version and handle maintenance mode
				const checkVersion = async () => {
					const result = await getVersionAction(CLIENT_VERSION)
					if (
						result !== undefined &&
						(result.data.successful !== true ||
							result.data.payload.version === false)
					) {
						sessionStorage.clear()
						window.location.reload()
					} else if (result?.data.payload.under_maintenance === true) {
						setUnderMaintenance(true)
						navigate('/maintenance')
					}
				}
				await checkVersion()

				// Track progress for each fetch
				let completed = 0
				const total = mapFetching.first_download.length
				updateProgress(completed, total) // Initial progress

				// Fetch essential data with progress tracking
				const promises = mapFetching.first_download.map((fetchFunc) =>
					fetchFunc().then(() => {
						completed += 1
						updateProgress(completed, total)
					}),
				)

				await Promise.all(promises)

				setTargetProgress(100)

				setFirstDownloadDone(true)
				localStorage.setItem('lastFetchTime', moment().toISOString())
				setProgress(100)
				console.timeEnd('Essential Data Loading')
			} catch (error) {
				console.error('Error loading essential data:', error)
			} finally {
				setDataReady(true) // Indicate that data fetching is complete

				setIsDownloading(false)
			}
		}

		// If data is older than one day, reload it
		if (dateIsOneDayOld) {
			fetchEssentialData()
		} else {
			setFirstDownloadDone(true) // Skip loading if data is recent
		}
	}, [])

	// Background data fetching for campaignHistory and network, only if data is over a day old
	useEffect(() => {
		if (firstDownloadDone && dateIsOneDayOld && userEmail) {
			setIsBackgroundLoading(true)
			const fetchBackgroundData = async () => {
				try {
					await Promise.all([
						logFetch(getCampaignHistoryAction, 'getCampaignHistoryAction'),
						logFetch(getNetworkAction, 'getNetworkAction'),
						logFetch(
							() => loadAllReportsAction(userEmail),
							'loadAllReportsAction',
						),
						logFetch(getp360aggregated, 'p360aggregated'),
					])
				} catch (error) {
					console.error('Error loading background data:', error)
				} finally {
					setIsBackgroundLoading(false)
				}
			}

			fetchBackgroundData()
		}
	}, [firstDownloadDone, dateIsOneDayOld])

	// Route-specific data fetching on path change
	useEffect(() => {
		const keyToDownload = convertPathToMapKey(window.location.pathname)
		if (keyToDownload && mapFetching[keyToDownload]) {
			const fetchRouteData = async () => {
				setIsDownloading(true)
				try {
					await Promise.all(
						mapFetching[keyToDownload].map((fetchFunc) => fetchFunc()),
					)
				} catch (error) {
					console.error(`Error fetching data for ${keyToDownload}:`, error)
				} finally {
					setIsDownloading(false)
				}
			}
			fetchRouteData()
		}
	}, [window.location.pathname])

	// Render fetch logs after loading
	useEffect(() => {
		if (!isDownloading && !isBackgroundLoading) {
			// console.table(fetchLogs)
			setTargetProgress(100) // Ensure it reaches 100% when done
		}
	}, [isDownloading, isBackgroundLoading])

	return firstDownloadDone ? (
		<>
			{(isDownloading || loadingFromComponent) && <Loading loading={true} />}
			{isBackgroundLoading && <Loading loading={false} />}
			<Outlet />
		</>
	) : (
		<FirstLoading progress={progress} />
	)
}
// No longer needed, we dont fetch data based on URL rather than by data age. TODO: remove this once loading and data fetching proves working
const convertPathToMapKey = (string: string) => {
	let result = ''

	const mappings = {
		'/news': 'first_download',
		'/campaigns': 'campaigns',
		'/advertisers': 'advertisers',
		'/users': 'users',
		'/publishers': 'publishers',
		'/creators': 'publishers',
		'/passwords': 'passwords',
		'/documents': 'learning',
		'/settings': 'settings',
		'/apps': 'apps',
		'/logs': 'logs',
		'/publisherwishlist': 'publisherwishlist',
		'/appsflyeraccounts': 'appsflyeraccounts',
		'/companySettings': 'companysettings',
		'/network': 'network',
		'/aggregated': 'aggregated',
		'/invalid': 'invalid',
	}

	for (const key in mappings) {
		if (string.startsWith(key)) {
			result = mappings[key as keyof typeof mappings]
			break
		}
	}
	// console.log('result = ', result)
	return result
}
