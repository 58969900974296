import { GetDashboardInterface, GetPasswordsInterface } from '../../models/model.interface'
import { ActionType } from '../action-types/index'
import { Action } from '../actions'

export interface dashboardReducerInterface {
	dashboard: GetDashboardInterface | any
}

const initialState: dashboardReducerInterface = {
	dashboard: undefined,
}

const reducer = (
	state: dashboardReducerInterface | any = initialState,
	action: Action,
): dashboardReducerInterface => {
	switch (action.type) {
		case ActionType.GETDASHBOARD:
			return {
				...state,
				dashboard: action.payload,
			}
		default:
			return state
	}
}

export default reducer
