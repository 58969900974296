import { ActionType } from '../action-types'
import { Action } from '../actions'

export interface ReportType {
	id: number
	name: string
	s3_file_url: string
	[key: string]: any
}

export interface ReportsByUser {
	[userEmail: string]: {
		network?: ReportType[]
		invalid?: ReportType[]
		aggregated?: ReportType[]
	}
}

export interface ReportReducerInterface {
	reports: ReportsByUser
	loading: boolean
	error: string | null
	tabIndices: {
		[userEmail: string]: {
			network?: number
			invalid?: number
			aggregated?: number
		}
	}
}

const initialState: ReportReducerInterface = {
	reports: {},
	loading: false,
	error: null,
	tabIndices: {},
}

const reportReducer = (
	state: ReportReducerInterface = initialState,
	action: Action,
): ReportReducerInterface => {
	switch (action.type) {
		case ActionType.LOAD_ALL_REPORTS_START:
			return {
				...state,
				loading: true,
				error: null,
			}
		case ActionType.LOAD_ALL_REPORTS_SUCCESS:
			return {
				...state,
				reports: {
					...state.reports,
					[action.payload.userEmail]: action.payload.reports,
				},
				loading: false,
				error: null,
			}
		case ActionType.LOAD_ALL_REPORTS_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			}
		case ActionType.UPDATE_REPORT_NAME_START:
			return {
				...state,
				loading: true,
				error: null,
			}
		case ActionType.UPDATE_REPORT_NAME_SUCCESS: {
			const updatedReports = { ...state.reports }
			Object.keys(updatedReports).forEach((userEmail) => {
				const reportTypes = updatedReports[userEmail]
				;(Object.keys(reportTypes) as Array<keyof typeof reportTypes>).forEach(
					(reportType) => {
						reportTypes[reportType] = reportTypes[reportType]?.map((report) =>
							report.id === action.payload.reportId
								? { ...report, name: action.payload.newName }
								: report,
						)
					},
				)
			})
			return {
				...state,
				reports: updatedReports,
				loading: false,
				error: null,
			}
		}
		case ActionType.UPDATE_REPORT_NAME_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			}
		case ActionType.DELETE_REPORT_START:
			return {
				...state,
				loading: true,
				error: null,
			}
		case ActionType.DELETE_REPORT_SUCCESS: {
			const updatedReports = { ...state.reports }
			const newTabIndices = { ...state.tabIndices }
			Object.keys(updatedReports).forEach((userEmail) => {
				const reportTypes = updatedReports[userEmail]
				;(Object.keys(reportTypes) as Array<keyof typeof reportTypes>).forEach(
					(reportType) => {
						const currentReports = reportTypes[reportType] || []
						const reportIndex = currentReports.findIndex(
							(report) => report.id === action.payload.reportId,
						)

						if (reportIndex !== -1) {
							// Remove the report from the list
							reportTypes[reportType] = currentReports.filter(
								(report) => report.id !== action.payload.reportId,
							)

							// Adjust the tab index
							if (
								newTabIndices[userEmail] &&
								newTabIndices[userEmail][reportType] !== undefined
							) {
								if (newTabIndices[userEmail][reportType]! >= reportIndex) {
									newTabIndices[userEmail][reportType] = Math.max(
										0,
										newTabIndices[userEmail][reportType]! - 1,
									)
								}
							}
						}
					},
				)
			})
			return {
				...state,
				reports: updatedReports,
				tabIndices: newTabIndices,
				loading: false,
				error: null,
			}
		}
		case ActionType.DELETE_REPORT_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			}

		case ActionType.SET_TAB_INDEX: {
			const { userEmail, reportType, tabIndex } = action.payload

			if (!state.tabIndices[userEmail]) {
				return {
					...state,
					tabIndices: {
						...state.tabIndices,
						[userEmail]: {
							[reportType]: tabIndex,
						},
					},
				}
			}

			return {
				...state,
				tabIndices: {
					...state.tabIndices,
					[userEmail]: {
						...state.tabIndices[userEmail],
						[reportType]: tabIndex,
					},
				},
			}
		}

		default:
			return state
	}
}

export default reportReducer
