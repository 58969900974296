import { P360Interface } from '../../models/model.interface'
import { ActionType } from '../action-types/index'
import { Action } from '../actions'

export interface P360ReducerInterface {
	P360: P360Interface | any
}

const initialState: P360ReducerInterface = {
	P360: [],
}

const reducer = (
	state: P360ReducerInterface = initialState,
	action: Action,
): P360ReducerInterface => {
	switch (action.type) {
		case ActionType.P360AGGREGATED: {
			return {
				...state,
				P360: action.payload,
			}
		}
		default: {
			return state
		}
	}
}

export default reducer
