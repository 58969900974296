import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import { Box, Typography, useTheme, TableContainer } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { UnborderedButton } from '../../components/Buttons'
import CustomReportTable from '../../components/CustomReportTable'
import {
	fetchReportData,
	reportColumnMap,
} from '../../../utils/helpers/reportHelperFuncs'
import { CustomLoader, SmallLoading } from '../../../assets/svg/loading'
import { useAlertsActions, useReportActions } from '../../../hooks/useActions'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import ReportTabs from '../../components/TabsComponents'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { ReportType } from '../../../models/reports.interface'

interface ReportTableSectionLayoutProps {
	report?: any
	chartData?: any
	reportFields: any
	isReportOpen: boolean
	setIsReportOpen: React.Dispatch<React.SetStateAction<boolean>>
	customReportFields: any
	reportType: ReportType
	setSelectedReportData: React.Dispatch<React.SetStateAction<any>>
	smallLoading: boolean
	setSmallLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const ReportTableSectionLayout: React.FC<ReportTableSectionLayoutProps> = ({
	report,
	setSelectedReportData,
	smallLoading,
	setSmallLoading,
	reportType,
	customReportFields,
	isReportOpen,
}) => {
	const theme = useTheme()
	const navigate = useNavigate()
	const location = useLocation()
	const [urlQuery] = useSearchParams()
	const reportParam = urlQuery.get('report') || ''
	const [reportFromQuery, setReportFromQuery] = useState(reportParam)
	const [isTableVisible, setIsTableVisible] = useState(true)
	const [selectedReportDataLocal, setSelectedReportDataLocal] =
		useState<any>(null)
	const [isLoading, setIsLoading] = useState(false)
	const [editedReportName, setEditedReportName] = useState('')
	const [editIndex, setEditIndex] = useState<number | null>(null)
	const [visibleStart, setVisibleStart] = useState(0)
	const containerRef = useRef<HTMLDivElement>(null)

	const { setTabIndex } = useAlertsActions()
	const { updateReportNameAction, deleteReportAction } = useReportActions()
	const { login, reports } = useTypedSelector((state) => state)
	const userEmail = login.user.email as string
	const [isFirstRender, setIsFirstRender] = useState(true)

	const prevReportLength = useRef(report?.length || 0)

	const [totalRow, setTotalRow] = useState<{
		[key: string]: string | number | null
	} | null>(null)

	// Filter reports based on reportType
	const filteredReports = useMemo(() => {
		const userReports = reports.reports[userEmail]?.[reportType] || []
		return userReports.map((r: any) => r.name)
	}, [reports.reports, userEmail, reportType])

	// Synchronize selectedReportIndex with Redux
	const selectedReportIndex = useMemo(() => {
		let index = reports.tabIndices[userEmail]?.[reportType] || 0
		if (reportFromQuery) {
			const found = report.findIndex((el: any) => el.uuid === reportFromQuery)
			if (found !== -1) {
				index = found
			}
		}
		return index
	}, [reports.tabIndices, userEmail, reportType, reportFromQuery, report])

	// Headers for API requests
	const headers = useMemo(
		() => ({
			Authorization: `Token ${login.user.token}`,
		}),
		[login.user.token],
	)

	// Memoize fetchSelectedReportData to prevent unnecessary re-creations
	const fetchSelectedReportData = useCallback(
		async (index: number, showLoader = true) => {
			try {
				if (showLoader) setIsLoading(true)
				const reportData = await fetchReportData(report[index].s3_file_url)

				// Extract the total row if it exists
				const totalRowIndex = reportData.findIndex(
					(row: any) =>
						row.date === reportColumnMap.total ||
						row.month === reportColumnMap.total ||
						row.day === reportColumnMap.total,
				)

				let extractedTotalRow = null

				if (totalRowIndex !== -1) {
					extractedTotalRow = reportData.splice(totalRowIndex, 1)[0]
				}

				setSelectedReportDataLocal(reportData)
				if (setSelectedReportData) {
					setSelectedReportData(reportData)
				}
				setTotalRow(extractedTotalRow)

				setIsLoading(false)

				if (showLoader) setIsLoading(false)
			} catch (error) {
				if (showLoader) setIsLoading(false)
			}
		},
		[report[selectedReportIndex]?.s3_file_url, setSelectedReportData],
	)

	// Update reportFromQuery when URL parameter changes
	useEffect(() => {
		setReportFromQuery(reportParam)
	}, [reportParam])

	// Fetch data for the selected tab whenever selectedReportIndex changes
	useEffect(() => {
		if (report && report.length > 0) {
			fetchSelectedReportData(selectedReportIndex, !isFirstRender)
			if (isFirstRender) {
				setIsFirstRender(false)
			}
		}
	}, [selectedReportIndex, report, fetchSelectedReportData])

	// Handle URL query parameter to set the correct tab index
	useEffect(() => {
		if (reportParam) {
			const foundIndex = report.findIndex((el: any) => el.uuid === reportParam)
			if (foundIndex !== -1 && foundIndex !== selectedReportIndex) {
				setTabIndex(userEmail, reportType, foundIndex)
			}
			navigate(location.pathname)
		}
	}, [
		reportParam,
		report,
		userEmail,
		reportType,
		selectedReportIndex,
		navigate,
		location.pathname,
		setTabIndex,
	])

	// Handle adding or removing reports
	useEffect(() => {
		if (report && report.length > 0) {
			if (report.length !== prevReportLength.current) {
				let newIndex = report.length - 1
				if (reportFromQuery) {
					const found = report.findIndex(
						(el: any) => el.uuid === reportFromQuery,
					)
					if (found !== -1) {
						newIndex = found
					}
				}
				setTabIndex(userEmail, reportType, newIndex)
				fetchSelectedReportData(newIndex)
				setVisibleStart(Math.max(newIndex - 12, 0))
			}
			prevReportLength.current = report.length
		}
	}, [
		report,
		reportFromQuery,
		userEmail,
		reportType,
		fetchSelectedReportData,
		setTabIndex,
	])

	// Adjust handleSetSelectedReportIndex to prevent unnecessary updates
	const handleSetSelectedReportIndex = (
		newIndex: number | ((prevState: number) => number),
	) => {
		if (typeof newIndex === 'function') {
			const computedIndex = newIndex(selectedReportIndex)
			if (computedIndex !== selectedReportIndex) {
				setTabIndex(userEmail, reportType, computedIndex)
			}
		} else {
			if (newIndex !== selectedReportIndex) {
				setTabIndex(userEmail, reportType, newIndex)
			}
		}
	}

	useEffect(() => {
		setIsTableVisible(isReportOpen)
	}, [isReportOpen])

	// Toggle table visibility
	const handleToggleTableVisibility = () => {
		setIsTableVisible(!isTableVisible)
	}

	if (!report || report.length === 0) {
		return (
			<Box sx={{ padding: '4rem' }}>
				<Typography
					variant='h6'
					sx={{
						color: theme.colors.base.black,
						fontFamily: 'Roboto',
						paddingBottom: '2rem',
						fontWeight: 'bold',
					}}
				>
					No report data available
				</Typography>
			</Box>
		)
	}

	return (
		<Box sx={{ padding: '4rem', position: 'relative' }}>
			<CustomLoader isLoading={isLoading} />
			<Box
				sx={{
					width: '100%',
					borderBottom: `1px solid ${theme.colors.base.grey}`,
					marginBottom: '2rem',
					fontWeight: 'bold',
				}}
			/>
			<Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '2rem' }}>
				<UnborderedButton
					onClick={handleToggleTableVisibility}
					label='Downloaded Reports'
				/>
				{isTableVisible ? (
					<ArrowDropDownIcon
						onClick={handleToggleTableVisibility}
						style={{
							cursor: 'pointer',
							color: `${theme.colors.text.titles}`,
							fontSize: '1.3rem',
						}}
					/>
				) : (
					<ArrowDropUpIcon
						onClick={handleToggleTableVisibility}
						style={{
							cursor: 'pointer',
							color: `${theme.colors.text.titles}`,
							fontSize: '1.3rem',
						}}
					/>
				)}
			</Box>
			{isTableVisible && (
				<Box
					ref={containerRef}
					sx={{
						width: '100%',
						backgroundColor: theme.palette.common.white,
						borderRadius: '12px',
						margin: '1rem 0',
						fontWeight: 'bold',
						position: 'relative',
					}}
				>
					<ReportTabs
						reportType={reportType}
						filteredReports={filteredReports}
						selectedReportIndex={selectedReportIndex}
						setSelectedReportIndex={handleSetSelectedReportIndex}
						visibleStart={visibleStart}
						setVisibleStart={setVisibleStart}
						visibleCount={13}
						loadingTabs={[]}
						editIndex={editIndex}
						setEditIndex={setEditIndex}
						editedReportName={editedReportName}
						setEditedReportName={setEditedReportName}
						handleReportTabClick={(event: any, newValue: any) =>
							handleSetSelectedReportIndex(newValue)
						}
						handleEditIconClick={(index) => setEditIndex(index)}
						handleReportNameChange={(event) =>
							setEditedReportName(event.target.value)
						}
						handleReportNameSave={async () => {
							if (editIndex === null) return
							setSmallLoading(true)
							try {
								await updateReportNameAction(
									headers,
									report[editIndex].id,
									editedReportName,
									(message: string) => console.error(message),
								)
								setEditIndex(null)
							} catch (error) {
								console.error('Error updating report name:', error)
							} finally {
								setSmallLoading(false)
							}
						}}
						handleCancelEdit={() => setEditIndex(null)}
						handleDeleteReport={async (index) => {
							setSmallLoading(true)
							try {
								await deleteReportAction(
									headers,
									report[index].id,
									(message: string) => console.error(message),
								)
								const newSelectedIndex =
									selectedReportIndex >= report.length - 1
										? Math.max(report.length - 2, 0)
										: selectedReportIndex
								setTabIndex(userEmail, reportType, newSelectedIndex)
							} catch (error) {
								console.error('Error deleting report:', error)
							} finally {
								setSmallLoading(false)
							}
						}}
						containerRef={containerRef}
					/>

					{selectedReportDataLocal && (
						<TableContainer sx={{ width: '100%', overflowX: 'auto' }}>
							<CustomReportTable
								reports={selectedReportDataLocal}
								reportNames={filteredReports}
								totalRow={totalRow || undefined}
								reportType={reportType}
							/>
						</TableContainer>
					)}
				</Box>
			)}
			{smallLoading && (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						marginTop: '2rem',
						fontWeight: 'bold',
						backgroundColor: 'transparent',
					}}
				>
					<SmallLoading />
				</Box>
			)}
		</Box>
	)
}

export default ReportTableSectionLayout
