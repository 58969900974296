import {
	GetAppsflayerInterface,
} from '../../models/model.interface'
import { ActionType } from '../action-types/index'
import { Action } from '../actions'

export interface AppsflyerReducerInterface {
  appsflyer: GetAppsflayerInterface[] | any;
}

const initialState = {
	appsflyer: [],
}

const reducer = (
	state: AppsflyerReducerInterface = initialState,
	action: Action | any
): AppsflyerReducerInterface => {
	switch (action.type) {
	case ActionType.GET_APPSFLYER:
		return {
			...state,
			appsflyer: action.payload,
		}
	case ActionType.LOGOUT:
		return {
			...state,
			appsflyer: [],
		}
	default:
		return state
	}
}

export default reducer
