import { Add, Edit } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import { Card, Grid, Typography } from '@mui/material'
import { BarChart, DonutChart, Legend, LineChart } from '@tremor/react'
import { useEffect, useState } from 'react'
import { formatDate } from '../../utils/helpers/tableHelper'
import GraphEditPopUp from './GraphEditPopup'
import { aggregateForDashboard, reduceRecursive } from './p360dashboard'
import { SubmitButton } from '../components/Buttons'
export const GraphWrapper = (props: {
	data: any

	filters: any
	users: any
	paramObjectReference: any
	setRefresh: any
	index: any
	optionsBreakdowns: any
	optionsStatistics: any
}) => {
	const [graphEditorPopup, setGraphEditorPopup] = useState(false)
	// const filteredData = props.data.map((e: any) => {
	// 	return { metric: e[props.metric], value: e[props.value] }
	// })
	const [metric, setMetric] = useState(
		props.paramObjectReference[props.index].metric || 'Media source',
	)
	const [value, setValue] = useState(
		props.paramObjectReference[props.index].value || 'Installs',
	)
	const [depth, setDepth] = useState(
		props.paramObjectReference[props.index].depth || 5,
	)
	const [type, setType] = useState(
		props.paramObjectReference[props.index].type || 'pie',
	)
	const [title, setTitle] = useState(
		props.paramObjectReference[props.index].title || 'title',
	)
	let recursiveResult: any[] = []
	let cleared: any[] = []

	let allBreakdowns = [metric, value]
	if (type === 'line' || type === 'bar') {
		allBreakdowns = [metric, value, 'Date']
	}

	aggregateForDashboard(
		props.data,
		cleared,
		props.filters.dateFrom,
		props.filters.dateTo,
		props.filters.filters.accountManager,
		props.users,
		props.filters.filters.publisherManager,
		props.filters.filters.appsflyerAccount,
		allBreakdowns,
	)
	let graphiefiedResult: any[] = []
	let bestNames: any[] = []
	if (type === 'line' || type === 'bar') {
		reduceRecursive(cleared, [metric], 0, recursiveResult)
		// console.log(recursiveResult)

		recursiveResult
			.map((e: any) => {
				return { name: e[metric], value: e[value] }
			})
			.sort((a, b) => b.value - a.value)
			.forEach((e: any, i: number) => {
				if (i > depth - 1) {
				} else {
					bestNames.push(e.name)
				}
			})

		reduceRecursive(cleared, ['Date', metric], 0, recursiveResult)
		let dateArray = [
			...new Set(
				recursiveResult.map((e: any) => {
					return e['Date']
				}),
			),
		]

		dateArray = dateArray.filter(
			(e) =>
				isNaN(e) &&
				formatDate(new Date(e)) >=
					formatDate(new Date(props.filters.dateFrom)) &&
				formatDate(new Date(e)) <= formatDate(new Date(props.filters.dateTo)),
		)
		for (let datedata of dateArray) {
			graphiefiedResult.push({ date: formatDate(new Date(datedata)) })
		}
		graphiefiedResult.forEach((graphRes: any, index: number) => {
			recursiveResult.forEach((e: any, i: number) => {
				if (
					formatDate(new Date(e['Date'])) ===
					formatDate(new Date(graphRes['date']))
				) {
					if (bestNames.includes(e[metric])) {
						graphiefiedResult[index][e[metric]] = e[value]
					}
				}
			})
		})
	} else {
		reduceRecursive(cleared, [metric], 0, recursiveResult)

		recursiveResult
			.map((e: any) => {
				return { name: e[metric], value: e[value] }
			})
			.sort((a, b) => b.value - a.value)
			.forEach((e: any, i: number) => {
				if (i > depth - 1) {
					if (!graphiefiedResult[depth]) {
						graphiefiedResult[depth] = { name: 'Others', value: 0 }
					}
					graphiefiedResult[depth] = {
						name: 'Others',
						value: graphiefiedResult[depth].value + e.value,
					}
				} else {
					graphiefiedResult.push(e)
				}
			})
	}

	useEffect(() => {
		props.paramObjectReference[props.index].metric = metric
	}, [metric])
	useEffect(() => {
		props.paramObjectReference[props.index].value = value
	}, [value])
	useEffect(() => {
		props.paramObjectReference[props.index].type = type
	}, [type])
	useEffect(() => {
		props.paramObjectReference[props.index].depth = depth
	}, [depth])
	useEffect(() => {
		props.paramObjectReference[props.index].title = title
	}, [title])

	return (
		<Grid item>
			<Graph
				{...props.paramObjectReference[props.index]}
				setGraphEditorPopup={setGraphEditorPopup}
				data={graphiefiedResult}
				metricNames={bestNames}
				paramObjectReference={props.paramObjectReference}
				setRefresh={props.setRefresh}
				index={props.index}
			></Graph>
			<GraphEditPopUp
				popUpVisible={graphEditorPopup}
				setPopUpVisible={setGraphEditorPopup}
				// object={filteredData}
				object={{
					metric: metric,
					value: value,
					depth: depth,
					type: type,
					title: title,
				}}
				setTitle={setTitle}
				setMetric={setMetric}
				setValue={setValue}
				setDepth={setDepth}
				setType={setType}
				optionsBreakdowns={props.optionsBreakdowns}
				optionsStatistics={props.optionsStatistics}
			/>
		</Grid>
	)
}

export const Graph = (props: {
	data?: any
	title: any
	setGraphEditorPopup: any
	metric: any
	value: any
	type: any
	depth: any
	metricNames?: any
	paramObjectReference: any
	setRefresh: any
	index: number
}) => {
	// console.log(props.data)
	let graphComponent: any = undefined
	switch (props.type) {
		case 'pie':
			graphComponent = (
				<DonutChart
					height='h-80'
					variant='pie'
					data={props.data}
					category='value'
					colors={['rose', 'yellow', 'orange', 'indigo', 'blue', 'emerald']}
					// onValueChange={props.onChange}
				/>
			)
			break
		case 'line':
			graphComponent = (
				<LineChart
					data={props.data}
					dataKey={'date'}
					categories={props.metricNames}
					colors={['rose', 'yellow', 'orange', 'indigo', 'blue', 'emerald']}
					yAxisWidth={'w-0.5'}
					height={'h-80'}
				/>
			)
			break
		case 'bar':
			graphComponent = (
				<BarChart
					data={props.data}
					dataKey='date'
					categories={props.metricNames}
					colors={['rose', 'yellow', 'orange', 'indigo', 'blue', 'emerald']}
					yAxisWidth={'w-0.5'}
				/>
			)
			break
	}
	return (
		<Card
			className='mx-auto max-w-xs'
			style={{
				width: '40vw',
				height: '40vh',
				padding: '5%',
				borderRadius: '40px',
				boxShadow:
					'0px 20px 10px -10px rgba(0,0,0,0.2), 0px 10px 10px 0px rgba(0,0,0,0.14), 0px 10px 30px 0px rgba(0,0,0,0.12)',
			}}
		>
			<Typography
				style={{ margin: '15px', maxWidth: '25px', float: 'left' }}
				fontWeight={'600'}
				fontSize={'18px'}
			>
				{props.title}
			</Typography>
			<SubmitButton
				style={{
					width: '40px',
					float: 'right',
					borderRadius: '40px',
					minWidth: '40px',
				}}
				onClick={() => {
					props.paramObjectReference.splice(props.index, 1)

					props.setRefresh((prev: boolean) => !prev)
				}}
			>
				<DeleteIcon style={{ color: 'black' }} />
			</SubmitButton>
			<SubmitButton
				style={{
					width: '40px',
					minWidth: '40px',
					float: 'right',
					background:
						'linear-gradient(90deg, rgba(238,29,66,1) 0%, rgba(64,19,129,1) 100%)',
					borderRadius: '40px',
				}}
				onClick={() => {
					props.paramObjectReference.push({
						metric: props.metric,
						value: props.value,
						type: props.type,
						depth: props.depth,
						title: props.title,
					})
					props.setRefresh((prev: boolean) => !prev)
				}}
			>
				<Add style={{ color: 'white' }} />
			</SubmitButton>
			<SubmitButton
				style={{
					width: '40px',
					float: 'right',
					borderRadius: '40px',
					minWidth: '40px',
				}}
				onClick={() => props.setGraphEditorPopup((prev: boolean) => !prev)}
			>
				<Edit style={{ color: 'black' }} />
			</SubmitButton>

			<div
				style={{
					marginTop: props.data && props.type === 'pie' ? '50px' : 'none',
					marginBottom: props.data && props.type === 'pie' ? '50px' : 'none',
					width: props.data && props.type === 'pie' ? '20vw' : '100%',
					float: 'left',
					minHeight: props.data && props.type === 'pie' ? 'auto' : '100px',
				}}
			>
				{graphComponent}
			</div>
			{props.data && props.type === 'pie' ? (
				<div
					style={{
						marginTop: '50px',
						marginBottom: '50px',
						width: '200px',
						float: 'right',
					}}
				>
					<Legend
						categories={props.data.map((e: any) => e.name)}
						colors={[
							'rose',
							'yellow',
							'orange',
							'indigo',
							'blue',
							'emerald',
							'gray',
						]}
					/>
				</div>
			) : undefined}
		</Card>
	)
}
