import { Checkbox, useTheme } from '@mui/material'
import React from 'react'
import { useEffect, useState } from 'react'
import { StyledNameField } from './reusableComponents'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { InputFieldText, RadioChip, StyledTextAreaField } from './Inputs'
import { DropList } from './SelectableInputs'
import { TitleComponent } from './Informative'

export const TreeCheckboxContainer = (props: {
	nodes: any
	selectedElements: any
	setSelectedElements: any
	isCountry?: boolean
	label: string
	notExpandable?: boolean
	countryData?: any
	setCountryData?: any
	setChangeToFilterDone?: any
	disabledBasedOnLength?: number
	elementsWidth?: string
	expandFilter?: boolean
	setExpandFilter?: any
	customInputLabel?: string
	dontGrayOut?: boolean
	disabled?: boolean
}) => {
	const theme = useTheme()
	// const checkHandler = (value: any, e: any) => {
	// 	// console.log(value, e.parent.value);
	// 	for (const continent of props.nodes) {
	// 		if (continent.value === e.parent.value) {
	// 			for (const country of continent.children) {
	// 				if (country.value === value[value.length - 1]) {
	// 					console.log(country)
	// 					break
	// 				}
	// 			}
	// 			break
	// 		}
	// 	}
	// }

	const isSelected = (arr: any, selectedArr: any) => {
		for (const element of arr) {
			for (const savedElement of selectedArr) {
				if (element === savedElement.value) {
					return true
				}
			}
		}
		return false
	}
	const arrIncludesValue = (arr1: any, value: string) => {
		for (const value1 of arr1) {
			if (value1.value === value) {
				return true
			}
		}
		return false
	}
	const possibleSelected = props.nodes.map(
		(countryNode: any) => countryNode.value,
	)
	const [titleIsOpen, setTitleIsOpen] = useState(false)
	const [globalCountries, setGlobalCountries] = useState(
		props.selectedElements.length >= 244,
	)
	// ,
	const [globalClicked, setGlobalClicked] = useState(false)
	const [mainChecked, setMainChecked] = useState(false)
	const [halfChecked, setHalfChecked] = useState(
		isSelected(possibleSelected, props.selectedElements),
	)
	const globalValues = props.countryData?.filter(
		(el: any) => el.value === 'global',
	)

	// props.isCountry &&
	// 	console.log('globalValues', props.countryData, globalValues)
	const [generalVolume, setGeneralVolume] = useState(
		props.isCountry ? globalValues[0]?.volume : '',
	)
	const [generalPayout, setGeneralPayout] = useState(
		props.isCountry ? globalValues[0]?.payout : '',
	)

	const allCountries: any = []
	if (props.isCountry) {
		for (const continent of props.nodes) {
			for (const country of continent.children) {
				allCountries.push(country)
			}
		}
	}
	const updateGeneralVolume = (volume: string) => {
		props.selectedElements.forEach((country: any) => {
			return (country.volume = volume)
		})
		props.countryData.forEach((continent: any) => {
			// if (continent.value === 'global') {
			continent.volume = volume
			// } else {
			// continent.volume = ''
			// }
		})
		if (props.setChangeToFilterDone) props.setChangeToFilterDone(true)
		setGeneralVolume(volume)
	}
	const updateGeneralPayout = (payout: string) => {
		props.selectedElements.forEach((country: any) => {
			return (country.payout = payout)
		})
		props.countryData.forEach((continent: any) => {
			// if (continent.value === 'global') {
			continent.payout = payout
			// } else {
			// 	continent.payout = ''
			// }
		})
		if (props.setChangeToFilterDone) props.setChangeToFilterDone(true)
		setGeneralPayout(payout)
	}
	const updateGeneralsToCustom = (continent: string, valueChanged: string) => {
		props.countryData.forEach((element: any) => {
			if (element.value === 'global') {
				element[valueChanged] = 'Custom'
				if (props.setChangeToFilterDone) props.setChangeToFilterDone(true)
			}
		})
		if (valueChanged === 'volume') {
			setGeneralVolume('Custom')
		} else {
			setGeneralPayout('Custom')
		}
	}
	const handleGlobalRemoval = () => {
		if (globalCountries) {
			props.countryData.forEach((continent: any) => {
				if (continent.value !== 'global') {
					continent.payout = ''
					continent.volume = ''
				}
			})
		} else {
			props.countryData.forEach((continent: any) => {
				if (continent.value === 'global') {
					continent.payout = ''
					continent.volume = ''
				}
			})
		}
	}
	const handlePopUp = () => {
		const isConfirmed: boolean = window.confirm(
			'This will overwrite all the country data you have. Are you sure you want to continue?',
		)
		if (isConfirmed) {
			setGlobalCountries(!globalCountries)
			setGlobalClicked(true)
			handleGlobalRemoval()
		} else {
			return
		}
	}
	useEffect(() => {
		if (globalCountries) {
			props.setSelectedElements(allCountries)
		} else if (globalClicked) {
			props.setSelectedElements([])
			updateGeneralVolume('')
			updateGeneralPayout('')
		}
	}, [globalCountries])

	useEffect(() => {
		if (props.expandFilter !== undefined) {
			setTitleIsOpen(props.expandFilter)
		}
	}, [props.expandFilter])

	return (
		<div
			style={{
				color: 'black',
				display: 'flex',
				flexDirection: 'column',
				fontSize: '15px',
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
				}}
			>
				<Checkbox
					style={{ flexShrink: 0, padding: '0px', marginBottom: 12 }}
					checked={titleIsOpen}
					onChange={() => {
						setTitleIsOpen(!titleIsOpen)
						props.setExpandFilter && props.setExpandFilter(false)
					}}
					key={'expand' + props.label}
					inputProps={{
						'aria-label': 'controlled',
					}}
					icon={
						<>
							<ArrowRightIcon
								style={{ scale: '0.8', color: theme.colors.base.grey600 }}
							/>
						</>
					}
					checkedIcon={
						<>
							<ArrowDropDownIcon
								style={{ scale: '0.8', color: theme.colors.base.grey900 }}
							/>
							{/* <span style={{ color: 'green' }}>Editable</span> */}
						</>
					}
				/>
				<TitleComponent
					style={{
						flex: 1,
						fontSize: theme.font.size.buttons,
						cursor: 'pointer',
						color: props.dontGrayOut
							? 'black'
							: titleIsOpen
							? theme.colors.base.grey900
							: theme.colors.base.grey600,
						marginBottom: 12,
					}}
					onClick={() => {
						setTitleIsOpen(!titleIsOpen)
						props.setExpandFilter && props.setExpandFilter(false)
					}}
				>
					{props.label}
				</TitleComponent>
				{/* {!titleIsOpen && (
					<span
						style={{
							color: 'red',
							paddingLeft: '12px',
							cursor: 'pointer',
							fontSize: '12px',
						}}
						onClick={() => {
							setTitleIsOpen(!titleIsOpen)
							props.setExpandFilter && props.setExpandFilter(false)
						}}
					>
						Edit
					</span>
				)} */}
			</div>
			{titleIsOpen
				? props.isCountry && (
						<li
							style={{
								listStyle: 'none',
								marginLeft: '8px',
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}
						>
							<div style={{ marginBottom: 8 }}>
								<RadioChip
									option={'Global'}
									value={globalCountries ? 'Global' : undefined}
									onChange={() => {
										if (!globalCountries && props.selectedElements.length > 0) {
											handlePopUp()
										} else {
											setGlobalCountries(!globalCountries)
											handleGlobalRemoval()
										}
									}}
								/>
								{/* <Checkbox
									checked={globalCountries}
									onChange={() => {
										if (!globalCountries && props.selectedElements.length > 0) {
											handlePopUp()
										} else {
											setGlobalCountries(!globalCountries)
											handleGlobalRemoval()
										}
									}}
									key={'checkbox-country-global'}
									inputProps={{
										'aria-label': 'controlled',
									}}
									icon={
										<CheckBoxOutlineBlankIcon
											style={{ scale: '1', color: themeOld.colors.black }}
										/>
									}
									checkedIcon={
										<CheckBoxIcon
											style={{ scale: '1', color: themeOld.colors.green }}
										/>
									}
								/>
								<span>{'Global'}</span> */}
							</div>
							{props.isCountry && globalCountries && (
								<div
									style={{
										width: props.elementsWidth ? props.elementsWidth : '230px',
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'center',
										alignItems: 'center',
										alignContent: 'center',
										marginTop: '-10px',
										gap: '8px',
										marginLeft: '8px',
									}}
								>
									<DropList
										onChange={(e: any) => updateGeneralVolume(e)}
										options={['High', 'Medium', 'Low', 'Custom']}
										label={'Volume'}
										value={generalVolume}
									/>
									<div style={{ marginTop: '2px', width: '100%' }}>
										<InputFieldText
											label={'Min. Payout'}
											value={generalPayout}
											onChange={(e: any) => updateGeneralPayout(e)}
											type='text'
										/>
									</div>
								</div>
							)}
						</li>
				  )
				: props.isCountry &&
				  globalCountries && (
						<li
							style={{
								listStyle: 'none',
								marginLeft: '8px',
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}
						>
							<div>
								<RadioChip
									option={'Global'}
									value={globalCountries ? 'Global' : undefined}
									onChange={() => {
										setGlobalCountries(!globalCountries)
									}}
									key={'checkbox-country-global'}
									disabled
								/>
								{/* <Checkbox
									checked={globalCountries}
									onChange={() => {
										setGlobalCountries(!globalCountries)
									}}
									key={'checkbox-country-global'}
									inputProps={{
										'aria-label': 'controlled',
									}}
									disabled
									icon={
										<CheckBoxOutlineBlankIcon
											style={{ scale: '1', color: themeOld.colors.black }}
										/>
									}
									checkedIcon={
										<CheckBoxIcon
											style={{ scale: '1', color: 'rgba(175,175,175,1)' }}
										/>
									}
								/>
								<span style={{ color: 'rgba(175,175,175,1)' }}>{'Global'}</span> */}
							</div>
							{props.isCountry && globalCountries && (
								<div
									style={{
										width: props.elementsWidth ? props.elementsWidth : '230px',
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'center',
										alignItems: 'center',
										alignContent: 'center',
										marginTop: '-10px',
										gap: '8px',
										marginLeft: '8px',
									}}
								>
									{generalVolume && (
										<DropList
											onChange={(e: any) => updateGeneralVolume(e)}
											options={['High', 'Medium', 'Low', 'Custom']}
											label={'Volume'}
											value={generalVolume}
											disabled
										/>
									)}
									{generalPayout && (
										<div style={{ marginTop: '2px', width: '100%' }}>
											<InputFieldText
												label={'Min. Payout'}
												value={generalPayout}
												onChange={(e: any) => updateGeneralPayout(e)}
												type={'text'}
												disabled
											/>
										</div>
									)}
								</div>
							)}
						</li>
				  )}
			<div style={{ display: 'flex', gap: '6px', flexDirection: 'column' }}>
				{titleIsOpen
					? props.nodes.map((el: any) => {
							return el.children ? (
								<TreeCheckBoxTitle
									el={el}
									selectedElements={props.selectedElements}
									setSelectedElements={props.setSelectedElements}
									key={el.label + 'title'}
									isCountry={props.isCountry}
									titleIsOpen={titleIsOpen}
									isGlobal={globalCountries}
									generalPayout={generalPayout}
									generalVolume={generalVolume}
									globalClicked={globalClicked}
									countryData={props.countryData}
									setCountryData={props.setCountryData}
									updateGeneralsToCustom={updateGeneralsToCustom}
									setChangeToFilterDone={props.setChangeToFilterDone}
									elementsWidth={props.elementsWidth}
									customInputLabel={props.customInputLabel}
									disabled={props.disabled}
								></TreeCheckBoxTitle>
							) : (
								<UnexpandableTreeCheckboxElement
									node={el}
									selectedElements={props.selectedElements}
									setSelectedElements={props.setSelectedElements}
									key={el.label + 'title'}
									mainChecked={mainChecked}
									setMainChecked={setMainChecked}
									halfChecked={halfChecked}
									setHalfChecked={setHalfChecked}
									arrIncludesValue={arrIncludesValue}
									titleIsOpen={titleIsOpen}
									setChangeToFilterDone={props.setChangeToFilterDone}
									disabledBasedOnLength={props.disabledBasedOnLength}
									customInputLabel={props.customInputLabel}
									disabled={props.disabled}
								></UnexpandableTreeCheckboxElement>
							)
					  })
					: props.nodes.map((el: any) => {
							return el.children ? (
								<TreeCheckBoxTitle
									el={el}
									selectedElements={props.selectedElements}
									setSelectedElements={props.setSelectedElements}
									key={el.label + 'title'}
									isCountry={props.isCountry}
									titleIsOpen={titleIsOpen}
									isGlobal={globalCountries}
									countryData={props.countryData}
									setCountryData={props.setCountryData}
									setChangeToFilterDone={props.setChangeToFilterDone}
									elementsWidth={props.elementsWidth}
									customInputLabel={props.customInputLabel}
									disabled={props.disabled}
								></TreeCheckBoxTitle>
							) : (
								<UnexpandableTreeCheckboxElement
									node={el}
									selectedElements={props.selectedElements}
									setSelectedElements={props.setSelectedElements}
									key={el.label + 'title'}
									mainChecked={mainChecked}
									setMainChecked={setMainChecked}
									halfChecked={halfChecked}
									setHalfChecked={setHalfChecked}
									arrIncludesValue={arrIncludesValue}
									titleIsOpen={titleIsOpen}
									setChangeToFilterDone={props.setChangeToFilterDone}
									disabledBasedOnLength={props.disabledBasedOnLength}
									customInputLabel={props.customInputLabel}
									dontGrayOut={props.dontGrayOut}
									disabled={props.disabled}
								></UnexpandableTreeCheckboxElement>
							)
					  })}
			</div>
		</div>
	)
}
export const TreeCheckBoxTitle = (props: {
	el: any
	selectedElements: any
	setSelectedElements: any
	isCountry?: boolean
	titleIsOpen?: boolean
	isGlobal?: boolean
	generalVolume?: string
	generalPayout?: string
	globalClicked?: boolean
	countryData?: any
	setCountryData?: any
	updateGeneralsToCustom?: any
	setChangeToFilterDone?: any
	elementsWidth?: string
	customInputLabel?: string
	disabled?: boolean
}) => {
	const theme = useTheme()
	const isSelected = (arr: any, selectedArr: any) => {
		for (const element of arr) {
			for (const savedElement of selectedArr) {
				if (element === savedElement.value) {
					return true
				}
			}
		}
		return false
	}
	const arrIncludesValue = (arr1: any, value: string) => {
		for (const value1 of arr1) {
			if (value1.value === value) {
				return true
			}
		}
		return false
	}
	const possibleSelected = props.el.children.map(
		(countryNode: any) => countryNode.value,
	)
	const [expanded, setExpanded] = useState(false)
	const [mainChecked, setMainChecked] = useState(
		isSelected(possibleSelected, props.selectedElements),
	)
	const [halfChecked, setHalfChecked] = useState(
		isSelected(possibleSelected, props.selectedElements),
	)
	const [firstRender, setFirstRender] = useState(true)
	const generalValue = props.countryData?.filter((el: any) => {
		return el.value === props.el.value
	})
	const [generalVolume, setGeneralVolume] = useState(
		props.isCountry && generalValue[0] ? generalValue[0].volume : '',
	)
	const [generalPayout, setGeneralPayout] = useState(
		props.isCountry && generalValue[0] ? generalValue[0].payout : '',
	)

	const updateGeneralVolume = (volume: string) => {
		props.el.children.forEach((country: any) => {
			return (country.volume = volume)
		})
		props.countryData.forEach((continent: any) => {
			if (continent.value === props.el.value) {
				continent.volume = volume
			}
		})
		if (props.setChangeToFilterDone) props.setChangeToFilterDone(true)
		if (props.generalVolume && props.generalVolume !== volume) {
			props.updateGeneralsToCustom(props.el.value, 'volume')
		}
		setGeneralVolume(volume)
	}
	const updateMinimumPayout = (payout: string) => {
		props.el.children.forEach((country: any) => {
			return (country.payout = payout)
		})
		props.countryData.forEach((continent: any) => {
			if (continent.value === props.el.value) {
				continent.payout = payout
			}
		})
		if (props.setChangeToFilterDone) props.setChangeToFilterDone(true)
		if (props.generalPayout && props.generalPayout !== payout) {
			props.updateGeneralsToCustom(props.el.value, 'payout')
		}
		setGeneralPayout(payout)
	}
	const updateGeneralsToCustom = (continent: string, valueChanged: string) => {
		props.countryData.forEach((element: any) => {
			if (element.value === continent) {
				element[valueChanged] = 'Custom'
			}
			if (props.setChangeToFilterDone) props.setChangeToFilterDone(true)
		})
		if (valueChanged === 'volume') {
			setGeneralVolume('Custom')
		} else {
			setGeneralPayout('Custom')
		}
	}
	useEffect(() => {
		if (props.isCountry) {
			if (!halfChecked && !mainChecked) {
				props.countryData.forEach((continent: any) => {
					if (continent.value === props.el.value) {
						continent.payout = ''
						continent.volume = ''
					}
				})
				if (props.setChangeToFilterDone) props.setChangeToFilterDone(true)
			}
		}
	}, [halfChecked, mainChecked])

	useEffect(() => {
		if (props.generalPayout && !firstRender) {
			setGeneralPayout(props.generalPayout)
		}
		if (
			props.generalVolume &&
			props.generalVolume !== 'Custom' &&
			!firstRender
		) {
			setGeneralVolume(props.generalVolume)
		}
		if (props.generalPayout && props.generalVolume) setFirstRender(false)
	}, [props.generalPayout, props.generalVolume])

	// const displayGroup = () => {
	// 	return (
	// 		props.el.children?.some((country: any) => {
	// 			return arrIncludesValue(props.selectedElements, country.value)
	// 		}) || false
	// 	)
	// }

	// const allElementsSelected =
	// 	!props.el.children?.some((country: any) => {
	// 		return !arrIncludesValue(props.selectedElements, country.value)
	// 	}) || true
	// const elementsList = props.el.children.map((country: any) => country)
	const displayGroup = () => {
		return props.el.children.some((country: any) => {
			return arrIncludesValue(props.selectedElements, country.value)
		})
	}

	const allElementsSelected = !props.el.children.some((country: any) => {
		return !arrIncludesValue(props.selectedElements, country.value)
	})
	const noElementSelected = props.el.children.every((country: any) => {
		return !arrIncludesValue(props.selectedElements, country.value)
	})
	const elementsList = props.el.children.map((country: any) => country)

	useEffect(() => {
		if (!props.isGlobal && props.globalClicked) {
			setHalfChecked(false)
			setMainChecked(false)
		} else if (props.isGlobal) {
			setMainChecked(true)
		}
	}, [props.isGlobal])
	useEffect(() => {
		if (noElementSelected === true) {
			setHalfChecked(false)
			setMainChecked(false)
		}
	}, [noElementSelected])

	return (
		<React.Fragment key={props.el.value}>
			{!props.titleIsOpen ? (
				!props.isGlobal &&
				(mainChecked || halfChecked) && (
					<li
						style={{
							listStyle: 'none',
							marginLeft: '8px',
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
							}}
						>
							<Checkbox
								style={{ marginRight: '-10px', marginTop: '-6px' }}
								checked={expanded}
								onChange={() => {
									setExpanded(!expanded)
								}}
								key={'expand' + props.el.value}
								inputProps={{
									'aria-label': 'controlled',
								}}
								icon={
									<KeyboardArrowRightIcon
										style={{ scale: '0.8', color: theme.colors.base.grey600 }}
									/>
								}
								checkedIcon={
									<KeyboardArrowDownIcon
										style={{ scale: '0.8', color: theme.colors.base.grey600 }}
									/>
								}
								disabled={props.disabled}
							/>
							<button
								style={{ border: 'none', textTransform: 'none' }}
								onClick={() => {
									setExpanded(!expanded)
								}}
								disabled={props.disabled}
							>
								<RadioChip
									option={props.el.value}
									value={props.el.value ? props.el.value : undefined}
									onChange={() => {
										setMainChecked(!mainChecked)
										setHalfChecked(false)
										if (props.selectedElements.length === 0) {
											const arr = props.el.children.map((el: any) => {
												return el
											})
											props.setSelectedElements(arr)
										} else if (allElementsSelected) {
											const newArr = props.selectedElements.filter(
												({ value: value1 }: any) =>
													!elementsList.some(
														({ value: value2 }: any) => value1 === value2,
													),
											)
											props.setSelectedElements(newArr)
										} else {
											props.setSelectedElements((prev: any) => {
												return prev ? [...prev, ...elementsList] : elementsList
											})
										}
									}}
									key={'checkbox' + props.el.value}
									disabled
									chipStyle={{ maxWidth: '100%' }}
								/>
							</button>
							{/* <Checkbox
								checked={halfChecked || mainChecked}
								onChange={() => {
									setMainChecked(!mainChecked)
									setHalfChecked(false)
									if (props.selectedElements.length === 0) {
										const arr = props.el.children.map((el: any) => {
											return el
										})
										props.setSelectedElements(arr)
									} else if (allElementsSelected) {
										const newArr = props.selectedElements.filter(
											({ value: value1 }: any) =>
												!elementsList.some(
													({ value: value2 }: any) => value1 === value2,
												),
										)
										props.setSelectedElements(newArr)
									} else {
										props.setSelectedElements((prev: any) => {
											return prev ? [...prev, ...elementsList] : elementsList
										})
									}
								}}
								disabled={true}
								key={'checkbox' + props.el.value}
								inputProps={{
									'aria-label': 'controlled',
								}}
								icon={
									<CheckBoxOutlineBlankIcon
										style={{ scale: '1', color: themeOld.colors.black }}
									/>
								}
								checkedIcon={
									<CheckBoxIcon
										style={{ scale: '1', color: 'rgba(175,175,175,1)' }}
									/>
								}
							/>
							<span
								style={{ cursor: 'pointer', color: 'rgba(175,175,175,1)' }}
								onClick={() => setExpanded(!expanded)}
							>
								{props.el.value}
							</span> */}
						</div>
						{props.isCountry && (halfChecked || mainChecked) && (
							<div
								style={{
									width: props.elementsWidth ? props.elementsWidth : '230px',
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'center',
									alignItems: 'center',
									alignContent: 'center',
									marginTop: '-10px',
									gap: '8px',
									marginLeft: '8px',
								}}
							>
								{generalVolume && (
									<DropList
										onChange={(e: any) => updateGeneralVolume(e)}
										options={['High', 'Medium', 'Low', 'Custom']}
										label={'Volume'}
										value={generalVolume}
										disabled
									/>
								)}
								{generalPayout && (
									<div style={{ marginTop: '2px', width: '100%' }}>
										<InputFieldText
											label={'Min. Payout'}
											value={generalPayout}
											onChange={(e: any) => updateMinimumPayout(e)}
											type={'text'}
											disabled
										/>
									</div>
								)}
							</div>
						)}
					</li>
				)
			) : (
				<li
					style={{
						listStyle: 'none',
						marginLeft: '8px',
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<Checkbox
							style={{ marginRight: '-10px', marginTop: '-6px' }}
							checked={expanded}
							onChange={() => {
								setExpanded(!expanded)
							}}
							key={'expand' + props.el.value}
							inputProps={{
								'aria-label': 'controlled',
							}}
							icon={
								<KeyboardArrowRightIcon
									style={{ scale: '0.8', color: theme.colors.base.grey900 }}
								/>
							}
							checkedIcon={
								<KeyboardArrowDownIcon
									style={{ scale: '0.8', color: theme.colors.base.grey900 }}
								/>
							}
						/>
						<RadioChip
							option={props.el.value}
							value={halfChecked || mainChecked ? props.el.value : undefined}
							onChange={() => {
								setMainChecked(!mainChecked)
								setHalfChecked(false)
								if (props.selectedElements.length === 0) {
									const arr = props.el.children.map((el: any) => {
										return el
									})
									props.setSelectedElements(arr)
								} else if (allElementsSelected) {
									// const newArr = props.selectedElements.filter(
									// 	({ value: value1 }: any) =>
									// 		!elementsList?.some(
									// 			({ value: value2 }: any) => value1 === value2,
									// 		) || true,
									// )
									const newArr = props.selectedElements.filter(
										({ value: value1 }: any) =>
											!elementsList.some(
												({ value: value2 }: any) => value1 === value2,
											),
									)
									props.setSelectedElements(newArr)
								} else {
									props.setSelectedElements((prev: any) => {
										return prev ? [...prev, ...elementsList] : elementsList
									})
								}
							}}
							chipStyle={{ maxWidth: '100%' }}
							key={'checkbox' + props.el.value}
							disabled={props.disabled}
						/>
						{/* <Checkbox
							checked={halfChecked || mainChecked}
							onChange={() => {
								setMainChecked(!mainChecked)
								setHalfChecked(false)
								if (props.selectedElements.length === 0) {
									const arr = props.el.children.map((el: any) => {
										return el
									})
									props.setSelectedElements(arr)
								} else if (allElementsSelected) {
									// const newArr = props.selectedElements.filter(
									// 	({ value: value1 }: any) =>
									// 		!elementsList?.some(
									// 			({ value: value2 }: any) => value1 === value2,
									// 		) || true,
									// )
									const newArr = props.selectedElements.filter(
										({ value: value1 }: any) =>
											!elementsList.some(
												({ value: value2 }: any) => value1 === value2,
											),
									)
									props.setSelectedElements(newArr)
								} else {
									props.setSelectedElements((prev: any) => {
										return prev ? [...prev, ...elementsList] : elementsList
									})
								}
							}}
							key={'checkbox' + props.el.value}
							inputProps={{
								'aria-label': 'controlled',
							}}
							icon={
								<CheckBoxOutlineBlankIcon
									style={{ scale: '1', color: themeOld.colors.black }}
								/>
							}
							checkedIcon={
								<CheckBoxIcon
									style={{ scale: '1', color: themeOld.colors.green }}
								/>
							}
						/>
						<span
							style={{ cursor: 'pointer' }}
							onClick={() => setExpanded(!expanded)}
						>
							{props.el.value}
						</span> */}
					</div>
					{props.isCountry && (halfChecked || mainChecked) && (
						<div
							style={{
								width: props.elementsWidth ? props.elementsWidth : '230px',
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'center',
								alignItems: 'center',
								alignContent: 'center',
								marginTop: '-10px',
								gap: '8px',
								marginLeft: '8px',
							}}
						>
							<DropList
								onChange={(e: any) => updateGeneralVolume(e)}
								options={['High', 'Medium', 'Low', 'Custom']}
								label={'Volume'}
								value={generalVolume}
							/>
							<div style={{ marginTop: '2px', width: '100%' }}>
								<InputFieldText
									label={'Min. Payout'}
									value={generalPayout}
									onChange={(e: any) => updateMinimumPayout(e)}
									type={'text'}
								/>
							</div>
						</div>
					)}
				</li>
			)}
			{props.titleIsOpen
				? props.isCountry
					? expanded && (
							<ul
								style={{
									display: 'flex',
									gap: '0px',
									flexDirection: 'column',
									margin: '0px',
								}}
							>
								{props.el.children.map((country: any) => {
									return (
										<TreeCheckboxCountryElement
											continent={props.el.value}
											country={country}
											key={'children-value-' + country.label}
											mainChecked={mainChecked}
											setMainChecked={setMainChecked}
											halfChecked={halfChecked}
											setHalfChecked={setHalfChecked}
											selectedElements={props.selectedElements}
											setSelectedElements={props.setSelectedElements}
											arrIncludesValue={arrIncludesValue}
											titleIsOpen={props.titleIsOpen}
											generalVolume={generalVolume}
											generalPayout={generalPayout}
											updateGeneralsToCustom={updateGeneralsToCustom}
											setChangeToFilterDone={props.setChangeToFilterDone}
											elementsWidth={props.elementsWidth}
										/>
									)
								})}
							</ul>
					  )
					: expanded && (
							<ul
								style={{
									display: 'flex',
									gap: '6px',
									flexDirection: 'column',
									margin: '0px',
								}}
							>
								{props.el.children.map((node: any) => {
									return (
										<TreeCheckboxElement
											node={node}
											key={'children-value-' + node.label}
											mainChecked={mainChecked}
											setMainChecked={setMainChecked}
											halfChecked={halfChecked}
											setHalfChecked={setHalfChecked}
											selectedElements={props.selectedElements}
											setSelectedElements={props.setSelectedElements}
											arrIncludesValue={arrIncludesValue}
											titleIsOpen={props.titleIsOpen}
											setChangeToFilterDone={props.setChangeToFilterDone}
											elementsWidth={props.elementsWidth}
											customInputLabel={props.customInputLabel}
											disabled={props.disabled}
										/>
									)
								})}
							</ul>
					  )
				: props.isCountry
				? expanded &&
				  displayGroup() && (
						<ul
							style={{
								display: 'flex',
								gap: '0px',
								flexDirection: 'column',
								// height: '34px',
								margin: '0px',
								marginLeft: '8px',
							}}
						>
							{props.el.children.map((country: any) => {
								return (
									<TreeCheckboxCountryElement
										continent={props.el.value}
										country={country}
										key={'children-value-' + country.label}
										mainChecked={mainChecked}
										setMainChecked={setMainChecked}
										halfChecked={halfChecked}
										setHalfChecked={setHalfChecked}
										selectedElements={props.selectedElements}
										setSelectedElements={props.setSelectedElements}
										arrIncludesValue={arrIncludesValue}
										titleIsOpen={props.titleIsOpen}
										generalVolume={generalVolume}
										generalPayout={generalPayout}
										updateGeneralsToCustom={updateGeneralsToCustom}
										setChangeToFilterDone={props.setChangeToFilterDone}
										elementsWidth={props.elementsWidth}
									/>
								)
							})}
						</ul>
				  )
				: expanded && (
						<ul
							style={{
								display: 'flex',
								gap: '0px',
								flexDirection: 'column',
								margin: '0px',
							}}
						>
							{props.el.children.map((node: any) => {
								return (
									<TreeCheckboxElement
										node={node}
										key={'children-value-' + node.label}
										mainChecked={mainChecked}
										setMainChecked={setMainChecked}
										halfChecked={halfChecked}
										setHalfChecked={setHalfChecked}
										selectedElements={props.selectedElements}
										setSelectedElements={props.setSelectedElements}
										arrIncludesValue={arrIncludesValue}
										titleIsOpen={props.titleIsOpen}
										setChangeToFilterDone={props.setChangeToFilterDone}
										elementsWidth={props.elementsWidth}
										customInputLabel={props.customInputLabel}
									/>
								)
							})}
						</ul>
				  )}
		</React.Fragment>
	)
}

export const TreeCheckboxCountryElement = (props: {
	continent?: string
	country: any
	mainChecked: boolean
	setMainChecked: any
	halfChecked: boolean
	setHalfChecked: any
	selectedElements: any
	setSelectedElements: any
	arrIncludesValue: any
	titleIsOpen?: boolean
	generalVolume: string
	generalPayout: string
	updateGeneralsToCustom?: any
	setChangeToFilterDone?: any
	elementsWidth?: string
}) => {
	const findElement = (arr1: any, value: string) => {
		for (const value1 of arr1) {
			if (value1.value === value) {
				return value1
			}
		}
		return { volume: '', payout: '' }
	}
	const [checked, setChecked] = useState(
		props.arrIncludesValue(props.selectedElements, props.country.value),
	)
	const [payoutValue, setPayoutValue] = useState(
		findElement(props.selectedElements, props.country.value).payout,
	)
	const [volumeValue, setVolumeValue] = useState(
		findElement(props.selectedElements, props.country.value).volume,
	)
	const [firstRender, setFirstRender] = useState(true)

	useEffect(() => {
		if (props.arrIncludesValue(props.selectedElements, props.country.value)) {
			setChecked(true)
			props.setHalfChecked(true)
		} else {
			setChecked(false)
		}
	}, [props.halfChecked])
	useEffect(() => {
		if (props.arrIncludesValue(props.selectedElements, props.country.value)) {
			setChecked(true)
		} else {
			setChecked(false)
			props.setHalfChecked(false)
		}
	}, [props.mainChecked])

	useEffect(() => {
		if (checked) {
			props.setHalfChecked(true)
		} else {
			props.setHalfChecked(false)
		}
	}, [checked])
	useEffect(() => {
		if (
			props.generalPayout &&
			!firstRender &&
			props.generalPayout !== 'Custom'
		) {
			setPayoutValue(props.generalPayout)
		}
		if (
			props.generalVolume &&
			!firstRender &&
			props.generalVolume !== 'Custom'
		) {
			setVolumeValue(props.generalVolume)
		}
		setFirstRender(false)
	}, [props.generalPayout, props.generalVolume])

	const handleCheck = () => {
		setChecked(!checked)
		if (!props.arrIncludesValue(props.selectedElements, props.country.value)) {
			props.setSelectedElements((prev: any) => {
				return prev ? [...prev, props.country] : [props.country]
			})
		} else {
			const arr = props.selectedElements.filter(
				(el: any) => el.value !== props.country.value,
			)
			props.setSelectedElements(arr)
		}

		// props.setMainChecked(true)
	}

	const handlePayout = (payout: any) => {
		setPayoutValue(payout)
		let index: number
		for (let i = 0; i < props.selectedElements.length; i++) {
			// console.log(props.selectedElements[i])
			if (props.selectedElements[i].value === props.country.value) {
				index = i
				break
			}
		}
		props.selectedElements[index!]['payout'] = payout
		if (props.setChangeToFilterDone) props.setChangeToFilterDone(true)
		if (props.generalPayout && props.generalPayout !== payout) {
			props.updateGeneralsToCustom(props.continent, 'payout')
		}
	}
	const handleVolume = (volume: any) => {
		setVolumeValue(volume)
		let index: number
		for (let i = 0; i < props.selectedElements.length; i++) {
			// console.log(props.selectedElements[i])
			if (props.selectedElements[i].value === props.country.value) {
				index = i
				break
			}
		}
		props.selectedElements[index!]['volume'] = volume
		if (props.setChangeToFilterDone) props.setChangeToFilterDone(true)
		if (props.generalVolume && props.generalVolume !== volume) {
			props.updateGeneralsToCustom(props.continent, 'volume')
		}
	}
	return !props.titleIsOpen ? (
		checked && (
			<li
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'space-between',
				}}
			>
				<div
					style={{
						width: '60%',
						display: 'flex',
						alignItems: 'center',
						marginLeft: '8px',
					}}
				>
					<RadioChip
						option={props.country.value}
						value={checked ? props.country.value : undefined}
						onChange={handleCheck}
						key={'checkbox' + props.country.value}
						disabled
						chipStyle={{ maxWidth: '100%' }}
					/>
					{/* <Checkbox
						checked={checked}
						onChange={handleCheck}
						icon={
							<CheckBoxOutlineBlankIcon
								style={{ scale: '1', color: themeOld.colors.black }}
							/>
						}
						disabled={true}
						checkedIcon={
							<CheckBoxIcon
								style={{ scale: '1', color: 'rgba(175,175,175,1)' }}
							/>
						}
					/>
					<span style={{ paddingTop: '4px', color: 'rgba(175,175,175,1)' }}>
						{props.country.value}
					</span> */}
				</div>
				{checked && (
					<div
						style={{
							width: props.elementsWidth ? props.elementsWidth : '230px',
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'center',
							alignItems: 'center',
							alignContent: 'center',
							marginTop: '-10px',
							gap: '8px',
							marginLeft: '8px',
						}}
					>
						<DropList
							onChange={(e: any) => handleVolume(e)}
							options={['High', 'Medium', 'Low', 'N/A']}
							label={'Volume'}
							value={volumeValue}
							disabled={true}
						/>

						<div style={{ marginTop: '2px' }}>
							<InputFieldText
								label={'Payout'}
								value={payoutValue}
								onChange={(e: any) => handlePayout(e)}
								type={'text'}
								disabled={true}
							/>
						</div>
					</div>
				)}
			</li>
		)
	) : (
		<li
			style={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-between',
			}}
		>
			<div
				style={{
					width: '60%',
					display: 'flex',
					alignItems: 'center',
					marginLeft: '8px',
				}}
			>
				<RadioChip
					option={props.country.value}
					value={checked ? props.country.value : undefined}
					onChange={handleCheck}
					key={'checkbox' + props.country.value}
					chipStyle={{ maxWidth: '100%' }}
				/>
				{/* <Checkbox
					checked={checked}
					onChange={handleCheck}
					icon={
						<CheckBoxOutlineBlankIcon
							style={{ scale: '1', color: themeOld.colors.black }}
						/>
					}
					checkedIcon={
						<CheckBoxIcon
							style={{ scale: '1', color: themeOld.colors.green }}
						/>
					}
				/>
				<span style={{ paddingTop: '4px' }}>{props.country.value}</span> */}
			</div>
			{checked && (
				<div
					style={{
						width: props.elementsWidth ? props.elementsWidth : '230px',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
						alignContent: 'center',
						marginTop: '-10px',
						gap: '8px',
						marginLeft: '8px',
						height: '70px',
					}}
				>
					<DropList
						onChange={(e: any) => handleVolume(e)}
						options={['High', 'Medium', 'Low', 'N/A']}
						label={'Volume'}
						value={volumeValue}
					/>

					<div style={{ marginTop: '2px' }}>
						<InputFieldText
							label={'Payout'}
							value={payoutValue}
							onChange={(e: any) => handlePayout(e)}
							type={'text'}
						/>
					</div>
				</div>
			)}
		</li>
	)
}
export const TreeCheckboxElement = (props: {
	node: any
	mainChecked: boolean
	setMainChecked: any
	halfChecked: boolean
	setHalfChecked: any
	selectedElements: any
	setSelectedElements: any
	arrIncludesValue: any
	titleIsOpen: any
	setChangeToFilterDone?: any
	elementsWidth?: string
	customInputLabel?: string
	disabled?: boolean
}) => {
	const findElement = (arr1: any, value: string) => {
		for (const value1 of arr1) {
			if (value1.value === value) {
				return value1.inputValue
			}
		}
		return 'X'
	}
	const [checked, setChecked] = useState(
		props.arrIncludesValue(props.selectedElements, props.node.value),
	)
	const [inputValue, setInputValue] = useState(
		findElement(props.selectedElements, props.node.value),
	)
	// const [volumeValue, setVolumeValue] = useState(
	// 	findElement(props.selectedElements, props.node.value).volume,
	// )

	useEffect(() => {
		if (props.arrIncludesValue(props.selectedElements, props.node.value)) {
			setChecked(true)
			props.setHalfChecked(true)
		} else {
			setChecked(false)
		}
	}, [props.halfChecked])
	useEffect(() => {
		if (props.arrIncludesValue(props.selectedElements, props.node.value)) {
			setChecked(true)
		} else {
			setChecked(false)
			props.setHalfChecked(false)
		}
	}, [props.mainChecked])

	useEffect(() => {
		if (checked) {
			props.setHalfChecked(true)
		} else {
			props.setHalfChecked(false)
		}
	}, [checked])

	const handleCheck = () => {
		setChecked(!checked)
		if (!props.arrIncludesValue(props.selectedElements, props.node.value)) {
			props.setSelectedElements((prev: any) => {
				return prev ? [...prev, props.node] : [props.node]
			})
		} else {
			const arr = props.selectedElements.filter(
				(el: any) => el.value !== props.node.value,
			)
			props.setSelectedElements(arr)
		}

		// props.setMainChecked(true)
	}

	const handleInputValue = (value: any) => {
		setInputValue(value)
		let index: number
		for (let i = 0; i < props.selectedElements.length; i++) {
			// console.log(props.selectedElements[i])
			if (props.selectedElements[i].value === props.node.value) {
				index = i
			}
		}
		props.selectedElements[index!]['inputValue'] = value
		if (props.setChangeToFilterDone) props.setChangeToFilterDone(true)
	}
	// const handleVolume = (volume: any) => {
	// 	setVolumeValue(volume)
	// 	let index: number
	// 	for (let i = 0; i < props.selectedElements.length; i++) {
	// 		// console.log(props.selectedElements[i])
	// 		if (props.selectedElements[i].value === props.node.value) {
	// 			index = i
	// 		}
	// 	}
	// 	props.selectedElements[index!]['volume'] = volume
	// }

	return props.titleIsOpen ? (
		<li
			style={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-between',
				// height: '34px',
			}}
		>
			<div
				style={{
					width: '60%',
					display: 'flex',
					alignItems: 'center',
					marginLeft: '8px',
				}}
			>
				<RadioChip
					option={props.node.value}
					value={checked ? props.node.value : undefined}
					onChange={handleCheck}
					key={'checkbox' + props.node.value}
					chipStyle={{ maxWidth: '100%' }}
					disabled={props.disabled}
				/>
				{/* <Checkbox
					checked={checked}
					onChange={handleCheck}
					icon={
						<CheckBoxOutlineBlankIcon
							style={{ scale: '1', color: themeOld.colors.black }}
						/>
					}
					checkedIcon={
						<CheckBoxIcon
							style={{ scale: '1', color: themeOld.colors.green }}
						/>
					}
				/>
				<span style={{ paddingTop: '4px' }}>{props.node.value}</span> */}
			</div>
			{checked && props.node.inputValue && (
				<div
					style={{
						width: '160px',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
						alignContent: 'center',
						marginTop: '-10px',
						gap: '8px',
						marginLeft: '8px',
						height: '70px',
					}}
				>
					<div style={{ marginTop: '2px' }}>
						<StyledNameField
							label={'X value'}
							value={inputValue}
							onChange={(e: any) => handleInputValue(e)}
							type={'text'}
							customInputLabel={props.customInputLabel}
							disabled={props.disabled}
						/>
					</div>
				</div>
			)}
		</li>
	) : (
		checked && (
			<li
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'space-between',
					// height: '34px',
				}}
			>
				<div
					style={{
						width: '60%',
						display: 'flex',
						alignItems: 'center',
						marginLeft: '8px',
					}}
				>
					<RadioChip
						option={props.node.value}
						value={checked ? props.node.value : undefined}
						onChange={handleCheck}
						key={'checkbox' + props.node.value}
						disabled
						chipStyle={{ maxWidth: '100%' }}
					/>
					{/* <Checkbox
						checked={checked}
						onChange={handleCheck}
						icon={
							<CheckBoxOutlineBlankIcon
								style={{ scale: '1', color: themeOld.colors.black }}
							/>
						}
						disabled={true}
						checkedIcon={
							<CheckBoxIcon
							// style={{ scale: '1', color: 'rgba(175,175,175,1)' }}
							/>
						}
					/>
					<span style={{ paddingTop: '4px', color: 'black' }}>
						{props.node.value}
					</span> */}
				</div>
				{props.node.inputValue && (
					<div
						style={{
							width: '160px',
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'center',
							alignItems: 'center',
							alignContent: 'center',
							marginTop: '-10px',
							gap: '8px',
							marginLeft: '8px',
							height: '70px',
						}}
					>
						<div style={{ marginTop: '2px' }}>
							<StyledNameField
								label={'X value'}
								value={inputValue}
								onChange={(e: any) => handleInputValue(e)}
								type={'text'}
								customInputLabel={props.customInputLabel}
								disabled={props.disabled}
							/>
						</div>
					</div>
				)}
			</li>
		)
	)
}
export const UnexpandableTreeCheckboxElement = (props: {
	node: any
	mainChecked: boolean
	setMainChecked: any
	halfChecked: boolean
	setHalfChecked: any
	selectedElements: any
	setSelectedElements: any
	arrIncludesValue: any
	titleIsOpen?: boolean
	setChangeToFilterDone?: any
	disabledBasedOnLength?: number
	customInputLabel?: string
	dontGrayOut?: boolean
	disabled?: boolean
}) => {
	const theme = useTheme()
	const findElement = (arr1: any, value: string) => {
		for (const value1 of arr1) {
			if (value1.value === value) {
				return value1.inputValue
			}
		}
		return 'X'
	}
	const [checked, setChecked] = useState(
		props.arrIncludesValue(props.selectedElements, props.node.value),
	)
	const [inputValue, setInputValue] = useState(
		findElement(props.selectedElements, props.node.value),
	)
	const [boxColor, setBoxColor] = useState(theme.colors.text.titles)

	const handleInputValue = (value: any) => {
		setInputValue(value)
		let index: number
		for (let i = 0; i < props.selectedElements.length; i++) {
			// console.log(props.selectedElements[i])
			if (props.selectedElements[i].value === props.node.value) {
				index = i
			}
		}
		props.selectedElements[index!]['inputValue'] = value
		if (props.setChangeToFilterDone) props.setChangeToFilterDone(true)
	}

	useEffect(() => {
		if (props.arrIncludesValue(props.selectedElements, props.node.value)) {
			setChecked(true)
			props.setHalfChecked(true)
		} else {
			setChecked(false)
		}
	}, [props.halfChecked])
	useEffect(() => {
		if (props.arrIncludesValue(props.selectedElements, props.node.value)) {
			setChecked(true)
		} else {
			setChecked(false)
			props.setHalfChecked(false)
		}
	}, [props.mainChecked])

	useEffect(() => {
		if (checked) {
			props.setHalfChecked(true)
		} else {
			props.setHalfChecked(false)
		}
	}, [checked])

	useEffect(() => {
		if (props.disabledBasedOnLength) {
			if (
				props.selectedElements.length >= props.disabledBasedOnLength - 1 &&
				!checked
			) {
				setBoxColor(theme.colors.base.grey300)
			} else {
				setBoxColor(theme.colors.text.titles)
			}
		}
	}, [props.selectedElements])

	const handleCheck = () => {
		setChecked(!checked)
		if (!props.arrIncludesValue(props.selectedElements, props.node.value)) {
			props.setSelectedElements((prev: any) => {
				return prev ? [...prev, props.node] : [props.node]
			})
		} else {
			const arr = props.selectedElements.filter(
				(el: any) => el.value !== props.node.value,
			)
			props.setSelectedElements(arr)
		}
	}
	return props.titleIsOpen ? (
		<li
			style={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-between',
				marginLeft: '40px',
				height: '34px',
			}}
		>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<RadioChip
					option={props.node.value}
					value={checked ? props.node.value : undefined}
					onChange={handleCheck}
					disabled={
						props.disabledBasedOnLength
							? props.selectedElements.length >=
									props.disabledBasedOnLength - 1 && !checked
							: false || props.disabled
					}
					chipStyle={{ maxWidth: '100%' }}
				/>
				{/* <Checkbox
					checked={checked}
					onChange={handleCheck}
					icon={
						<CheckBoxOutlineBlankIcon style={{ scale: '1', color: boxColor }} />
					}
					checkedIcon={
						<CheckBoxIcon
							style={{ scale: '1', color: themeOld.colors.green }}
						/>
					}
					disabled={
						props.disabledBasedOnLength
							? props.selectedElements.length >=
									props.disabledBasedOnLength - 1 && !checked
							: false
					}
				/>
				<span style={{ color: boxColor }}>{props.node.value}</span> */}
			</div>
			{checked && props.node.inputValue && (
				<div
					style={{
						width: '100px',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
						alignContent: 'center',
						marginTop: '-10px',
						gap: '8px',
						marginLeft: '8px',
						height: '70px',
					}}
				>
					<div style={{ marginTop: '2px' }}>
						<StyledNameField
							label={'X value'}
							value={inputValue}
							onChange={(e: any) => handleInputValue(e)}
							type={'text'}
							customInputLabel={props.customInputLabel}
							disabled={props.disabled}
						/>
					</div>
				</div>
			)}
		</li>
	) : (
		checked && (
			<li
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'space-between',
					marginLeft: '40px',
					height: '34px',
				}}
			>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<RadioChip
						option={props.node.value}
						value={checked ? props.node.value : undefined}
						onChange={handleCheck}
						disabled
						chipStyle={{ maxWidth: '100%' }}
					/>
					{/* <Checkbox
						checked={checked}
						onChange={handleCheck}
						disabled
						icon={
							<CheckBoxOutlineBlankIcon
								style={{ scale: '1', color: themeOld.colors.black }}
							/>
						}
						checkedIcon={
							props.dontGrayOut ? (
								<></>
							) : (
								<CheckBoxIcon
									style={{
										scale: '1',
										color: props.dontGrayOut ? 'black' : 'rgba(175,175,175,1)',
									}}
								/>
							)
						}
					/>
					<span
						style={{
							paddingTop: '4px',
							color: props.dontGrayOut ? 'black' : 'rgba(175,175,175,1)',
						}}
					>
						{props.node.value}
					</span> */}
				</div>
				{props.node.inputValue && (
					<div
						style={{
							width: '100px',
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'center',
							alignItems: 'center',
							alignContent: 'center',
							marginTop: '-10px',
							gap: '8px',
							marginLeft: '8px',
							height: '70px',
						}}
					>
						<div style={{ marginTop: '2px' }}>
							<StyledNameField
								label={'X value'}
								value={inputValue}
								onChange={(e: any) => handleInputValue(e)}
								type={'text'}
								customInputLabel={props.customInputLabel}
								disabled={props.disabled}
							/>
						</div>
					</div>
				)}
			</li>
		)
	)
}
