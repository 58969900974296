import React, { useEffect, useState } from 'react'
import { Alert } from '../../../models/alert.interface'
import { useTheme, IconButton } from '@mui/material'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import CloseIcon from '@mui/icons-material/Close'
import MinimizeIcon from '@mui/icons-material/Minimize'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Tooltip from '@mui/material/Tooltip'
import { SmallLoading } from '../../../assets/svg/loading'
import { reportRoutes } from '../../../config'
import { useAlertsActions } from '../../../hooks/useActions'

const AlertComponent = ({
	alert,
	removeAlert,
	onLinkClick,
}: {
	alert: Alert
	removeAlert: (id: string) => void
	onLinkClick: (link: string) => void
}) => {
	const theme = useTheme()
	const [dots, setDots] = useState('')
	const { minimizeAlert } = useAlertsActions() // Use minimizeAlert action

	const handleLinkClick = () => {
		let url = alert.link as string
		const isReportType = Object.values(reportRoutes).includes(
			alert.link as string,
		)
		if (alert.extra_data && isReportType) {
			url = `${alert.link}?report=${alert.extra_data}`
		}
		onLinkClick(url)
	}

	useEffect(() => {
		if (alert.status === 'pending') {
			const interval = setInterval(() => {
				setDots((prevDots) => (prevDots.length < 3 ? prevDots + '.' : ''))
			}, 500)

			return () => clearInterval(interval)
		} else {
			setDots('')
		}
	}, [alert.status])

	const handleMinimize = () => {
		minimizeAlert(alert.id) // Minimize (hide) this specific alert
	}

	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				width: '20vw',
				background: theme.colors.alerts.grey50,
				boxShadow: '0px 0px 4px 2px ' + theme.colors.alerts.grey600,
				borderRadius: '30px',
				color: theme.colors.alerts.grey900,
				padding: '1rem',
				position: 'relative',
				whiteSpace: 'normal',
				wordWrap: 'break-word',
			}}
		>
			{/* Existing alert content */}
			{alert.status === 'pending' ? (
				<div
					style={{
						width: '25px',
						height: '25px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						marginRight: '1rem',
					}}
				></div>
			) : alert.isError ? (
				<div
					style={{
						width: '25px',
						height: '25px',
						background: theme.colors.alerts.red300,
						display: 'flex',
						borderRadius: '50%',
						alignItems: 'center',
						justifyContent: 'center',
						marginRight: '1rem',
					}}
				>
					<CloseIcon
						style={{
							color: theme.colors.status.red,
						}}
					/>
				</div>
			) : (
				<div
					style={{
						width: '25px',
						height: '25px',
						background: theme.colors.status.green,
						display: 'flex',
						borderRadius: '50%',
						alignItems: 'center',
						justifyContent: 'center',
						marginRight: '1rem',
					}}
				>
					<CheckOutlinedIcon
						style={{
							scale: '0.9',
							color: theme.colors.alerts.white,
						}}
					/>
				</div>
			)}

			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '2px',
					flexGrow: 1,
				}}
			>
				<span
					style={{
						color: theme.colors.alerts.grey300,
						fontSize: theme.font.size.caption,
					}}
				>
					Notification
				</span>
				<span
					style={{
						color: theme.colors.alerts.grey900,
						fontSize: theme.font.size.body,
						display: 'flex',
						alignItems: 'center',
					}}
				>
					{alert.message}
					{alert.status === 'pending' && (
						<>
							<span>{dots}</span>
							<SmallLoading
								style={{
									position: 'absolute',
									right: '10px',
									top: '60%',
									transform: 'translateY(-50%)',
								}}
							/>
						</>
					)}

					{alert.link && alert.status !== 'pending' && (
						<span
							style={{
								textDecoration: 'underline',
								color: theme.colors.alerts.green,
								cursor: 'pointer',
								marginLeft: '5px',
							}}
							onClick={handleLinkClick}
						>
							View Report
						</span>
					)}
					{!alert.isError && alert.link && alert.status !== 'pending' && (
						<Tooltip title='The report name is the last 5 digits of the CSV file name.'>
							<InfoOutlinedIcon
								style={{
									marginLeft: '5px',
									fontSize: '1rem',
									cursor: 'pointer',
									marginBottom: '-0.2rem',
								}}
							/>
						</Tooltip>
					)}
				</span>
			</div>

			<IconButton
				sx={{
					position: 'absolute',
					top: '1px',
					right: '35px',
					padding: '2px',
					'&:hover': {
						color: theme.colors.alerts.grey900,
					},
				}}
				onClick={handleMinimize}
				size='small'
			>
				<MinimizeIcon
					fontSize='small'
					sx={{ color: theme.colors.alerts.grey300 }}
				/>
			</IconButton>

			<IconButton
				sx={{
					position: 'absolute',
					top: '5px',
					right: '6px',
					padding: '4px',
					'&:hover': {
						color: theme.colors.alerts.grey900,
					},
				}}
				onClick={() => removeAlert(alert.id)}
				size='small'
			>
				<CloseIcon
					fontSize='small'
					sx={{ color: theme.colors.alerts.grey300 }}
				/>
			</IconButton>
		</div>
	)
}

export default AlertComponent
