import { Container, useTheme } from '@mui/material'
import zIndex from '@mui/material/styles/zIndex'
import React, { useEffect, useState } from 'react'
import NewAdvertiserItem from './NewAdvertiserItem'
import NewPublisherItem from './NewPublisherItem'
import { TitleComponent } from '../components/Informative'

const NewsDashboardContainer = (props: {
	list: string[]
	title: string
	mainHeight: number
}) => {
	const theme = useTheme()
	useEffect(() => {
		if (props.list.length > 0) {
			const data = []
			for (const el of props.list) {
				data.push(JSON.parse(el))
			}
			setItems(data)
		}
	}, [props.list])
	const [items, setItems] = useState<any>([])
	return (
		<Container
			style={{
				height: props.mainHeight === 0 ? '40vh' : props.mainHeight * 0.85,
				// height:
				// 	props.mainHeight === 0
				// 		? '40vh'
				// 		: props.title === 'Advertisers'
				// 		? `${Math.floor(props.mainHeight * 0.45)}px`
				// 		: `${Math.floor(props.mainHeight * 0.45)}px`,
				minHeight: '100px',
				paddingTop: '30px',
				marginBottom: props.title === 'Publishers' ? '0px' : '100px',
				display: 'flex',
				alignItems: 'flex-start',
				flexDirection: 'column',
				gap: '30px',
			}}
		>
			<TitleComponent
				component={'span'}
				style={{
					fontSize: '22px',
					fontWeight: '1000',
					letterSpacing: '-1px',
					marginTop: '18px',
				}}
			>
				{'New ' + props.title}
			</TitleComponent>

			<Container
				style={{
					overflowY: 'scroll',
					height: props.title === 'Advertisers' ? '100%' : '100%',
					minHeight: '100px',
					paddingLeft: '4px',
					marginBottom: '22px',
					marginTop: '0px',
					color: theme.colors.text.titles,
				}}
				id={'news-container'}
			>
				{items.map((element: any) => {
					if (props.title === 'Advertisers' && items.length > 0) {
						return (
							<React.Fragment
								key={
									element.advertiser_name + 'fragment-adv' + element.createdAt
								}
							>
								<NewAdvertiserItem
									item={element}
									key={element.advertiser_name}
									title={props.title}
								/>
							</React.Fragment>
						)
					} else if (props.title === 'Publishers' && items.length > 0) {
						return (
							<React.Fragment key={element.publisher_name + 'fragment-pub'}>
								<NewPublisherItem
									item={element}
									key={element.publisher_name}
									title={props.title}
								/>
							</React.Fragment>
						)
					}
					return <></>
				})}
			</Container>
		</Container>
	)
}

export default NewsDashboardContainer
