import {
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	Paper,
	Checkbox,
	Typography,
	useTheme,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useActions, useUserActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { UserInterface } from '../../models/model.interface'
import { UserPopUpForm } from './UserPopUpComponents'
import { SubmitButton, UnborderedButton } from '../components/Buttons'

import {
	hasPermission,
	sumPermissions,
} from '../../utils/helpers/permissionsHelper'
import { StyledCheckboxFormControl } from '../reports/aggregated/FiltersStyled'
import { TableSearchComponent } from '../components/Search'

type permission = {
	name: string
	id: string
	value: string
	type: string
}

const ChangeRolePermissions = (props: {
	PopUpVisible: boolean
	setPopUpVisible: any
	row?: UserInterface
	setLoading: any
	handleClose: any
	errorMessage: null | string
	setErrorMessage: any
	isErrorPromptOpened: boolean
	setIsErrorPromptOpened: any
}): JSX.Element => {
	const theme = useTheme()
	const { permissions, roles } = useTypedSelector((state) => state)
	const [selectedPermissions, setSelectedPermissions] = useState<string[]>([])
	const [searchQuery, setSearchQuery] = useState<string>('')
	const [defaultPermissionsValue, setDefaultPermissionsValue] =
		useState<any>(null)
	const [isCustomRole, setIsCustomRole] = useState<boolean>(false)
	const { editUser, getLoggedInUser } = useUserActions()

	useEffect(() => {
		const userPermissions = props.row?.permissions || null
		const currentRole = roles.roles.find(
			(role) => role.name === props.row?.role,
		)

		if (!userPermissions || !permissions.permissions || !currentRole) return

		const defaultPermissionsValue = BigInt(currentRole.default_permission)
		setDefaultPermissionsValue(defaultPermissionsValue)

		const userPermissionsValue = BigInt(userPermissions)


		setIsCustomRole(userPermissionsValue !== defaultPermissionsValue)

		const checkedPermissions = permissions.permissions.reduce(
			(acc: string[], permission: permission) => {
				if (hasPermission(userPermissionsValue, BigInt(permission.value))) {
					acc.push(permission.name)
				}
				return acc
			},
			[],
		)

		setSelectedPermissions(checkedPermissions)
	}, [props.row, permissions.permissions, roles])

	const nullifyFields = () => {
		setSelectedPermissions([])
	}

	const handleCheckboxChange = (permissionName: string) => {
		setSelectedPermissions((prevSelectedPermissions) => {
			const newPermissions = prevSelectedPermissions.includes(permissionName)
				? prevSelectedPermissions.filter((r) => r !== permissionName)
				: [...prevSelectedPermissions, permissionName]

			// Calculate new permission sum and compare with defaultPermissionsValue
			const newPermissionsValue = sumPermissions(
				newPermissions,
				permissions.permissions,
			)
			setIsCustomRole(
				BigInt(newPermissionsValue) !== BigInt(defaultPermissionsValue),
			)

			return newPermissions
		})
	}

	const trySubmit = async () => {
		if (selectedPermissions.length > 0) {
			const newPermissionsValue = sumPermissions(
				selectedPermissions,
				permissions.permissions,
			)
			const user = props.row
			const updatedUser = {
				...user,
				permissions: newPermissionsValue.toString(),
			}
			props.setLoading(true)
			await editUser(updatedUser, props.setErrorMessage)
			await getLoggedInUser()
			props.setLoading(false)

			nullifyFields()
			props.handleClose()
		}
	}

	const capitalizeWords = (str: string): string => {
		return str
			.toLowerCase()
			.split('_')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ')
	}

	const groupPermissionsByType = (
		permissions: permission[],
	): Record<string, permission[]> => {
		return permissions.reduce(
			(acc: Record<string, permission[]>, permission: permission) => {
				if (!acc[permission.type]) {
					acc[permission.type] = []
				}
				acc[permission.type].push(permission)
				return acc
			},
			{},
		)
	}

	// Filter permissions based on search query
	const filteredPermissions = permissions.permissions.filter(
		(permission: any) =>
			permission.name.toLowerCase().includes(searchQuery.toLowerCase()),
	)

	const groupedPermissions = groupPermissionsByType(filteredPermissions)

	return (
		<UserPopUpForm open={props.PopUpVisible}>
			{/* Dialog Title */}
			<DialogTitle
				style={{
					background: theme.colors.base.white,
					color: theme.colors.text.titles,
					textAlign: 'left',
					paddingBottom: '10px',
				}}
			>
				Change Permissions for {props.row?.name || 'User'}
			</DialogTitle>

			<DialogTitle
				style={{
					background: theme.colors.base.white,
					color: theme.colors.text.titles,
					textAlign: 'left',
					paddingBottom: '10px',
				}}
			>
				Current Role: {props.row?.role} {isCustomRole ? '- Custom' : ''}
			</DialogTitle>

			{/* Dialog Content */}
			<DialogContent
				style={{
					background: theme.colors.base.white,
					color: theme.colors.text.titles,
					maxHeight: '500px',
					overflowY: 'auto',
					paddingBottom: '20px',
				}}
			>
				{/* Explanation Text */}
				<DialogContentText
					style={{
						background: theme.colors.base.white,
						color: theme.colors.text.titles,
						textAlign: 'left',
						marginBottom: '20px',
					}}
				>
					Update User Permissions
				</DialogContentText>

				{/* Search Bar */}
				<Grid container justifyContent='center'>
					<Grid item xs={10} md={8} style={{ marginBottom: '20px' }}>
						<TableSearchComponent
							search={searchQuery}
							setSearch={setSearchQuery}
							placeholder='permissions'
							autoFocus={true}
							style={{
								width: '100%',
								height: '32px',
								padding: '0.1rem 1rem',
							}}
						/>
					</Grid>
				</Grid>

				{/* Permission list */}
				<Grid container direction='column' spacing={4}>
					{Object.entries(groupedPermissions).map(([type, perms]) => (
						<Grid
							item
							key={type}
							container
							spacing={2}
							alignItems='flex-start'
							style={{ marginBottom: '20px' }}
						>
							{/* Permission type headline */}
							<Grid item xs={3}>
								<Typography
									variant='h6'
									style={{
										textAlign: 'left',
										marginBottom: '10px',
									}}
								>
									{capitalizeWords(type)}
								</Typography>
							</Grid>
							{/* List of permissions */}
							<Grid item xs={9}>
								{perms.map((permission: permission) => (
									<Paper
										key={permission.name}
										style={{
											padding: '10px',
											marginBottom: '10px',
											background: theme.colors.base.white,
											display: 'flex',
											justifyContent: 'space-between',
											alignItems: 'center',
											width: '100%',
										}}
									>
										<StyledCheckboxFormControl
											control={
												<Checkbox
													checked={selectedPermissions.includes(
														permission.name,
													)}
													onChange={() => handleCheckboxChange(permission.name)}
													style={{ color: theme.colors.base.green200 }}
												/>
											}
											label={
												<Typography
													fontSize='16px'
													fontWeight={500}
													color={'secondary'}
													style={{
														color: theme.colors.text.titles,
													}}
												>
													{capitalizeWords(permission.name)}
												</Typography>
											}
										/>
									</Paper>
								))}
							</Grid>
						</Grid>
					))}
				</Grid>
			</DialogContent>
			<DialogActions
				style={{
					background: theme.colors.base.white,
					color: theme.colors.text.titles,
					justifyContent: 'center',
					padding: '0.5rem 0',
				}}
			>
				<UnborderedButton
					onClick={() => {
						nullifyFields()
						props.handleClose()
					}}
					label={'Cancel'}
					style={{ marginRight: '2rem' }}
				/>
				<SubmitButton
					onClick={() => trySubmit()}
					disabled={selectedPermissions.length === 0}
					style={{ marginLeft: '2rem' }}
				>
					Save
				</SubmitButton>
			</DialogActions>
		</UserPopUpForm>
	)
}

export default ChangeRolePermissions
