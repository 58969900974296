import { ActionType } from '../action-types/index'
import { Action } from '../actions'

export interface publisherWishListReducerInterface {
	wishlist: any
}

const initialState: publisherWishListReducerInterface = {
	wishlist: [],
}

const reducer = (
	state: publisherWishListReducerInterface | any = initialState,
	action: Action,
): publisherWishListReducerInterface => {
	switch (action.type) {
		case ActionType.GETWISHLIST: {
			return {
				...state,
				wishlist: action.payload,
			}
		}
		case ActionType.UPDATEWISHLIST: {
			const newState = []
			for (const row of state.wishlist) {
				if (row.uuid !== action.payload.uuid) {
					newState.push(row)
				} else {
					newState.push(action.payload)
				}
			}
			return {
				...state,
				wishlist: newState,
			}
		}
		case ActionType.ADDWISHLIST: {
			const newState = [...state.wishlist]
			newState.push(action.payload)
			return {
				wishlist: newState,
			}
		}
		case ActionType.DELETEWISHLIST: {
			const newState = state.wishlist.filter(
				(el: any) => el.uuid !== action.payload.uuid,
			)
			return {
				wishlist: newState,
			}
		}
		default:
			return state
	}
}

export default reducer
