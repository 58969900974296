import { useSelector } from 'react-redux'
import { useTypedSelector } from './useTypedSelector'
import { RootState } from '../state/reducers'
import { selectPermissionsMap } from '../state/selectors/permissionsSelector'
import { hasPermission } from '../utils/helpers/permissionsHelper'

export const useIsAuthorized = (permissionName: string): boolean => {
	const permissionsMap = useSelector((state: RootState) =>
		selectPermissionsMap(state),
	)
	// console.log('permissionsMap', permissionsMap)
	const user = useTypedSelector((state: RootState) => state.login.user)
	const userPermissions = user?.permissions
		? BigInt(user.permissions)
		: BigInt(0)

	if (typeof permissionName === 'boolean') {
		return permissionName
	}
	const requiredPermissionValue = permissionsMap[permissionName]
	if (requiredPermissionValue === undefined) {
		// console.log(`Permission ${permissionName} not found in permissions map`)
		return false // Deny access if the permission is not found
	}

	// console.log(`Required Permission Value for ${permissionName}: ${requiredPermissionValue}`);

	// Check if the user has the required permission
	const hasUserPermission = hasPermission(
		userPermissions,
		requiredPermissionValue,
	)
	// console.log(`User has required permission: ${hasUserPermission}`);

	return hasUserPermission
}
