import React from 'react'
import { Grid, useTheme } from '@mui/material'
import { useNavigate } from 'react-router'
import ReactCountryFlag from 'react-country-flag'
import { PageWrapper } from '../components/reusableComponents'
import { UnborderedButton } from '../components/Buttons'
import { ContactField, TitleComponent } from '../components/Informative'
import CompanyInformationHeader from '../components/CompanyInformationHeader'
import SocialLinks from '../components/SocialLinksHeader'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import EditIcon from '@mui/icons-material/Edit'
import defaultIcon from '../../assets/default-icon.webp'

const InfoField = (props: {
	label: string
	value: string | string[]
	isArray?: boolean
	isCountry?: boolean
	whiteSpace?: string
	isLink?: boolean
	link?: string
}) => {
	const theme = useTheme()
	const handleClickedLink = () => {
		if (props.link) {
			if (!props.link.startsWith('http')) {
				window.open('http://' + props.link, '_blank')
			} else {
				window.open(props.link, '_blank')
			}
		}
	}

	return (
		<div style={{ display: 'flex', gap: '6px', alignItems: 'center' }}>
			<span style={{ color: theme.colors.base.grey600 }}>
				{props.label ? props.label + ':' : ''}
			</span>
			{props.isCountry ? (
				<>
					<ReactCountryFlag
						style={{
							height: '9px',
							borderRadius: '100px',
							border: `1px solid ${theme.colors.black}`,
						}}
						countryCode={props.value === 'UK' ? 'GB' : (props.value as string)}
						svg
					/>
					<span>{props.value}</span>
				</>
			) : props.isArray ? (
				<div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
					{(props.value as string[]).map((element: string) => (
						<span
							key={element}
							style={{
								padding: '6px',
								background: theme.colors.base.green200,
								borderRadius: '10px',
								whiteSpace: 'nowrap',
							}}
						>
							{element}
						</span>
					))}
				</div>
			) : props.isLink ? (
				<span
					onClick={handleClickedLink}
					style={{ cursor: 'pointer', color: theme.colors.base.black }}
				>
					{props.value}
				</span>
			) : (
				<span style={{ overflowWrap: 'anywhere' }}>{props.value}</span>
			)}
		</div>
	)
}

const CapabilityContainer = (props: { value: any; label: string }) => {
	const theme = useTheme()
	const valueArray = Array.isArray(props.value) ? props.value : []

	if (valueArray.length === 0) {
		return null
	}

	return (
		<div
			style={{
				border: '1px solid ' + theme.colors.base.grey200,
				borderRadius: '20px',
				padding: '20px',
				display: 'flex',
				flexDirection: 'column',
				gap: '12px',
				height: '100%',
			}}
		>
			<span style={{ fontWeight: '600' }}>{props.label}</span>
			<div
				style={{
					display: 'grid',
					gap: '12px',
					gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
				}}
			>
				{valueArray.map((element: any) => (
					<span
						key={element.value}
						style={{
							border: '2px solid ' + theme.colors.base.green200,
							padding: '6px',
							borderRadius: '10px',
							textAlign: 'center',
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
						}}
					>
						{element.value}
					</span>
				))}
			</div>
		</div>
	)
}

const CompanyInformationContainer = (props: {
	advertiser: any
	closeDetailedView: any
	onEdit: () => void
}) => {
	const theme = useTheme()
	const trafficRestrictions =
		props.advertiser?.traffic_restrictions?.length > 0
			? JSON.parse(props.advertiser.traffic_restrictions)
			: []
	const existingChannels =
		props.advertiser?.existing_channels?.length > 0
			? JSON.parse(props.advertiser.existing_channels)
			: []

	const financialContacts: any = []
	const mainContacts: any = []
	props.advertiser?.contacts &&
		props.advertiser?.contacts.forEach((contact: any) => {
			if (contact.type === 'finance') {
				financialContacts.push(contact)
			} else {
				mainContacts.push(contact)
			}
		})

	return (
		<div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
			<div
				style={{
					display: 'flex',
					justifyContent: 'flex-end',
					alignItems: 'center',
				}}
			>
				<UnborderedButton
					style={{
						display: 'flex',
						justifyContent: 'flex-end',
						alignItems: 'center',
						marginRight: '10rem',
						zIndex: 8,
					}}
					onClick={props.onEdit}
					icon={<EditIcon />}
				>
					Edit
				</UnborderedButton>
			</div>

			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					flexDirection: 'row',
					gap: 20,
					padding: '1rem',
					marginTop: -40,
				}}
			>
				<CompanyInformationHeader
					name={props.advertiser.advertiser_name}
					iconUrl={props.advertiser.advertiser_icon || defaultIcon}
					style={{ marginTop: 20 }}
				/>
				<SocialLinks
					links={{
						website: props.advertiser.website,
						linkedin: props.advertiser.linkedin,
						io: props.advertiser.link_to_io,
						pub: props.advertiser.link_to_pub,
					}}
				/>
			</div>

			<Grid container spacing={2} style={{ fontSize: theme.font.size.body }}>
				<Grid item xs={3}>
					<InfoField label='Legal Name' value={props.advertiser.legal_name} />
				</Grid>
				<Grid item xs={3}>
					<InfoField
						label='Head Office Geo'
						value={props.advertiser.geo}
						isCountry
					/>
				</Grid>
				<Grid item xs={3}>
					<InfoField label='Address' value={props.advertiser.address} />
				</Grid>
				<Grid item xs={3}>
					<InfoField label='Team' value={props.advertiser.team} />
				</Grid>
				<Grid item xs={3}>
					<InfoField
						label='Tracking System'
						value={props.advertiser?.platform?.join(' | ')}
					/>
				</Grid>
				<Grid item xs={3}>
					<InfoField
						label='Communication Channel'
						value={props.advertiser?.communication_channel?.join(', ')}
					/>
				</Grid>
				<Grid item xs={3}>
					<InfoField
						label='Fraud Tool'
						value={props.advertiser?.fraud_tool?.join(', ')}
					/>
				</Grid>
				<Grid item xs={3}>
					<InfoField label='Vertical' value={props.advertiser.vertical} />
				</Grid>
				<Grid item xs={3}>
					<InfoField label='Type' value={props.advertiser.advertiser_type} />
				</Grid>
				<Grid item xs={3}></Grid>
				<Grid item xs={6}>
					<InfoField
						label={"Apps ID's"}
						value={props.advertiser.apps.join(' - ') || []}
						whiteSpace={'normal'}
					/>
				</Grid>
				<Grid item xs={12}>
					<TitleComponent style={{ color: theme.colors.base.grey500 }}>
						Brief:
					</TitleComponent>
					<div style={{ whiteSpace: 'pre-wrap', marginTop: '8px' }}>
						{props.advertiser.brief || 'No brief at the moment'}
					</div>
				</Grid>
				<Grid item xs={6}>
					<TitleComponent style={{ color: theme.colors.base.grey500 }}>
						Special Request:
					</TitleComponent>
					<div style={{ whiteSpace: 'pre-wrap', marginTop: '8px' }}>
						{props.advertiser.special_request ||
							'No Special Request at the moment'}
					</div>
				</Grid>
				<Grid item xs={6}>
					<TitleComponent style={{ color: theme.colors.base.grey500 }}>
						Parameters Template:
					</TitleComponent>
					<div style={{ whiteSpace: 'pre-wrap', marginTop: '8px' }}>
						{props.advertiser.parameters_template ||
							'No Parameters Template at the moment'}
					</div>
				</Grid>{' '}
				{mainContacts.length > 0 && (
					<Grid item xs={11}>
						<TitleComponent
							style={{
								fontSize: theme.font.size.body,
								alignSelf: 'flex-start',
							}}
						>
							Contacts
						</TitleComponent>
						<div
							style={{
								display: 'flex',
								gap: '12px',
								flexDirection: 'column',
								padding: '12px 0px 12px 0px',
								width: '100%',
							}}
						>
							{mainContacts.map((contact: any) => {
								return (
									<ContactField
										contact={contact}
										key={contact.name}
									></ContactField>
								)
							})}
						</div>
					</Grid>
				)}
				{financialContacts.length > 0 && (
					<Grid item xs={11}>
						<TitleComponent
							style={{
								fontSize: theme.font.size.body,
								alignSelf: 'flex-start',
							}}
						>
							Finance
						</TitleComponent>
						<div
							style={{
								display: 'flex',
								gap: '12px',
								flexDirection: 'column',
								padding: '12px 0px 12px 0px',
								width: '100%',
							}}
						>
							{financialContacts.map((contact: any) => {
								return (
									<ContactField
										contact={contact}
										key={contact.name}
									></ContactField>
								)
							})}
						</div>
					</Grid>
				)}
				<Grid item xs={6}>
					<CapabilityContainer
						value={trafficRestrictions}
						label='Traffic Restrictions'
					/>
				</Grid>
				<Grid item xs={6}>
					<CapabilityContainer
						value={existingChannels}
						label='Existing Channels'
					/>
				</Grid>
			</Grid>
		</div>
	)
}

const AdvertiserCompanyPreviewPage = (props: {
	setLoading: any
	companyData: any
	setPopUpVisible: (visible: boolean) => void
}) => {
	const navigate = useNavigate()
	const theme = useTheme()
	const row = props.companyData

	const closeDetailedView = () => {
		navigate('/companySettings')
		props.setPopUpVisible(false)
	}

	const handleEdit = () => {
		navigate(`/companySettings/edit`)
	}

	return (
		<PageWrapper
			style={{
				color: theme.colors.text.titles,
				display: 'flex',
				flexDirection: 'column',
				gap: '30px',
				paddingBottom: '30px',
			}}
		>
			<CompanyInformationContainer
				advertiser={row}
				closeDetailedView={closeDetailedView}
				onEdit={handleEdit}
			/>
		</PageWrapper>
	)
}

export default AdvertiserCompanyPreviewPage
