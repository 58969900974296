import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material'
import { LoginButton } from '../login/loginPageComponents'
import StatusPagesCommonLayout from './StatusPagesCommonLayout'

const NotFound = () => {
	const navigate = useNavigate()
	const theme = useTheme()

	return (
		<StatusPagesCommonLayout
			title='Oops! Page not found'
			description='Sorry, we were unable to find that page'
		>
			<LoginButton
				variant='contained'
				size='large'
				onClick={() => navigate('/')}
				style={{
					width: '436px',
					height: '44px',
					font: 'Lato',
					backgroundColor: theme.customComponents.button.green,
					color: '#242D32',
					borderRadius: '6px',
				}}
			>
				Back to home
			</LoginButton>
		</StatusPagesCommonLayout>
	)
}

export default NotFound
