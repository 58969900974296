import AdapterDateFns from '@date-io/date-fns'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { FormGroup, Grid } from '@mui/material'
import { DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro'
import { addDays, isValid, setDay, subDays } from 'date-fns'
import { useEffect, useRef, useState } from 'react'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import {
	AdvertiserInterface,
	CampaignInterface,
	PublisherInterface,
	UserInterface,
} from '../../models/model.interface'
import { formatDate } from '../../utils/helpers/helperFuncs'
import { countryList } from '../components/countryList'
import {
	ReportItem,
	ReportItemHeading,
	StyledBox,
	StyledTextField,
} from '../dashboard/ReportsStyled'
import { CountryListSelector, DropList } from '../components/SelectableInputs'
import { ChipInputList } from '../components/ChipInput'

export interface ReportFieldsInterface {
	from_date: Date
	to_date: Date
	breakdowns: string[]
	statistics: string[]
	filters: FilterInterface
}
export interface FilterInterface {
	app_id: string[]
	advertiser_name: string[]
	media_source_pid: string[]
	publisher: string[]
	email: string[]
	campaign_name: string[]
	campaign_id: string[]
	country: string[]
}

export interface DashboardReportFieldsInterface {
	from_date: Date
	to_date: Date
	breakdowns: string[]
	statistics: string[]
	filters: DashboardFilterInterface
}
export interface DashboardFilterInterface {
	app_id: string[]
	country: string[]
	advertiser_name: string[]
	publisher: string[]
	account_manager: string[]
	publisher_manager: string[]
	campaign_name: string[]
	advertiser_manager: string[]
	sales_rep: string[]
	filters?: string[]
	team: string[]
}

export const FiltersComponentList = (props: {
	setAppIds: any
	setAdvertisers: any
	setUser: any
	setPid: any
	setPublishers: any
	setCampaignNames: any
	setCampaignIds: any
	setCountries: any
	dataObj: any
	setDataObj: any
	row?: any
	setDate?: any
	date?: any
	setAccountManager?: any
	setPublisherManager?: any
	setAdvertiserManager?: any
	setTeam?: any
	setSalesRep?: any
	viewDataFor?: any
	setViewDataFor?: any
	tableType: any
	setFiltersList: any
	setDatesArray: any
}) => {
	const [publishersChecked, setPublishersChecked] = useState<boolean>(false)
	const [campaignNamesChecked, setCampaignNamesChecked] =
		useState<boolean>(false)

	const [filters, setFilters] = useState<any[]>([])

	const { publisher, users, campaign, settings, advertiser } = useTypedSelector(
		(state) => state,
	)

	const advertiserList = advertiser.advertiser.map(
		(e: AdvertiserInterface) => e.advertiser_name,
	)
	const teamList = settings.settings.teams
		? settings.settings.teams
		: ['no teams']

	const userList = users.users
		.filter((e: UserInterface) => {
			return !e.blocked
		})
		.map((e: UserInterface) => e.name)

	const advertiserSalesRepListRaw: any = advertiser.advertiser
		.map((e: AdvertiserInterface) => e.sales_rep)
		.filter((e: string) => e !== '')
	let advertiserSalesRepList: string[] = []
	advertiserSalesRepListRaw.forEach((sales_rep: any) => {
		if (!advertiserSalesRepList.includes(sales_rep)) {
			advertiserSalesRepList.push(sales_rep)
		}
	})

	const pidListRaw: string[] = publisher.publisher
		.map((e: PublisherInterface) => e.media_source_pid)
		.flat()
		.filter((e: string) => e !== 'All' && e !== 'all')
	let pidList: string[] = []
	pidListRaw.forEach((pid) => {
		if (!pidList.includes(pid)) {
			pidList.push(pid)
		}
	})

	const publisherList = publisher.publisher.map(
		(e: PublisherInterface) => e.publisher_name,
	)

	const publisherSalesRepListRaw = publisher.publisher
		.map((e: PublisherInterface) => e.sales_rep)
		.filter((e: string) => e !== '')
	let publisherSalesRepList: string[] = []
	publisherSalesRepListRaw.forEach((sales_rep: any) => {
		if (!publisherSalesRepList.includes(sales_rep)) {
			publisherSalesRepList.push(sales_rep)
		}
	})

	const campaignNameList = campaign.campaign.map(
		(e: CampaignInterface) => e.campaign_name,
	)

	const ref = useRef(null)

	let selectedFilterName: any = {
		onChange: setFilters,
		checked: true,
		label: 'Advertisers',
		id: 'advertisers',
		arrayOnChange: props.setAdvertisers,
		array: filters,
		disabled: props.dataObj.filters.advertisers === undefined,
		value: props.dataObj.filters.advertisers
			? props.dataObj.filters.advertisers
			: [],
	}
	let twoFilters: boolean = false
	let countryFilter: boolean = false
	let advertiserFilters: boolean = false
	let publishersFilters: boolean = false
	let secondFilterName: any = {
		onChange: props.setFiltersList,
		checked: true,
		label: 'Advertisers Sales Representatives',
		id: 'advertiser_sales_rep',
		arrayOnChange: props.setFiltersList,
		array: advertiserSalesRepList,
		disabled: props.dataObj.filters.advertiser_sales_rep === undefined,
		value: props.dataObj.filters.advertiser_sales_rep
			? props.dataObj.filters.advertiser_sales_rep
			: [],
	}
	let countryFilterName: any = {
		onChange: props.setFiltersList,
		checked: true,
		label: 'Country',
		id: 'country',
		arrayOnChange: props.setFiltersList,
		array: countryList,
		disabled: props.dataObj.filters.country === undefined,
		value: props.dataObj.filters.country,
	}
	switch (props.tableType) {
		case 'advertiser':
			twoFilters = true
			countryFilter = true
			selectedFilterName = {
				onChange: props.setFiltersList,
				checked: true,
				label: 'Advertisers',
				id: 'advertisers',
				arrayOnChange: props.setFiltersList,
				array: advertiserList,
				disabled: props.dataObj.filters.advertisers === undefined,
				value: props.dataObj.filters.advertisers
					? props.dataObj.filters.advertisers
					: [],
			}
			secondFilterName = {
				onChange: props.setFiltersList,
				checked: true,
				label: 'Advertisers Sales Representatives',
				id: 'advertiser_sales_rep',
				arrayOnChange: props.setFiltersList,
				array: advertiserSalesRepList,
				disabled: props.dataObj.filters.advertiser_sales_rep === undefined,
				value: props.dataObj.filters.advertiser_sales_rep
					? props.dataObj.filters.advertiser_sales_rep
					: [],
			}
			break
		case 'campaign':
			twoFilters = false
			countryFilter = false
			advertiserFilters = true
			publishersFilters = true
			selectedFilterName = {
				onChange: props.setFiltersList,
				checked: true,
				label: 'Campaign Name',
				id: 'campaign_name',
				arrayOnChange: props.setFiltersList,
				array: campaignNameList,
				disabled: campaignNamesChecked,
				value: props.dataObj.filters.campaign_name,
			}

			break
		case 'publisher':
			twoFilters = true
			countryFilter = true
			selectedFilterName = {
				onChange: props.setFiltersList,
				checked: true,
				label: 'Publishers',
				id: 'publishers',
				arrayOnChange: props.setFiltersList,
				array: publisherList,
				disabled: publishersChecked,
				value: props.dataObj.filters.publishers,
			}
			secondFilterName = {
				onChange: props.setFiltersList,
				checked: true,
				label: 'Publishers Sales Representatives',
				id: 'publisher_sales_rep',
				arrayOnChange: props.setFiltersList,
				array: publisherSalesRepList,
				disabled: props.dataObj.filters.publisher_sales_rep === undefined,
				value: props.dataObj.filters.publisher_sales_rep
					? props.dataObj.filters.publisher_sales_rep
					: [],
			}
			break
		case 'country':
			twoFilters = false
			countryFilter = false
			selectedFilterName = {
				onChange: props.setFiltersList,
				checked: true,
				label: 'Country',
				id: 'country',
				arrayOnChange: props.setFiltersList,
				array: countryList,
				disabled: props.dataObj.filters.country === undefined,
				value: props.dataObj.filters.country,
			}
			break
		case 'accountManager':
			twoFilters = true
			countryFilter = false
			publishersFilters = true
			selectedFilterName = {
				onChange: props.setFiltersList,
				checked: true,
				label: 'Team',
				id: 'Team',
				arrayOnChange: props.setFiltersList,
				array: teamList.sort(),
				disabled: props.dataObj.filters.team === undefined,
				value: props.dataObj.filters.team ? props.dataObj.filters.team : [],
			}
			secondFilterName = {
				onChange: props.setFiltersList,
				checked: true,
				label: 'Advertisers Sales Representatives',
				id: 'advertiser_sales_rep',
				arrayOnChange: props.setFiltersList,
				array: advertiserSalesRepList,
				disabled: props.dataObj.filters.advertiser_sales_rep === undefined,
				value: props.dataObj.filters.advertiser_sales_rep
					? props.dataObj.filters.advertiser_sales_rep
					: [],
			}
			break
		case 'publisherManager':
			twoFilters = true
			countryFilter = false
			advertiserFilters = true
			selectedFilterName = {
				onChange: props.setFiltersList,
				checked: true,
				label: 'Team',
				id: 'Team',
				arrayOnChange: props.setFiltersList,
				array: teamList.sort(),
				disabled: props.dataObj.filters.team === undefined,
				value: props.dataObj.filters.team ? props.dataObj.filters.team : [],
			}
			secondFilterName = {
				onChange: props.setFiltersList,
				checked: true,
				label: 'Advertisers Sales Representatives',
				id: 'advertiser_sales_rep',
				arrayOnChange: props.setFiltersList,
				array: advertiserSalesRepList,
				disabled: props.dataObj.filters.advertiser_sales_rep === undefined,
				value: props.dataObj.filters.advertiser_sales_rep
					? props.dataObj.filters.advertiser_sales_rep
					: [],
			}
			break
	}

	return (
		<div
			style={{
				width: 'inherit',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<span
				style={{
					height: '50px',
					display: 'inherit',
					justifyContent: 'flex-end',
				}}
			></span>

			<Grid container direction={'row'}>
				<Grid item xs={4}>
					<TimeframeComponent
						setStartDay={props.setDate.setStartDay}
						setEndDay={props.setDate.setEndDay}
						startDay={props.date.startDay}
						endDay={props.date.endDay}
						viewDataFor={props.viewDataFor}
						setDatesArray={props.setDatesArray}
						setViewDataFor={props.setViewDataFor}
						compact={true}
					/>
				</Grid>
				<Grid item xs={8}>
					<FormGroup
						key={'form-group-2'}
						row={props.row ? true : false}
						style={{
							width: props.row ? '60vw' : '260px',
							marginTop: '-20px',
						}}
					>
						<CheckedFilter
							id={selectedFilterName.id}
							onChange={selectedFilterName.onChange}
							checked={selectedFilterName.checked}
							label={selectedFilterName.label}
							arrayOnChange={selectedFilterName.arrayOnChange}
							array={selectedFilterName.array}
							{...props}
						/>
						{twoFilters ? (
							<CheckedFilter
								id={secondFilterName.id}
								onChange={secondFilterName.onChange}
								checked={secondFilterName.checked}
								label={secondFilterName.label}
								arrayOnChange={secondFilterName.arrayOnChange}
								array={userList}
								{...props}
							/>
						) : (
							<></>
						)}
						{countryFilter ? (
							<CheckedFilter
								id={countryFilterName.id}
								onChange={countryFilterName.onChange}
								checked={countryFilterName.checked}
								label={countryFilterName.label}
								arrayOnChange={countryFilterName.arrayOnChange}
								array={countryFilterName.array}
								{...props}
							/>
						) : (
							<></>
						)}
						{advertiserFilters ? (
							<>
								<CheckedFilter
									id={'advertiser_name'}
									onChange={props.setFiltersList}
									checked={countryFilterName.checked}
									label={'Advertiser Name'}
									arrayOnChange={props.setFiltersList}
									array={advertiserList}
									{...props}
								/>
								<CheckedFilter
									id={'advertiser_owner'}
									onChange={props.setFiltersList}
									checked={countryFilterName.checked}
									label={'Advertiser Owner'}
									arrayOnChange={props.setFiltersList}
									array={userList}
									{...props}
								/>
							</>
						) : (
							<></>
						)}
						{publishersFilters ? (
							<>
								<CheckedFilter
									id={'publisher_name'}
									onChange={props.setFiltersList}
									checked={countryFilterName.checked}
									label={'Publisher Name'}
									arrayOnChange={props.setFiltersList}
									array={publisherList}
									{...props}
								/>
								<CheckedFilter
									id={'publisher_owner'}
									onChange={props.setFiltersList}
									checked={countryFilterName.checked}
									label={'Publisher Owner'}
									arrayOnChange={props.setFiltersList}
									array={userList}
									{...props}
								/>
							</>
						) : (
							<></>
						)}
					</FormGroup>
				</Grid>
			</Grid>
		</div>
	)
}
const CheckedFilter = (props: {
	id: any
	onChange: any
	checked: boolean
	label: string
	arrayOnChange: any
	array: any[]
	value?: any[]
	disabled?: boolean
	setDataObj?: any
	dataObj?: any
	remove?: any
	row?: any
}) => {
	const [extraFilter, setExtraFilter] = useState(false)
	const [selectedCountries, setSelectedCountries] = useState<string[]>([])
	const selectCountryFilter = (e: any) => {
		setSelectedCountries(e)
		const newDataObj = { ...props.dataObj }
		newDataObj.filters.country = e
		props.setDataObj(newDataObj)
	}
	const selectNonCountryFilter = (e: any) => {
		const newDataObj = { ...props.dataObj }
		newDataObj.filters[props.id] = e
		props.setDataObj(newDataObj)
	}
	useEffect(() => {
		const possibleFilters = [
			'app_id',
			'media_source_pid',
			'campaign_name',
			'campaign_id',
			'country',
		]
		if (possibleFilters.includes(props.id)) {
			setExtraFilter(true)
		}
	}, [])

	return (
		<div style={{ width: '260px' }}>
			{props.checked ? (
				props.label !== 'Country' ? (
					<div key={props.id} style={{ display: 'flex' }}>
						<ChipInputList
							{...props}
							onChange={(e: any) => selectNonCountryFilter(e)}
							options={props.array}
							value={
								extraFilter ? props.dataObj.filters[props.id] : props.value
							}
							openWithClick={true}
							style={{
								width: '100%',
								marginBottom: props.row ? '3vw' : 'unset',
							}}
						/>
						{props.remove ? (
							<button
								style={{
									background: 'none',
									border: 'none',
									cursor: 'pointer',
									height: '30px',
									alignSelf: 'center',
									marginTop: '24px',
									marginLeft: '-10px',
									width: '50px',
								}}
								onClick={() => props.remove(props.id)}
							>
								<DeleteForeverIcon />
							</button>
						) : (
							<span style={{ height: '30px', width: '40px' }}></span>
						)}
					</div>
				) : (
					<div key={props.id} style={{ display: 'flex' }}>
						<CountryListSelector
							{...props}
							onChange={(e: any) => {
								const arr = []
								for (const element of e) {
									arr.push(element.toLowerCase())
								}
								selectCountryFilter(arr)
							}}
							options={props.array}
							value={selectedCountries}
							style={{ width: '100%' }}
						/>
						{props.remove ? (
							<button
								style={{
									background: 'none',
									border: 'none',
									cursor: 'pointer',
									height: '30px',
									alignSelf: 'center',
									marginTop: '24px',
									marginLeft: '-10px',
									width: '50px',
								}}
								onClick={() => props.remove(props.id)}
							>
								<DeleteForeverIcon />
							</button>
						) : (
							<span style={{ height: '30px', width: '50px' }}></span>
						)}
					</div>
				)
			) : (
				<></>
			)}
		</div>
	)
}

export const TimeframeComponent = (props: {
	viewDataFor: string
	setViewDataFor: any
	startDay: Date
	setStartDay: any
	endDay: Date
	setEndDay: any
	setDatesArray: any
	compact?: boolean
}) => {
	const [value, setValue] = useState<any>([props.startDay, props.endDay])

	useEffect(() => {
		let datesArray: any[] = []
		var diff = Math.abs(value[0].getTime() - value[1].getTime())
		var diffDays = Math.ceil(diff / (1000 * 3600 * 24))
		const date = new Date(value[0])

		if (diffDays <= 31) {
			for (let i = 0; i <= diffDays; i++) {
				datesArray.push(
					new Date(addDays(date, i - 1)).toISOString().substring(0, 10),
				)
			}
			props.setStartDay(value[0])
			props.setEndDay(value[1])
			props.setDatesArray(datesArray)
			// console.log('1: datesArray : ', datesArray)
		} else {
			props.setStartDay(value[0])
			props.setEndDay(value[0])
		}
	}, [value])

	const handleViewDataForChange = (e: any) => {
		const date = new Date(formatDate(new Date()))
		props.setViewDataFor(e.target.value)
		switch (e.target.value) {
			case 'Today':
				props.setStartDay(date)
				props.setEndDay(date)
				setValue([date, date])
				break
			case 'Yesterday':
				props.setStartDay(subDays(new Date(date), 1))
				props.setEndDay(subDays(new Date(date), 1))
				setValue([subDays(new Date(date), 1), subDays(new Date(date), 1)])
				break
			case 'Last 7 Days':
				props.setStartDay(subDays(new Date(date), 7))
				props.setEndDay(date)
				setValue([subDays(new Date(date), 7), date])
				break
			case 'From Start of the Month':
				props.setStartDay(new Date(date.getFullYear(), date.getMonth(), 1))
				props.setEndDay(new Date())
				setValue([new Date(subDays(date, date.getDate() - 1)), date])
				break
			default:
				break
		}
	}
	const checkAndFormatPickedDates = (date1: Date, date2: Date, setter: any) => {
		const dateFrom = new Date(formatDate(new Date(date1 as Date)))
		const dateTo = new Date(formatDate(new Date(date2 as Date)))

		setter([dateFrom, dateTo])
	}
	return (
		<ReportItem
			style={{
				height: props.compact === true ? '26vh' : '350px',
				marginRight: props.compact === true ? '3vw' : 'unset',
				marginBottom: props.compact === true ? '3vw' : 'unset',
			}}
		>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<ReportItemHeading>Timeframe</ReportItemHeading>
				<StyledBox
					style={{
						overflowX: 'hidden',
						height: props.compact === true ? '22vh' : '600px',
					}}
				>
					<DropList
						onChange={(e: any) => handleViewDataForChange(e)}
						label={'View Data For'}
						value={props.viewDataFor}
						options={[
							'Today',
							'Yesterday',
							'From Start of the Month',
							'Last 7 Days',
							'Custom',
						]}
						fontSize='13px'
					></DropList>

					<DateRangePicker
						value={value}
						calendars={2}
						minDate={new Date(subDays(new Date(), 31))}
						maxDate={new Date()}
						inputFormat='dd/MM/yyyy'
						onChange={(newValue) => {
							const result = checkAndFormatPickedDates(
								newValue[0] as Date,
								newValue[1] as Date,
								setValue,
							)
							props.setViewDataFor('Custom')
						}}
						className='datePicker'
						renderInput={(startProps, endProps) => (
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									width: '100%',
									justifyContent: 'space-around',
									marginTop: '26px',
								}}
							>
								<StyledTextField
									{...startProps}
									label='Start Date'
									focused
									style={{ width: '120px' }}
									InputLabelProps={{
										style: { top: '-8px', fontSize: '15px' },
									}}
								/>
								<StyledTextField
									{...endProps}
									label='End Date'
									focused
									style={{ width: '120px' }}
									InputLabelProps={{
										style: { top: '-8px', fontSize: '15px' },
									}}
								/>
							</div>
						)}
					/>
				</StyledBox>
			</LocalizationProvider>
		</ReportItem>
	)
}
