import React, { MouseEventHandler, useEffect, useRef, useState } from 'react'
import { TitleComponent } from '../components/Informative'
import logoWhite from '../../assets/varys-logo-white.png'
import { useTheme } from '@mui/material'

const TermsAndConditions = (props: {
	handleTermsPopUp: MouseEventHandler<HTMLButtonElement>
}) => {
	const theme = useTheme()
	const viewportHeight = window.innerHeight
	const responsiveOffset = viewportHeight * 0.17 //hardcoded value that simulates 12% of margin top (aprox)
	const sectionRefs = useRef(
		sections.reduce((acc: any, value: any) => {
			acc[value.id] = React.createRef()
			return acc
		}, {}),
	)

	const [activeSection, setActiveSection] = useState(sections[0].id)

	const handleScroll = () => {
		const sectionPositions = sections.map((section) => {
			const sectionRef = sectionRefs.current[section.id]
			return {
				id: section.id,
				top: sectionRef.current.offsetTop - responsiveOffset,
			}
		})

		const currentSection = sectionPositions.find((section: any) => {
			return (
				window.scrollY >= section.top - 50 &&
				window.scrollY <
					section.top + sectionRefs.current[section.id].current.offsetHeight
			)
		})

		if (currentSection && currentSection.id !== activeSection) {
			setActiveSection(currentSection.id)
		}
	}

	useEffect(() => {
		window.addEventListener('scroll', handleScroll)
		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [activeSection])

	const scrollToSection = (id: any) => {
		const sectionRef = sectionRefs.current[id]
		if (sectionRef && sectionRef.current) {
			window.scrollTo({
				top: sectionRef.current.offsetTop - responsiveOffset,
				behavior: 'smooth',
			})
		}
	}

	return (
		<div
			style={{
				display: 'relative',
				position: 'absolute',
				width: '100%',
				height: '100%',
				background: theme.colors.base.white,
				zIndex: '25',
			}}
		>
			<button
				style={{
					display: 'flex',
					position: 'fixed',
					top: '5.5%',
					left: '2%',
					border: 'none',
					cursor: 'pointer',
					zIndex: '25',
				}}
				onClick={props.handleTermsPopUp}
			>
				Go back
			</button>
			<div
				style={{
					display: 'flex',
					position: 'fixed',
					top: '4%',
					right: '8%',
					alignItems: 'center',
					gap: 12,
					zIndex: '25',
				}}
			>
				<img src={logoWhite} alt='logo' style={{ width: 40 }} />
				<span
					style={{
						color: theme.colors.base.white,
						fontSize: theme.font.size.titles,
					}}
				>
					Varys
				</span>
			</div>
			<header
				style={{
					backgroundImage: theme.colors.gradientMenu,
					backgroundSize: '100% 100%',
					backgroundPosition: '0px 0px',
					width: '100%',
					height: '14%',
					display: 'flex',
					position: 'fixed',
					alignItems: 'center',
					flexDirection: 'column',
					// justifyContent: 'center',
					gap: 12,
					paddingTop: 12,
				}}
			>
				<TitleComponent
					style={{
						color: theme.colors.base.grey900,
						fontSize: theme.font.size.mainTitles,
						marginTop: '2rem',
					}}
				>
					Terms and Conditions of Use
				</TitleComponent>
				<TitleComponent
					style={{
						color: theme.colors.base.grey900,
						fontSize: theme.font.size.body,
					}}
				>
					Update 01 June 2024
				</TitleComponent>
			</header>

			<div
				style={{
					position: 'fixed',
					top: '24%',
					left: '6%',
					width: '200px',
					color: theme.colors.base.grey900,
					background: theme.colors.base.white,
				}}
			>
				{sections.map((section) => (
					<button
						key={section.id}
						onClick={() => scrollToSection(section.id)}
						style={{
							cursor: 'pointer',
							padding: '10px',
							borderTop: 'none',
							borderRight: 'none',
							borderBottom: 'none',
							borderLeft:
								'4px solid ' +
								(activeSection === section.id
									? theme.colors.base.green300
									: theme.colors.base.grey300),
							fontSize: theme.font.size.caption,
						}}
					>
						{section.title}
					</button>
				))}
			</div>
			<div
				style={{
					padding: '0px 100px 100px',
					width: '50%',
					margin: '12% auto 0',
					display: 'flex',
					flexDirection: 'column',
					gap: 18,
				}}
			>
				{sections.map((el: any) => {
					return (
						<div
							key={el.id}
							style={{ display: 'flex', gap: 18, flexDirection: 'column' }}
							ref={sectionRefs.current[el.id]}
						>
							<TitleComponent style={{ color: theme.colors.base.grey600 }}>
								{el.id}. {el.title}
							</TitleComponent>
							<div
								style={{
									whiteSpace: 'pre-wrap',
									color: theme.colors.base.grey600,
								}}
							>
								{el.content}
							</div>
						</div>
					)
				})}
			</div>
		</div>
	)
}

const sections = [
	{
		id: 1,
		title: 'INTRODUCTION',
		content: `These are terms and conditions of use of website https://varys.io/ (“Site”) and the Software offered through it. Please read these terms and conditions (“Terms and Conditions”) carefully, because they set forth the important terms that you will need to know about the Site and the Software. These Terms and Conditions are binding and must be followed by each individual using the Site irrespective of whether the person is registered as a User (as defined below in Definitions) or is using the Site as a Visitor (as defined below in Definitions) without registration.

Persons (individuals/businesses) who are not registered on the Site will not have the ability to use the Software. Such persons must in any event comply with these Terms and Conditions, the Privacy Policy, other special conditions and rules provided on the Site (collectively, “Site Rules”) and all applicable laws including the regular registration process.

These Terms and Conditions may be changed or modified in whole or in portions at any time without further notice. Changes to these Terms and Conditions will be posted on the Site. It is your responsibility to review the Terms and Conditions frequently and to remain informed of any changes to them. Your continued use of the Site and/or the Software after any such changes constitutes your acceptance of the new Terms and Conditions. If you do not agree to abide by these or any future Terms and Conditions, do not use or access (or continue to use or access) the Site and/or the Software. Any amendment to the Terms and Conditions published on the Site will be applicable to Users and Visitors from the “Updated” date.
`,
	},
	{
		id: 2,
		title: 'DEFINITIONS',
		content: `The following terms shall have the following meanings in these Terms and Conditions:

Provider: Thing Or Two Ltd, a company incorporated and existent in 150 Begin Rd.. Tel-Aviv, 6492128 Israel, who is granted right by the Owner to provide the Users access to the Software via the Site and collect fees from Users (“Varys”, “we”, “our” or “us”).

End-User Agreement: Agreement with User made by Owner or Provider (or other Owner's licensee) which grants User a right to access and use Software through Site.

Owner: the sole owner of intellectual property rights and other rights to the Software and the Site Thing Or Two Ltd, a company incorporated and existent in 150 Begin Rd.. Tel-Aviv, 6492128 Israel, (“Varys”, “we”, “our” or “us”).

Privacy Policy: The document setting out how personal data and other important information from any User or Visitor is collected, used, retained and transferred.

Site: The website located at the address https://varys.io/ (“Varys”, “we”, “our” or “us”).

Software: marketing software solution (including any and all upgrades, additions, enhancements, modifications, customization) obtained and/or developed, in whole or in part, by the Owner, which is accessible by the User via the Site and allows the User to (1) deeply analyze the performance of its advertising campaigns based on (a) end-user actions (conversions, impressions, clicks), or (b) the amount of monthly active end-users, depending on which feature set(s) the User orders and uses – Varys, (2) to optimize the campaigns, and/or (3) to measure and increase marketing effectiveness.

Terms and Conditions: The present Terms and Conditions of Use with their appendices and all further and subsequent supplements and amendments.

User: Any person/business who, after having registered for the Site and after accepting Terms and Conditions and/or conclusion of End-User Agreement, is entitled to use Software through the Site (“you” or “your” as well as “client/customer”).

User's Account or Account: The account resulting from a User's registration on the Site, containing personal/corporate data including data with regard to the use of Software via the Site.

Visitor: Any person/business who is not registered, but may visit the Site, subject to the Terms and Conditions and Privacy Policy, to the extent that registration is required for such use (“you” or “your”).

Advertiser: Any person/business that pays for a product, service, event to be advertised.

Publisher: Any placement that displays advertisements on behalf of an Advertiser.

Geographic location data: can either be precise (identifying the end user's and visitor's precise location) or imprecise (identifying the end user's and visitor's city or country). In Varys we collect imprecise geographic data through IP address and/or wi-fi networks in the end user's/visitor's vicinity. We collect this data to help us provide more relevant advertisements and content to our clients (including former and potential clients) and to ensure we comply with country specific requirements as well as for our internal statistical purposes.

Impressions: occur when a User sees an ad for an Advertiser on a Publisher's site or app.

Postbacks: are server-to-server communications between parties (generally through an API/MMP).

Cookies: are small text image files stored on your computer either temporarily or permanently to allow websites to recognize users, keep track of their preferences, to improve your experience on the website and to analyze your behavior on it.

`,
	},
	{
		id: 3,
		title: 'SOFTWARE AND SITE',
		content: `The performance marketing software solution is used for data retrieval, analyses, sorting, warehousing, processing and presentation as available to Users through the Site.

3.1. Requirements for the use of the Software and the Site

You need to register on the Site and to provide only accurate, complete, and up-to-date information. Each time, to access and use the Software via the Site, you shall be required to login by using your email and password as provided with your registration.

3.2. Use of the Software and the Site

You shall be allowed to (i) upload data to the Software; (ii) use Software to retrieve, arrange, analyze and store the data, (iii) download data from the Software, as provided in the End-User Agreement. With respect to the use of the Site/the Software, you are prohibited and you agree not to engage in (i) offenses against any individual and his privacy and (ii) the uploading of indecent materials or the use of the Site/the Software in any matter that is inconsistent with applicable laws or such as to injure Provider's and/or Owner's business reputation.

You agree that Provider and/or Owner are not responsible or liable for any data (including content and links) uploaded by you on the Site/the Software. Owner is entitled to remove from the Site any information. You use the Site/the Software at your own risk.

3.3. Communication and offers

Owner or Provider is entitled at any time to publish on the Site any proposals, to propose new services, and to amend these Terms and Conditions accordingly. In the case of inconsistencies between these Terms and Conditions and special terms and conditions published on the Site regarding any of the foregoing, the latter shall prevail.

Subject to the terms set forth in the Privacy Policy, Owner and Provider shall have the right to send Users email messages, using the email address provided by Users at the time of registration, regarding the Site/Software and any new proposed services, or any other relevant matters that in Owner's/Provider's view may assist in enhancing Users' experience using the Software. Such messages may include reminders, invitations, suggestions and descriptions of existing, new or future services, other important notices, as well as marketing and promotional content.

3.4. Support

Support of two kinds shall be provided to the clients/customers under these Terms and Conditions:

(1) advice and consultations on the use of the Site and/or Software. Such support shall be delivered during working days via support request from the PIC (person in charge) of account management;

(2) technical support, which requires access, modification or update of Software and/or access to Users' data processed by Software.

`,
	},
	{
		id: 4,
		title: 'PAYMENTS',
		content: `Provider is entitled to collect fees from Users for the granted right (sub-license) to access and use the Software via the Site, according to the prices indicated in the Order Form to the End-User Agreement.

Varys may use various billing service providers. When using such provider's services, you agree to be subject to, and to comply with, the terms and policies of such provider. Varys shall not be liable and/or responsible for any acts or omissions of such billing providers.

All payment conditions as well invoice issuing terms are indicated in the End-User Agreement and the Order Form to it.
`,
	},
	{
		id: 5,
		title: 'GENERAL RESPONSIBILITIES',
		content: `All Users and Visitors acknowledge and agree that Owner or Provider is not required to verify (though it has the right to do so), and therefore cannot be held responsible for any Users' actions or inactions related to the Site or the Software and/or their compliance with applicable law.

Every User and Visitor is responsible and must comply with all applicable laws related to his/her use of the Site and the Software. In particular, but without limitation, Users and Visitors must not: (i) infringe any third-party rights, or (ii) use the identities of other companies/individuals.

You acknowledge and agree that Provider and/or the Owner are/is not responsible for any damage, whether material or not, experienced by natural or legal persons and which could arise directly or indirectly as a consequence of a Visitor and/or User actions while using the Site and/or the Software. Only Visitor/User himself is responsible for his actions while using the Site and/or Software and his/her compliance with all applicable laws.
`,
	},
	{
		id: 6,
		title: "USER'S RIGHTS",
		content: `6.1. Users undertake the following: (i) when registering on the Site, to provide true information, including his or her real name, company he/she represents, contact details; (ii) to sign up for the Site only once and not to create more than one Account (one Account per User); (iii) not to use the Site and/or Software for performance of unlawful actions or transactions or frauds; (iv) not to copy or to otherwise make an illegal use of information placed on the Site that was uploaded by Owner/Provider or any other third party; and (v) to ensure that all information provided is correct.

Users and Visitors undertake that, while using the Site and Software, they will: (i) not infringe property rights or personal non-property rights of Owner/Provider and/or any third parties (including intellectual property rights); (ii) not violate any applicable laws or regulations; and (iii) not upload any data that is contrary to law or contains any viruses or other computer programs or files that may (a) interfere with the normal operation of the Site and/or Software, (b) cause damage to the Owner/Provider and/or User or their property, or impede the User's use of the Site or Software and the User's computer.

You also agree and shall not to use the Site and/or the Software for any unlawful purpose, in a way prohibited by the Terms and Conditions, including, but not limiting to, (i) not to use any hacking or cheating software or tools to obstruct, disrupt or interfere with the operation of the Site and/or the Software; (ii) not to use any robot, iframe, spider, crawler, scraper or other automated means or interface not provided by us to access the Site and/or the Software, including, without limitation, for the purpose of copying, extracting, aggregating, displaying, publishing or distributing any content or data made available via Site and/or Software; (iii) not to obtain or attempt to obtain any information from the Site and/or the Software, including email addresses or phone numbers of other account holders or other software data; (iv) not to use the Site and/or the Software for any illegal, obscene, offensive or immoral purpose.

Owner or Provider (or other Owner's licensee) are not responsible for any Visitor's/User's behavior while using the Site or the Software. Visitors/Users are solely responsible for his/her use of Site and/or the Software, including the use by their employees, partners (publishers/advertisers), and/or other authorized users, or any unauthorized person.

Users are responsible for maintaining the confidentiality of their password and account, if any, and are fully responsible for any and all activities that occur under their password or account. Users agree to (i) immediately notify Owner or Provider of any unauthorized use of their password or account or any other breach of security, and (ii) ensure that they exit from their account at the end of each session when accessing the Site/the Software. Provider and/or the Owner will not be liable for any loss or damage arising from your failure to comply with these obligations.

Users undertake to promptly update any information on the Site that becomes irrelevant, outdated or inapplicable due to any changes in the User's previously submitted data (particularly, all data submitted during registration at the Site).

6.2. Representations and Warranties

When using the Site, you represent and warrant that you: (i) are 18 years old or older; (ii) shall properly comply with all of your obligations as set forth in these Terms and Conditions, End-User Agreement and any other Site Rules; (iii) shall be fully and exclusively responsible for any use of the Site and/or Software; (iv) have the right to represent the legal entity on behalf of which you use the Site/Software and commit to payments on its behalf; (v) shall pay any and all taxes related to the use of the Site/Software; and (vi) shall promptly pay Owner or Provider for any paid Software pursuant to the fees and procedures specified in the End-User Agreement and the Order Form(s) to it, and you understand that the non-performance of such obligations may result in an obligation to provide full compensation for any losses incurred by the Owner/Provider.

You agree that you are not entitled to collect or store, or attempt to collect or store, personal data about third parties without their knowledge and consent. You acknowledge and agree that you are prohibited from using Software/Site in violation of applicable laws and regulations.

You represent that all information and data uploaded by you or retrieved, processed by the Software on your behalf shall not contain any material (a) protected by copyright, trademark, trade secret, patent or any other intellectual property right without authorization, or (b) that is defamatory, trade libelous, unlawfully threatening or harassing, pornographic, obscene or harmful to minors, racist, vulgar, abusive, promoting hatred, discriminating or displaying prejudice based on religion, ethnic heritage, race, sexual orientation or age, or (c) that violates any law or regulation, including without limitation, the laws and regulations governing export control, unfair competition, anti-discrimination, or false advertising, or (d) that contains any viruses, worms or any other software intended to damage or alter a computer system or data.

You represent that all information and data uploaded by you or retrieved, processed by the Software on your behalf shall not contain any material that contains/concerns mass mailings or any form of “spam”.

`,
	},
	{
		id: 7,
		title: 'RIGHTS OF THE SITE',
		content: `Owner/Provider is entitled, at its sole discretion, to limit or terminate a User's or Visitor's right to use the Site and/or Software, including cancelling a User's Account and preventing a User from re-registering on the Site or a Visitor from re-accessing the Site.
Owner/Provider shall have the right, at its sole discretion, with or without sending a formal notice to a User, to partially or fully block, suspend or terminate such User's Account, as a result of which the User may be deprived of the right to use the Site/the Software. Such block, suspension or termination without notice can be caused by the following: (i) the Software is being used by the User in violation of any applicable law; (ii) there is actual or suspected unauthorized use of the Software from the User's side; (iii) the User makes any breach of the Terms and Conditions, End-User Agreement and/or Data Processing Addendum; (iv) User's access to the Software is regarded by the Provider as a risk to its commercial activities or reputation.

Owner/Provider has the right (but is not required to), with an aim of restraining illegal activities, to observe at any time the User's and Visitor's actions, and the Users and the Visitors hereby agree to the same. Owner/Provider, aiming to protect the Site Users and Visitors from frauds and other offenses, may collect data about the conduct of Users and Visitors.

Owner shall have the right at any time to (i) modify, update and change the Site and/or Software; (ii) remove or modify features, functionalities of the Site and/or Software; (iii) stop or terminate the Site / the Software entirely or in part; or (iv) rearrange any uploaded or posted information as long as such rearrangement does not affect Users' data.

The operation and maintenance of the Site and the Software in terms of significant changes and major improvements shall be provided solely by the Owner. Provider has no right to significantly modify the Site and the Software and amend their core features as well as no right to use the Software. Provider does not provide any services to the Users, except for support services which do not involve changes to object code, source code of the Site and/or the Software or any portion of the foregoing.

Owner is entitled at any time to unilaterally terminate or suspend or transfer to third parties the Site and the Software, and, to the extent practicable, shall use commercially reasonable efforts to provide Provider and Users with notification of the same.

Use of Services and Availability. While Owner/Provider uses reasonable efforts to keep the Software accessible, the Software may be unavailable from time to time. You understand and agree that there may be interruptions to the Software and/or use of and/or access to your Account due to circumstances both within Owner's/Provider's control (e.g., routine maintenance) and outside of their control. You are solely responsible for ensuring that your access to and/or use of the Software and/or the Site is in compliance with all laws, rules and regulations applicable to you and the right to access and/or use the Software and/or the Site is revoked where your access to and/or use of the Software and/or the Site is prohibited.

Data regarding the User and protection of rights. As the User, you hereby authorize Owner/Provider to transfer, process and store data enabling Owner/Provider to identify you. You hereby agree to the Owner/Provider using its own means to check whether you are using the Software/Site in accordance with the provisions of the Terms and Condition, the End-User Agreement and other Site Rules. You hereby issue your agreement to data being transferred, during communication between the Software/Site and Owner's/Provider's computer systems or those of its business partners, the purpose of which is to ensure functionality of and authorization to use the Software/Site and protection of Owner's/Provider's rights. Following your registration on the Site, Owner/Provider or any of its business partners shall be entitled to transfer, process and store essential data identifying you, for billing purposes and performance of the End-User Agreement, Terms and Conditions and other Site Rules.

`,
	},
	{
		id: 8,
		title: 'PERSONAL DATA',
		content: `8.1. Personal/corporate data of Users and Visitors shall be collected and processed in accordance with the provisions of law and in accordance with the Privacy Policy.

8.2. The cancellation of the User's Account does not mean that Owner/Provider will delete all the Account relevant information or User's personal/corporate data. Such deletion shall be at Owner's/Provider's option and discretion and Owner/Provider may decide to retain and store such information in connection with an investigation or as otherwise required by law or in order to take legal action in consequence of a violation of these Terms and Conditions, the Privacy Policy or any other Site Rules.

8.3. Processing of personal data by us and sub-processors, which is subject to General Data Protection Regulation 2016/679, on behalf/instructions of our clients for the purposes of clients' use of the Software under these Terms and Conditions and under the End-User Agreement, when/if concluded, must be additionally agreed by Varys and its clients/customers in writing (Data Processing Agreement which shall be made an integral part of these Terms and Conditions, unless the Client/customer has entered into an End-User Agreement with Varys by way of signing the Order Form to it, in which case Data Processing Addendum forms a part of such agreement).

Varys acts as a processor in relation to its clients, meaning it only processes personal data of its clients' end-users following the clients' instructions under the Data Processing Addendum. Varys has no relationship with its clients' business partners (publishers, advertisers, and their agencies or representatives).

8.4. Client/customer of Varys (as a controller) acknowledges and agree that it shall fully comply with Art. 13 of the GDPR and provide the data subjects whose personal data is collected and processed through the Software on behalf of the client/customer with all the required information in its privacy policy.

Client/customer of Varys (as a controller) acknowledges and agree that it shall inform its end-users about the processing of their IP addresses in their respective privacy policies. This requirement applies to use of the Software if the client/customers choose to continue access and use of the Software.

Client/customer of Varys (as a controller) acknowledges and agrees that it shall inform its end-users about the use of cookies in their respective privacy policies and provide an opt-out. This requirement applies to the free trial period and the subsequent use of the Software if the client/customer chooses to continue access and use of the Software.

`,
	},
	{
		id: 9,
		title: 'GENERAL LEGAL PROVISIONS',
		content: `9.1. Site Content

The contents of the Site/Software, such as text, graphics, images, trademarks, service marks, logos and other material, (collectively “Content”), are protected by copyright and other intellectual property laws under the State of Israel, and foreign laws, and are owned by the Owner and Provider. Unauthorized use of the Content may violate copyright, trademark, and other laws. You may not sell or modify the Content or reproduce, display, publicly perform, distribute, create derivative works or otherwise use or exploit the Content in any way for any public or commercial purpose. The use of the Content on any other website or in a networked computer environment for any purpose is prohibited.

9.2. Limited warranty

The Content may contain inaccuracies or typographical errors. Owner (including Provider and other Owner's licensees) makes no representations about the accuracy, reliability, completeness, or timeliness of the Content or about the results to be obtained from using the Site and the Software. The use of the Site, the Software and the Content is at your own risk. Changes are periodically made to the Site / the Software and may be made at any time.

OWNER AND PROVIDER DO NOT WARRANT AND DO NOT GUARANTEE THAT (I) THE SITE AND/OR THE SOFTWARE WILL OPERATE ERROR-FREE OR MEET YOUR REQUIREMENTS; (II) THE SOFTWARE, THE SITE AND ITS SERVERS WILL BE UNINTERRUPTED, TIMELY, SECURE AND/OR FREE OF COMPUTER VIRUSES AND OTHER HARMFUL COMPONENTS; (III) THERE WILL BE ABSENCE OF ERRORS AND FAILURES IN THE OPERATION OF THE SITE AND/OR SOFTWARE, INCLUDING THE CORRECTNESS OF WORK OF THE SOFTWARE AND AVAILABILITY OF THE SYSTEM; (IV) THE DATA MADE AVAILABLE ON THE SITE AND/OR IN THE SOFTWARE IS ACCURATE, RELIABLE, CORRECT OR COMPLETE. IF YOUR USE OF THE SITE OR THE CONTENT RESULTS IN THE NEED FOR SERVICING OR REPLACING EQUIPMENT OR DATA, OWNER AND PROVIDER ARE NOT RESPONSIBLE FOR THOSE COSTS. THE SITE, SOFTWARE AND CONTENT ARE PROVIDED ON AN ‘AS IS' BASIS WITHOUT ANY WARRANTIES OF ANY KIND. OWNER AND PROVIDER, TO THE FULLEST EXTENT PERMITTED BY LAW, DISCLAIM ALL WARRANTIES.

9.3. Limitation of Liability

IN NO EVENT SHALL OWNER AND/OR PROVIDER, THEIR OFFICERS, DIRECTORS, EMPLOYEES, PARTNERS, LICENSORS, AND/OR SUPPLIERS BE LIABLE TO THE CLIENT AND/OR ANYONE ELSE FOR (I) SPECIAL, INCIDENTAL, INDIRECT, CONSEQUENTIAL AND/OR PUNITIVE DAMAGES WHATSOEVER, INCLUDING THOSE RESULTING FROM LOSS OF PROFITS, USE AND/OR DATA, BUSINESS INTERRUPTION, THE USE OR INABILITY TO USE THE SITE, THE SOFTWARE AND THE CONTENT, WHETHER OR NOT FORESEEABLE, REGARDLESS OF THE FORM OF ACTION, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT OWNER AND/OR PROVIDER HAVE/HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES IN ADVANCE; (II) AND/OR (II) ANY BUGS, VIRUSES, TROJAN HORSES, AND/OR THE LIKE (REGARDLESS OF THE SOURCE OF ORIGINATION).

LIMITATION ON AMOUNT OF LIABILITY. THE MAXIMUM AGGREGATE LIABILITY OF PROVIDER (INCLUDING OWNER) TO THE CLIENT FOR ALL DIRECT DAMAGES ARISING FROM THE SITE, THE SOFTWARE AND THE CONTENT IS LIMITED TO THE AMOUNT PAID BY THE CLIENT TO PROVIDER HEREUNDER DURING SIX MONTHS PRECEDING THE DATE OF THE CLAIM.

9.4. Indemnity

You agree to defend, indemnify, and hold harmless Owner, Provider and/or other Owner's licensees, its subsidiaries and affiliates, and each of their directors, officers, agents, contractors, partners and employees, from and against any loss, liability, claim, demand, damages, costs and expenses, including reasonable attorney's fees, arising out of or in connection with any content, data and/or materials you upload into the Site/Software or otherwise provide for processing by the Software, your use of the Software or the Site, or any violation by you of these Terms and Conditions and/or End-User Agreement or of any law or the rights of any third party.

9.5. User Submissions

You acknowledge and agree that any questions, comments, reviews, suggestions, ideas and feedback related to the Site/the Software and our team work (collectively, “Feedback”), provided by you in the form of email or other submissions to the Site, may be used, reused or shared by Owner/Provider to better serve you. The ideas and proposals made via Feedback are not an intellectual property of the User and/or Visitor. Owner/Provider shall be entitled to the unrestricted use and dissemination of Feedback for any purpose, excepting those uses and disseminations that violate the Privacy Policy.

All information and data uploaded by the User or retrieved, processed by the Software on behalf of the User shall remain the property of the User. User shall have a right to download, retrieve, copy, duplicate any of his/her data as stored on the Site or in/by the Software.

Owner/Provider has the right to use, on a royalty-free basis, the trademark (service mark) and/or the User's logo, as well as its name by posting on the Owner's service and in promotional materials and/or reference you as our customer during the term of the End-User Agreement and for a period of three (3) years after the termination of the End-User Agreement.

9.6. Links to Other Sites

The Site may contain links to third party websites. Owner/Provider is not responsible for the content of linked third-party sites and does not make any representation regarding the content, accuracy and/or reliability of materials on such third party websites. If you decide to access linked third-party website, you do so at your own risk. Owner/Provider reserves the right to terminate any link and/or linking program at any time in its sole and absolute discretion. Owner/Provider disclaims all warranties, express and implied, as to the accuracy, validity and legality and/or otherwise of any materials and/or information contained on such linked third-party sites.

9.7. Miscellaneous

These Terms and Conditions are governed by the laws of the State of Israel, without respect to its conflict of laws principles. If any provision of these Terms and Conditions is found to be invalid by any court having competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of these Terms and Conditions, which shall remain in full force and effect. No waiver of any provision of these Terms and Conditions shall be deemed a further or continuing waiver of such term or any other term. In the event of a legal dispute, it will be resolved according to dispute resolution procedures as provided in the End-User Agreement.

These Terms and Conditions are incorporated by reference into the End-User Agreement and together with it, Privacy Policy and other Site Rules, Data Processing Addendum (if applicable) and any order forms, exhibits, appendices, addenda or schedules attached to the End-User Agreement as well as the invoices to it constitute the entire agreement between you and Owner or Provider with respect to the use of the Site, the Software and the Content.

In the event of any conflict between the documents that make up the Agreement (as defined in the End-User Agreement), the following order of precedence will apply (in descending order): (1) the invoice, (2) the Order Form to the End-User Agreement, (3) the End-User Agreement, (4) these Terms and Conditions, (5) Privacy Policy. Notwithstanding anything to the contrary herein, if there is a conflict between the End-User Agreement, these Terms and Conditions, Privacy Policy and the Data Processing Addendum, the terms of the Data Processing Addendum shall control.
`,
	},
]

export default TermsAndConditions
