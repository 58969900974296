import { GetPasswordsInterface } from '../../models/model.interface'
import { ActionType } from '../action-types/index'
import { Action } from '../actions'

export interface passwordsReducerInterface {
	passwords: GetPasswordsInterface[] | any
}

const initialState: passwordsReducerInterface = {
	passwords: [],
}

const reducer = (
	state: passwordsReducerInterface | any = initialState,
	action: Action,
): passwordsReducerInterface => {
	switch (action.type) {
		case ActionType.GETPASSWORDS:
			return {
				...state,
				passwords: action.payload,
			}
		case ActionType.LOGOUT:
			return {
				...state,
				passwords: [],
			}
		default:
			return state
	}
}

export default reducer
