import React, { useEffect, useState } from 'react'
import { TitleComponent } from '../components/Informative'
import { UnborderedButton } from '../components/Buttons'
import { useTheme } from '@mui/material'

interface Guideline {
	title: string
	explanation: string
}
interface Intro {
	explanation: string
}
const Guidelines = (props: {
	data: Guideline[]
	closeFunc: Function
	intro?: Intro[]
	outro?: Intro[]
	timelines?: Guideline[]
	timelinesTable?: any
	tableTitle?: string
	timelinesTableMap?: any
}) => {
	const theme = useTheme()
	const [currentPage, setCurrentPage] = useState(0)
	const [headers, setHeaders] = useState<any>([])

	useEffect(() => {
		const result = []
		if (props.timelinesTableMap) {
			for (const key of Object.keys(props.timelinesTable[0])) {
				result.push(props.timelinesTableMap[key])
			}
			setHeaders(result)
		}
	}, [props.timelinesTableMap])

	return (
		<div
			style={{
				background: theme.colors.base.white,
				height: 'auto',
				width: '60vw',
				display: 'flex',
				position: 'absolute',
				zIndex: '20',
				borderRadius: '10px',
				top: '45%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
				border: '2px solid rgba(0,0,0,0.6)',
				justifyContent: 'center',
				alignItems: 'center',
				padding: '100px 80px 80px 80px',
				maxHeight: '60%',
				// overflow: 'auto',
			}}
		>
			{props.timelines ? (
				<div
					style={{
						display: 'flex',
						position: 'absolute',
						top: '4%',
						left: '74px',
						fontSize: theme.font.size.titles,
						gap: 20,
					}}
				>
					<UnborderedButton
						onClick={() => {
							setCurrentPage(0)
						}}
						style={{
							fontSize: 'inherit',
							color:
								currentPage === 0
									? theme.colors.chip.textActive
									: theme.colors.base.grey300,
						}}
						// style={{
						// 	fontSize: theme.font.size.titles,
						// 	display: 'flex',
						// 	gap: '8px',
						// 	position: 'absolute',
						// 	top: '4%',
						// 	left: '79px',
						// 	marginLeft: '0px',
						// }}
					>
						<span
							style={{
								color:
									currentPage === 0
										? theme.colors.chip.textActive
										: theme.colors.base.grey300,
							}}
						>
							Guidelines
						</span>
					</UnborderedButton>
					<UnborderedButton
						onClick={() => {
							setCurrentPage(1)
						}}
						style={{
							fontSize: 'inherit',
							color:
								currentPage === 1
									? theme.colors.chip.textActive
									: theme.colors.base.grey300,
						}}
						// style={{
						// 	fontSize: theme.font.size.titles,
						// 	display: 'flex',
						// 	gap: '8px',
						// 	position: 'absolute',
						// 	top: '4%',
						// 	left: '79px',
						// 	marginLeft: '0px',
						// }}
					>
						<span
							style={{
								color:
									currentPage === 1
										? theme.colors.chip.textActive
										: theme.colors.base.grey300,
							}}
						>
							Timelines
						</span>
					</UnborderedButton>
				</div>
			) : (
				<TitleComponent
					style={{
						fontSize: theme.font.size.titles,
						display: 'flex',
						gap: '8px',
						position: 'absolute',
						top: '4%',
						left: '79px',
						marginLeft: '0px',
					}}
				>
					Guidelines
				</TitleComponent>
			)}
			<button
				onClick={() => props.closeFunc()}
				style={{
					border: 'none',
					width: '2%',
					height: '2%',
					right: '30px',
					top: '12px',
					display: 'flex',
					position: 'absolute',
					fontSize: theme.font.size.body,
					color: theme.colors.text.titles,
					cursor: 'pointer',
				}}
			>
				X
			</button>
			{currentPage === 0 && (
				<div
					style={{
						display: 'flex',
						color: theme.colors.text.titles,
						flexDirection: 'column',
						gap: '34px',
						maxHeight: '60vh',
						overflow: 'auto',
						width: '100%',
						paddingTop: '30px',
					}}
				>
					{props.intro &&
						props.intro.map((guideline: Intro) => {
							return (
								<div
									style={{ display: 'flex' }}
									key={guideline.explanation.length}
								>
									<span
										style={{
											fontSize: theme.font.size.body,
											width: '100%',
											whiteSpace: 'pre-wrap',
											lineHeight: '24px',
										}}
									>
										{guideline.explanation}
									</span>
								</div>
							)
						})}
					{props.data.map((guideline: Guideline) => {
						return (
							<div style={{ display: 'flex' }} key={guideline.title}>
								<span
									style={{
										fontSize: theme.font.size.body,
										fontWeight: '500',
										color: theme.colors.base.grey600,
										width: '20%',
										textDecoration: 'underline',
										paddingRight: '100px',
									}}
								>
									{guideline.title}
								</span>
								<span
									style={{
										fontSize: theme.font.size.body,
										width: '80%',
										whiteSpace: 'pre-wrap',
										lineHeight: '24px',
									}}
								>
									{guideline.explanation}
								</span>
							</div>
						)
					})}
					{props.outro &&
						props.outro.map((guideline: Intro) => {
							return (
								<div
									style={{ display: 'flex' }}
									key={guideline.explanation.length}
								>
									<span
										style={{
											fontSize: theme.font.size.body,
											width: '100%',
											whiteSpace: 'pre-wrap',
											lineHeight: '24px',
										}}
									>
										{guideline.explanation}
									</span>
								</div>
							)
						})}
				</div>
			)}
			{currentPage === 1 && (
				<div
					style={{
						display: 'flex',
						color: theme.colors.text.titles,
						flexDirection: 'column',
						gap: '34px',
						maxHeight: '60vh',
						overflow: 'auto',
						width: '100%',
						paddingTop: '30px',
					}}
				>
					{props.timelines &&
						props.timelines.map((guideline: Guideline) => {
							return (
								<div style={{ display: 'flex' }} key={guideline.title}>
									<span
										style={{
											fontSize: theme.font.size.body,
											fontWeight: '500',
											color: theme.colors.base.grey600,
											width: '20%',
											textDecoration: 'underline',
											paddingRight: '100px',
										}}
									>
										{guideline.title}
									</span>
									<span
										style={{
											fontSize: theme.font.size.body,
											width: '80%',
											whiteSpace: 'pre-wrap',
											lineHeight: '24px',
										}}
									>
										{guideline.explanation}
									</span>
								</div>
							)
						})}
					{props.tableTitle && (
						<div
							style={{
								fontSize: theme.font.size.body,
								fontWeight: '500',
								color: theme.colors.base.grey400,
								width: '20%',
								textDecoration: 'underline',
								paddingRight: '100px',
								marginTop: 20,
							}}
							key={props.tableTitle}
						>
							{props.tableTitle}
						</div>
					)}
					<table
						style={{ fontSize: theme.font.size.body, borderSpacing: '0 10px' }}
					>
						<thead>
							<tr>
								{headers.map((header: any) => (
									<th
										key={header}
										style={{ textAlign: 'left', textDecoration: 'underline' }}
									>
										{header}
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{props.timelinesTable.map((row: any, index: number) => (
								<tr key={index}>
									{headers.map((header: any) => {
										const foundEntry = Object.entries(
											props.timelinesTableMap,
										).find(([key, val]) => val === header)

										return (
											foundEntry && <td key={header}>{row[foundEntry[0]]}</td>
										)
									})}
								</tr>
							))}
						</tbody>
					</table>
				</div>
			)}
		</div>
	)
}

export default Guidelines
