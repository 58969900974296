import { RootState } from '../reducers'
import { Permission } from '../reducers/permissionsReducer'
import { createSelector } from 'reselect'

const selectPermissions = (state: RootState) => state.permissions.permissions

export const selectPermissionsMap: any = createSelector(
	[selectPermissions],
	(permissions: Permission[]): Record<string, bigint> => {
		return permissions.reduce((map, permission) => {
			map[permission.name] = BigInt(permission.value)
			return map
		}, {} as Record<string, bigint>)
	},
)

export const selectPermissionNames = createSelector(
	[selectPermissions],
	(permissions: Permission[]): Record<string, string> => {
		return permissions.reduce((names, permission) => {
			names[permission.name] = permission.name
			return names
		}, {} as Record<string, string>)
	},
)
