import ReportPage from '../common/ReportPage'

import {
	getRefreshNetworkAction,
	saveAppsflyerAction,
} from '../../../state/action-creators'
import { getNetworksFiltersAction } from '../../../state/action-creators/reportActions'
import {
	ReportGrid,
	ReportItem,
	ReportItemHeading,
	StyledBox,
} from './ReportsStyled'
const NetworkPage = (props: {
	children?: any
	setLoading?: any
	errorMessage: string | null
	setErrorMessage: any
	isErrorPromptOpened: boolean
	setIsErrorPromptOpened: any
	setIsWarningPromptOpened: any
	setViewRecord: any
}) => {
	const initialReportFields = {
		from_date: '',
		to_date: '',
		breakdowns: [],
		statistics: [],
		filters: {
			app_id: [''],
			app_name: [''],
			media_source_pid: [''],
			platform: [''],
			pmd_af_prt: [''],
			agency: [''],
			username: [''],
		},
	}

	const filtersToShow = [
		'date_range',
		'platform',
		'agency',
		'app_id',
		'media_source_pid',
	]

	const breakdownOptions = [
		'Date',
		'Month',
		'Day',
		'Day of the Week',
		'App ID',
		'Media Source Pid',
		'Platform',
		'Agency',
		'Username',
	]

	const statisticOptions = ['Impressions', 'Clicks', 'Installs', 'CR']

	const reportType = 'network'

	const reportSelectors = (reports: any, email: string) =>
		reports.reports[email]?.network || []

	return (
		<ReportPage
			reportType={reportType}
			initialReportFields={initialReportFields}
			filtersToShow={filtersToShow}
			breakdownOptions={breakdownOptions}
			statisticOptions={statisticOptions}
			getReportAction={getNetworksFiltersAction}
			saveAction={saveAppsflyerAction}
			refreshAction={getRefreshNetworkAction}
			reportSelectors={reportSelectors}
			ReportGrid={ReportGrid}
			ReportStyles={{ StyledBox, ReportItem, ReportItemHeading }}
			{...props}
		/>
	)
}

export default NetworkPage
