import { TableCell, TableRow, Typography, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'

import {
	decryptContacts,
	filterContactsByType,
} from '../../utils/helpers/helperFuncs'
import { DropList } from '../components/SelectableInputs'
import { ChipInputList } from '../components/ChipInput'

const EmailElement = (props: {
	allPublishers: any
	allPublishersNames: string[]
	selectedAdvertiser: any
	reportSettings: any
	emailGroup: any
	setEmailGroup: any
	index: number
	app_id: any
}) => {
	const { login, accounts } = useTypedSelector((state) => state)
	const getOptionsList = () => {
		const options = props.allPublishers.filter(
			(pub: any) => pub.publisher_name === selectedPublisher[0],
		)
		const arr: string[] = [
			...options.map((el: any) => el.media_source_pid),
		].flat()
		return arr
	}
	const [selectedPublisherEntireObj, setSelectedPublisherEntireObj] =
		useState<any>([])
	const { getEventsByAppsAction } = useActions()
	const [eventList, setEventList] = useState<any>([])
	const [selectedPublisher, setSelectedPublisher] = useState(
		props.reportSettings.publisher ? [...props.reportSettings.publisher] : [],
	)
	const [agencyAccount, setAgencyAccount] = useState(
		props.reportSettings.agency_account
			? props.reportSettings.agency_account
			: [],
	)
	const [PIDList, setPIDList] = useState<string[]>(
		props.reportSettings.PID.length > 0 ? getOptionsList() : [],
	)
	const [selectedPIDList, setSelectedPIDList] = useState<string[]>(
		props.reportSettings.PID ? props.reportSettings.PID : [],
	)
	const [selectedReportType, setSelectedReportType] = useState<string[]>(
		props.reportSettings.report ? props.reportSettings.report : [''],
	)
	const [inappEventsChosen, setInappEventsChosen] = useState<string[]>(
		props.reportSettings.events ? props.reportSettings.events : [''],
	)
	const [emailsList, setEmailsList] = useState<string[]>([])
	const [selectedEmails, setSelectedEmails] = useState<string[]>(
		props.reportSettings.email ? props.reportSettings.email : [],
	)
	const [selectedReportTime, setSelectedReportTime] = useState<string>(
		props.reportSettings.timeframe ? props.reportSettings.timeframe : undefined,
	)
	const [periodForReportSending, setPeriodForReportSending] = useState<string>(
		props.reportSettings.repeat ? props.reportSettings.repeat : undefined,
	)
	const [decryptedContacts, setDecryptedContacts] = useState<any>([])

	const [agencyAccounts, setAgencyAccounts] = useState<string[]>([])
	// const headers = {
	// 	Authorization: `Token ${login.user.token}`,
	// }
	useEffect(() => {
		getEventsByAppsAction(props.app_id, setEventList)
	}, [])

	useEffect(() => {
		setSelectedPublisherEntireObj(
			props.allPublishers.filter(
				(pub: any) => pub.publisher_name === selectedPublisher[0],
			),
		)
		if (props.reportSettings.publisher[0] !== selectedPublisher[0]) {
			setPIDList([])
			setSelectedPIDList([])
			setEmailsList([])
			setSelectedEmails([])
			setInappEventsChosen([])
		}
	}, [selectedPublisher])

	useEffect(() => {
		const arr: string[] = [
			...selectedPublisherEntireObj.map((el: any) => el.media_source_pid),
		].flat()
		if (PIDList.length === 0 && selectedPIDList.length === 0) {
			setPIDList(arr)
			setSelectedPIDList(arr)
		}
		if (
			selectedPublisherEntireObj.length > 0 &&
			selectedPublisherEntireObj[0].contacts
		) {
			const decryptContactsData = async () => {
				try {
					const result = await decryptContacts(
						selectedPublisherEntireObj[0].contacts,
					)
					setDecryptedContacts(result)
				} catch (error) {
					console.error('Failed to decrypt contact information:', error)
				}
			}
			decryptContactsData()
		} else {
			if (emailsList.length === 0 && selectedEmails.length === 0) {
				setEmailsList([])
				selectedPublisherEntireObj[0]
					? setSelectedEmails(
							selectedPublisherEntireObj[0].email
								? [selectedPublisherEntireObj[0].email]
								: [],
					  )
					: setSelectedEmails([])
			}
		}
	}, [selectedPublisherEntireObj])

	useEffect(() => {
		if (decryptedContacts && selectedPublisherEntireObj[0]) {
			const advertiserOwner = props.selectedAdvertiser.email
			const contactsFromPublisher =
				decryptedContacts.length > 0
					? filterContactsByType(decryptedContacts, 'main')
					: []
			const arr = [
				advertiserOwner,
				...contactsFromPublisher,
				selectedPublisherEntireObj[0].email,
			]
			const uniqueArr = [...new Set(arr)]
				.sort()
				.filter((el: string) => el !== undefined)
			if (emailsList.length === 0 && selectedEmails.length === 0) {
				setEmailsList(uniqueArr)
				setSelectedEmails(uniqueArr)
			}
		}
	}, [decryptedContacts])

	useEffect(() => {
		const obj = {
			id: props.reportSettings.id,
			publisher: selectedPublisher
				? selectedPublisher
				: props.reportSettings.publisher,
			agency_account: agencyAccount
				? agencyAccount
				: props.reportSettings.agency_account,
			PID: selectedPIDList ? selectedPIDList : props.reportSettings.PID,
			email: selectedEmails ? selectedEmails : props.reportSettings.email,
			report: selectedReportType
				? selectedReportType
				: props.reportSettings.report,
			timeframe: selectedReportTime
				? selectedReportTime
				: props.reportSettings.timeframe,
			repeat: periodForReportSending
				? periodForReportSending
				: props.reportSettings.repeat,
			events: inappEventsChosen
				? inappEventsChosen
				: props.reportSettings.repeat,
		}
		const updatedInfo = props.emailGroup.map((el: any) => {
			if (el.id === obj.id) return obj
			return el
		})
		// console.log('updatedInfo', updatedInfo)
		props.setEmailGroup([obj])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		selectedPublisher,
		selectedPIDList,
		selectedEmails,
		selectedReportType,
		selectedReportTime,
		periodForReportSending,
		agencyAccount,
		inappEventsChosen,
	])

	const getAgencyAccountsOptions = () => {
		const accountNamesArr = []

		for (let i = 0; i < accounts.accounts.length; i++) {
			let id = accounts.accounts[i].identifier
			switch (id) {
				case 'weknowil870':
					accountNamesArr.push('weknowil870')
					break
				case 'tatanka':
					accountNamesArr.push('Tatanka')
					break
				case 'thingortwo':
					accountNamesArr.push('Thing or Two')
					break
				case 'smartassmeil951':
					accountNamesArr.push('SmartAss')
					break
			}
		}
		return accountNamesArr
	}

	useEffect(() => {
		setAgencyAccounts(getAgencyAccountsOptions())
	}, [])

	return (
		<div
			style={{
				width: '100%',
				gap: 40,
				display: 'flex',
				justifyContent: 'center',
				padding: '2% 0% 4% 0%',
			}}
		>
			<div
				style={{
					width: '50%',
					display: 'flex',
					flexDirection: 'column',
					gap: '18px',
				}}
			>
				<div>
					<ChipInputList
						style={{ width: 'inherit' }}
						value={agencyAccount}
						options={
							agencyAccounts && agencyAccounts.length
								? agencyAccounts
								: ['Tatanka', 'Thing or Two', 'SmartAss', 'weknowil870']
						}
						onChange={setAgencyAccount}
						ShowSelected
						openWithClick
						label={'Agency Account'}
						onlyOptionsAllowed
					/>
				</div>
				<div>
					<DropList
						value={selectedPublisher}
						onChange={(e: any) => {
							setSelectedPublisher([e])
						}}
						options={props.allPublishersNames || []}
						label={'Publisher'}
					/>
				</div>
				<div>
					<DropList
						value={selectedReportTime}
						onChange={(e: any) => setSelectedReportTime(e)}
						options={['Yesterday', 'Last 7 days', 'Start of the month']}
						label={'Timeframe'}
					/>
				</div>
				<div>
					<DropList
						label='Repeat'
						value={periodForReportSending}
						onChange={(e: any) => setPeriodForReportSending(e)}
						options={['Every day', 'Every 7 days', 'Every month']}
					/>
				</div>
				<div>
					<ChipInputList
						style={{ width: '100%' }}
						value={selectedEmails}
						options={emailsList}
						onChange={setSelectedEmails}
						ShowSelected
						openWithClick
						label={'Email'}
					/>
				</div>
			</div>
			<div
				style={{
					width: '50%',
					display: 'flex',
					flexDirection: 'column',
					gap: 40,
				}}
			>
				<div id={'test_anchor_input'}>
					<ChipInputList
						style={{ width: '100%', height: '120px' }}
						value={selectedPIDList}
						options={PIDList}
						onChange={setSelectedPIDList}
						singular={false}
						seeAll={false}
						ShowSelected
						openWithClick
						label={'PIDs'}
						inputStyle={{
							height: '120px',
							display: 'flex',
							position: 'relative',
							overflowY: 'auto',
						}}
						anchorId={'test_anchor_input'}
					/>
				</div>
				<div>
					<ChipInputList
						style={{ width: '100%', maxWidth: '100%' }}
						value={selectedReportType}
						onChange={setSelectedReportType}
						options={[
							'Raw - Installs',
							'Raw - Post attribution installs',
							'Raw - Blocked installs postback',
							'Raw - Post-attribution in-app events',
							'Aggregated by PID',
							'Aggregated by SITE ID',
						]}
						singular={true}
						ShowSelected
						openWithClick
						label={'Report'}
						inputStyle={{ minHeight: '80px' }}
					/>
				</div>
				{selectedReportType.includes(
					'Raw - Post-attribution in-app events',
				) && (
					<div>
						<ChipInputList
							style={{ width: '100%', maxWidth: '100%' }}
							value={inappEventsChosen}
							onChange={setInappEventsChosen}
							options={eventList}
							ShowSelected
							openWithClick
							label={'Event Names'}
						/>
					</div>
				)}
			</div>
		</div>
	)
}

export default EmailElement
