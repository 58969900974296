import React from 'react'
import {
	Card,
	CardContent,
	Typography,
	Grid,
	TextField,
	Button,
	useTheme,
} from '@mui/material'
import { InputFieldText } from './Inputs'
import { CountryListSelector, DropList } from './SelectableInputs'
import { ChipInputList } from './ChipInput'
import { countryList } from './countryList'

interface CardProps {
	title: string
	fields: any[]
}

const RegistrationCard = (props: CardProps) => {
	const { title, fields } = props
	const theme = useTheme()
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				padding: '10px 20px',
			}}
		>
			<Card
				sx={{
					maxWidth: 1000,
					width: 'auto',
					margin: 'auto',
					padding: 3,
					boxShadow: 3,
					borderRadius: 10,
					backgroundColor: theme.colors.base.grey,
				}}
			>
				<CardContent
					sx={{
						position: 'relative',
						textAlign: 'center',
						paddingX: 8, // Adds padding to the left and right sides of CardContent
						paddingY: 2, // Adds padding to the top and bottom of CardContent
					}}
				>
					<div
						style={{
							position: 'relative',
							display: 'inline-block',
							marginBottom: '20px',
							// marginTop: '5px'
						}}
					>
						<Typography variant='h5' align='center' gutterBottom>
							{title}
						</Typography>

						{/* Green underline */}
						<div
							style={{
								position: 'absolute',
								width: '100%',
								height: '0.563rem',
								borderTopLeftRadius: '1rem',
								borderTopRightRadius: '1rem',
								top: '100%',
								left: '50%',
								transform: 'translateX(-50%)',
								background: theme.colors.base.green200,
							}}
						></div>
					</div>

					<Grid container spacing={3} sx={{ marginTop: '20px' }}>
						{fields.map((field, index) => (
							<Grid
								item
								xs={12}
								sm={field.type === 'textarea' ? 11.6 : 4} // Full width for textarea
								key={index}
								sx={{ paddingX: 2 }}
							>
								{field.type === 'text' && (
									<InputFieldText
										fullWidth
										type='text'
										label={field.label}
										value={field.value}
										onChange={(e: any) => field.onChange(e)}
                                        mandatory={!!field.mandatory}
									/>
								)}
								{field.type === 'droplist' && (
									<DropList
										label={field.label}
										options={field.options.map((item: any) => item.label)}
										value={field.value}
										onChange={field.onChange}
                                        mandatory={!!field.mandatory}
									/>
								)}
								{field.type === 'textarea' && (
									<InputFieldText
										type='text'
										label={field.label}
										multiline
										fullWidth
										value={field.value}
										placeholder={field.placeholder}
										onChange={(e: any) => field.onChange(e)}
										sx={{
											height: '48px',
											padding: '12px',
										}}
                                        mandatory={!!field.mandatory}
									/>
								)}
								{field.type === 'chiplist' && (
									<ChipInputList
										onChange={(e: any) => field.onChange(e)}
										options={field.options || []}
										label={field.label}
										openWithClick={true}
										value={field.value}
										onlyOptionsAllowed={true}
                                        mandatory={!!field.mandatory}
										disableClearable={true}
									/>
								)}
								{field.type === 'country' && (
									<CountryListSelector
										label={field.label}
										options={countryList}
										value={field.value || []}
										onChange={(e: any) => field.onChange(e)}
										singular={false}
                                        mandatory={!!field.mandatory}
									/>
								)}
							</Grid>
						))}
					</Grid>
				</CardContent>
			</Card>
		</div>
	)
}

export default RegistrationCard
