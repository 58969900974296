import { PersonAdd } from '@mui/icons-material'
import { Container, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { PlusButton } from '../campaigns/CampaignStyled'
import NewsletterElement from './NewsletterElement'
import NewsletterElementOnlyText from './NewsletterElementOnlyText'
import NewsletterTitle from './NewsletterTitle'
import './newsletter-style.css'

const NewsletterContainer = (props: {
	mainObj: any
	editMode: boolean
	setDeleted: any
	deleteTitle: any
	login: any
	setErrorMessage: any
	index: number
	length: number
	// cardHoveredIndex: number
	// setCardHoveredIndex: any
}) => {
	const theme = useTheme()
	const [title, setTitle] = useState(props.mainObj.title || '')
	const [titles, setTitles] = useState(props.mainObj.titles || [])
	const [elements, setElements] = useState<any>(props.mainObj.elements || [])

	const addElement = (type: string) => {
		const newElement = {
			type: type,
			title: '',
			value: '',
			image: null,
			id: uuidv4(),
		}
		setElements((prev: any) => (prev ? [...prev, newElement] : [newElement]))
		props.mainObj.elements.push(newElement)
	}

	const deleteElement = (id: string) => {
		const deletedElement = elements.filter((el: any) => el.id === id)[0]
		const filteredElements = elements.filter((el: any) => el.id !== id)
		setElements(filteredElements)
		props.mainObj.elements = props.mainObj.elements.filter(
			(el: any) => el.id !== id,
		)
		if (deletedElement.image === 'uploaded') {
			props.setDeleted((prev: any) =>
				prev ? [...prev, deletedElement.id] : [deletedElement.id],
			)
		}
	}
	const getCardHeight = (index: number) => {
		const id = 'newsletter-card-' + index
		const cardHeight = document.getElementById(id)?.clientHeight
		return cardHeight
	}
	// const emojiHandler = (label: string) => {
	// 	const map = {
	// 		anniversar: '🎉',
	// 		birthday: '🎂',
	// 		open: '📚',
	// 		holiday: '🏝️',
	// 		promotion: '🎓',
	// 		employe: '👋',
	// 	}
	// 	for (const key in map) {
	// 		if (label.toLowerCase().includes(key)) {
	// 			return map[key as keyof typeof map]
	// 		}
	// 	}
	// }
	const orderEditor = (id: string, newIndex: number) => {
		let currentIndex
		for (let i = 0; i < elements.length; i++) {
			if (elements[i].id === id) {
				currentIndex = i
			}
		}
		//left -1, right +1
		const newArr = [...elements]
		;[newArr[currentIndex as number], newArr[newIndex]] = [
			newArr[newIndex],
			newArr[currentIndex as number],
		]
		setElements(newArr)
		props.mainObj.elements = newArr
	}
	return (
		<Container
			key={props.mainObj.id}
			className={
				props.editMode
					? ''
					: props.index === 0
					? 'newsletter-card'
					: 'newsletter-card not-first-card'
			}
			id={'newsletter-card-' + props.index}
			// onMouseEnter={() => props.setCardHoveredIndex(props.index)}
			style={{
				border: props.editMode ? '1px dashed ' + theme.colors.base.grey300 : '',
				borderRadius: '20px',
				color: theme.colors.text.titles,
				// boxShadow: `-2px 2px 0px 1px rgba(0,0,0,0.4), ${'0rem 0.6rem 1rem -0.5rem rgba(0,0,0,0.8)'}`,
				paddingTop: '16px',
				paddingBottom: '24px',
				marginLeft: '-24px',
				display: 'flex',
				width: '100%',
				flexDirection: 'column',
				// transitionDuration: '2000',
				marginTop: '0px',
				// zIndex: props.cardHoveredIndex === 0 ? props.length - props.index : props.cardHoveredIndex > props.index ? props.index : props.length - props.index
			}}
		>
			<NewsletterTitle
				value={title}
				setTitle={setTitle}
				mainObj={props.mainObj}
				editMode={props.editMode}
			></NewsletterTitle>
			<span
				style={{
					// display: 'flex',
					position: 'absolute',
					fontSize: `${Math.floor(getCardHeight(props.index)! * 0.6)}px`,
					zIndex: '-1',
					opacity: '0.1',
					marginTop: '10px',
					// top: '0%',
					right: '10%',
				}}
			>
				{/* {emojiHandler(title)} */}
			</span>
			{props.editMode && (
				<PlusButton
					style={{
						background: theme.colors.base.red300,
						margin: '0px',
						left: '99%',
						top: '-20px',
						marginTop: '-20px',
						width: '20px',
						height: '20px',
						paddingTop: '4px',
					}}
					onClick={() => props.deleteTitle(props.mainObj.id)}
				>
					x
				</PlusButton>
			)}
			<div
				style={{
					display: 'grid',
					gridTemplateColumns: '20% 20% 20%',
					width: 'inherit',
					columnGap: '80px',
					marginLeft: '-32px',
					gridTemplateRows: 'repeat(auto, 100px)',
				}}
			>
				{elements &&
					elements.map((el: any, index: number) => {
						if (el.type === 'image') {
							return (
								<React.Fragment key={'newsletterGroup' + el.id + 'fragment'}>
									<NewsletterElement
										title={el.title}
										value={el.value}
										image={el.image}
										imageSrc={el.imageSrc || null}
										id={el.id}
										index={index}
										elements={elements}
										setElements={setElements}
										mainObj={props.mainObj}
										editMode={props.editMode}
										handleDeleteElement={deleteElement}
										setErrorMessage={props.setErrorMessage}
										key={'newsletterGroup' + el.id}
										orderEditor={orderEditor}
										length={elements.length}
									/>
								</React.Fragment>
							)
						}
						if (el.type === 'text') {
							return (
								<React.Fragment
									key={'elementTextOnlyBlock' + el.id + Date.now() + 'fragment'}
								>
									<NewsletterElementOnlyText
										titles={titles}
										setTitles={setTitles}
										value={el.value}
										id={el.id}
										index={index}
										mainObj={props.mainObj}
										editMode={props.editMode}
										handleDeleteElement={deleteElement}
										key={'elementTextOnlyBlock' + el.id + Date.now()}
										orderEditor={orderEditor}
										length={elements.length}
									/>
								</React.Fragment>
							)
						} else
							return <React.Fragment key={'frag-' + index}></React.Fragment>
					})}
				{props.editMode && (
					<div style={{ marginLeft: '35px' }}>
						<button
							onClick={() => addElement('image')}
							style={{
								opacity: '0.8',
								height: '109px',
								marginLeft: elements[elements.length - 1]?.image
									? '0px'
									: '50px',
								width: '140px',
							}}
						>
							<PersonAdd style={{ scale: '1.6' }} />
						</button>
						<button
							onClick={() => addElement('text')}
							style={{
								opacity: '0.8',
								height: '109px',
								marginLeft: elements[elements.length - 1]?.image
									? '0px'
									: '50px',
								width: '140px',
								color: theme.colors.text.titles,
							}}
						>
							ADD TEXT ONLY
						</button>
					</div>
				)}
			</div>
		</Container>
	)
}

export default NewsletterContainer
