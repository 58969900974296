import { NetworksInterface } from '../../models/model.interface'
import { ActionType } from '../action-types/index'
import { Action } from '../actions'

export interface NetworksReducerInterface {
	networks: NetworksInterface | any
}

const initialState: NetworksReducerInterface = {
	networks: [],
}

const reducer = (
	state: NetworksReducerInterface = initialState,
	action: Action,
): NetworksReducerInterface => {
	switch (action.type) {
		case ActionType.GET_NETWORK: {
			return {
				...state,
				networks: action.payload,
			}
		}
		default: {
			return state
		}
	}
}

export default reducer
